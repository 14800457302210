const PREFIX = 'user'

const ROLE = `${PREFIX}Role`
const IS_ACTIVE = `${PREFIX}IsActive`
const B2B_CONTACTS = `${PREFIX}B2BContacts`
const B2B_CONTACTS_FILTER = `${PREFIX}B2BContactsFilter`
const B2B_ROLES = `${PREFIX}B2BRoles`
const SUPPORT_USERS = `${PREFIX}SupportUsers`

export const KEYS_USER = {
  ROLE,
  IS_ACTIVE,
  B2B_CONTACTS,
  B2B_CONTACTS_FILTER,
  B2B_ROLES,
  SUPPORT_USERS,
}
