import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { FORM_ITEMS } from '@/constants/formItems'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { MAX_ALLOWED_TEXT_AREA_CHARACTERS } from '@/constants/constants'

let itemComments = {
  keyName: KEYS_RFQ.COM.COM_COMMENTS,
  singleLevelObject: true,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.COMMENTS],
  formType: FORM_ITEMS.TYPES.TEXTAREA,
  value: '',
  maxLength: MAX_ALLOWED_TEXT_AREA_CHARACTERS,
  validationTypes: [],
  comments: null,
  messages: {
    label: '',
    placeholder: 'Write a comment',
  },
}

let itemCommentsCustomer = {
  keyName: KEYS_RFQ.COM.COM_COMMENTS_CUSTOMER,
  singleLevelObject: true,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.NOTES_SALES],
  formType: FORM_ITEMS.TYPES.TEXTAREA,
  value: '',
  maxLength: MAX_ALLOWED_TEXT_AREA_CHARACTERS,
  validationTypes: [],
  comments: null,
  messages: {
    label: '',
    placeholder: 'Write a comment',
  },
}

let itemCommentsSupplier = {
  keyName: KEYS_RFQ.COM.COM_COMMENTS_SUPPLIER,
  singleLevelObject: true,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.COMMENTS],
  formType: FORM_ITEMS.TYPES.TEXTAREA,
  value: '',
  maxLength: MAX_ALLOWED_TEXT_AREA_CHARACTERS,
  validationTypes: [],
  comments: null,
  messages: {
    label: '',
    placeholder: 'Write a comment',
  },
}

let itemCommentsInternal = {
  keyName: KEYS_RFQ.COM.COM_COMMENTS_INTERNAL,
  singleLevelObject: true,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.NOTES_FOR_ME],
  formType: FORM_ITEMS.TYPES.TEXTAREA,
  value: '',
  maxLength: MAX_ALLOWED_TEXT_AREA_CHARACTERS,
  validationTypes: [],
  comments: null,
  messages: {
    label: '',
    placeholder: 'Write a comment',
  },
}

let itemCommentsInternalSupplierExpert = {
  keyName: KEYS_RFQ.COM.COM_INTERNAL_COMMENTS_BOSCH,
  singleLevelObject: true,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.INTERNAL_COMMENTS_BOSCH],
  formType: FORM_ITEMS.TYPES.TEXTAREA,
  value: '',
  maxLength: 1000,
  validationTypes: [],
  comments: null,
  messages: {
    label: '',
    placeholder: 'Write a comment',
  },
}

let itemCommentsBoschSupplierComments = {
  keyName: KEYS_RFQ.COM.COM_BOSCH_SUPPLIER_COMMENTS,
  singleLevelObject: true,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.OFFER_COMMENTS],
  formType: FORM_ITEMS.TYPES.TEXTAREA,
  value: '',
  maxLength: 1000,
  validationTypes: [],
  comments: null,
  messages: {
    label: '',
    placeholder: 'Write a comment',
  },
}

export let rfqCommunicationTypes = {
  [itemComments.keyName]: itemComments,
  [itemCommentsCustomer.keyName]: itemCommentsCustomer,
  [itemCommentsSupplier.keyName]: itemCommentsSupplier,
  [itemCommentsInternal.keyName]: itemCommentsInternal,
  [itemCommentsInternalSupplierExpert.keyName]:
    itemCommentsInternalSupplierExpert,
  [itemCommentsBoschSupplierComments.keyName]:
    itemCommentsBoschSupplierComments,
}
