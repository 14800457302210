import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { AXIOS } from '@/constants/axios'
import { VALIDATION_TYPE } from '@/constants/validation'
import { FORM_ITEMS } from '@/constants/formItems'
import { FILE_TYPES } from '@/constants/fileTypes'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { PLACEHOLDER } from '@/constants/constants'
import { INFO_ANCHORS } from '@/constants/anchorTags/infoAnchorTags'

let itemOfferUploadFile = {
  keyName: KEYS_RFQ.OFFER_UPLOAD.OFFER_UPLOAD_FILE,
  inputType: FORM_ITEMS.INPUT_TYPE.FILE,
  value: '',
  required: false,
  invalid: false,
  export: false,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.OFFER_UPLOAD.OFFER_UPLOAD_FILE,
  ],
  targetId: INFO_ANCHORS.QUOTATION_UPLOAD.QUOTATION_FILE,
  file: {
    type: FILE_TYPES.OFFER_UPLOAD,
    name: null,
    url: {
      targetPlaceholderId: PLACEHOLDER.ID.OFFER,
      upload: AXIOS.API.POST.UPLOAD_OFFER_FILE,
      download: AXIOS.API.GET.DOWNLOAD_OFFER_FILE,
    },
    formats: [FILE_TYPES.FORMATS.ZIP, FILE_TYPES.FORMATS.SEVEN_ZIP],
  },
  fileType: FILE_TYPES.OFFER_UPLOAD,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  messages: {
    placeholder: 'No file chosen',
    error: 'Please select a file',
    label: '',
    notes: 'Accepted formats',
    status: '',
  },
}

export let rfqOfferUploadTypes = {
  [itemOfferUploadFile.keyName]: itemOfferUploadFile,
}
