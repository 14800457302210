<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <li
      v-for="item in breadcrumbs"
      :key="item"
      :class="getBreadcrumbClass(item)"
    >
      <router-link
        :to="item.active ? '' : item.path"
        :id="this.getBreadcrumbsAnchorTagMixin(item.anchorTag)"
      >
        {{ item.name }}
      </router-link>
    </li>
  </CBreadcrumb>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'
import anchorTagsMixins from '@/mixins/anchorTagsMixins'

export default {
  name: 'AppBreadcrumb',
  mixins: [anchorTagsMixins],
  setup() {
    const breadcrumbs = ref()

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active: route.name === router.currentRoute.value.name,
          name: route.meta.title,
          path: `${router.options.history.base}${route.path}`,
          anchorTag: route.meta.anchorTag ? route.meta.anchorTag : 'Home',
        }
      })
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
  methods: {
    getBreadcrumbClass(item) {
      return 'breadcrumb-item ' + (item.active ? 'active' : '')
    },
  },
}
</script>

<style scoped>
.breadcrumb-item a {
  text-decoration: none;
  color: rgb(44, 56, 74);
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item.active a {
  text-decoration: none;
  color: rgba(44, 56, 74, 0.681);
  cursor: default;
}
</style>
