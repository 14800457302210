const PREFIX = 'modal'

export const MODALS = {
  ADD_NEW_ADDRESS: `${PREFIX}AddNewAddress`,
  DELETE_ADDRESS: `${PREFIX}DeleteAddress`,
  CHANGE_REQUEST: `${PREFIX}ChangeRequest`,
  DECLINE_QUOTE: `${PREFIX}DeclineQuote`,
  QUOTE_SAMPLE_MAIL: `${PREFIX}QuoteSampleMail`,
  CANCEL_RFQ: `${PREFIX}CancelRFQ`,
  DELETE_DRAFT: `${PREFIX}DeleteDraft`,
  VIEW_REJECTION: `${PREFIX}ViewRejection`,
  VIEW_CHANGE_REQUEST: `${PREFIX}ViewChangeRequest`,
  MANAGE_USER: `${PREFIX}ManageUser`,
  DELETE_USER: `${PREFIX}DeleteUser`,
  SELECT_DELIVERY_LOCATION: `${PREFIX}SelectDeliveryLocation`,
  SELECT_USER: `${PREFIX}SelectUser`,
  PRICE_INDICATION_INFO: `${PREFIX}PriceIndicationInfo`,
  DELETE_DELIVERY_LOCATION: `${PREFIX}DeleteDeliveryLocation`,
  UNLOCK_RFQ: `${PREFIX}UnlockRFQ`,
  SELECT_SUPPLIER: `${PREFIX}SelectSupplier`,
  ADJUST_DELIVERY_LOCATION: `${PREFIX}AdjustDeliveryLocation`,
  REJECT_RFQ: `${PREFIX}RejectRFQ`,
  REJECT_OFFER: `${PREFIX}RejectOffer`,
  PRICING_DELIVERY_STRATEGY: `${PREFIX}PricingDeliveryStrategy`,
  ERROR_HANDLING: `${PREFIX}ErrorHandling`,
  SET_NEW_DUE_DATE_FOR_RFQ: `${PREFIX}SetNewDueDateForRfQ`,
  RECALL_RFQ: `${PREFIX}RecallRfQ`,
  REQUEST_REWORK_RFQ: `${PREFIX}RequestReworkRfQ`,
  ADD_NEW_SUPPLIER: `${PREFIX}AddNewSupplier`,
  CLARIFICATION_REQUEST: `${PREFIX}ClarificationRequest`,
  REQUEST_SUPPLIER_EXPERT_REWORK_RFQ: `${PREFIX}RequestSupplierExpertReworkRfQ`,
  DELETE_LOGISTIC_COST_DATA: `${PREFIX}DeleteLogisticCostData`,
  PROVIDE_RFQ_INFORMATION: `${PREFIX}ProvideRfQInformation`,
}

function getModalStatus() {
  let modalStatus = {}
  Object.values(MODALS).forEach((entry) => {
    modalStatus[entry] = false
  })
  return modalStatus
}

export const MODALS_STATUS = getModalStatus()
