import { VALIDATION_TYPE } from '@/constants/validation'
import { deliveryObject } from '@/constants/deliveries'
import cloneDeep from 'lodash/cloneDeep'
import validationMixins from '@/mixins/validationMixins'
import { RFQ_TYPES } from '@/constants/constants'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'

export default {
  mixins: [validationMixins],
  methods: {
    getTotalQuantityMixin(data, rfqYears, formatNumber = true) {
      let sum = 0
      data.forEach((delivery) => {
        sum += delivery.sop.slice(0, parseInt(rfqYears)).reduce((sum, x) => {
          return parseFloat(sum) + parseFloat(x)
        }, 0)
        sum += parseFloat(delivery.samples)
      })
      return formatNumber ? this.numberFormatMixin(sum) : sum
    },
    getRowQuantityMixin(sop, samples, rfqYears, formatNumber = true) {
      const sum =
        sop.slice(0, parseInt(rfqYears)).reduce((sum, x) => {
          return parseFloat(sum) + parseFloat(x)
        }, 0) + parseFloat(samples)
      return formatNumber ? this.numberFormatMixin(sum) : sum
    },
    getAnnualForecastQuantityMixin(data, formatNumber = true) {
      let sum = 0
      data.forEach((delivery) => {
        sum += Number(delivery.annualForecast)
      })
      return formatNumber ? this.numberFormatMixin(sum) : sum
    },
    getHighestColQuantityMixin(
      data,
      years,
      includeSamples = true,
      formatNumber = false,
    ) {
      let highestQuantity = 0
      if (includeSamples) {
        highestQuantity = this.getColQuantityMixin(data, 0, true, false)
      }
      let colQuantity = 0
      for (let i = 0; i < years; i++) {
        colQuantity = this.getColQuantityMixin(data, i, false, false)
        if (highestQuantity < colQuantity) {
          highestQuantity = colQuantity
        }
      }
      return formatNumber
        ? this.numberFormatMixin(highestQuantity)
        : highestQuantity
    },
    getColQuantityMixin(
      data,
      position,
      isSamples = false,
      formatNumber = true,
    ) {
      let sum = 0
      if (isSamples) {
        data.forEach((delivery) => {
          sum += Number(delivery.samples)
        })
      } else {
        data.forEach((delivery) => {
          sum += Number(delivery.sop[position])
        })
      }
      return formatNumber ? this.numberFormatMixin(sum) : sum
    },
    updateAnnualForecastMixin(event, delivery, isIndividualScenario = false) {
      let sanitizedInput = this.sanitizeInputMixin(
        event.inputEvent.target.value,
        {
          validationTypes: [VALIDATION_TYPE.INTEGER],
          keyName: event.keyName,
          targetId: event.targetId,
          value: delivery.data[event.deliveryPosition].annualForecast,
          validationSettings: {
            formatNumber: true,
          },
          hiddenRangeCap: true,
        },
      )
      if (isIndividualScenario) {
        delivery.individualScenarios.annualForecast[
          delivery.data[event.deliveryPosition].location.value
        ] = sanitizedInput
      } else {
        delivery.data[event.deliveryPosition].annualForecast = sanitizedInput
      }
      this.validateDeliveryLocationsMixin(delivery)
      return delivery
    },
    updateSamplesMixin(event, delivery) {
      delivery.data[event.deliveryPosition].samples = this.sanitizeInputMixin(
        event.inputEvent.target.value,
        {
          validationTypes: [VALIDATION_TYPE.INTEGER],
          keyName: event.keyName,
          targetId: event.targetId,
          value: delivery.data[event.deliveryPosition].samples,
          validationSettings: {
            formatNumber: true,
          },
          hiddenRangeCap: true,
        },
      )
      this.validateDeliveryLocationsMixin(delivery)
      return delivery
    },
    updateSopMixin(event, delivery) {
      delivery.data[event.deliveryPosition].sop[event.position] =
        this.sanitizeInputMixin(event.inputEvent.target.value, {
          validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
          keyName: event.keyName,
          targetId: event.targetId,
          value: delivery.data[event.deliveryPosition].sop[event.position],
          validationSettings: {
            formatNumber: true,
          },
          hiddenRangeCap: true,
        })
      this.validateDeliveryLocationsMixin(delivery)
      return delivery
    },
    addLocationToDeliveryMixin(newLocationData, delivery) {
      delivery.data.push(cloneDeep(deliveryObject))
      newLocationData.index = delivery.data.length - 1
      return this.updateAvailableLocationsMixin(newLocationData, delivery)
    },
    initializeTranslatedLocationsMixin(data) {
      let newLocationsData = []
      if (data !== undefined) {
        data.forEach((address) => {
          let label =
            address[API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.CATEGORY] &&
            address[API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.CATEGORY][
              API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.NICKNAME
            ]
              ? address[API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.CATEGORY] &&
                address[API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.CATEGORY][
                  API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.NICKNAME
                ]
              : `${address.city}`

          newLocationsData.push({
            label: label,
            value: address.id,
            isAvailable: true,
          })
        })
      }
      return newLocationsData
    },
    initializeAvailableLocationsMixin(locations, importedData) {
      let availableLocations = []
      if (
        importedData !== null &&
        importedData.deliveries &&
        importedData.deliveries.length
      ) {
        const deliveries = importedData.deliveries
        locations.forEach((currentLocation) => {
          let foundLocation = false
          for (let i = 0; i < deliveries.length; i++) {
            if (deliveries[i].locations) {
              for (let k = 0; k < deliveries[i].locations.length; k++) {
                if (
                  deliveries[i].locations[k].customerAddressId ===
                  currentLocation.value
                ) {
                  foundLocation = true
                  break
                }
              }
            }
            if (foundLocation) {
              break
            }
          }
          if (!foundLocation) {
            availableLocations.push(currentLocation)
          }
        })
      } else {
        locations.forEach((locations) => {
          availableLocations.push(locations)
        })
      }
      return availableLocations
    },

    updateAvailableLocationsMixin(
      newLocationData,
      delivery,
      deleteLocation = false,
    ) {
      if (newLocationData !== undefined) {
        if (deleteLocation) {
          delivery.data.splice(newLocationData, 1)
        } else {
          delivery.data[newLocationData.index].location =
            newLocationData.location
        }

        delivery.locations.forEach((location, locationIndex) => {
          location.isAvailable = true
          delivery.data.forEach((deliveryData) => {
            if (deliveryData.location.value === location.value) {
              location.isAvailable = false
              delivery.locations[locationIndex] = location
            }
          })
        })
      }

      let newLocationsArray = []
      delivery.locations.forEach((location) => {
        if (location.isAvailable) {
          newLocationsArray.push({
            value: location.value,
            label: location.label,
          })
        }
      })

      delivery.availableLocations = newLocationsArray
      delivery.activeLocations = delivery.data.length
      this.validateDeliveryLocationsMixin(delivery)
      return delivery
    },
    getYearsLabelMixin(index, sop, rfqType, seriesPrice = false) {
      switch (rfqType) {
        case RFQ_TYPES.YEARS:
          if (index) {
            return this.$store.getters.getActivePermissionGroup ===
              PERMISSION_GROUPS.SUPPLIER.VALUE
              ? `SOP + ${index}`
              : `${sop + index}`
          } else {
            return `SOP ${sop}`
          }
        case RFQ_TYPES.SCENARIOS:
          return `Scenario ${index + 1}`
        case RFQ_TYPES.LOT_SIZES:
          if (seriesPrice) {
            return 'Series Price'
          } else {
            return `Lot size ${index + 1}`
          }
      }
    },
    updateDeliveryLotSizesMixin(event) {
      this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SPECIFIED_LOT_SIZES].data[
        event.index
      ] = parseInt(
        this.sanitizeInputMixin(event.value, {
          validationTypes: [VALIDATION_TYPE.INTEGER],
          keyName: event.keyName,
          targetId: event.targetId,
          value:
            this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SPECIFIED_LOT_SIZES].data[
              event.index
            ],
          validationSettings: {
            formatNumber: true,
          },
          hiddenRangeCap: true,
        }),
      )
    },
    addIndividualScenariosMixin(rfqType, delivery, years) {
      switch (rfqType) {
        case RFQ_TYPES.SCENARIOS:
          delivery[KEYS_GENERAL.INDIVIDUAL_SCENARIOS] = {
            samples: 0,
            scenarios: new Array(years).fill(0),
          }
          break
        case RFQ_TYPES.LOT_SIZES:
          delivery[KEYS_GENERAL.INDIVIDUAL_SCENARIOS] = {
            samples: 0,
            lotSizes: new Array(years).fill(0),
            annualForecast: {},
          }
          delivery.data.forEach((rowItem) => {
            delivery[KEYS_GENERAL.INDIVIDUAL_SCENARIOS].annualForecast[
              rowItem.location.value
            ] = 0
          })
          break
        case RFQ_TYPES.YEARS:
          delivery[KEYS_GENERAL.INDIVIDUAL_SCENARIOS] = {
            samples: 0,
            years: new Array(years).fill(0),
          }
          break
      }
    },
    deleteIndividualScenariosMixin(delivery) {
      delete delivery[KEYS_GENERAL.INDIVIDUAL_SCENARIOS]
    },
    updateIndividualScenariosMixin(event, delivery, rfqType) {
      let oldValue
      let targetKey
      if (event.sop) {
        switch (rfqType) {
          case RFQ_TYPES.SCENARIOS:
            targetKey = 'scenarios'
            break
          case RFQ_TYPES.LOT_SIZES:
            targetKey = 'lotSizes'
            break
          case RFQ_TYPES.YEARS:
            targetKey = 'years'
            break
        }
        oldValue = delivery.individualScenarios[targetKey][event.index]
      } else {
        oldValue = delivery.individualScenarios.samples
      }
      let individualScenarioItem = {
        validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
        keyName: delivery.keyName,
        targetId: event.targetId,
        value: oldValue,
        validationSettings: {
          formatNumber: true,
        },
        hiddenRangeCap: true,
      }
      let sanitizedValue = this.sanitizeInputMixin(
        this.restoreNumberFormatMixin(
          individualScenarioItem,
          event.newValue,
          true,
        ),
        individualScenarioItem,
      )
      if (event.sop) {
        delivery.individualScenarios[targetKey][event.index] = sanitizedValue
      } else {
        delivery.individualScenarios.samples = sanitizedValue
      }
    },
  },
}
