<template>
  <CModal
    :content-class-name="getModalAnchorTagMixin(MODAL_ANCHORS.ERROR_HANDLING)"
    :visible="visibleModal"
    @close="this.closeModal()"
    size="xl"
  >
    <CModalHeader class="e-alt-card-background" @click="this.closeModal()">
      <CModalTitle></CModalTitle>
    </CModalHeader>
    <CModalBody class="e-modal">
      <h2>Something went wrong</h2>
      <div class="e-modal-content mb-4">
        <CAlert color="danger" class="e-alert-box">
          <span v-if="IS_DEV_MODE">
            {{ this.$store.getters.getAxiosData.errorMessage }}
            <hr />
            <pre class="pre-scrollable e-code-block">
              <code>
                {{ this.getErrorMessage() }}
              </code>
            </pre>
          </span>
          <span v-else class="e-alert-box">
            {{ this.$store.getters.getAxiosData.errorMessage }}
          </span>
        </CAlert>
      </div>
      <CAccordion
        v-if="IS_DEV_MODE && commandItems.length"
        class="e-additional-info"
        :style="{
          '--cui-accordion-body-padding-x': 0,
          '--cui-accordion-body-padding-y': 0,
        }"
      >
        <CAccordionItem>
          <CAccordionHeader
            ><h2 class="m-0">Additional debug info</h2></CAccordionHeader
          >
          <CAccordionBody>
            <CTable bordered small>
              <CTableBody>
                <CTableRow :key="index" v-for="(item, index) in commandItems">
                  <CTableHeaderCell
                    v-if="item.isCategory"
                    colspan="2"
                    color="dark"
                  >
                    <h3 class="m-2">{{ item.description }}</h3>
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    v-if="!item.isCategory"
                    scope
                    row="row"
                    class="px-2"
                    >{{ item.description }}</CTableHeaderCell
                  >
                  <CTableDataCell
                    v-if="!item.isCategory"
                    :class="item.codeBlock ? 'p-0' : ''"
                  >
                    <pre
                      v-if="item.codeBlock"
                      class="pre-scrollable e-code-block m-0"
                    ><code>
                  {{
                      item.value
                    }}
                </code></pre>
                    <span v-else>{{ item.value }}</span>
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </CModalBody>
    <CModalFooter>
      <CButton
        class="me-3"
        color="light"
        @click="this.closeModal()"
        :id="
          getModalActionAnchorTagMixin(
            MODAL_ANCHORS.ERROR_HANDLING,
            ACTION_ANCHORS.CANCEL,
          )
        "
        >Cancel</CButton
      >
      <CButton
        v-if="IS_DEV_MODE"
        color="primary"
        @click="this.createErrorLog()"
        :id="
          getModalActionAnchorTagMixin(
            MODAL_ANCHORS.ERROR_HANDLING,
            ACTION_ANCHORS.CREATE_ERROR_LOG,
          )
        "
        >Generate error logs</CButton
      >
    </CModalFooter>
  </CModal>
</template>

<script>
import anchorTagsMixins from '@/mixins/anchorTagsMixins'
import { MODAL_ANCHORS } from '@/constants/anchorTags/modalAnchorTags'
import { ACTION_ANCHORS } from '@/constants/anchorTags/actionAnchorTags'
import { RFQ_ACTIONS } from '@/constants/rfqActions'
import utilMixins from '@/mixins/utilMixins'
import { IS_DEV_MODE } from '@/constants/constants'
import dayjs from 'dayjs'
import { ROUTE_TYPES } from '@/constants/routerData'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ModalErrorHandling',
  emits: ['closed'],
  mixins: [anchorTagsMixins, utilMixins],
  props: {
    visibleModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      RFQ_ACTIONS,
      MODAL_ANCHORS,
      ACTION_ANCHORS,
    }
  },
  computed: {
    commandItems() {
      let command = this.$store.getters.getErrorLog.command
      let commandItems = []

      if (command) {
        commandItems.push({
          description: 'User',
          isCategory: true,
        })

        commandItems.push({
          description: 'ID',
          value: this.$store.getters.currentUserData.id,
        })

        commandItems.push({
          description: 'Permission group',
          value: this.$store.getters.getActivePermissionGroup,
        })

        commandItems.push({
          description: 'Role',
          value: this.$store.getters.currentUserData.customFields.isAdmin
            ? 'Admin'
            : 'User',
        })

        commandItems.push({
          description: 'App route',
          isCategory: true,
        })

        commandItems.push({
          description: 'Name',
          value: this.$route.name,
        })

        commandItems.push({
          description: 'Path',
          value: this.$route.path,
        })

        if (this.$route.meta.routeType) {
          commandItems.push({
            description: 'Type',
            value: Object.keys(ROUTE_TYPES)
              .filter((key) => ROUTE_TYPES[key] === this.$route.meta.routeType)
              .pop(),
          })
        }

        if (this.$store.getters.getActiveEntityData) {
          commandItems.push({
            description: 'Active entity (RfQ / Offer)',
            isCategory: true,
          })

          commandItems.push({
            description: 'Data',
            codeBlock: true,
            value: this.$store.getters.getActiveEntityData,
          })
        }

        commandItems.push({
          description: 'API call',
          isCategory: true,
        })

        let commandType
        if (command.action || command.file) {
          commandType = command.action ? 'CTA' : 'File'
        }
        if (commandType) {
          commandItems.push({
            description: 'Command type',
            value: commandType,
          })
        }
        if (command.action) {
          commandItems.push({
            description: 'Action',
            value: command.action.value,
          })
        }

        commandItems.push({
          description: 'Route',
          value: command.path,
        })

        if (command.type) {
          commandItems.push({
            description: 'Type',
            value: command.type,
          })
        }

        if (command.body) {
          commandItems.push({
            description: 'Body',
            codeBlock: true,
            value: command.body,
          })
        }
      }
      return commandItems
    },
    IS_DEV_MODE() {
      return IS_DEV_MODE
    },
  },
  methods: {
    closeModal() {
      this.$emit('closed', false)
    },
    getErrorMessage() {
      const error = this.$store.getters.getErrorLog.error
      let stackTraceMessage = ''
      if (error.response && Object.keys(error.response).length) {
        stackTraceMessage = JSON.stringify(error.response, undefined, 2)
      } else if (error.request && Object.keys(error.request).length) {
        stackTraceMessage = JSON.stringify(error.request, undefined, 2)
      } else if (error.stack) {
        stackTraceMessage = error.stack
      } else {
        stackTraceMessage = error.message
      }
      return stackTraceMessage
    },
    createErrorLog() {
      let zip = new JSZip()
      zip.file('stack-trace.json', this.getErrorMessage())
      let additionalDebugInfo = {}
      let activeCategory = {}
      let activeCategoryKey = ''
      this.commandItems.forEach((item) => {
        if (item.isCategory) {
          if (activeCategory && activeCategoryKey) {
            additionalDebugInfo[activeCategoryKey] = cloneDeep(activeCategory)
          }
          activeCategory = {}
          activeCategoryKey = item.description.replace(' ', '').toLowerCase()
        } else {
          activeCategory[item.description.replace(' ', '').toLowerCase()] =
            cloneDeep(item.value)
        }
      })
      additionalDebugInfo[activeCategoryKey] = cloneDeep(activeCategory)
      zip.file(
        'additional-info.json',
        JSON.stringify(additionalDebugInfo, undefined, 2),
      )
      zip
        .generateAsync({
          type: 'blob',
          compression: 'DEFLATE',
          compressionOptions: {
            level: 9,
          },
        })
        .then((content) => {
          saveAs(
            content,
            `error-log-${dayjs().format('DD-MMM-YYYY').toString()}.zip`,
          )
          this.closeModal()
        })
    },
  },
}
</script>

<style scoped>
.e-code-block {
  max-height: 450px;
  background-color: black;
  color: white;
}

.e-additional-info .accordion-body {
  padding: 0;
}
</style>
