import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import {
  APP_TITLE,
  DATE_FORMAT_SHORT,
  NOT_AVAILABLE,
  PANEL_TYPES,
} from '@/constants/constants'
import { STATES } from '@/constants/states'
import utilMixins from '@/mixins/utilMixins'
import numberMixins from '@/mixins/numberMixins'

export default {
  mixins: [utilMixins, numberMixins],
  computed: {
    CUSTOMER_SPECIFIED_PANEL_INDEX() {
      return PANEL_TYPES.CUSTOMER_SPECIFIED - 1
    },
    PROPOSED_PANEL_INDEX() {
      return PANEL_TYPES.PROPOSED - 1
    },
  },
  methods: {
    sortQuoteDetailsMixin: function (quote, isPriceIndication = false) {
      let sortedQuoteDetails = []
      if (quote.totalQuantity === null) {
        sortedQuoteDetails.push({
          value: NOT_AVAILABLE,
          rawValue: NOT_AVAILABLE,
        })
      } else {
        sortedQuoteDetails.push({
          value: this.numberFormatMixin(quote.totalQuantity),
          rawValue: quote.totalQuantity,
        })
      }

      if (quote.averageQuantity === null) {
        sortedQuoteDetails.push({
          value: NOT_AVAILABLE,
          rawValue: NOT_AVAILABLE,
        })
      } else {
        sortedQuoteDetails.push({
          value: this.numberFormatMixin(quote.averageQuantity),
          rawValue: quote.averageQuantity,
        })
      }

      if (isPriceIndication) {
        let midPoint =
          this.$props.rfq[KEYS_RFQ.PRICE_INDICATION_PREFIX][
            KEYS_RFQ.PRICE_INDICATION.MID_POINT_CUSTOMER
          ].value

        let upperPoint =
          this.$props.rfq[KEYS_RFQ.PRICE_INDICATION_PREFIX][
            KEYS_RFQ.PRICE_INDICATION.UPPER_BOUND_CUSTOMER
          ].value

        if (midPoint && upperPoint) {
          midPoint = midPoint.toFixed(2)
          upperPoint = upperPoint.toFixed(2)

          sortedQuoteDetails.push({
            value: `${this.numberFormatMixin(midPoint, {
              isActive: true,
              currency: 'USD',
            })} - ${this.numberFormatMixin(upperPoint, {
              isActive: true,
              currency: 'USD',
            })}`,
            rawValue: midPoint,
            rawValue2: upperPoint,
          })
        } else {
          sortedQuoteDetails.push({
            value: 'Not available for this project',
            rawValue: 'Not available for this project',
          })
        }
      } else {
        if (quote.averagePrice === null) {
          sortedQuoteDetails.push({
            value: NOT_AVAILABLE,
            rawValue: NOT_AVAILABLE,
          })
        } else {
          sortedQuoteDetails.push({
            value: this.numberFormatMixin(quote.averagePrice, {
              isActive: true,
              currency: 'USD',
            }),
            rawValue: quote.averagePrice,
          })
        }
      }

      if (!isPriceIndication) {
        if (quote.validUntil === null) {
          sortedQuoteDetails.push({
            value: NOT_AVAILABLE,
            rawValue: NOT_AVAILABLE,
          })
        } else {
          sortedQuoteDetails.push({
            value: quote.validUntil,
            rawValue: quote.validUntil,
          })
        }
      }
      return sortedQuoteDetails
    },
    gotPriceIndicationMixin(state) {
      if (state !== STATES.DRAFT.VALUE && state !== STATES.NEW.VALUE) {
        return (
          this.$props.rfq[KEYS_RFQ.PRICE_INDICATION_PREFIX][
            KEYS_RFQ.PRICE_INDICATION.MID_POINT_CUSTOMER
          ].value &&
          this.$props.rfq[KEYS_RFQ.PRICE_INDICATION_PREFIX][
            KEYS_RFQ.PRICE_INDICATION.UPPER_BOUND_CUSTOMER
          ].value
        )
      }
    },
    getQuoteBestRegardsTextMixin() {
      const user = this.$store.getters.currentUserData
      return `%0D%0A%0D%0ABest regards%0D%0A%0D%0A${
        user.title !== null ? `${user.title} ` : ''
      }${user.firstName} ${user.lastName}, ${user.company}`
    },
    getQuoteAddressMixin(targetAddress) {
      return `${targetAddress.city}, ${targetAddress.zipcode}, ${targetAddress.country.name}`
    },
    getQuoteDateMixin(date) {
      return this.getFormatedDateMixin(date, DATE_FORMAT_SHORT)
    },
    initializeQuotePanelsMixin(importedQuote) {
      let importedCustomerPanel = null
      let importedProposedPanel = null
      if (importedQuote.quotePanels) {
        importedQuote.quotePanels.forEach((quotePanel) => {
          switch (quotePanel.panelType) {
            case PANEL_TYPES.CUSTOMER_SPECIFIED:
              importedCustomerPanel = quotePanel
              break
            case PANEL_TYPES.PROPOSED:
              importedProposedPanel = quotePanel
              break
          }
        })
      }

      let newCustomerPanel = {
        active: this.isInternalPanelSelectionActiveMixin(
          this.$data.formInternalPanelSelection,
          PANEL_TYPES.CUSTOMER_SPECIFIED,
        ),
        panelType: PANEL_TYPES.CUSTOMER_SPECIFIED,
        id: importedCustomerPanel ? importedCustomerPanel.id : null,
      }

      let newProposedPanel = {
        active: this.isInternalPanelSelectionActiveMixin(
          this.$data.formInternalPanelSelection,
          PANEL_TYPES.PROPOSED,
        ),
        panelType: PANEL_TYPES.PROPOSED,
        id: importedProposedPanel ? importedProposedPanel.id : null,
      }
      return [newCustomerPanel, newProposedPanel]
    },
    getAcceptQuoteMailMixin(quote, quoteNumber, projectData) {
      const date = this.getQuoteDateMixin(quote.createdAt.date)
      window.open(
        `mailto:${
          process.env.VUE_APP_SUPPORT_MAIL
        }?subject=Congratulations to be nominated for ${
          projectData[KEYS_RFQ.PROJECT_DATA.PROJECT_NAME].value
        }, (Quote #${quoteNumber}), ${date}&body=Dear ${APP_TITLE},
        %0D%0A%0D%0Areferring to our RFQ for "${
          projectData[KEYS_RFQ.PROJECT_DATA.PROJECT_NAME].value
        }" and your quotation (Quote #${quoteNumber}), ${date}, we are pleased to inform you, that you have been nominated for respective project.
      %0D%0APlease note that this is not a binding order but pre-information subject to signed contracts.
      %0D%0ACongratulations and thank you for your continuous support.
      ${this.getQuoteBestRegardsTextMixin()}`,
        '_blank',
      )
    },
    getSamplesMailMixin(quote, quoteNumber, projectData, rfqId, modalData) {
      const date = this.getQuoteDateMixin(quote.createdAt.date)
      window.open(
        `mailto:${process.env.VUE_APP_SUPPORT_MAIL}?subject=Sample Order for ${
          projectData[KEYS_RFQ.PROJECT_DATA.PROJECT_NAME].value
        }, (Quote #${quoteNumber}), ${date}&body=Dear ${APP_TITLE},
      %0D%0A%0D%0Areferring to our RFQ for "${
        projectData[KEYS_RFQ.PROJECT_DATA.PROJECT_NAME].value
      }" and your quotation (Quote #${quoteNumber}), ${date} I herewith order ${
          modalData.number
        } samples according to RFQ ${rfqId} at quoted price. I am aware and herewith accept the quoted NRE charge, packaging charge and minimum order volume related to sample orders. As quoted, target location is ${this.getQuoteAddressMixin(
          quote.targetAddress,
        )}. Target delivery date is ${this.getQuoteDateMixin(
          modalData.date,
        )}. I am aware about additional charges on expedite and herewith accept additional charges in case due date is earlier than 30 days from now.
      %0D%0A%0D%0AThis sample order is not an awarding confirmation for the whole project.
      %0D%0APlease confirm and from your side with expected delivery date and if applicable expedite charges.
      ${this.getQuoteBestRegardsTextMixin()}`,
        '_blank',
      )
    },
    getDeclineMailMixin(quote, quoteNumber, projectData) {
      const date = this.getQuoteDateMixin(quote.createdAt.date)
      window.open(
        `mailto:${
          process.env.VUE_APP_SUPPORT_MAIL
        }?subject=Sorry for not being nominated for ${
          projectData[KEYS_RFQ.PROJECT_DATA.PROJECT_NAME].value
        }, (Quote #${quoteNumber}), ${date}&body=Dear ${APP_TITLE},
      %0D%0A%0D%0Areferring to our RFQ for "${
        projectData[KEYS_RFQ.PROJECT_DATA.PROJECT_NAME].value
      }" and your quotation (Quote #${quoteNumber}), ${date} we are sorry to inform you, that you have not been nominated for respective project.
      %0D%0AHowever, we thank you a lot for your effort during the quotation phase.
      %0D%0AIn case of question, please feel free to contact us.
      ${this.getQuoteBestRegardsTextMixin()}`,
        '_blank',
      )
    },
  },
}
