import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { CURRENCIES, CURRENCY_TYPES } from '@/constants/currency'

export default {
  computed: {
    KEYS_RFQ() {
      return KEYS_RFQ
    },
    CURRENCIES() {
      return CURRENCIES
    },
  },
  methods: {
    convertCurrencyValueMixin(
      value,
      currencyType = CURRENCY_TYPES.VALUE,
      nullOnMismatch = false,
    ) {
      switch (true) {
        case Object.values(CURRENCIES.USD).includes(value):
          return CURRENCIES.USD[currencyType]
        case Object.values(CURRENCIES.EUR).includes(value):
          return CURRENCIES.EUR[currencyType]
        case Object.values(CURRENCIES.CNY).includes(value):
          return CURRENCIES.CNY[currencyType]
      }
      if (nullOnMismatch) {
        return null
      }
      return value
    },
    getExchangeRateMixin() {
      let cny
      let eur
      if (this.$props.coqExchange) {
        cny =
          this.$props.coqExchange[
            KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE.COQ_CURRENCY_EXCHANGE_ONE
          ]
        eur =
          this.$props.coqExchange[
            KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE.COQ_CURRENCY_EXCHANGE_TWO
          ]
      }

      let decimalPlaces = 3
      if (
        cny &&
        cny.validationSettings &&
        cny.validationSettings.decimal &&
        cny.validationSettings.decimal.decimal_digits
      ) {
        decimalPlaces =
          parseInt(cny.validationSettings.decimal.decimal_digits) + 1
      }
      if (parseFloat(cny.value) && parseFloat(eur.value)) {
        //parseFloat the final result to remove trailing zeros
        return parseFloat(
          Number(parseFloat(cny.value) / parseFloat(eur.value)).toPrecision(
            decimalPlaces,
          ),
        )
      } else {
        return 'Invalid input'
      }
    },
    getCOQCurrencyMixin(currencyType = CURRENCY_TYPES.CURRENCY) {
      let coqCurrencyObject
      if (this.$props.coqExchange) {
        coqCurrencyObject =
          this.$props.coqExchange[
            KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE.DEFINE_COQ_CURRENCY
          ]
      } else {
        coqCurrencyObject =
          this.$data[KEYS_GENERAL.FORM][
            KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX
          ][KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE.DEFINE_COQ_CURRENCY]
      }
      return this.convertCurrencyValueMixin(
        coqCurrencyObject.value,
        currencyType,
      )
    },
    convertCurrencyIdToValueMixin(currencyId, convertValue = true) {
      if (currencyId) {
        let value = this.$store.getters.currencies
          .filter((currency) => currency.id === currencyId)[0]
          .translated.shortName.toLowerCase()
        if (convertValue) {
          return this.convertCurrencyValueMixin(value)
        } else {
          return value
        }
      } else {
        return '-'
      }
    },
    getCurrencyIdMixin(value, convertValue = true) {
      if (value === CURRENCIES.NONE.VALUE || value === CURRENCIES.NONE.LABEL) {
        return null
      } else {
        return this.$store.getters.currencies.filter((currency) => {
          if (convertValue) {
            return (
              currency.translated.shortName.toLowerCase() ===
              this.convertCurrencyValueMixin(value)
            )
          } else {
            return currency.translated.shortName.toLowerCase() === value
          }
        })[0].id
      }
    },
  },
}
