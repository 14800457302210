import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import {
  API_OBJECT_GROUPS,
  API_OBJECT_KEY_NAMES,
} from '@/constants/apiObjectKeyNames'
import { IMPORT_EXPORT } from '@/constants/importExport'
import { FORM_ITEMS } from '@/constants/formItems'

export let itemQuotations = {
  keyName: KEYS_RFQ.QUOTATIONS,
  apiObjectGroup: API_OBJECT_GROUPS.QUOTATIONS,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS.PREFIX],
  parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
  formType: FORM_ITEMS.TYPES.QUOTATION,
  singleLevelObject: true,
  required: true,
  inputMin: 0.0001,
  inputMax: 10000,
  validationSettings: {
    formatNumber: false,
    decimal: { decimal_digits: '4' },
  },
  data: [],
  messages: {
    placeholder: 'Please enter',
  },
}
