import {
  FORM_ITEMS,
  FORM_TYPES_WITH_SUB_FORM_DATA,
} from '@/constants/formItems'
import { passwordCheckTypes } from '@/assets/inputDataObjects/types/passwordCheck'
import { passwordCheckList } from '@/assets/inputDataObjects/misc/passwordChecklist'
import { KEYS_REGISTRATION } from '@/constants/objectKeys/registration'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import isStrongPassword from 'validator/es/lib/isStrongPassword'
import isEmail from 'validator/es/lib/isEmail'
import {
  VALIDATION_TYPE,
  VALIDATION_TYPES_WITH_RANGE,
} from '@/constants/validation'
import isInt from 'validator/es/lib/isInt'
import { ROUTE_TYPES, ROUTER_DATA } from '@/constants/routerData'
import { FILE_TYPES } from '@/constants/fileTypes'
import sum from 'lodash/sum'
import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import {
  FILE_TRANSFER_STATUS,
  MAX_ALLOWED_NUMBER,
  MAX_ALLOWED_STRING_LENGTH,
  MAX_ALLOWED_TEXT_AREA_CHARACTERS,
  OUT_OF_RANGE_VALUE_ERROR_MESSAGE,
  PLACEHOLDER,
  QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES,
  RFQ_TYPES,
} from '@/constants/constants'
import {
  AUTO_CORRECT_OUT_OF_RANGE,
  AUTO_CORRECT_VALUE,
} from '@/constants/autoCorrectSettings'
import utilMixins from '@/mixins/utilMixins'
import routeMixins from '@/mixins/routeMixins'
import dayjs from 'dayjs'
import anchorTagsMixins from '@/mixins/anchorTagsMixins'
import { INPUT_TYPES_ANCHORS } from '@/constants/anchorTags/inputTypeAnchorTags'
import numberMixins from '@/mixins/numberMixins'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import cloneDeep from 'lodash/cloneDeep'
import { STATES } from '@/constants/states'
import { AXIOS } from '@/constants/axios'
import { DATA_MIXIN } from '@/constants/data'
import { IMPORT_EXPORT } from '@/constants/importExport'

export default {
  mixins: [routeMixins, utilMixins, numberMixins, anchorTagsMixins],
  data() {
    return {
      activateErrorCounters: false,
      [KEYS_GENERAL.ERROR_COUNTERS]: {
        [KEYS_GENERAL.ERROR_SUM]: 0,
        [KEYS_GENERAL.SHOW_VALIDATION_ERRORS]: false,
      },
      scrollSpyGroups: {
        [KEYS_RFQ.BASIC_FACTOR_PREFIX]: [KEYS_RFQ.PCB_TECHNOLOGY_DATA_GROUP],
        [KEYS_RFQ.COPPER_CRITERIA_PREFIX]: [KEYS_RFQ.PCB_TECHNOLOGY_DATA_GROUP],
        [KEYS_RFQ.PCB_DIMENSIONS_PREFIX]: [KEYS_RFQ.PCB_TECHNOLOGY_DATA_GROUP],
        [KEYS_RFQ.OTHERS_PREFIX]: [KEYS_RFQ.PCB_TECHNOLOGY_DATA_GROUP],
        [KEYS_RFQ.SPECIFIED_LOT_SIZES]: [KEYS_RFQ.DELIVERY],
        [KEYS_RFQ.QUOTE_PREPARATION.SHIPPING_COST]: [
          KEYS_RFQ.QUOTE_PREPARATION_PREFIX,
        ],
        [KEYS_RFQ.LOGISTIC_COSTS_TABLES]: [KEYS_RFQ.QUOTE_PREPARATION_PREFIX],
        [KEYS_RFQ.MASS_PRODUCTION_TABLES]: [KEYS_RFQ.MASS_PRODUCTION_PREFIX],
        [KEYS_RFQ.MASS_PRODUCTION_TABLES]: [KEYS_RFQ.SAMPLES_NRE_PREFIX],
        [KEYS_RFQ.SUPPLIER_OVERVIEW.OVERVIEW_QUOTA_CALCULATION]: [
          KEYS_RFQ.COST_OVERVIEW_GROUP_PREFIX,
          KEYS_RFQ.DETAILS_GROUP_PREFIX,
          KEYS_RFQ.DETAILS_GROUP.COMPARISON_OF_OFFERS_SUBGROUP,
        ],
        [KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX]: [
          KEYS_RFQ.QUOTATION_AND_DELIVERY_STRATEGY_PREFIX,
        ],
        [KEYS_RFQ.SUPPLIER_OFFER_STRATEGY_PREFIX]: [
          KEYS_RFQ.QUOTATION_AND_DELIVERY_STRATEGY_PREFIX,
        ],
        [KEYS_RFQ.DELIVERY_STRATEGY_PREFIX]: [
          KEYS_RFQ.QUOTATION_AND_DELIVERY_STRATEGY_PREFIX,
        ],
        [KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX]: [
          KEYS_RFQ.PANEL_DIMENSIONS_DATA_GROUP,
        ],
        [KEYS_RFQ.SUPPLIER_SCENARIOS]: [
          KEYS_RFQ.DETAILS_GROUP_PREFIX,
          KEYS_RFQ.DETAILS_GROUP.DELIVERY_SUBGROUP,
          KEYS_RFQ.DETAILS_GROUP_SCENARIOS_FOR_SUPPLIERS,
        ],
        [KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX]: [
          KEYS_RFQ.SALES_EXPERT_PCB_COST_PREFIX,
        ],
      },
    }
  },
  mounted() {
    const QUOTATIONS_GROUP = [
      KEYS_RFQ.QUOTATION_DETAILS_GROUP_PREFIX,
      KEYS_RFQ.DETAILS_GROUP.QUOTATION_OF_SUPPLIERS_SUBGROUP,
    ]
    switch (this.$route.name) {
      case ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS.NAME:
      case ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_EDIT.NAME:
        this.$data.scrollSpyGroups[KEYS_RFQ.QUOTATIONS] = QUOTATIONS_GROUP
        this.$data.scrollSpyGroups[KEYS_RFQ.NRE_MOV_PREFIX] = QUOTATIONS_GROUP
        this.$data.scrollSpyGroups[KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX] =
          QUOTATIONS_GROUP
    }
  },
  methods: {
    resetCalculationTableErrorCount() {
      let tables = [
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.QUOTE_PREPARATION_PREFIX][
          KEYS_RFQ.LOGISTIC_COSTS_TABLES
        ],
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.MASS_PRODUCTION_PREFIX][
          KEYS_RFQ.MASS_PRODUCTION_TABLES
        ],
      ]
      tables.forEach((table) => {
        table.errorCount = 0
        table.subItems.forEach((subItem) => {
          subItem.errorCount = {
            deliveryOptions: 0,
            inputs: 0,
            sum: 0,
          }
        })
      })
    },
    validateCalculationTablesMixin() {
      this.resetCalculationTableErrorCount()
      this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM])
      this.validateFormMixin(this.$data[KEYS_GENERAL.FORM])
    },
    restoreNumberFormatMixin(
      item,
      value,
      revert = false,
      returnZeroIfEmpty = false,
    ) {
      let newValue = ''
      if (value) {
        if (item.validationSettings && item.validationSettings.formatNumber) {
          value = String(value)
          let lastChar = value.slice(-1)
          if (lastChar === ',') {
            value = value.replace(/.$/, '.')
          }
          if (revert) {
            newValue = value.replaceAll(',', '')
          } else {
            let valueArray = value.split('.')
            newValue = this.numberFormatMixin(valueArray[0])
            if (valueArray.length > 1) {
              newValue = `${newValue}.${valueArray[1]}`
            }
          }
        } else {
          newValue = value
        }
      }
      if (returnZeroIfEmpty && !newValue) {
        return 0
      } else {
        return newValue
      }
    },
    focusValidationMixin(event) {
      if (event.target) {
        event.target.select()
      }
    },
    blurValidationMixin(target) {
      if (target.validationTypes.includes(VALIDATION_TYPE.DECIMAL)) {
        if (target.value[target.value.length - 1] === '.') {
          target.value = target.value.slice(0, -1)
        }
      } else if (
        typeof target.value === 'string' &&
        target.value.trim().length === 0 &&
        target.defaultValue
      ) {
        target.value = target.defaultValue
      }
      if (
        target.validationTypes.some(
          (type) => VALIDATION_TYPES_WITH_RANGE.indexOf(type) >= 0,
        )
      ) {
        if (target.value === '-' || target.value === '-0') {
          target.value = 0
        }

        if (target.inputMin === undefined) {
          target.inputMin = 0
        }

        if (target.inputMax === undefined) {
          target.inputMax = MAX_ALLOWED_NUMBER
        }
      }
      this.prepareAutoCorrectSettingsCheckMixin(target)
    },
    prepareAutoCorrectSettingsCheckMixin(item) {
      let triggeredValueReset = false
      if (item.autoCorrectSettings && item.disableAutoCorrect !== true) {
        item.autoCorrectSettings.forEach((autoCorrectSetting) => {
          if (
            !triggeredValueReset &&
            autoCorrectSetting.event === AUTO_CORRECT_VALUE.EVENT.BLUR
          ) {
            triggeredValueReset = this.autoCorrectSettingsMixin(
              item,
              autoCorrectSetting,
            )
          }
        })
      }

      // Make sure that the values are in the set range
      if (
        !triggeredValueReset &&
        item.disableAutoCorrect !== true &&
        item.value &&
        (item.inputMin || item.inputMax)
      ) {
        this.autoCorrectSettingsMixin(item, AUTO_CORRECT_OUT_OF_RANGE)
      }
    },
    autoCorrectSettingsMixin(item, autoCorrectSettings) {
      let targetItem
      let targetForm
      let targetParentItem
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SUPPLIER.VALUE:
          targetForm = KEYS_GENERAL.FORM_OFFER
          break
        default:
          targetForm = KEYS_GENERAL.FORM
      }
      let triggeredValueReset = false

      let isSelfTarget =
        autoCorrectSettings.targetKey === AUTO_CORRECT_VALUE.TARGET.SELF
      if (isSelfTarget) {
        targetItem = item
      } else {
        if (autoCorrectSettings.targetGroupKey) {
          if (autoCorrectSettings.targetSubItem !== undefined) {
            targetItem =
              this.$data[targetForm][autoCorrectSettings.targetGroupKey][
                autoCorrectSettings.targetKey
              ].subItems[autoCorrectSettings.targetSubItem]
          } else {
            targetItem =
              this.$data[targetForm][autoCorrectSettings.targetGroupKey][
                autoCorrectSettings.targetKey
              ]
          }
        } else {
          if (autoCorrectSettings.targetSubItem !== undefined) {
            targetItem =
              this.$data[targetForm][autoCorrectSettings.targetKey].subItems[
                autoCorrectSettings.targetSubItem
              ]
            targetParentItem =
              this.$data[targetForm][autoCorrectSettings.targetKey]
          } else {
            targetItem = this.$data[targetForm][autoCorrectSettings.targetKey]
          }
        }
      }

      let refItem
      if (autoCorrectSettings.refKey === AUTO_CORRECT_VALUE.TARGET.SELF) {
        refItem = item
      } else {
        if (autoCorrectSettings.refGroupKey) {
          if (autoCorrectSettings.refSubItem !== undefined) {
            refItem =
              this.$data[targetForm][autoCorrectSettings.refGroupKey][
                autoCorrectSettings.refKey
              ].subItems[autoCorrectSettings.refSubItem]
          } else {
            refItem =
              this.$data[targetForm][autoCorrectSettings.refGroupKey][
                autoCorrectSettings.refKey
              ]
          }
        } else {
          if (autoCorrectSettings.refSubItem !== undefined) {
            refItem =
              this.$data[targetForm][autoCorrectSettings.refKey].subItems[
                autoCorrectSettings.refSubItem
              ]
          } else {
            refItem = this.$data[targetForm][autoCorrectSettings.refKey]
          }
        }
      }

      if (refItem.subSelectionActive) {
        refItem = refItem.subItems[0]
      }

      let dateNeedsCorrection = false
      let refItemDisabledDate = null
      switch (autoCorrectSettings.rule) {
        case AUTO_CORRECT_VALUE.RULE.NOT_REQUIRED_IF_SELF_NOT_EMPTY:
          if (refItem.subItems) {
            let gotOneMatch = false
            refItem.subItems.forEach((subItem) => {
              if (subItem.value) {
                gotOneMatch = true
              }
            })
            targetItem.required = gotOneMatch
          } else {
            targetItem.required = !refItem.value
          }
          if (!targetItem.required) {
            targetItem.invalid = false
          }
          break
        case AUTO_CORRECT_VALUE.RULE.REQUIRED_IF_SELF_NOT_EMPTY:
          if (refItem.subItems) {
            let gotOneMatch = false
            refItem.subItems.forEach((subItem) => {
              if (subItem.value) {
                gotOneMatch = true
              }
            })
            targetItem.required = gotOneMatch
            if (
              targetItem.validationTypes.includes(
                VALIDATION_TYPE.INTEGER_OPTIONAL,
              )
            ) {
              targetItem.invalid = targetItem.required
            }
          } else {
            targetItem.required = !!refItem.value
          }
          break
        case AUTO_CORRECT_VALUE.RULE.CHECK_MIN:
          if (
            targetItem.inputMin &&
            parseFloat(targetItem.inputMin) > parseFloat(targetItem.value)
          ) {
            targetItem.messages.autoCorrect = `Must be higher than '${targetItem.inputMin}'.`
            targetItem.value = ''
            triggeredValueReset = true
            targetItem.invalid = true
          }
          break
        case AUTO_CORRECT_VALUE.RULE.CHECK_DEFAULT_TARGET:
          if (
            (targetItem.inputMin &&
              targetItem.inputDefaultTarget &&
              parseFloat(targetItem.inputMin) > parseFloat(targetItem.value)) ||
            targetItem.value === ''
          ) {
            targetItem.messages.autoCorrect = `Please correct to the available range.`
            targetItem.value = ''
            triggeredValueReset = true
            targetItem.invalid = true
          }
          break
        case AUTO_CORRECT_VALUE.RULE.GREATER_THAN_REF:
          if (
            refItem.value &&
            !isNaN(refItem.value) &&
            parseFloat(refItem.value) >= parseFloat(targetItem.value)
          ) {
            targetItem.messages.autoCorrect = `Must be higher than '${refItem.messages.label}'.`
            targetItem.value = ''
            triggeredValueReset = true
            targetItem.invalid = true
          }
          break
        case AUTO_CORRECT_VALUE.RULE.MIN_REQUIRED_SIZE:
          if (
            (refItem.value &&
              !isNaN(refItem.value) &&
              parseFloat(refItem.value) + autoCorrectSettings.value >
                parseFloat(targetItem.value)) ||
            !targetItem.value
          ) {
            if (targetItem.inputMin && autoCorrectSettings.value === 0) {
              targetItem.messages.autoCorrect = `Must be at least ${targetItem.inputMin}${targetItem.unit} or higher.`
            } else {
              targetItem.messages.autoCorrect = `Must be at least ${
                parseFloat(refItem.value) + autoCorrectSettings.value
              }${targetItem.unit} or higher.`
            }
            targetItem.value = ''
            triggeredValueReset = true
            targetItem.invalid = true
          }
          break
        case AUTO_CORRECT_VALUE.RULE.GREATER_EQUAL_THAN_REF:
          if (
            refItem.value &&
            !isNaN(refItem.value) &&
            parseFloat(refItem.value) > parseFloat(targetItem.value)
          ) {
            targetItem.messages.autoCorrect = `Must be greater or equal to ${refItem.messages.label}.`
            targetItem.value = ''
            triggeredValueReset = true
            targetItem.invalid = true
          }
          break
        case AUTO_CORRECT_VALUE.RULE.CHECK_OUT_OF_RANGE:
          if (
            !targetItem.value ||
            parseFloat(targetItem.inputMin) > parseFloat(targetItem.value) ||
            parseFloat(targetItem.inputMax) < parseFloat(targetItem.value)
          ) {
            targetItem.messages.autoCorrect = OUT_OF_RANGE_VALUE_ERROR_MESSAGE
            targetItem.invalid = true
            targetItem.value = ''
            triggeredValueReset = true
          }
          break
        case AUTO_CORRECT_VALUE.RULE.SET_VISIBILITY:
          targetItem.isHidden = !autoCorrectSettings.value.includes(
            parseInt(refItem.value),
          )
          if (
            !targetItem.isHidden &&
            targetParentItem &&
            targetParentItem.formType === FORM_ITEMS.TYPES.SWITCH
          ) {
            targetItem.isHidden = !targetParentItem.check
          }
          if (targetItem.isHidden) {
            targetItem.value = '0'
          }
          break
        case AUTO_CORRECT_VALUE.RULE.LOWER_THAN_REF:
          if (
            refItem.value &&
            !isNaN(refItem.value) &&
            parseFloat(refItem.value) <= parseFloat(targetItem.value)
          ) {
            targetItem.messages.autoCorrect = `Must be lower than '${refItem.messages.label}'.`
            targetItem.value = ''
            triggeredValueReset = true
            targetItem.invalid = true
          }
          break
        case AUTO_CORRECT_VALUE.RULE.CHECK_DATE_MIN:
        case AUTO_CORRECT_VALUE.RULE.CHECK_DATE_MIN_DISABLE_TARGET_ON_INVALID:
          if (refItem.value || autoCorrectSettings.valueRefEmpty) {
            if (refItem.value) {
              refItemDisabledDate = dayjs(refItem.value).add(
                autoCorrectSettings.value,
                'day',
              )
            } else {
              refItemDisabledDate = dayjs()
                .add(autoCorrectSettings.valueRefEmpty, 'day')
                .startOf('date')
            }

            if (targetItem.value && refItemDisabledDate) {
              dateNeedsCorrection = dayjs(targetItem.value).isBefore(
                refItemDisabledDate.toDate(),
              )
            }
          }
          if (dateNeedsCorrection || !targetItem.value || !refItem.value) {
            if (!refItem.value && targetItem.value) {
              targetItem.value = ''
              if (!autoCorrectSettings.disableErrorMessage) {
                targetItem.invalid = true
                if (autoCorrectSettings.valueRefEmpty) {
                  targetItem.messages.autoCorrect = `Please select a date.`
                } else {
                  targetItem.messages.autoCorrect = `Please select a date for '${refItem.messages.label}'.`
                }
              }
            } else {
              if (targetItem.value && dateNeedsCorrection) {
                if (
                  refItemDisabledDate &&
                  refItemDisabledDate.month() ===
                    dayjs(targetItem.value).month()
                ) {
                  targetItem.value = refItemDisabledDate.toDate()
                } else {
                  let coolDownSubstring = 'after'
                  if (autoCorrectSettings.value > 1) {
                    coolDownSubstring = `${autoCorrectSettings.value} days after`
                  }
                  targetItem.messages.autoCorrect = `Must be ${coolDownSubstring} '${refItem.messages.label}'. Please reselect the date.`
                  targetItem.invalid = true
                  targetItem.value = ''
                }
              }
            }
          }

          if (
            autoCorrectSettings.rule ===
            AUTO_CORRECT_VALUE.RULE.CHECK_DATE_MIN_DISABLE_TARGET_ON_INVALID
          ) {
            if (!refItem.value || refItem.invalid) {
              targetItem.disabled = true
              if (!autoCorrectSettings.disableErrorMessage) {
                targetItem.invalid = true
                targetItem.messages.autoCorrect = `Please select a date for '${refItem.messages.label}'.`
              }
            } else {
              targetItem.disabled = false
            }
          }

          if (refItemDisabledDate) {
            targetItem.date.disabledTo = refItemDisabledDate
          } else {
            delete targetItem.date.disabledTo
          }
          break
        case AUTO_CORRECT_VALUE.RULE.ADJUST_RFQ_YEAR_LABEL:
          if (autoCorrectSettings.values) {
            targetItem.messages.label =
              autoCorrectSettings.values[Number(refItem.value)]
            switch (Number(refItem.value)) {
              case RFQ_TYPES.YEARS:
                targetItem.messages.type = 'Year'
                targetItem.messages.typePlural = 'Years'
                break
              default:
                targetItem.messages.type = ''
                targetItem.messages.typePlural = ''
            }
          }
      }
      this.setErrorCounterMixin(this.$data[targetForm])
      return triggeredValueReset
    },
    getRelatedItemsMixin(target, targetForm = null) {
      // set the active form
      let form = targetForm
      if (!form && this.$props.form) {
        form = this.$props.form
      } else if (!form) {
        switch (this.$store.getters.getActivePermissionGroup) {
          case PERMISSION_GROUPS.SUPPLIER.VALUE:
            form = this.$data[KEYS_GENERAL.FORM_OFFER]
            break
          default:
            this.$route.meta.routeType === ROUTE_TYPES.LISTING
              ? (form = this.$data[KEYS_GENERAL.FORM_COPY])
              : (form = this.$data[KEYS_GENERAL.FORM])
        }
      }

      // Set the targetItem Group
      let group
      if (target.keyNameGroup) {
        group = form[target.keyNameGroup]
      } else {
        group = form
      }

      // Set the parent Item
      let parent
      if (target.keyNameParent) {
        parent = group[target.keyNameParent]
      }
      return { group, parent, target }
    },
    manageFileSelectionMixin(item, value) {
      if (value.length && !item.file.transferInProgress) {
        item.file.transferInProgress = true
        item.file.downloadStatus = 0
        const splitFileName = value[0].name.split('.')
        if (
          splitFileName.length &&
          item.file.formats.includes(
            splitFileName[splitFileName.length - 1].toLowerCase(),
          )
        ) {
          let bodyFormData = new FormData()
          bodyFormData.append('file', value[0])
          let newCommand = this.axiosGetNewCommandObjectMixin()
          newCommand.config.headers['Content-Type'] = 'multipart/form-data'
          newCommand.config['onUploadProgress'] = (progressEvent) => {
            let percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            )
            if (percent === 100) {
              item.messages.status = FILE_TYPES.STATUS.UPLOAD.PROCESSING.TEXT
            } else {
              if (!isFinite(percent)) {
                percent = 0
              }
              item.messages.status =
                FILE_TYPES.STATUS.UPLOAD.IN_PROGRESS.TEXT.replace(
                  '__percentage__',
                  percent,
                )
              item.file.status = FILE_TRANSFER_STATUS.ACTIVE
            }
          }
          newCommand.body = bodyFormData
          newCommand.targets.data = item.file.type
          newCommand.item = item
          newCommand.file = item.file

          if (item.file.url.targetId) {
            newCommand.path = item.file.url.upload.replace(
              item.file.url.targetPlaceholderId,
              item.file.url.targetId,
            )
          } else {
            newCommand.path = item.file.url.upload.replace(
              item.file.url.targetPlaceholderId,
              this.$route.params[
                item.file.url.targetPlaceholderId.replace(':', '')
              ],
            )
          }

          this.axiosPostMixin(newCommand)
        } else {
          item.file.status = FILE_TRANSFER_STATUS.FAILED
          item.messages.status = FILE_TYPES.STATUS.VALIDATION.FORMAT
        }
      }
    },
    sanitizeInputMixin(newValue, item, allowNegativeRange = false) {
      // Handle negative number inputs
      const ALLOWS_NEGATIVE_RANGE =
        (parseFloat(item.inputMin) && parseFloat(item.inputMin) < 0) ||
        allowNegativeRange
      if (ALLOWS_NEGATIVE_RANGE) {
        if (newValue === '-') {
          return newValue
        }

        if (
          (String(newValue).match(/-/g) &&
            String(newValue).match(/-/g).length > 1) ||
          (String(newValue).length > 2 &&
            String(newValue).startsWith('-0') &&
            !String(newValue).startsWith('-0.'))
        ) {
          newValue = item.value
        }
      }

      // Remove previous auto-correct messages
      if (item.messages) {
        delete item.messages.autoCorrect
      }

      let isIntOnly = false
      let punctuationCounter = 0
      newValue = `${newValue}`
      let punctuationMatches = newValue.match(/\./g)
      if (punctuationMatches) {
        punctuationCounter = punctuationMatches.length
      }

      if (item.validationTypes && newValue !== FORM_ITEMS.SELECTION_SUB_ITEM) {
        item.validationTypes.forEach((type) => {
          let options = {}
          const regexpNoNumbers = /\d/g

          switch (type) {
            case VALIDATION_TYPE.NO_NUMBERS:
              if (regexpNoNumbers.test(newValue)) {
                newValue = item.value
              }
              break
            case VALIDATION_TYPE.DECIMAL:
            case VALIDATION_TYPE.DECIMAL_OPTIONAL:
              // Replace commas with a punctuation
              newValue = newValue.replace(',', '.')

              if (
                item.hiddenRangeCap &&
                parseFloat(newValue) > MAX_ALLOWED_NUMBER
              ) {
                newValue = String(MAX_ALLOWED_NUMBER)
              } else if (item.hiddenRangeCap && parseFloat(newValue) < 0) {
                newValue = String(0)
              }

              if (newValue === '.' && item.value.length === 1) {
                newValue = item.value + newValue
              }

              // Check that there is only one punctuation
              if (newValue.replace(/[^. || A]/g, '').length > 1) {
                newValue = `${item.value}`
              }
              // Don't allow punctuations at the beginning
              if (newValue.length && newValue[0] === '.') {
                newValue = `${item.value}`
              }

              // Make sure that there is no more than one single punctuation
              if (punctuationCounter > 1) {
                let foundCounter = 0
                for (let i = 0; i < newValue.length; i++) {
                  if (newValue[i] === '.') {
                    foundCounter++
                  }

                  if (foundCounter === 2) {
                    newValue = `${newValue.substring(0, i)}`
                    break
                  }
                }
              }
              // Remove all characters that are not part of a decimal number
              if (ALLOWS_NEGATIVE_RANGE) {
                newValue = `${newValue.replace(/[^- ^. ^0-9 || A]/g, '')}`
              } else {
                newValue = `${newValue.replace(/[^. ^0-9 || A]/g, '')}`
              }
              // Prevent the input from exceeding the decimal limit
              if (
                newValue.includes('.') &&
                item.validationSettings &&
                item.validationSettings.decimal &&
                item.validationSettings.decimal.decimal_digits
              ) {
                const splitValue = String(newValue).split('.')
                if (
                  splitValue[splitValue.length - 1].length >
                  parseInt(item.validationSettings.decimal.decimal_digits)
                ) {
                  newValue = `${item.value}`
                }
              }
              // Set value to 0 if empty
              if (
                newValue === '' &&
                type !== VALIDATION_TYPE.DECIMAL_OPTIONAL
              ) {
                newValue = '0'
              }
              break
            case VALIDATION_TYPE.INTEGER:
            case VALIDATION_TYPE.INTEGER_OPTIONAL:
              isIntOnly = true

              if (!item.inputMin) {
                item.inputMin = 0
              }

              if (
                item.hiddenRangeCap &&
                parseFloat(newValue) > MAX_ALLOWED_NUMBER
              ) {
                newValue = String(MAX_ALLOWED_NUMBER)
              } else if (item.hiddenRangeCap && parseFloat(newValue) < 0) {
                newValue = String(0)
              }

              if (
                newValue === '' &&
                type !== VALIDATION_TYPE.INTEGER_OPTIONAL
              ) {
                newValue = '0'
              }

              if (item.validationSettings && item.validationSettings.integer) {
                options = item.validationSettings.decimal
              } else {
                options = {
                  min: 0,
                  allow_leading_zeroes: false,
                }
              }

              if (
                type === VALIDATION_TYPE.INTEGER_OPTIONAL &&
                newValue !== ''
              ) {
                if (!isInt(String(newValue), options)) {
                  newValue = `${item.value}`
                }
              } else if (
                type !== VALIDATION_TYPE.INTEGER_OPTIONAL &&
                !isInt(String(newValue), options)
              ) {
                newValue = `${item.value}`
              }
              break
          }
        })
      }
      if (!isIntOnly && newValue !== 0 && item.formType === undefined) {
        newValue = newValue.trimLeft()
      }

      if (
        item.formType !== FORM_ITEMS.TYPES.DATE &&
        item.formType !== FORM_ITEMS.TYPES.SELECT
      ) {
        if (item.formType === FORM_ITEMS.TYPES.TEXTAREA) {
          let target
          if (item.targetId) {
            target = document.getElementById(item.targetId)
          } else {
            target = document.getElementById(
              this.getInputAnchorTagMixin(
                item.keyName,
                INPUT_TYPES_ANCHORS.TEXT_AREA,
              ),
            )
          }
          if (target) {
            target.value = this.restoreNumberFormatMixin(item, newValue)
          }
        } else {
          let target
          if (item.targetId) {
            target = document.getElementById(item.targetId)
          } else {
            target = document.getElementById(
              this.getInputAnchorTagMixin(item.keyName),
            )
          }
          if (target) {
            target.value = this.restoreNumberFormatMixin(item, newValue)
            if (
              newValue.length === 1 &&
              newValue === '0' &&
              (item.validationTypes.includes(VALIDATION_TYPE.DECIMAL) ||
                item.validationTypes.includes(VALIDATION_TYPE.INTEGER)) &&
              !item.ignoreSelect
            ) {
              target.select()
            }
          }
        }
      }
      if (
        !item.validationTypes.includes(VALIDATION_TYPE.DECIMAL) &&
        !item.validationTypes.includes(VALIDATION_TYPE.INTEGER) &&
        item.formType !== FORM_ITEMS.TYPES.TEXTAREA &&
        item.formType !== FORM_ITEMS.TYPES.DATE &&
        newValue.length > MAX_ALLOWED_STRING_LENGTH
      ) {
        newValue = newValue.slice(0, MAX_ALLOWED_STRING_LENGTH)
      }
      return newValue
    },
    markAsRequiredMixin(item, text) {
      if (item.messages !== undefined) {
        if (item.required && !item.noStar) {
          return `${text}*`
        } else {
          return text
        }
      }
    },
    validateRangeAndLengthMixin(newValue, item) {
      if (item.range) {
        if (newValue < item.range.min || isNaN(newValue)) {
          newValue = item.range.min
        } else if (newValue > item.range.max) {
          newValue = item.range.max
        }
      } else if (item.maxLength && newValue.length > item.maxLength) {
        newValue = newValue.slice(0, item.maxLength)
      }
      return newValue
    },
    toggleVisibilityMixin(item) {
      item.checked = !item.checked
      this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM])
    },
    updateRadioValue(event) {
      event.item.radioValue = event.value
      this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM])
    },
    updateCheckedMixin(item, dataGroup = null) {
      if (dataGroup === null && this.$data[KEYS_GENERAL.FORM]) {
        dataGroup = this.$data[KEYS_GENERAL.FORM]
      }
      if (dataGroup) {
        if (item.keyNameGroup) {
          dataGroup[item.keyNameGroup][item.keyName].checked =
            !dataGroup[item.keyNameGroup][item.keyName].checked
          if (
            item.validationTypes &&
            item.validationTypes.includes(VALIDATION_TYPE.CHECKED)
          ) {
            dataGroup[item.keyNameGroup][item.keyName].invalid =
              !dataGroup[item.keyNameGroup][item.keyName].checked
          }
        } else if (dataGroup[item.keyName]) {
          dataGroup[item.keyName].checked = !dataGroup[item.keyName].checked
          if (
            item.validationTypes &&
            item.validationTypes.includes(VALIDATION_TYPE.CHECKED)
          ) {
            dataGroup[item.keyName].invalid = !dataGroup[item.keyName].checked
          }
        }
      }
      this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM])
    },
    updateQuotaErrorCounterMixin() {
      if (
        this.$data[KEYS_GENERAL.FORM] &&
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS] &&
        this.$data[KEYS_GENERAL.IMPORT_DATA] &&
        this.$data[KEYS_GENERAL.IMPORT_DATA][API_OBJECT_KEY_NAMES.RFQ.STATE] ===
          STATES.SUPPLIER_OFFERS_COMPLETED.VALUE
      ) {
        let errorCount = {
          supplier: 0,
          quota: 0,
        }
        if (this.selectedSuppliersQuotas && this.selectedSuppliersQuotas.size) {
          for (
            let scenarioIndex = 0;
            scenarioIndex < this.comparisonScenariosCount;
            scenarioIndex++
          ) {
            for (
              let yearIndex = -1;
              yearIndex < this.comparisonRowCount - 1;
              yearIndex++
            ) {
              let rowSum = 0
              this.selectedSuppliersQuotas.forEach((quota) => {
                if (yearIndex === -1) {
                  if (this.comparisonRfqType === RFQ_TYPES.SCENARIOS) {
                    rowSum +=
                      quota && quota[scenarioIndex]
                        ? quota[scenarioIndex].samples
                        : 0
                  } else {
                    rowSum += quota.samples ? quota.samples : 0
                  }
                } else {
                  switch (this.comparisonRfqType) {
                    case RFQ_TYPES.YEARS:
                      rowSum += quota.years[yearIndex]
                        ? quota.years[yearIndex]
                        : 0
                      break
                    case RFQ_TYPES.LOT_SIZES:
                      rowSum += quota.lotSizes[yearIndex]
                        ? quota.lotSizes[yearIndex]
                        : 0
                      break
                    case RFQ_TYPES.SCENARIOS:
                      rowSum +=
                        quota[scenarioIndex] &&
                        quota[scenarioIndex].years[yearIndex]
                          ? quota[scenarioIndex].years[yearIndex]
                          : 0
                      break
                  }
                }
              })
              if (rowSum !== 100) {
                errorCount.quota += this.selectedSuppliersQuotas.size
              }
            }
          }
        } else {
          errorCount.supplier = 1
          errorCount.quota = 1
        }
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].errorCount =
          errorCount
      }
      this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM])
    },
    updateQuotaMixin(event) {
      let index = 0
      for (
        let i = 0;
        i < this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data.length;
        i++
      ) {
        if (
          this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[i][
            KEYS_RFQ.SUPPLIER_SETTINGS
          ].info.id === event.supplierId
        ) {
          index = i
          break
        }
      }
      let quota =
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[index][
          KEYS_RFQ.SUPPLIER_SETTINGS
        ].selection.quota
      switch (event.rfqType) {
        case RFQ_TYPES.YEARS:
          if (event.sopIndex === -1) {
            quota.samples = event.value
          } else {
            quota.years[event.sopIndex] = event.value
          }
          break
        case RFQ_TYPES.SCENARIOS:
          if (event.sopIndex === -1) {
            quota[event.scenarioIndex].samples = event.value
          } else {
            quota[event.scenarioIndex].years[event.sopIndex] = event.value
          }
          break
        case RFQ_TYPES.LOT_SIZES:
          if (event.sopIndex === -1) {
            quota.samples = event.value
          } else {
            quota.lotSizes[String(event.sopIndex)] = event.value
          }
          break
      }
      this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[index][
        KEYS_RFQ.SUPPLIER_SETTINGS
      ].selection.quota = quota
      this.updateQuotaErrorCounterMixin()
    },
    updateSelectedSupplierMixin(event) {
      let info =
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[
          event.supplierIndex
        ][KEYS_RFQ.SUPPLIER_SETTINGS].info
      let selection =
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[
          event.supplierIndex
        ][KEYS_RFQ.SUPPLIER_SETTINGS].selection
      selection.selected ===
      QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES.NOT_SELECTED
        ? (selection.selected = cloneDeep(
            QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES.ACTIVE,
          ))
        : (selection.selected =
            QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES.NOT_SELECTED)
      if (
        selection.selected ===
        QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES.NOT_SELECTED
      ) {
        selection.comment = ''
        selection.quota = {}
      } else {
        switch (event.rfqType) {
          case RFQ_TYPES.YEARS:
            selection.quota = {
              samples: 100,
              years: Array(event.sopLength).fill(100),
            }
            break
          case RFQ_TYPES.SCENARIOS:
            for (let i = 0; i < event.scenariosCount; i++) {
              selection.quota[String(i)] = null
            }
            break
          case RFQ_TYPES.LOT_SIZES:
            selection.quota = {
              samples: 100,
              lotSizes: {},
            }
            for (let i = 0; i < event.sopLength; i++) {
              selection.quota.lotSizes[String(i)] = null
            }
            break
        }
      }
      if (!info.annualPriceDown) {
        selection.flatPrice = true
      }
      this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[
        event.supplierIndex
      ][KEYS_RFQ.SUPPLIER_SETTINGS].selection = selection
      this.updateQuotaErrorCounterMixin()
    },
    updateSupplierSelectionCommentMixin(event) {
      let comment =
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[
          event.supplierIndex
        ][KEYS_RFQ.SUPPLIER_SETTINGS].selection.comment
      comment = event.value.slice(0, MAX_ALLOWED_TEXT_AREA_CHARACTERS)
      document.getElementById(event.targetId).value = comment
      this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[
        event.supplierIndex
      ][KEYS_RFQ.SUPPLIER_SETTINGS].selection.comment = comment
      this.updateQuotaErrorCounterMixin()
    },
    updateMCRCheckboxMixin(event) {
      let selection =
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[
          event.index
        ][KEYS_RFQ.SUPPLIER_SETTINGS].selection
      selection.flatPrice = event.isFlatPrice
      selection.selected = cloneDeep(
        QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES.ACTIVE,
      )
      this.updateQuotaErrorCounterMixin()
    },
    updateSupplierCheckboxMixin(event) {
      let selection =
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[
          event.supplierIndex
        ][KEYS_RFQ.SUPPLIER_SETTINGS].selection
      if (selection.selected.has(event.selectionIndex)) {
        selection.selected.delete(event.selectionIndex)
        switch (event.rfqType) {
          case RFQ_TYPES.SCENARIOS:
            selection.quota[String(event.selectionIndex)] = null
            break
          case RFQ_TYPES.LOT_SIZES:
            selection.quota.lotSizes[String(event.selectionIndex)] = null
            break
          case RFQ_TYPES.YEARS:
            selection.quota = null
            break
        }
      } else {
        switch (event.rfqType) {
          case RFQ_TYPES.SCENARIOS:
            selection.quota[String(event.selectionIndex)] = {
              samples: 100,
              years: Array(4).fill(100),
            }
            break
          case RFQ_TYPES.LOT_SIZES:
            selection.quota.lotSizes[String(event.selectionIndex)] = 100
            break
          case RFQ_TYPES.YEARS:
            selection.quota = {
              samples: 100,
              years: Array(event.sopLength).fill(100),
            }
            break
        }
        selection.selected.add(event.selectionIndex)
      }
      this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data[
        event.supplierIndex
      ][KEYS_RFQ.SUPPLIER_SETTINGS].selection = selection
      this.updateQuotaErrorCounterMixin()
    },
    updateValueMixin(updateData, targetForm) {
      if (this.$route.name === ROUTER_DATA.SHARED.RFQ_EDIT.NAME) {
        this.$store.commit('toggleModifiedActiveRFQ', true)
      }

      if (updateData.item) {
        let items = this.getRelatedItemsMixin(updateData.item, targetForm)

        // Check if the target item handles files
        if (items.target.file) {
          // Check if the form got quote panels and initialize those if they are missing
          if (
            this.$route.name ===
            ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
              .RFQ_DETAILS_CALCULATION_EDIT.NAME
          ) {
            let quote =
              this.$data[KEYS_GENERAL.IMPORT_DATA][
                API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP
              ][0]
            if (!quote.panelType) {
              let nextCommandsList = []
              let saveQuoteCommand = this.axiosGetNewCommandObjectMixin()

              saveQuoteCommand.path = AXIOS.API.POST.UPDATE_QUOTE
              saveQuoteCommand.body = this.prepareQuoteForExportMixin(
                this.$data[KEYS_GENERAL.FORM],
                quote,
              )
              saveQuoteCommand.body = this.adjustPayloadBody(
                saveQuoteCommand.body,
              )
              saveQuoteCommand.type = AXIOS.TYPES.POST
              nextCommandsList = this.axiosAddCommandToQueueMixin(
                nextCommandsList,
                saveQuoteCommand,
                DATA_MIXIN.UPDATE_QUOTE,
              )

              let lockRfQCommand = this.axiosGetNewCommandObjectMixin()
              lockRfQCommand.type = AXIOS.TYPES.POST
              lockRfQCommand.path = AXIOS.API.POST.RFQ_LOCK.replace(
                PLACEHOLDER.ID.RFQ,
                this.$route.params.id,
              )
              lockRfQCommand.fileItem = {
                item: items.target,
                value: updateData.value,
              }
              nextCommandsList = this.axiosAddCommandToQueueMixin(
                nextCommandsList,
                lockRfQCommand,
              )

              this.dataStartExecuteNextCommandMixin(
                nextCommandsList,
                null,
                DATA_MIXIN.INITIALIZE_QUOTE_PANELS_BEFORE_FILE_UPLOAD,
                false,
              )
            } else {
              this.manageFileSelectionMixin(items.target, updateData.value)
            }
          } else {
            this.manageFileSelectionMixin(items.target, updateData.value)
          }
        } else {
          // make target item visible if selected
          if (
            items.target.formType === FORM_ITEMS.TYPES.SELECT &&
            items.target.keyNameParent === undefined
          ) {
            items.target.subSelectionActive =
              updateData.value === FORM_ITEMS.SELECTION_SUB_ITEM
          }

          // clean up user input of the target item
          updateData.value = this.validateRangeAndLengthMixin(
            updateData.value,
            items.target,
          )
          updateData.value = this.sanitizeInputMixin(
            updateData.value,
            items.target,
          )

          items.target.value = updateData.value

          // Move the value to a different item if an override attribute exists
          if (
            items.target.overrideTarget &&
            updateData.value ===
              items.target.options[items.target.overrideTarget.optionsValue]
                .value
          ) {
            items.group[items.target.overrideTarget.keyName].value =
              items.target.options[
                items.target.overrideTarget.optionsValue
              ].value
          }
          this.validateInputMixin(items)

          this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM])
          if (updateData.item.autoCorrectSettings) {
            updateData.item.autoCorrectSettings.forEach(
              (autoCorrectSetting) => {
                if (
                  autoCorrectSetting.event === AUTO_CORRECT_VALUE.EVENT.UPDATE
                ) {
                  this.autoCorrectSettingsMixin(
                    updateData.item,
                    autoCorrectSetting,
                  )
                }
              },
            )
          }
        }
      }
    },
    validatedRepeatedPasswordMixin() {
      let repeatedPassword =
        this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.REPEATED_PASSWORD]
      let newPassword =
        this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.NEW_PASSWORD]

      // Ignore empty values as long as a submit event hasn't been triggered (isProcessing)
      if (this.$store.getters.getAxiosData.isProcessing) {
        repeatedPassword.invalid = Boolean(
          repeatedPassword.value !== newPassword.value ||
            !repeatedPassword.value,
        )
      } else {
        repeatedPassword.invalid = Boolean(
          repeatedPassword.value &&
            repeatedPassword.value !== newPassword.value,
        )
      }
      if (repeatedPassword.value) {
        repeatedPassword.messages.error = repeatedPassword.messages.errorNoMatch
      } else {
        repeatedPassword.messages.error = repeatedPassword.messages.errorEmpty
      }

      if (repeatedPassword.invalid) {
        this.$data.formIsValid = false
      }
    },
    validateNewPasswordMixin(item) {
      let errorCount = 0
      Object.keys(passwordCheckList).forEach((key) => {
        const check = passwordCheckList[key]
        let isCheckVal = true
        let substring = ''
        let required
        let CHECK_TYPES
        if (check.type === passwordCheckTypes.CHECK_TYPES.REQUIRED_CHARACTERS) {
          required = passwordCheckTypes.itemRequiredCharacters.required
          CHECK_TYPES = passwordCheckTypes.CHECK_TYPES
        }
        switch (check.type) {
          case passwordCheckTypes.CHECK_TYPES.NO_EMAIL:
            if (!this.$route.meta.remoteEmailValidation) {
              substring =
                this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.EMAIL].value
              if (substring.includes('@')) {
                substring = substring.substring(0, substring.indexOf('@'))
              }
              isCheckVal = !item.value.includes(substring)
              this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.NEW_PASSWORD][
                passwordCheckTypes.CHECK_TYPES.NO_EMAIL
              ] = isCheckVal
            }
            break
          case passwordCheckTypes.CHECK_TYPES.MINIMUM_LENGTH:
            isCheckVal = item.value.length >= check.value
            this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.NEW_PASSWORD][
              passwordCheckTypes.CHECK_TYPES.MINIMUM_LENGTH
            ] = isCheckVal
            break
          case passwordCheckTypes.CHECK_TYPES.REQUIRED_CHARACTERS:
            isCheckVal = isStrongPassword(item.value.toString(), {
              minLowercase: required[CHECK_TYPES.LOWERCASE],
              minUppercase: required[CHECK_TYPES.UPPERCASE],
              minSymbols: 0,
              minLength: 0,
              minNumbers: required[CHECK_TYPES.NUMBER],
            })
            this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.NEW_PASSWORD][
              passwordCheckTypes.CHECK_TYPES.REQUIRED_CHARACTERS
            ] = isCheckVal
            break
        }
        if (!isCheckVal) {
          errorCount++
        }
      })

      this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.NEW_PASSWORD].invalid =
        errorCount > 0
      if (errorCount) {
        this.$data.formIsValid = false
      }
    },
    validationIsRequiredItemMixin(items) {
      // Check if the panel item should be validated
      if (
        items.target.apiTransferStyle &&
        this.$data.formInternalPanelSelection !== undefined &&
        !this.isPanelTypeEnabledMixin(items.target.apiTransferStyle)
      ) {
        return false
      }

      if (items.target.disableValidation) {
        return false
      }
      let isRequired = items.target.required

      // Is the item disabled and not a single checkbox?
      if (!items.parent && items.target.checked === false) {
        if (
          !items.target.validationTypes ||
          !items.target.validationTypes.includes(VALIDATION_TYPE.CHECKED)
        ) {
          isRequired = false
        }
      }

      // Is the parent disabled?
      if (items.parent && items.parent.checked === false) {
        isRequired = false
      }

      // Is the radioValue Group not active?
      if (
        items.parent &&
        items.parent.radioValue !== items.target.radioValueGroup
      ) {
        isRequired = false
      }

      // Is the parent a select box and spawned the target item as an optional input field?
      if (
        items.parent &&
        items.parent.formType === FORM_ITEMS.TYPES.SELECT &&
        items.parent.value !== FORM_ITEMS.SELECTION_SUB_ITEM
      ) {
        isRequired = false
      }

      // Is the item an optional date field with a set date?
      if (
        items.target.formType === FORM_ITEMS.TYPES.DATE &&
        items.target.value
      ) {
        isRequired = true
      }
      return isRequired
    },
    validateLogisticCostsMixin(items) {
      let inputs = 0
      let deliveryOptions = 0
      const ACTIVE_TABLE_DATA =
        items.target.apiTransferStyle ===
        IMPORT_EXPORT.API_TRANSFER_STYLE.CUSTOMER_SPECIFIED_PANEL
          ? this.$data.formQuoteTableSpecifiedPanels
          : this.$data.formQuoteTableProposedPanels

      // Check that at least one delivery option is selected
      if (ACTIVE_TABLE_DATA === 0) {
        deliveryOptions++
      }
      if (items.target.data.size > 0) {
        items.target.data.forEach((cost) => {
          if (
            cost.quantity < parseFloat(items.target.inputMin) ||
            cost.quantity > parseFloat(items.target.inputMax)
          ) {
            inputs++
          }
          cost.quoteScenarioPricingDeliveries.forEach((delivery) => {
            const BIT_MASK =
              1 <<
              (this.getDeliveryOptionBitValueMixin(delivery.deliveryType) - 1)
            if (
              ACTIVE_TABLE_DATA & BIT_MASK &&
              (delivery.logisticCost < parseFloat(items.target.inputMin) ||
                delivery.logisticCost > parseFloat(items.target.inputMax))
            ) {
              inputs++
            }
          })
        })
      } else {
        inputs++
      }
      items.target.errorCount = {
        deliveryOptions: deliveryOptions,
        inputs: inputs,
        sum: deliveryOptions + inputs,
      }
      items.parent.errorCount += items.target.errorCount.sum
      if (items.parent.errorCount > 0) {
        items.parent.invalid = true
      }
      return items.target.errorCount.sum
    },
    validateMassProductionMixin(items) {
      let inputs = 0
      const ACTIVE_TABLE_DATA =
        items.target.apiTransferStyle ===
        IMPORT_EXPORT.API_TRANSFER_STYLE.CUSTOMER_SPECIFIED_PANEL
          ? this.$data.formQuoteTableSpecifiedPanels
          : this.$data.formQuoteTableProposedPanels
      if (items.target.data.size > 0) {
        items.target.data.forEach((massProduction) => {
          const BIT_MASK =
            1 <<
            (this.getDeliveryOptionBitValueMixin(massProduction.deliveryType) -
              1)
          if (ACTIVE_TABLE_DATA & BIT_MASK) {
            if (
              this.$data[KEYS_GENERAL.IMPORT_DATA][
                API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE
              ] === RFQ_TYPES.LOT_SIZES
            ) {
              // Check if margin is valid
              if (
                parseFloat(items.target.inputMin) >
                  parseFloat(massProduction.lotSizes) ||
                parseFloat(items.target.inputMax) <
                  parseFloat(massProduction.lotSizes)
              ) {
                inputs++
              }
              // Check if price is valid
              if (
                parseFloat(massProduction.price) >
                  parseFloat(massProduction.maxPrice) ||
                parseFloat(massProduction.price) < 0.01
              ) {
                inputs++
              }
            } else {
              // Check if margins are valid
              Object.values(massProduction.lotSizes).forEach(
                (lotSizeMargin) => {
                  if (
                    parseFloat(items.target.inputMin) >
                      parseFloat(lotSizeMargin) ||
                    parseFloat(items.target.inputMax) <
                      parseFloat(lotSizeMargin)
                  ) {
                    inputs++
                  }
                },
              )
              // Check if prices is valid
              Object.values(massProduction.price).forEach((price, index) => {
                if (
                  parseFloat(price) >
                    parseFloat(Object.values(massProduction.maxPrice)[index]) ||
                  parseFloat(price) < 0.01
                ) {
                  inputs++
                }
              })
            }
          }
        })
      } else {
        inputs++
      }
      items.target.errorCount = {
        inputs: inputs,
        sum: inputs,
      }
      items.parent.errorCount += items.target.errorCount.sum
      return items.target.errorCount.sum
    },
    validateInputMixin(items) {
      if (this.validationIsRequiredItemMixin(items)) {
        let validationErrors = 0
        switch (items.target.formType) {
          case FORM_ITEMS.TYPES.SUPPLIER_SELECTION:
            validationErrors = this.validateSupplierSelectionMixin(items.target)
            break
          case FORM_ITEMS.TYPES.DELIVERY_LOCATION:
            this.validateDeliveryLocationsMixin(items.target)
            break
          case FORM_ITEMS.TYPES.SAMPLES_NRE:
            if (
              parseFloat(items.target.value) <
                parseFloat(items.target.inputMin) ||
              parseFloat(items.target.value) > parseFloat(items.target.inputMax)
            ) {
              validationErrors++
            }
            break
          case FORM_ITEMS.TYPES.LOGISTIC_COSTS_TABLES:
            validationErrors = this.validateLogisticCostsMixin(items)
            break
          case FORM_ITEMS.TYPES.MASS_PRODUCTION_TABLES:
            validationErrors = this.validateMassProductionMixin(items)
            break
          case FORM_ITEMS.TYPES.LOT_SIZES:
            validationErrors = items.target.errorCount.sum
            break
          case FORM_ITEMS.TYPES.QUOTATION:
            this.validateQuotationsMixin(items.target)
            break
          case FORM_ITEMS.TYPES.SUPPLIER_FORMS:
            this.validateSupplierFormsMixin(items.target)
            break
          default:
            if (items.target.validationTypes) {
              items.target.validationTypes.forEach((validationType) => {
                switch (validationType) {
                  case VALIDATION_TYPE.CHECKED:
                    validationErrors += items.target.checked === false ? 1 : 0
                    break
                  case VALIDATION_TYPE.EMAIL:
                    validationErrors += !isEmail(items.target.value) ? 1 : 0
                    break
                  case VALIDATION_TYPE.NOT_EMPTY:
                    validationErrors += items.target.value.length === 0 ? 1 : 0
                    break
                  case VALIDATION_TYPE.MINIMUM_LENGTH:
                    validationErrors +=
                      items.target.value.length < items.target.minLength ? 1 : 0
                    break
                  case VALIDATION_TYPE.DATE_START:
                    if (
                      items.target.value &&
                      items.target.date &&
                      items.target.date.disabledTo
                    ) {
                      validationErrors += dayjs(items.target.value).isBefore(
                        items.target.date.disabledTo,
                      )
                    } else if (items.target.value && items.target.date) {
                      validationErrors += dayjs(items.target.value).isBefore(
                        dayjs(),
                      )
                    } else if (!items.target.value) {
                      validationErrors++
                    }
                    break
                  case VALIDATION_TYPE.DATE_START_MONTH:
                    if (
                      items.target.value &&
                      items.target.date &&
                      items.target.date.disabledTo
                    ) {
                      validationErrors += dayjs(items.target.value).isBefore(
                        items.target.date.disabledTo,
                        'month',
                      )
                    } else if (items.target.value && items.target.date) {
                      validationErrors += dayjs(items.target.value).isBefore(
                        dayjs(),
                        'month',
                      )
                    } else if (!items.target.value) {
                      validationErrors++
                    }
                    break
                  case VALIDATION_TYPE.REPEATED_PASSWORD:
                    this.validatedRepeatedPasswordMixin()
                    break
                  case VALIDATION_TYPE.NEW_PASSWORD:
                    if (items.target.isEmailMain) {
                      this.validateNewPasswordMixin(
                        items.group[KEYS_REGISTRATION.NEW_PASSWORD],
                      )
                    } else {
                      this.validateNewPasswordMixin(items.target)
                    }
                    break
                }
              })
            }
        }
        if (!items.target.isPasswordMain && !items.target.isPasswordRepeat) {
          if (validationErrors > 0) {
            items.target.invalid = true
            this.$data.formIsValid = false
          } else {
            items.target.invalid = false
          }
        }
      } else {
        items.target.invalid = false
      }
    },
    validateFormMixin(
      dataGroup,
      firstCall = true,
      forceValidation = false,
      targetForm = null,
    ) {
      Object.entries(dataGroup).forEach(([key, groupItem]) => {
        if (
          forceValidation ||
          this.requiredForValidationMixin(key, firstCall)
        ) {
          // Is the item a subgroup?
          if (!groupItem.keyName) {
            this.validateFormMixin(
              groupItem,
              false,
              forceValidation,
              targetForm,
            )
          } else if (
            FORM_TYPES_WITH_SUB_FORM_DATA.includes(groupItem.formType)
          ) {
            groupItem.data.forEach((subFormData) => {
              this.validateFormMixin(
                subFormData.form,
                false,
                true,
                subFormData.form,
              )
            })
          } else {
            this.validateInputMixin(
              this.getRelatedItemsMixin(groupItem, targetForm),
            )
            this.prepareAutoCorrectSettingsCheckMixin(groupItem)
            if (groupItem.subItems) {
              groupItem.subItems.forEach((subItem) => {
                this.validateInputMixin(
                  this.getRelatedItemsMixin(subItem, targetForm),
                )
                this.prepareAutoCorrectSettingsCheckMixin(subItem)
              })
            }
          }
        }
      })
      if (firstCall) {
        this.setErrorCounterMixin(dataGroup, forceValidation, targetForm)
      }
      return (
        this.$data[KEYS_GENERAL.ERROR_COUNTERS][KEYS_GENERAL.ERROR_SUM] === 0
      )
    },
    setErrorCounterMixin(form, forceValidation = false, targetForm = null) {
      this.$data[KEYS_GENERAL.ERROR_COUNTERS] = {
        [KEYS_GENERAL.ERROR_SUM]: 0,
        [KEYS_GENERAL.SHOW_VALIDATION_ERRORS]: this.$data.activateErrorCounters,
      }
      this.$data[KEYS_GENERAL.ERROR_COUNTERS][KEYS_GENERAL.ERROR_SUM] =
        this.validationGetErrorCountMixin(
          form,
          0,
          true,
          forceValidation,
          targetForm,
        )
    },
    updateLotSizeErrorCounterMixin(errors) {
      this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SPECIFIED_LOT_SIZES].errorCount =
        errors
      this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM])
    },
    updateErrorCountMixin(key, groupErrors) {
      this.$data[KEYS_GENERAL.ERROR_COUNTERS][
        this.getScrollspyAnchorName(key)
      ] = groupErrors
      if (this.$data.scrollSpyGroups[key]) {
        this.$data.scrollSpyGroups[key].forEach((groupKey) => {
          if (
            !this.$data[KEYS_GENERAL.ERROR_COUNTERS][
              this.getScrollspyAnchorName(groupKey)
            ]
          ) {
            this.$data[KEYS_GENERAL.ERROR_COUNTERS][
              this.getScrollspyAnchorName(groupKey)
            ] = 0
          }
          this.$data[KEYS_GENERAL.ERROR_COUNTERS][
            this.getScrollspyAnchorName(groupKey)
          ] += groupErrors
        })
      }
      this.$data[KEYS_GENERAL.ERROR_COUNTERS][KEYS_GENERAL.ERROR_SUM] +=
        groupErrors
    },
    validationGetErrorCountMixin(
      dataGroup,
      errorCount = 0,
      firstCall = true,
      forceValidation = false,
      targetForm = null,
    ) {
      if (dataGroup) {
        Object.entries(dataGroup).forEach(([key, groupItem]) => {
          if (
            forceValidation ||
            this.requiredForValidationMixin(key, firstCall)
          ) {
            if (FORM_TYPES_WITH_SUB_FORM_DATA.includes(groupItem.formType)) {
              if (groupItem.data.length > 0) {
                let groupErrorSum = 0
                groupItem.data.forEach((subFormData) => {
                  groupErrorSum += this.validationGetErrorCountMixin(
                    subFormData.form,
                    errorCount,
                    false,
                    forceValidation,
                    subFormData.form,
                  )
                })
                this.updateErrorCountMixin(key, groupErrorSum)
                errorCount += groupErrorSum
              } else {
                this.updateErrorCountMixin(key, 1)
                errorCount += 1
              }
            } // Is the item a subgroup?
            else if (!groupItem.keyName) {
              let groupErrors = this.validationGetErrorCountMixin(
                groupItem,
                0,
                false,
                forceValidation,
                targetForm,
              )
              this.updateErrorCountMixin(key, groupErrors)
              errorCount += groupErrors
            } else {
              if (groupItem.keyName === KEYS_RFQ.SUPPLIER_FORMS) {
                let supplierFormErrors =
                  groupItem.errorCount.supplier + groupItem.errorCount.quota

                this.updateErrorCountMixin(
                  KEYS_RFQ.SUPPLIER_OVERVIEW.OVERVIEW_QUOTA_CALCULATION,
                  supplierFormErrors,
                )
                errorCount += supplierFormErrors
              } else if (groupItem.keyName === KEYS_RFQ.SUPPLIER_SCENARIOS) {
                errorCount += groupItem.errorCount.sum
                this.updateErrorCountMixin(key, groupItem.errorCount.sum)
              } else if (
                groupItem.keyName === KEYS_RFQ.LOGISTIC_COSTS_TABLES ||
                groupItem.keyName === KEYS_RFQ.MASS_PRODUCTION_TABLES
              ) {
                errorCount += groupItem.errorCount
                this.updateErrorCountMixin(key, groupItem.errorCount)
              } else if (groupItem.keyName === KEYS_RFQ.DELIVERY) {
                let deliveryErrors = groupItem.errorCount.sum
                if (
                  deliveryErrors === 0 &&
                  groupItem.data.length === 0 &&
                  this.$route.meta.routeType === ROUTE_TYPES.RFQ_EDITOR &&
                  this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.PROJECT_DATA_PREFIX][
                    KEYS_RFQ.PROJECT_DATA.SOP
                  ].value
                ) {
                  deliveryErrors = 1
                }
                this.updateErrorCountMixin(key, deliveryErrors)
                errorCount += deliveryErrors
              } else if (
                groupItem.keyName === KEYS_RFQ.QUOTATIONS ||
                groupItem.keyName === KEYS_RFQ.SPECIFIED_LOT_SIZES
              ) {
                this.updateErrorCountMixin(key, groupItem.errorCount)
                errorCount += groupItem.errorCount
              } else {
                const items = this.getRelatedItemsMixin(groupItem, targetForm)
                if (
                  this.validationIsRequiredItemMixin(items) &&
                  items.target.invalid
                ) {
                  errorCount++
                }
                if (groupItem.subItems) {
                  groupItem.subItems.forEach((subItem) => {
                    const items = this.getRelatedItemsMixin(subItem, targetForm)
                    if (
                      this.validationIsRequiredItemMixin(items) &&
                      items.target.invalid
                    ) {
                      errorCount++
                    }
                  })
                }
              }
            }
          }
        })
      }
      return parseInt(errorCount)
    },
    validationQuotationDataMixin(settings, value) {
      let parsedValue = parseFloat(value)
      return parsedValue < settings.inputMin ||
        parsedValue > settings.inputMax ||
        isNaN(parsedValue)
        ? 1
        : 0
    },
    validateSupplierFormsMixin(supplierForms) {
      supplierForms.invalid =
        supplierForms.errorCount.supplier + supplierForms.errorCount.quota > 0
    },
    validateQuotationsMixin(
      quotations,
      gotFlatPrice = this.$data[KEYS_GENERAL.IMPORT_OFFER_DATA][
        API_OBJECT_KEY_NAMES.OFFER.PRICING_DELIVERY.FLAT_PRICE
      ],
      gotAnnualPriceDown = this.$data[KEYS_GENERAL.IMPORT_OFFER_DATA][
        API_OBJECT_KEY_NAMES.OFFER.PRICING_DELIVERY.ANNUAL_PRICE_DOWN
      ],
    ) {
      quotations.invalid = false
      quotations.errorCount = 0

      const KEY_NAMES = API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS
      quotations.data.forEach((panel) => {
        Object.values(panel[KEY_NAMES.QUOTATION]).forEach((currency) => {
          switch (
            this.$data[KEYS_GENERAL.IMPORT_DATA][
              [API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE]
            ]
          ) {
            case RFQ_TYPES.YEARS:
              if (gotFlatPrice) {
                quotations.errorCount += this.validationQuotationDataMixin(
                  quotations,
                  currency[KEY_NAMES.FLAT][KEY_NAMES.SAMPLES],
                )
                quotations.errorCount += this.validationQuotationDataMixin(
                  quotations,
                  currency[KEY_NAMES.FLAT][KEY_NAMES.YEARS],
                )
              }

              if (gotAnnualPriceDown) {
                quotations.errorCount += this.validationQuotationDataMixin(
                  quotations,
                  currency[KEY_NAMES.ANNUAL_PRICE_DOWN][KEY_NAMES.SAMPLES],
                )

                currency[KEY_NAMES.ANNUAL_PRICE_DOWN][KEY_NAMES.YEARS].forEach(
                  (value) => {
                    quotations.errorCount += this.validationQuotationDataMixin(
                      quotations,
                      value,
                    )
                  },
                )
              }
              break
            case RFQ_TYPES.LOT_SIZES:
              quotations.errorCount += this.validationQuotationDataMixin(
                quotations,
                currency[KEY_NAMES.SAMPLE],
              )
              quotations.errorCount += this.validationQuotationDataMixin(
                quotations,
                currency[KEY_NAMES.SERIES],
              )
              break
            case RFQ_TYPES.SCENARIOS:
              if (gotFlatPrice) {
                quotations.errorCount += this.validationQuotationDataMixin(
                  quotations,
                  currency[KEY_NAMES.FLAT][KEY_NAMES.SAMPLES],
                )
                currency[KEY_NAMES.FLAT][KEY_NAMES.SCENARIOS].forEach(
                  (value) => {
                    quotations.errorCount += this.validationQuotationDataMixin(
                      quotations,
                      value,
                    )
                  },
                )
              }

              if (gotAnnualPriceDown) {
                quotations.errorCount += this.validationQuotationDataMixin(
                  quotations,
                  currency[KEY_NAMES.ANNUAL_PRICE_DOWN][KEY_NAMES.SAMPLES],
                )

                currency[KEY_NAMES.ANNUAL_PRICE_DOWN][
                  KEY_NAMES.SCENARIOS
                ].forEach((array) => {
                  array.forEach((value) => {
                    quotations.errorCount += this.validationQuotationDataMixin(
                      quotations,
                      value,
                    )
                  })
                })
              }
              break
          }
        })
      })
      quotations.invalid = quotations.errorCount > 0
      this.$data.formIsValid = !quotations.invalid
      this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM_OFFER])
    },
    validateSupplierSelectionMixin(supplierSelection) {
      supplierSelection.invalid = false
      if (!supplierSelection.data.length) {
        supplierSelection.invalid = true
        return 1
      }
      return 0
    },
    validateDeliveryLocationsMixin(delivery) {
      let form = this.$data[KEYS_GENERAL.FORM_COPY]
        ? this.$data[KEYS_GENERAL.FORM_COPY]
        : this.$data[KEYS_GENERAL.FORM]
      const RFQ_TYPE = Number(
        form[KEYS_RFQ.PROJECT_DATA_PREFIX][KEYS_RFQ.PROJECT_DATA.RFQ_TYPE]
          .value,
      )
      delivery.invalid = false
      delivery.errorCount = {
        annualForecast: 0,
        address: 0,
        deliveryLocation: 0,
        years: {
          sum: 0,
          entry: new Set(),
        },
        sum: 0,
      }
      if (delivery.data.length) {
        //Check if the quantity per location is greater than 0
        delivery.data.forEach((deliveryLocation) => {
          if (
            deliveryLocation.location.value === '' ||
            deliveryLocation.location.missingAddress === true
          ) {
            deliveryLocation.invalidLocation = true
            delivery.errorCount.address++
          } else {
            deliveryLocation.invalidLocation = false
          }
          let SOP_ARRAY = Object.values(deliveryLocation.sop).map(function (x) {
            return parseFloat(x, 10)
          })
          if (
            parseFloat(deliveryLocation.samples, 10) +
              sum(
                SOP_ARRAY.slice(
                  0,
                  form[KEYS_RFQ.PROJECT_DATA_PREFIX][
                    KEYS_RFQ.PROJECT_DATA.RFQ_YEARS
                  ].value,
                ),
              ) ===
            0
          ) {
            deliveryLocation.invalidSum = true
            delivery.errorCount.deliveryLocation++
          } else {
            deliveryLocation.invalidSum = false
          }

          if (RFQ_TYPE === RFQ_TYPES.LOT_SIZES) {
            const BIGGEST_LOT_SIZE = Math.max.apply(
              Math,
              SOP_ARRAY.slice(
                0,
                form[KEYS_RFQ.PROJECT_DATA_PREFIX][
                  KEYS_RFQ.PROJECT_DATA.RFQ_YEARS
                ].value,
              ),
            )
            if (BIGGEST_LOT_SIZE > deliveryLocation.annualForecast) {
              deliveryLocation.invalidAnnualForecast = true
              delivery.errorCount.annualForecast++
            } else {
              deliveryLocation.invalidAnnualForecast = false
            }
          }
        })

        //Check if the quantity per sample and year is greater than 0
        let validCheck = false
        validCheck =
          this.getColQuantityMixin(delivery.data, 0, true, false) !== 0
        if (!validCheck) {
          delivery.errorCount.years.sum++
          delivery.errorCount.years.entry.add(0)
        }
        for (
          let i = 0;
          i <
          form[KEYS_RFQ.PROJECT_DATA_PREFIX][KEYS_RFQ.PROJECT_DATA.RFQ_YEARS]
            .value;
          i++
        ) {
          validCheck =
            this.getColQuantityMixin(delivery.data, i, false, false) !== 0
          if (!validCheck) {
            delivery.errorCount.years.sum++
            delivery.errorCount.years.entry.add(i + 1)
          }
        }
        delivery.errorCount.sum += delivery.errorCount.address
        delivery.errorCount.sum += delivery.errorCount.deliveryLocation
        delivery.errorCount.sum += delivery.errorCount.annualForecast
        delivery.errorCount.sum += delivery.errorCount.years.sum
      } else {
        delivery.errorCount.sum = 1
      }

      if (delivery.errorCount.sum) {
        delivery.invalid = true
        this.$data.formIsValid = false
      }
      this.setErrorCounterMixin(form)
    },
    resetInvalidStatusMixin(dataGroup) {
      Object.values(dataGroup).forEach((groupItem) => {
        // Is the item a subgroup?
        if (!groupItem.keyName) {
          this.resetInvalidStatusMixin(groupItem)
        } else {
          if (groupItem.invalid) {
            groupItem.invalid = false
          }
          if (groupItem.subItems) {
            groupItem.subItems.forEach((subItem) => {
              if (subItem.invalid) {
                subItem.invalid = false
              }
            })
          }
        }
      })
    },
  },
}
