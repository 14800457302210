export const FILE_TYPES = {
  CUSTOMER_QUOTE_SPECIFIED: 'customerQuoteFileSpecified',
  CUSTOMER_QUOTE_PROPOSED: 'customerQuoteFileProposed',
  PDF_PARSER: 'pdfParser',
  GERBER: 'gerberFile',
  SPECIFICATION: 'specificationFile',
  PANEL_DRAWING: 'panelDrawingFile',
  OTHER: 'otherFile',
  TECH_CONFIRMED: 'techConfirmedFile',
  TECH_CSCC: 'techCSCCFile',
  CONTRACT: 'contractFile',
  ADDENDUM: 'addendumFile',
  COMMUNICATION: 'communicationFile',
  VALIDATION_PLACEHOLDER: 'eFileSuccessfullyUploaded',
  OFFER_UPLOAD: 'offerFile',
  QUOTE_FILE: 'quoteFile',
  QUOTATION: 'quotationFile',
  FRAME_CONTRACT: 'frameContractFile',
  TERMS_CONDITION: 'termsConditionFile',
  STATUS: {
    UPLOAD: {
      ERROR: {
        TEXT: 'Something went wrong',
        STYLE: 'e-upload-status-error',
      },
      PROCESSING: {
        TEXT: 'Processing',
        STYLE: 'e-upload-status-processing',
      },
      IN_PROGRESS: {
        TEXT: 'Uploading (__percentage__%)',
        STYLE: 'e-upload-status-processing',
      },
      SUCCESS: {
        TEXT: 'Upload finished',
        STYLE: 'e-upload-status-success',
      },
    },
    DOWNLOAD: {
      ERROR: {
        TEXT: 'Something went wrong',
        STYLE: 'e-upload-status-error',
      },
      IN_PROGRESS: {
        TEXT: 'Downloading (__percentage__%)',
        STYLE: 'e-upload-status-processing',
      },
      SUCCESS: {
        TEXT: 'Download finished',
        STYLE: 'e-upload-status-success',
      },
    },
    VALIDATION: {
      FORMAT: 'Not allowed file format',
      SIZE: 'Max. file size __filesize__ MB',
      MULTIPLE_FILES: 'Only one file allowed',
    },
    ACCEPTED_FORMATS: 'Accepted formats: __formats__',
    MAX_ALLOWED_FILESIZE: 'Max. file size: __filesize__MB, 1 file only',
    EXISTING_FILE_IS_KEPT: 'Existing upload is kept',
  },
  FORMATS: {
    PDF: 'pdf',
    SEVEN_ZIP: '7z',
    ZIP: 'zip',
    TXT: 'txt',
    GBR: 'gbr',
    JPG: 'jpg',
    JPEG: 'jpeg',
    PNG: 'png',
    DOCX: 'docx',
  },
  MAXIMUM_ALLOWED_SIZE_IN_MIB: 50,
  BYTES_TO_MIB_CONVERSION: 1048576,
}
