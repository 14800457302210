export const IMPORT_EXPORT = {
  PARSE_TYPE: {
    INT: 'int',
    DOUBLE: 'double',
    BOOLEAN: 'boolean',
    DATE: 'date',
    STRING: 'string',
    CURRENCY_ID: 'currencyId',
  },
  API_TRANSFER_STYLE: {
    CUSTOMER_SPECIFIED_PANEL: 'customerSpecifiedPanelTransfer',
    PROPOSED_PANEL: 'proposedPanelTransfer',
  },
}
