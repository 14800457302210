export const PERMISSION_GROUPS = {
  CUSTOMER: {
    VALUE: 'customer',
    TRANSLATION: 'Customer',
  },
  SALES_EXPERT: {
    VALUE: 'salesExpert',
    TRANSLATION: 'Sales Expert',
  },
  SALES_MANAGER: {
    VALUE: 'salesManager',
    TRANSLATION: 'Sales Manager',
  },
  SUPPLIER: {
    VALUE: 'supplier',
    TRANSLATION: 'Supplier',
  },
  SUPPLIER_EXPERT: {
    VALUE: 'supplierExpert',
    TRANSLATION: 'Supplier Expert',
  },
  SUPPLIER_MANAGER: {
    VALUE: 'supplierManager',
    TRANSLATION: 'Supplier Manager',
  },
  NO_PERMISSION: {
    VALUE: 'noPermission',
    TRANSLATION: 'No Permission',
  },
  SUPER_ADMIN: {
    VALUE: 'superAdmin',
    TRANSLATION: 'Super Admin',
  },
}

export const PERMISSION_GROUPS_WITH_ASSIGNMENT_REQUIREMENT = [
  PERMISSION_GROUPS.SALES_EXPERT.VALUE,
  PERMISSION_GROUPS.SALES_MANAGER.VALUE,
  PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE,
  PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE,
]
