import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { FORM_ITEMS } from '@/constants/formItems'
import { IMPORT_EXPORT } from '@/constants/importExport'
import { VALIDATION_TYPE } from '@/constants/validation'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { CURRENCIES } from '@/constants/currency'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'

export let itemAnnualPrice = {
  keyName: KEYS_RFQ.PRICING_DELIVERY.ANNUAL_PRICE,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.PRICING_DELIVERY.ANNUAL_PRICE_DOWN,
  ],
  validationTypes: [],
  checked: true,
  exportCheckedValue: true,
  formType: FORM_ITEMS.TYPES.SWITCH,
  parseType: IMPORT_EXPORT.PARSE_TYPE.BOOLEAN,
  messages: {
    label: 'Need annual Price down?',
    badge: 'Annual price',
  },
}

export let itemFlatPrice = {
  keyName: KEYS_RFQ.PRICING_DELIVERY.NEED_FLAT_PRICE,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.PRICING_DELIVERY.FLAT_PRICE],
  validationTypes: [],
  checked: true,
  exportCheckedValue: true,
  formType: FORM_ITEMS.TYPES.SWITCH,
  parseType: IMPORT_EXPORT.PARSE_TYPE.BOOLEAN,
  messages: {
    label: 'Need flat price?',
    badge: 'Flat price',
  },
}

let itemSpecifyAnnualPrice = {
  keyName: KEYS_RFQ.PRICING_DELIVERY.SPECIFY_ANNUAL_PRICE,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.OFFER_PRICING.REDUCTION_YEARS,
    API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.OFFER_PRICING
      .REDUCTION_PERCENTAGE,
  ],
  subItems: [
    {
      keyName:
        KEYS_RFQ.PRICING_DELIVERY.SPECIFY_ANNUAL_PRICE +
        KEYS_GENERAL.SUB_ITEM_1,
      keyNameParent: KEYS_RFQ.PRICING_DELIVERY.SPECIFY_ANNUAL_PRICE,
      keyNameGroup: KEYS_RFQ.PRICING_DELIVERY_PREFIX,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.OFFER_PRICING
          .REDUCTION_YEARS,
      ],
      value: '',
    },
    {
      keyName:
        KEYS_RFQ.PRICING_DELIVERY.SPECIFY_ANNUAL_PRICE +
        KEYS_GENERAL.SUB_ITEM_2,
      keyNameParent: KEYS_RFQ.PRICING_DELIVERY.SPECIFY_ANNUAL_PRICE,
      keyNameGroup: KEYS_RFQ.PRICING_DELIVERY_PREFIX,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.OFFER_PRICING
          .REDUCTION_PERCENTAGE,
      ],
      value: '',
    },
  ],
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  style: 'mb-3',
  value: '3x3%',
  required: true,
  invalid: false,
  messages: {
    label: 'Specify Annual Price down',
  },
}

export let itemQuotationCurrency = {
  keyName: KEYS_RFQ.PRICING_DELIVERY.QUOTATION_CURRENCY,
  formType: FORM_ITEMS.TYPES.SELECT,
  isCurrency: true,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.PRICING_DELIVERY.CURRENCY],
  style: 'mb-3',
  required: true,
  invalid: false,
  value: CURRENCIES.USD.VALUE,
  defaultOption: 0,
  options: [
    {
      label: CURRENCIES.USD.CURRENCY,
      value: CURRENCIES.USD.VALUE,
    },
    {
      label: CURRENCIES.EUR.CURRENCY,
      value: CURRENCIES.EUR.VALUE,
    },
    {
      label: CURRENCIES.CNY.CURRENCY,
      value: CURRENCIES.CNY.VALUE,
    },
  ],
  messages: {
    placeholder: 'Select',
    label: 'Quotation currency',
    error: 'Please select a quotation currency',
  },
}

export let itemAdditionalQuotationCurrency = {
  keyName: KEYS_RFQ.PRICING_DELIVERY.ADDITIONAL_QUOTATION_CURRENCY,
  formType: FORM_ITEMS.TYPES.SELECT,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  isCurrency: true,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.PRICING_DELIVERY.ADDITIONAL_CURRENCY,
  ],
  style: 'mb-3',
  required: true,
  invalid: false,
  value: CURRENCIES.EUR.VALUE,
  defaultOption: 1,
  options: [
    {
      label: CURRENCIES.USD.CURRENCY,
      value: CURRENCIES.USD.VALUE,
    },
    {
      label: CURRENCIES.EUR.CURRENCY,
      value: CURRENCIES.EUR.VALUE,
    },
    {
      label: CURRENCIES.CNY.CURRENCY,
      value: CURRENCIES.CNY.VALUE,
    },
  ],
  messages: {
    placeholder: 'Select',
    label: 'Additional quotation currency',
    error: 'Please select an additional quotation currency',
  },
}

export let itemNamedPlace = {
  keyName: KEYS_RFQ.PRICING_DELIVERY.NAMED_PLACE,
  formType: FORM_ITEMS.TYPES.SELECT,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.PRICING_DELIVERY.NAMED_PLACE],
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  style: 'mb-3',
  required: true,
  invalid: false,
  value: 'Hong Kong',
  options: [
    {
      label: 'Hong Kong',
      value: 'Hong Kong',
    },
    {
      label: 'New York',
      value: 'New York',
    },
  ],
  messages: {
    placeholder: 'Select',
    label: 'Named Place',
    error: 'Please select an option',
  },
}

let itemExpectedProjectLifeTime = {
  keyName: KEYS_RFQ.PRICING_DELIVERY.EXPECTED_PROJECT_LIFETIME,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.PRICING_DELIVERY.NAMED_PLACE],
  validationTypes: [],
  checked: true,
  formType: FORM_ITEMS.TYPES.SWITCH,
  parseType: IMPORT_EXPORT.PARSE_TYPE.BOOLEAN,
  messages: {
    label: 'Expected Project Lifetime',
  },
}

let itemMinOrderVolume = {
  keyName: KEYS_RFQ.PRICING_DELIVERY.MIN_ORDER_VOLUME,
  style: 'e-large-table-input',
  value: '0',
  required: true,
  invalid: false,
  noStar: true,
  floatingLabel: false,
  radioValueGroup: 0,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_VOLUME],
  validationSettings: {
    formatNumber: true,
  },
  parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
  messages: {},
}

export let rfqPricingDeliveryTypes = {
  [itemAnnualPrice.keyName]: itemAnnualPrice,
  [itemFlatPrice.keyName]: itemFlatPrice,
  [itemSpecifyAnnualPrice.keyName]: itemSpecifyAnnualPrice,
  [itemNamedPlace.keyName]: itemNamedPlace,
  [itemQuotationCurrency.keyName]: itemQuotationCurrency,
  [itemAdditionalQuotationCurrency.keyName]: itemAdditionalQuotationCurrency,
  [itemExpectedProjectLifeTime.keyName]: itemExpectedProjectLifeTime,
  [itemMinOrderVolume.keyName]: itemMinOrderVolume,
}
