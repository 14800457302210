import { ROUTER_DATA } from '@/constants/routerData'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { BADGE_TRANSLATION, BADGE_STYLE } from '@/constants/badges'
import { RFQ_ACTIONS } from '@/constants/rfqActions'
import { union } from 'lodash/array'

// State Types
export const STATE_TYPE = {
  RFQ: 1,
  OFFER: 2,
}

// States locked to beginSupplierSelection are only used in the frontend for the notification system and RFQ Actions
const LOCKED = 'locked'
const DELETED = 'deleted'
const RECALLED = 'recalled'
const ADDED_SUPPLIER = 'addedSupplier'
const SET_NEW_DUE_DATE = 'setNewDueDate'
const REQUEST_SUPPLIER_TO_REWORK = 'requestSupplierToRework'
const CALCULATE_INSTANT_QUOTE = 'calculateInstantQuote'
const QUOTE_ACCEPTED_INSTANT_QUOTE = 'quoteAcceptedInstantQuote'
const PROVIDE_RFQ_INFORMATION = 'provideRFQInformation'
const BEGIN_SUPPLIER_SELECTION = 'beginSupplierSelection'

const DRAFT = 'draft'
const NEW = 'new'
const VALIDATED = 'validated'
const CUSTOMER_CLARIFICATION = 'customer_clarification'
const INTERNAL_CLARIFICATION = 'internal_clarification'
const EXTERNAL_CLARIFICATION = 'external_clarification'
const SUPPLIER_SELECTION = 'supplier_selection'
const SUPPLIER_OFFER_REQUESTED = 'supplier_offer_requested'
const SUPPLIER_OFFERS_COMPLETED = 'supplier_offers_completed'
const QUOTE_CALCULATION = 'quote_calculation'
const QUOTE_TO_APPROVE = 'quote_to_approve'
const QUOTE_APPROVED = 'quote_approved'
const QUOTE_APPROVAL_REJECTED = 'quote_approval_rejected'
const QUOTE_SENT = 'quote_sent'
const QUOTE_REJECTED = 'quote_rejected'
const QUOTE_TO_REWORK = 'quote_to_rework'
const QUOTE_ACCEPTED = 'quote_accepted'
const OFFER_CONTRACT_APPROVAL = 'offer_contract_approval'
const OFFER_CONTRACT_APPROVED = 'offer_contract_approved'
const WAITING_FOR_CONFIRMATION = 'offer_waiting_for_confirmation'
const CONTRACTED = 'offer_contracted'
const CANCELLED = 'cancelled'
const REJECTED = 'rejected'
const CHANGE_REQUESTED = 'change_requested'
const ARCHIVED = 'archived'

//Offer States
export const OFFER_NEW = 'new'
export const OFFER_REJECTED_BY_SUPPLIER = 'offer_rejected_by_supplier'
export const OFFER_MADE = 'offer_made'
export const OFFER_EXPIRED = 'offer_expired'
export const OFFER_NEEDS_REWORK = 'offer_needs_rework'
export const OFFER_SELECTED = 'offer_selected'
export const OFFER_NOT_SELECTED = 'offer_not_selected'
export const OFFER_UNDER_CONTRACTING = 'under_contracting'
export const OFFER_CONTRACTED = 'offer_contracted'

export const COMPARISON_OF_OFFERS_STATES = [
  OFFER_MADE,
  OFFER_SELECTED,
  OFFER_NOT_SELECTED,
  OFFER_UNDER_CONTRACTING,
  OFFER_CONTRACTED,
]
export const RFQ_REJECTED_CANCELLED_BY_CUSTOMER =
  'rfq_rejected_cancelled_by_customer'
export const RFQ_STATES = [
  DRAFT,
  NEW,
  CUSTOMER_CLARIFICATION,
  VALIDATED,
  INTERNAL_CLARIFICATION,
  EXTERNAL_CLARIFICATION,
  SUPPLIER_SELECTION,
  SUPPLIER_OFFER_REQUESTED,
  SUPPLIER_OFFERS_COMPLETED,
  QUOTE_CALCULATION,
  QUOTE_TO_APPROVE,
  QUOTE_APPROVAL_REJECTED,
  QUOTE_APPROVED,
  QUOTE_SENT,
  QUOTE_REJECTED,
  QUOTE_TO_REWORK,
  QUOTE_ACCEPTED,
  OFFER_CONTRACT_APPROVAL,
  OFFER_CONTRACT_APPROVED,
  WAITING_FOR_CONFIRMATION,
  CONTRACTED,
  CANCELLED,
  REJECTED,
  CHANGE_REQUESTED,
  ARCHIVED,
]

export const OFFER_STATES = [
  OFFER_NEW,
  OFFER_REJECTED_BY_SUPPLIER,
  OFFER_MADE,
  OFFER_EXPIRED,
  OFFER_NEEDS_REWORK,
  OFFER_SELECTED,
  OFFER_NOT_SELECTED,
  OFFER_UNDER_CONTRACTING,
  OFFER_CONTRACTED,
  RFQ_REJECTED_CANCELLED_BY_CUSTOMER,
  ARCHIVED,
]

export const RFQ_STATUS_PLACEHOLDER_DATA = {
  [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [SUPPLIER_OFFERS_COMPLETED],
  [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [SUPPLIER_OFFERS_COMPLETED],
  [PERMISSION_GROUPS.SUPPLIER.VALUE]: [SUPPLIER_OFFER_REQUESTED],
  [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [SUPPLIER_OFFERS_COMPLETED],
  [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [SUPPLIER_OFFERS_COMPLETED],
}

export const REQUIRE_ACTION_STATES = {
  [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
    CUSTOMER_CLARIFICATION,
    EXTERNAL_CLARIFICATION,
    QUOTE_SENT,
  ],
  [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [
    NEW,
    VALIDATED,
    INTERNAL_CLARIFICATION,
    QUOTE_CALCULATION,
    QUOTE_APPROVAL_REJECTED,
    QUOTE_APPROVED,
    QUOTE_TO_REWORK,
    CHANGE_REQUESTED,
  ],
  [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [QUOTE_TO_APPROVE],
  [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
    NEW,
    OFFER_NEEDS_REWORK,
    OFFER_UNDER_CONTRACTING,
  ],
  [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [
    SUPPLIER_SELECTION,
    SUPPLIER_OFFERS_COMPLETED,
    QUOTE_ACCEPTED,
    OFFER_CONTRACT_APPROVED,
  ],
  [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [OFFER_CONTRACT_APPROVAL],
}

// RfQ Route Filters
const CUSTOMER_FILTER = {
  MY_RFQS: {
    IN_PROGRESS: [
      NEW,
      VALIDATED,
      SUPPLIER_SELECTION,
      INTERNAL_CLARIFICATION,
      SUPPLIER_OFFER_REQUESTED,
      SUPPLIER_OFFERS_COMPLETED,
      QUOTE_CALCULATION,
      QUOTE_TO_APPROVE,
      QUOTE_APPROVED,
    ],
    CLARIFICATION: [
      EXTERNAL_CLARIFICATION,
      REJECTED,
      CHANGE_REQUESTED,
      QUOTE_TO_REWORK,
      CUSTOMER_CLARIFICATION,
      QUOTE_APPROVAL_REJECTED,
    ],
    QUOTE: [QUOTE_SENT],
    DRAFTS: [DRAFT],
  },
  MY_BUSINESS: {
    ORDERS: [],
    CONTRACTS: [
      QUOTE_ACCEPTED,
      OFFER_CONTRACT_APPROVAL,
      OFFER_CONTRACT_APPROVED,
      WAITING_FOR_CONFIRMATION,
      CONTRACTED,
    ],
    SERIES_DELIVERY: [],
  },
  ARCHIVE: [CANCELLED, QUOTE_REJECTED, ARCHIVED],
}

const SUPPLIER_FILTER = {
  RFQ_INBOX: [OFFER_NEW],
  RUNNING_RFQS: [
    OFFER_NEEDS_REWORK,
    OFFER_MADE,
    OFFER_SELECTED,
    OFFER_NOT_SELECTED,
  ],
  CONTRACTS: [OFFER_CONTRACTED, OFFER_UNDER_CONTRACTING],
  ARCHIVE: [
    OFFER_EXPIRED,
    OFFER_REJECTED_BY_SUPPLIER,
    RFQ_REJECTED_CANCELLED_BY_CUSTOMER,
    ARCHIVED,
  ],
}

export const SUPPLIER_EXPERT_FILTER = {
  NEW_RFQS: [SUPPLIER_SELECTION],
  OVERVIEW: [CHANGE_REQUESTED, CANCELLED, REJECTED],
  RFQ_PREPARATION: [INTERNAL_CLARIFICATION, EXTERNAL_CLARIFICATION],
  OFFER_COLLECTION: [SUPPLIER_OFFER_REQUESTED, SUPPLIER_OFFERS_COMPLETED],
  RUNNING_RFQ: [
    QUOTE_CALCULATION,
    QUOTE_TO_APPROVE,
    QUOTE_APPROVAL_REJECTED,
    QUOTE_APPROVED,
    QUOTE_SENT,
    QUOTE_REJECTED,
    QUOTE_TO_REWORK,
  ],
  SOURCING_CONTRACTING: [
    QUOTE_ACCEPTED,
    OFFER_CONTRACT_APPROVAL,
    OFFER_CONTRACT_APPROVED,
    WAITING_FOR_CONFIRMATION,
  ],
  CONTRACTS: [OFFER_CONTRACTED],
  ARCHIVE: [CANCELLED, REJECTED, QUOTE_REJECTED],
}

const SALES_EXPERT_FILTER = {
  NEW_RFQS: [NEW],
  OVERVIEW: [CANCELLED, REJECTED],
  IN_VALIDATION: [
    CUSTOMER_CLARIFICATION,
    VALIDATED,
    INTERNAL_CLARIFICATION,
    EXTERNAL_CLARIFICATION,
    CHANGE_REQUESTED,
  ],
  QUOTE_PREPARATION: [
    SUPPLIER_SELECTION,
    SUPPLIER_OFFER_REQUESTED,
    SUPPLIER_OFFERS_COMPLETED,
    QUOTE_CALCULATION,
    QUOTE_TO_APPROVE,
    QUOTE_APPROVAL_REJECTED,
    QUOTE_APPROVED,
    QUOTE_TO_REWORK,
  ],
  CONTRACT_PREPARATION: [
    QUOTE_SENT,
    QUOTE_REJECTED,
    QUOTE_ACCEPTED,
    OFFER_CONTRACT_APPROVAL,
    OFFER_CONTRACT_APPROVED,
    WAITING_FOR_CONFIRMATION,
  ],
  IN_CONTRACT: [OFFER_CONTRACTED],
  ARCHIVE: [CANCELLED, REJECTED, QUOTE_REJECTED],
}

export const STATES = {
  OFFER_NEW: {
    VALUE: OFFER_NEW,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.NEW_RFQ,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.NEW,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.SUBMIT_OFFER.VALUE,
        RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
        RFQ_ACTIONS.ENTER_QUOTE.VALUE,
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
        RFQ_ACTIONS.REJECT_OFFER.VALUE,
        RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_NEEDS_REWORK: {
    VALUE: OFFER_NEEDS_REWORK,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.OFFER_NEEDS_REWORK,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFER_NEEDS_REWORK,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
        RFQ_ACTIONS.ENTER_QUOTE.VALUE,
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
        RFQ_ACTIONS.SUBMIT_OFFER.VALUE,
        RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_MADE: {
    VALUE: OFFER_MADE,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.OFFER_IN_PROGRESS,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFER_IN_PROGRESS,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_EXPIRED: {
    VALUE: OFFER_EXPIRED,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.RFQ_EXPIRED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.EXPIRED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.PURPLE,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.PURPLE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_RFQ_REJECTED: {
    VALUE: OFFER_REJECTED_BY_SUPPLIER,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.RFQ_REJECTED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.REJECTED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_SELECTED: {
    VALUE: OFFER_SELECTED,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.OFFER_IN_PROGRESS,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFER_IN_PROGRESS,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_NOT_SELECTED: {
    VALUE: OFFER_NOT_SELECTED,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.RFQ_LOST,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_UNDER_CONTRACTING: {
    VALUE: OFFER_UNDER_CONTRACTING,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]:
        BADGE_TRANSLATION.OFFER_UNDER_CONTRACTING,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFER_UNDER_CONTRACTING,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
        RFQ_ACTIONS.ACCEPT_CONTRACT.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_CONTRACTED: {
    VALUE: OFFER_CONTRACTED,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.OFFER_CONTRACTED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFER_CONTRACTED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DARK_GREEN,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.DARK_GREEN,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  RFQ_REJECTED_CANCELLED_BY_CUSTOMER: {
    VALUE: RFQ_REJECTED_CANCELLED_BY_CUSTOMER,
    TYPE: STATE_TYPE.OFFER,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.RFQ_LOST,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  CANCELLED: {
    VALUE: CANCELLED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.CANCELLED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.CANCELLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.CANCELLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.CANCELLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.CANCELLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.CANCELLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.GREY,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.GREY,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.GREY,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.GREY,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.GREY,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  DRAFT: {
    VALUE: DRAFT,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.DRAFT,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.GREY,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.EDIT_DRAFT.VALUE,
        RFQ_ACTIONS.DELETE_DRAFT.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  ARCHIVED: {
    VALUE: ARCHIVED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.ARCHIVED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.ARCHIVED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.ARCHIVED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.ARCHIVED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.ARCHIVED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.ARCHIVED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.ARCHIVED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.RED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  NEW: {
    VALUE: NEW,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.NEW_RFQ,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.REQUEST_CHANGE.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [
        RFQ_ACTIONS.CLARIFICATION_REQUEST.VALUE,
        RFQ_ACTIONS.REJECT_RFQ.VALUE,
        RFQ_ACTIONS.VALIDATE_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  CUSTOMER_CLARIFICATION: {
    VALUE: CUSTOMER_CLARIFICATION,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]:
        BADGE_TRANSLATION.CLARIFICATION_NEEDED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]:
        BADGE_TRANSLATION.IN_CLARIFICATION,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.PREPARE_PROVIDE_RFQ_INFORMATION.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  VALIDATED: {
    VALUE: VALIDATED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.RFQ_TO_PREPARE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.REQUEST_CHANGE.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [
        RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
        RFQ_ACTIONS.PREPARE_INSTANT_QUOTE.VALUE,
        RFQ_ACTIONS.REJECT_RFQ.VALUE,
        RFQ_ACTIONS.SELECT_SUPPLIERS.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  EXTERNAL_CLARIFICATION: {
    VALUE: EXTERNAL_CLARIFICATION,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]:
        BADGE_TRANSLATION.CLARIFICATION_NEEDED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]:
        BADGE_TRANSLATION.IN_CLARIFICATION,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.IN_CLARIFICATION,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.REQUEST_CHANGE.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  REJECTED: {
    VALUE: REJECTED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.RFQ_REJECTED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.RFQ_REJECTED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.RFQ_REJECTED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.RFQ_REJECTED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]:
        BADGE_TRANSLATION.RFQ_REJECTED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.RFQ_REJECTED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.RED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.RED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.VIEW_REJECTION.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  INTERNAL_CLARIFICATION: {
    VALUE: INTERNAL_CLARIFICATION,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]:
        BADGE_TRANSLATION.CLARIFICATION_NEEDED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.IN_CLARIFICATION,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.REQUEST_CHANGE.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  SUPPLIER_SELECTION: {
    VALUE: SUPPLIER_SELECTION,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFER_PREPARATION,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.NEW_RFQ,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.REQUEST_CHANGE.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [
        RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
        RFQ_ACTIONS.REQUEST_OFFER.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  SUPPLIER_OFFER_REQUESTED: {
    VALUE: SUPPLIER_OFFER_REQUESTED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFER_PREPARATION,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFER_REQUESTED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.REQUEST_CHANGE.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
        RFQ_ACTIONS.SUBMIT_OFFER.VALUE,
        RFQ_ACTIONS.DOWNLOAD_DATA.VALUE,
        RFQ_ACTIONS.CONTACT.VALUE,
        RFQ_ACTIONS.REJECT_OFFER.VALUE,
        RFQ_ACTIONS.OFFER_DUE.VALUE,
        RFQ_ACTIONS.SAVE_SUPPLIER_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [
        RFQ_ACTIONS.PREPARE_SET_NEW_DUE_DATE.VALUE,
        RFQ_ACTIONS.PREPARE_ADD_NEW_SUPPLIER.VALUE,
        RFQ_ACTIONS.PREPARE_REQUEST_REWORK_RFQ.VALUE,
        RFQ_ACTIONS.PREPARE_RECALL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  SUPPLIER_OFFERS_COMPLETED: {
    VALUE: SUPPLIER_OFFERS_COMPLETED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFER_PREPARATION,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.OFFERS_TO_REVIEW,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.REQUEST_CHANGE.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [
        RFQ_ACTIONS.REOPEN_OFFERS.VALUE,
        RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SEND_OUT_CONTRACTS.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_CONTRACT_APPROVAL: {
    VALUE: OFFER_CONTRACT_APPROVAL,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.CONTRACTS_AT_MANAGER,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]:
        BADGE_TRANSLATION.CONTRACT_TO_APPROVE,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  QUOTE_CALCULATION: {
    VALUE: QUOTE_CALCULATION,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]:
        BADGE_TRANSLATION.QUOTE_TO_PREPARE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.REQUEST_CHANGE.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [
        RFQ_ACTIONS.PREPARE_REQUEST_SUPPLIER_EXPERT_TO_REWORK.VALUE,
        RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CREATE_QUOTE.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  QUOTE_TO_APPROVE: {
    VALUE: QUOTE_TO_APPROVE,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_APPROVAL,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]:
        BADGE_TRANSLATION.QUOTE_TO_APPROVE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.REQUEST_CHANGE.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  QUOTE_APPROVAL_REJECTED: {
    VALUE: QUOTE_APPROVAL_REJECTED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_TO_REWORK,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]:
        BADGE_TRANSLATION.QUOTE_TO_REWORK,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [
        RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CREATE_QUOTE.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  QUOTE_APPROVED: {
    VALUE: QUOTE_APPROVED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_TO_SEND,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.QUOTE_APPROVED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
        RFQ_ACTIONS.CANCEL_RFQ.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [RFQ_ACTIONS.SEND_QUOTE.VALUE],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  QUOTE_SENT: {
    VALUE: QUOTE_SENT,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.QUOTE_RECEIVED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_SENT,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.QUOTE_SENT,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_SENT,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  QUOTE_REJECTED: {
    VALUE: QUOTE_REJECTED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.QUOTE_REJECTED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_REJECTED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.QUOTE_REJECTED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.QUOTE_REJECTED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.PURPLE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.PURPLE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.PURPLE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.PURPLE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  QUOTE_TO_REWORK: {
    VALUE: QUOTE_TO_REWORK,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.CHANGE_REQUESTED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.RFQ_TO_REWORK,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]:
        BADGE_TRANSLATION.CHANGE_REQUESTED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.IN_PROGRESS,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [
        RFQ_ACTIONS.PREPARE_REQUEST_SUPPLIER_EXPERT_TO_REWORK.VALUE,
        RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CREATE_QUOTE.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  QUOTE_ACCEPTED: {
    VALUE: QUOTE_ACCEPTED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.FINAL_SOURCING,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [
        RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
        RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
        RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
        RFQ_ACTIONS.PREPARE_CONTRACTS.VALUE,
        RFQ_ACTIONS.BEGIN_SUPPLIER_SELECTION.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  OFFER_CONTRACT_APPROVED: {
    VALUE: OFFER_CONTRACT_APPROVED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.FINISH_CONTRACTING,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]:
        BADGE_TRANSLATION.UNDER_CONTRACTING,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [
        RFQ_ACTIONS.SEND_OUT_CONTRACTS.VALUE,
      ],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  CHANGE_REQUESTED: {
    VALUE: CHANGE_REQUESTED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.CHANGE_REQUESTED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]:
        BADGE_TRANSLATION.CHANGE_REQUESTED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]:
        BADGE_TRANSLATION.CHANGE_REQUESTED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.CHANGE_REQUESTED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]:
        BADGE_TRANSLATION.CHANGE_REQUESTED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]:
        BADGE_TRANSLATION.CHANGE_REQUESTED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.YELLOW,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.VIEW_CHANGE_REQUEST.VALUE,
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  WAITING_FOR_CONFIRMATION: {
    VALUE: WAITING_FOR_CONFIRMATION,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]:
        BADGE_TRANSLATION.CONTRACTS_AT_SUPPLIERS,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]:
        BADGE_TRANSLATION.UNDER_CONTRACTING,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.DISABLED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.BLUE,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.DISABLED,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  CONTRACTED: {
    VALUE: CONTRACTED,
    TYPE: STATE_TYPE.RFQ,
    TRANSLATION: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_TRANSLATION.QUOTE_ACCEPTED,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_TRANSLATION.CONTRACTED,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_TRANSLATION.CONTRACTED,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_TRANSLATION.DISABLED,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_TRANSLATION.CONTRACTED,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_TRANSLATION.CONTRACTED,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_TRANSLATION.CONTRACTED,
    },
    STYLE: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: BADGE_STYLE.GREEN,
      [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: BADGE_STYLE.GREEN,
    },
    ACTIONS: {
      [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
        RFQ_ACTIONS.COPY_AND_CREATE_NEW.VALUE,
      ],
      [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [],
      [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [],
    },
  },
  DELETED: {
    VALUE: DELETED,
  },
  CALCULATE_INSTANT_QUOTE: {
    VALUE: CALCULATE_INSTANT_QUOTE,
  },
  QUOTE_ACCEPTED_INSTANT_QUOTE: {
    VALUE: QUOTE_ACCEPTED_INSTANT_QUOTE,
  },
  PROVIDE_RFQ_INFORMATION: {
    VALUE: PROVIDE_RFQ_INFORMATION,
  },
  LOCKED: {
    VALUE: LOCKED,
  },
  RECALLED: {
    VALUE: RECALLED,
  },
  ADDED_SUPPLIER: {
    VALUE: ADDED_SUPPLIER,
  },
  SET_NEW_DUE_DATE: {
    VALUE: SET_NEW_DUE_DATE,
  },
  REQUEST_SUPPLIER_TO_REWORK: {
    VALUE: REQUEST_SUPPLIER_TO_REWORK,
  },
  BEGIN_SUPPLIER_SELECTION: {
    VALUE: BEGIN_SUPPLIER_SELECTION,
  },
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW.NAME]: union(
    CUSTOMER_FILTER.MY_RFQS.IN_PROGRESS,
    CUSTOMER_FILTER.MY_RFQS.CLARIFICATION,
    CUSTOMER_FILTER.MY_RFQS.QUOTE,
  ),
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.IN_PROGRESS.NAME]:
    CUSTOMER_FILTER.MY_RFQS.IN_PROGRESS,
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.CLARIFICATION.NAME]:
    CUSTOMER_FILTER.MY_RFQS.CLARIFICATION,
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.QUOTE.NAME]:
    CUSTOMER_FILTER.MY_RFQS.QUOTE,
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.DRAFTS.NAME]:
    CUSTOMER_FILTER.MY_RFQS.DRAFTS,
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].ARCHIVE.NAME]:
    CUSTOMER_FILTER.ARCHIVE,
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.OVERVIEW.NAME]:
    union(
      CUSTOMER_FILTER.MY_BUSINESS.CONTRACTS,
      CUSTOMER_FILTER.MY_BUSINESS.ORDERS,
      CUSTOMER_FILTER.MY_BUSINESS.SERIES_DELIVERY,
    ),
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS
    .CONTRACT_PREPARATION.NAME]: CUSTOMER_FILTER.MY_BUSINESS.CONTRACTS,
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.ACCEPTED.NAME]:
    CUSTOMER_FILTER.MY_BUSINESS.ORDERS,
  [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.DELIVERY.NAME]:
    CUSTOMER_FILTER.MY_BUSINESS.SERIES_DELIVERY,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX.NAME]:
    SUPPLIER_FILTER.RFQ_INBOX,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS.NAME]:
    SUPPLIER_FILTER.RUNNING_RFQS,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].CONTRACTS.NAME]:
    SUPPLIER_FILTER.CONTRACTS,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].ARCHIVE.NAME]:
    SUPPLIER_FILTER.ARCHIVE,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS.NAME]:
    SUPPLIER_EXPERT_FILTER.NEW_RFQS,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OVERVIEW.NAME]: union(
    SUPPLIER_EXPERT_FILTER.OVERVIEW,
    SUPPLIER_EXPERT_FILTER.RFQ_PREPARATION,
    SUPPLIER_EXPERT_FILTER.OFFER_COLLECTION,
    SUPPLIER_EXPERT_FILTER.RUNNING_RFQ,
    SUPPLIER_EXPERT_FILTER.SOURCING_CONTRACTING,
    SUPPLIER_EXPERT_FILTER.CONTRACTS,
    SUPPLIER_EXPERT_FILTER.ARCHIVE,
  ),
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_PREPARATION.NAME]:
    SUPPLIER_EXPERT_FILTER.RFQ_PREPARATION,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RUNNING_RFQ.NAME]:
    SUPPLIER_EXPERT_FILTER.RUNNING_RFQ,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OFFER_COLLECTION.NAME]:
    SUPPLIER_EXPERT_FILTER.OFFER_COLLECTION,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].SOURCING_CONTRACTING
    .NAME]: SUPPLIER_EXPERT_FILTER.SOURCING_CONTRACTING,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].CONTRACTS.NAME]:
    SUPPLIER_EXPERT_FILTER.CONTRACTS,
  [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].ARCHIVE.NAME]:
    SUPPLIER_EXPERT_FILTER.ARCHIVE,
  [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD.NAME]: union(
    SALES_EXPERT_FILTER.NEW_RFQS,
    SALES_EXPERT_FILTER.IN_VALIDATION,
    SALES_EXPERT_FILTER.QUOTE_PREPARATION,
    SALES_EXPERT_FILTER.CONTRACT_PREPARATION,
  ),
  [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].NEW_RFQS.NAME]:
    SALES_EXPERT_FILTER.NEW_RFQS,
  [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].OVERVIEW.NAME]: union(
    SALES_EXPERT_FILTER.OVERVIEW,
    SALES_EXPERT_FILTER.IN_VALIDATION,
    SALES_EXPERT_FILTER.QUOTE_PREPARATION,
  ),
  [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_VALIDATION.NAME]:
    SALES_EXPERT_FILTER.IN_VALIDATION,
  [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].QUOTE_PREPARATION.NAME]:
    SALES_EXPERT_FILTER.QUOTE_PREPARATION,
  [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACTS_OVERVIEW.NAME]:
    union(
      SALES_EXPERT_FILTER.CONTRACT_PREPARATION,
      SALES_EXPERT_FILTER.IN_CONTRACT,
    ),
  [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACT_PREPARATION.NAME]:
    SALES_EXPERT_FILTER.CONTRACT_PREPARATION,
  [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_CONTRACT.NAME]:
    SALES_EXPERT_FILTER.IN_CONTRACT,
  [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].ARCHIVE.NAME]:
    SALES_EXPERT_FILTER.ARCHIVE,
}
