import { NOT_SPECIFIED } from '@/constants/constants'
import capitalize from 'lodash/capitalize'

export default {
  methods: {
    numberFormatMixin(
      number,
      currencyData = { isActive: false, currency: 'USD' },
      returnPlaceholderIfEmpty = false,
    ) {
      if (number) {
        if (currencyData.isActive) {
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyData.currency,
          }).format(number)
        } else {
          return new Intl.NumberFormat('en-US', {}).format(number)
        }
      }
      if (returnPlaceholderIfEmpty) {
        return capitalize(NOT_SPECIFIED)
      }
      return number
    },
  },
}
