const RFQ = 'RfQ'
const QUOTE = 'Quote'

export const INFO_ANCHORS = {
  USER: {
    NAME: 'Name',
    LAST_LOGIN: 'LastLogin',
  },
  RFQ: {
    ID: `${RFQ}ID`,
    INTERNAL_ID: `${RFQ}InternalID`,
    CREATED_AT: `${RFQ}CreatedAt`,
    CREATED_BY: `${RFQ}CreatedBY`,
    UPDATED_AT: `${RFQ}UpdatedAt`,
    UPDATED_BY: `${RFQ}UpdatedBy`,
    STATUS: `${RFQ}Status`,
    TITLE: `${RFQ}Title`,
    CUSTOMER: `${RFQ}Customer`,
  },
  ADDRESS: {
    CATEGORY: 'Address',
    NICKNAME: 'Nickname',
    COMPANY: 'Company',
    VAT_ID: 'VatID',
    PHONE: 'Phone',
    CITY: 'City',
    ZIP: 'Zip',
    STREET: 'Street',
    COUNTRY: 'Country',
  },
  DOCUMENTS: {
    GERBER_FILE: 'GerberFile',
    PANEL_DRAWING: 'PanelDrawing',
    SPECIFICATION: 'Specification',
    OTHER_DOCUMENTS: 'OtherDocuments',
    QUOTE_FILE: 'QUOTE_FILE',
    QUOTATION: 'Quotation',
    FRAME_CONTRACT: 'FrameContract',
    TERMS_CONDITIONS: 'TerminsConditions',
  },
  DELIVERY_LOCATION: {
    COUNTER: 'DeliveryLocationCount',
    NAME: 'Name',
  },
  QUOTE: {
    TOTAL_RFQ_QUANTITY: `${QUOTE}TotalRfQQuantity`,
    FULL_SWING_QUANTITY: `${QUOTE}FullSwingQuantity`,
    AVERAGE_PRICE_PER_PIECE: `${QUOTE}AveragePricePerPiece`,
    VALIDITY: `${QUOTE}Validity`,
  },
  DETAILS: {
    CATEGORY: 'RfQDetails',
    PROJECT_DATA: 'ProjectData',
    DOCUMENTS: 'Documents',
    DELIVERY: 'Delivery',
    PCB_TECHNOLOGY_DATA: 'PCBTechnologyData',
  },
  COMMUNICATION: {
    CATEGORY: 'Communication',
    SALES_EXPERT: 'SalesExpert',
    NOTES_FOR_ME: 'NotesForMe',
    INTERNAL: 'Internal',
    SUPPLIER: 'Supplier',
    CUSTOMER: 'Customer',
    USER: 'User',
    DATE: 'Date',
    MESSAGE: 'Message',
    ROLE: 'Role',
    COUNT: 'Count',
    MAX_LENGTH: 'MaxLength',
    ADDITIONAL_INFORMATION: 'AdditionalInformation',
    CLARIFICATION_REQUEST: 'ClarificationRequest',
    REJECT_REASON: 'RejectReason',
    RECALL_RFQ: 'RecallRfQ',
    REQUEST_REWORK_RFQ: 'RequestReworkRfQ',
    SUPPLIER_SELECTION: 'SupplierSelection',
    CHANGE_REQUEST: `ChangeRequest`,
    REQUEST_SUPPLIER_EXPERT_REWORK: 'RequestSupplierExpertRework',
    PROVIDE_RFQ_INFORMATION: 'ProvideRfQInformation',
  },
  TECH_CSCC: {
    TECH_CONFIRMATION_FILE: 'TechConfirmationFile',
    CSCC_FILE: 'CSCCFile',
    COMMENT: 'TechComment',
  },
  QUOTATION_UPLOAD: {
    QUOTATION_FILE: 'QuotationFile',
  },
  NRE_MOV: {
    TESTER_CHARGE: 'TesterCharge',
    OTHER_CHARGES: 'OtherCharges',
  },
  PDF_PARSER: 'PdfParser',
  CUSTOMER_QUOTE: 'CustomerQuote',
  QUOTATION_NOTES: {
    GENERAL_CONDITIONS: 'GeneralConditions',
    FURTHER_REMARKS: 'FurtherRemarks',
  },
  TECH_OFFER_BASE_CUSTOMER_FREE_TEXT: 'TechOfferBaseCustomerFreeText',
  PRICE_INDICATION: {
    COST_MATRIX_COMMENT: 'CostMatrixComment',
  },
  PRICE_PER_MOV: 'PricePerMOV',
  QUOTE_DUE_DATE: 'QuoteDueDate',
  ASSIGNED_TO_ME: 'AssignedToMe',
  OFFERS_COUNT: {
    RECEIVED: 'OffersReceived',
    REJECTED_EXPIRED: 'OffersRejectedExpired',
    TOTAL: 'OffersTotal',
  },
}
