import { RFQ_TYPES } from '@/constants/constants'
import numberMixins from '@/mixins/numberMixins'
import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import capitalize from 'lodash/capitalize'
import { TABLE_COLUMN_ANCHORS } from '@/constants/anchorTags/tableColumnAnchorTags'

export default {
  mixins: [numberMixins],
  methods: {
    getRFQTypeTableAnchorTagMixin(rfqType) {
      switch (rfqType) {
        case RFQ_TYPES.YEARS:
          return TABLE_COLUMN_ANCHORS.QUANTITY_PER_YEAR
        case RFQ_TYPES.SCENARIOS:
          return TABLE_COLUMN_ANCHORS.QUANTITY_PER_SCENARIO
        case RFQ_TYPES.LOT_SIZES:
          return TABLE_COLUMN_ANCHORS.QUANTITY_PER_LOT_SIZE
      }
    },
    getRFQTypeLabelMixin(rfqType, capitalizeLabel = false) {
      let label
      switch (rfqType) {
        case RFQ_TYPES.YEARS:
          label = 'years'
          break
        case RFQ_TYPES.SCENARIOS:
          label = 'scenarios'
          break
        case RFQ_TYPES.LOT_SIZES:
          label = 'lot sizes'
          break
      }
      return capitalizeLabel ? capitalize(label) : label
    },
    getRFQTypeTableLabelMixin(rfqType, entry, sop, sopStartIndex = null) {
      switch (rfqType) {
        case RFQ_TYPES.YEARS:
          if (entry === 1 || entry === sopStartIndex) {
            return `SOP ${Number(sop) + (Number(entry) - 1)}`
          } else {
            return `${Number(sop) + (Number(entry) - 1)}`
          }
        case RFQ_TYPES.LOT_SIZES:
          return `Lot size ${entry}`
        case RFQ_TYPES.SCENARIOS:
          return `Scenario ${entry}`
      }
    },
    getRFQTypeTotalQuantityMixin(rfqType, delivery) {
      let sum = 0
      switch (rfqType) {
        case RFQ_TYPES.YEARS:
          delivery.data.forEach((deliveryLocation) => {
            sum += deliveryLocation.sop.reduce(
              (sum, x) => Number(sum) + Number(x),
            )
            sum += Number(deliveryLocation.samples)
          })
          return this.numberFormatMixin(sum)
        case RFQ_TYPES.LOT_SIZES:
          delivery.data.forEach((deliveryLocation) => {
            sum += Number(deliveryLocation.annualForecast)
          })
          return this.numberFormatMixin(sum)
      }
    },
    getFooterTotalQuantityMixin(length = 3, delivery, returnFormatted = true) {
      let sum = 0
      delivery.data.forEach((deliveryLocation) => {
        sum += deliveryLocation.sop
          .slice(0, length)
          .reduce((sum, x) => Number(sum) + Number(x))
      })
      if (returnFormatted) {
        return this.numberFormatMixin(sum)
      } else {
        return sum
      }
    },
    getFooterTotalQuantitySupplierMixin(length = 3, delivery, supplier) {
      let sum = 0
      let sopQuantity = 0
      for (let i = 0; i < length - 1; i++) {
        sopQuantity = this.getRFQTypeEntrySumMixin(false, i, delivery, false)

        // Number at the end to remove trailing zeros
        if (supplier && supplier[KEYS_RFQ.SUPPLIER_SETTINGS].data.annualPrice) {
          sum += Number(
            Number(sopQuantity) *
              Number(
                supplier[KEYS_RFQ.SUPPLIER_SETTINGS].data.annualPrice.price[i],
              ),
          )
        }
      }
      return this.numberFormatMixin(sum)
    },
    getRFQTypeEntrySumMixin(
      isSamples = false,
      entry,
      delivery,
      formatResult = true,
    ) {
      let sum = 0
      if (isSamples) {
        delivery.data.forEach((deliveryLocation) => {
          sum += Number(deliveryLocation.samples)
        })
      } else {
        delivery.data.forEach((deliveryLocation) => {
          sum += Number(deliveryLocation.sop[entry])
        })
      }
      return formatResult ? this.numberFormatMixin(sum) : sum
    },
  },
}
