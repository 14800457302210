<template>
  <div
    id="dragAndDropContainerTarget"
    class="d-flex flex-column min-vh-100"
    :class="`
      ${
        this.$store.getters.getAxiosData.isPreloading ||
        !this.canDisplayComponentsMixin()
          ? 'e-center-elements'
          : ''
      }
    `"
  >
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div
        class="body flex-grow-1 px-3"
        :class="
          this.$store.getters.getAxiosData.isPreloading ||
          !this.canDisplayComponentsMixin()
            ? 'e-center-elements'
            : ''
        "
      >
        <div :class="getContainerSize()">
          <router-view :key="$route.path" />
        </div>
        <ModalErrorHandling
          :visibleModal="this.modalTypeIsVisible(MODALS.ERROR_HANDLING)"
          @closed="this.modalSetInvisible(MODALS.ERROR_HANDLING)"
        ></ModalErrorHandling>
      </div>
    </div>
    <AppWIPStatusToast />
  </div>
</template>
<script>
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/appSidebar/AppSidebar.vue'
import dataMixins from '@/mixins/dataMixins'
import { ROUTE_TYPES } from '@/constants/routerData'
import dummyDataMixins from '@/mixins/dummyDataMixins'
import AppWIPStatusToast from '@/components/wipStatusToast/AppWIPStatusToast'
import fileUploadAreaMixins from '@/mixins/fileUploadAreaMixins'
import modalMixins from '@/mixins/modalMixins'
import { MODALS } from '@/constants/modals'
import ModalErrorHandling from '@/components/modal/ModalErrorHandling.vue'
import notificationsMixins from '@/mixins/notificationsMixins'
import routeMixins from '@/mixins/routeMixins'

export default {
  name: 'DefaultLayout',
  computed: {
    MODALS() {
      return MODALS
    },
  },
  mixins: [
    dataMixins,
    dummyDataMixins,
    fileUploadAreaMixins,
    modalMixins,
    notificationsMixins,
    routeMixins,
  ],
  components: {
    AppWIPStatusToast,
    AppHeader,
    AppSidebar,
    ModalErrorHandling,
  },
  watch: {
    '$store.getters.getErrorLog': {
      handler() {
        let errorLog = this.$store.getters.getErrorLog
        if (errorLog) {
          if (
            errorLog.error &&
            errorLog.error.response &&
            errorLog.error.response.status === 403
          ) {
            this.$store.commit(
              'setAxiosDataErrorMessage',
              this.printErrorMixin(errorLog.error),
            )
            this.$store.commit('triggerLogout')
          } else {
            this.modalSetVisible(MODALS.ERROR_HANDLING)
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    getContainerSize() {
      switch (this.$route.meta.routeType) {
        case ROUTE_TYPES.RFQ_DETAILS:
        case ROUTE_TYPES.RFQ_EDITOR:
          return 'e-container-xl'
        default:
          return 'container-lg'
      }
    },
  },
}
</script>

<style scoped>
.e-center-elements {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
