export const TABLE_COLUMN_ANCHORS = {
  RFQ_ID: 'RfQID',
  RFQ_DATE: 'RfQDate',
  CUSTOMER: 'Customer',
  COMPANY: 'Company',
  RFQ_NAME: 'RfQName',
  FIRST_SAMPLE: 'FirstSample',
  SOP: 'SOP',
  TECHNOLOGY: 'Technology',
  RFQ_STATUS: 'RfQStatus',
  SAMPLES: 'Samples',
  CONTRACT_STATUS: 'ContractStatus',
  INDUSTRY: 'industry',
  PRODUCT: 'product',
  QUOTE_DUE: 'QuoteDue',
  VALID_UNTIL: 'ValidUntil',
  FIRST_NAME: 'FirstName',
  LAST_NAME: 'LastName',
  CUSTOMER_NUMBER: 'CustomerNumber',
  COMPANY_NAME: 'CompanyName',
  EMAIL: 'EMail',
  ROLE: 'Role',
  ACTIVE: 'Active',
  ASSIGNMENT: 'Assignment',
  ASSIGNEE: 'Assignee',
  TOTAL_VOLUME: 'TotalVolume',
  TOTAL_QUANTITY: 'TotalQuantity',
  QUANTITY_PER_LOCATION: 'QuantityPerLocation',
  QUANTITY_PER_SCENARIO: 'QuantityPerScenario',
  QUANTITY_PER_LOT_SIZE: 'QuantityPerLotSize',
  SCENARIO: 'Scenario',
  LOT_SIZE: 'LotSize',
  QUANTITY_PER_YEAR: 'QuantityPerYear',
  ANNUAL_FORECAST: 'AnnualForecast',
  SALES_EXPERT: 'SalesExpert',
  INDIVIDUAL_SCENARIO: 'IndividualScenario',
}
