import { PANEL_TYPES, RFQ_TYPES } from '@/constants/constants'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import cloneDeep from 'lodash/cloneDeep'

export default {
  methods: {
    generateQuotationPanelMixin(
      offerData,
      rfqData,
      panelType = PANEL_TYPES.CUSTOMER_SPECIFIED,
    ) {
      const QUOTATIONS = API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS
      const RFQ_KEYS = API_OBJECT_KEY_NAMES.RFQ
      const PLACEHOLDER_VALUE = ''
      const EXPECTED_LIFETIME =
        API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.EXPECTED_LIFETIME
      const SCENARIOS_ANNUAL_PRICE_DOWN_LENGTH =
        rfqData[EXPECTED_LIFETIME] > 4 || rfqData[EXPECTED_LIFETIME] === 0
          ? 4
          : rfqData[EXPECTED_LIFETIME]
      let rowLength
      const SCENARIOS_DATA =
        rfqData[API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.SCENARIOS_PREFIX]
      if (
        rfqData[RFQ_KEYS.PROJECT_DATA.RFQ_TYPE] === RFQ_TYPES.SCENARIOS &&
        SCENARIOS_DATA.length &&
        Array.isArray(SCENARIOS_DATA[0].scenario.scenario) &&
        SCENARIOS_DATA[0].scenario.scenario.length
      ) {
        rowLength = SCENARIOS_DATA[0].scenario.scenario.length
      } else {
        rowLength = rfqData[RFQ_KEYS.PROJECT_DATA.RFQ_YEARS]
      }
      let annualPriceDownScenarios = []
      let rowArray = new Array(rowLength)
      const INPUT_ROW = rowArray.fill(PLACEHOLDER_VALUE, 0, rowArray.length)

      let currencyObject = {}
      switch (rfqData[RFQ_KEYS.PROJECT_DATA.RFQ_TYPE]) {
        case RFQ_TYPES.LOT_SIZES:
          currencyObject[QUOTATIONS.SAMPLE] = PLACEHOLDER_VALUE
          currencyObject[QUOTATIONS.SERIES] = PLACEHOLDER_VALUE
          break
        case RFQ_TYPES.SCENARIOS:
          currencyObject[QUOTATIONS.FLAT] = {
            [QUOTATIONS.SCENARIOS]: cloneDeep(INPUT_ROW),
            [QUOTATIONS.SAMPLES]: PLACEHOLDER_VALUE,
          }
          for (let i = 0; i < SCENARIOS_ANNUAL_PRICE_DOWN_LENGTH; i++) {
            annualPriceDownScenarios.push(cloneDeep(INPUT_ROW))
          }

          currencyObject[QUOTATIONS.ANNUAL_PRICE_DOWN] = {
            [QUOTATIONS.SCENARIOS]: cloneDeep(annualPriceDownScenarios),
            [QUOTATIONS.SAMPLES]: PLACEHOLDER_VALUE,
          }
          break
        default:
          currencyObject[QUOTATIONS.FLAT] = {
            [QUOTATIONS.YEARS]: PLACEHOLDER_VALUE,
            [QUOTATIONS.SAMPLES]: PLACEHOLDER_VALUE,
          }
          currencyObject[QUOTATIONS.ANNUAL_PRICE_DOWN] = {
            [QUOTATIONS.YEARS]: cloneDeep(INPUT_ROW),
            [QUOTATIONS.SAMPLES]: PLACEHOLDER_VALUE,
          }
      }

      let panel = {
        [QUOTATIONS.QUOTATION]: {
          [offerData[API_OBJECT_KEY_NAMES.OFFER.CURRENCY]]:
            cloneDeep(currencyObject),
        },
        [QUOTATIONS.PANEL_TYPE]: PANEL_TYPES.CUSTOMER_SPECIFIED,
      }

      if (offerData[API_OBJECT_KEY_NAMES.OFFER.ADDITIONAL_CURRENCY]) {
        panel[QUOTATIONS.QUOTATION][
          offerData[API_OBJECT_KEY_NAMES.OFFER.ADDITIONAL_CURRENCY]
        ] = cloneDeep(currencyObject)
      }
      panel[QUOTATIONS.PANEL_TYPE] = panelType
      return cloneDeep(panel)
    },
  },
}
