import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { FORM_ITEMS } from '@/constants/formItems'
import { FILE_TYPES } from '@/constants/fileTypes'
import { PLACEHOLDER } from '@/constants/constants'
import { AXIOS } from '@/constants/axios'
import { VALIDATION_TYPE } from '@/constants/validation'
import { INFO_ANCHORS } from '@/constants/anchorTags/infoAnchorTags'

export let itemQuoteFile = {
  keyName: KEYS_RFQ.QUOTE_FILE,
  inputType: FORM_ITEMS.INPUT_TYPE.FILE,
  value: '',
  required: true,
  invalid: false,
  export: false,
  file: {
    type: FILE_TYPES.QUOTE_FILE,
    name: null,
    url: {
      targetPlaceholderId: PLACEHOLDER.ID.RFQ,
      download: AXIOS.API.GET.DOWNLOAD_CUSTOMER_QUOTE_SPECIFIED_FILE,
      upload: '',
    },
    formats: [],
  },
  fileType: FILE_TYPES.QUOTE_FILE,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  targetId: INFO_ANCHORS.DOCUMENTS.QUOTE_FILE,
  messages: {
    placeholder: 'No file chosen',
    error: 'Please select a file',
    label: 'Quote File',
    notes: 'Accepted formats',
    status: '',
  },
}

export let itemRFQuotes = {
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP],
  keyName: KEYS_RFQ.QUOTES,
  singleLevelObject: true,
  data: [],
}
