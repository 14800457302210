<template>
  <a v-if="this.$props.targetShopware" :href="$eLandingPageURL" target="_blank">
    <AppBrandLogo
      :color="this.$props.color"
      :height="this.$props.height"
      :useBoschLogo="this.$props.useBoschLogo"
      :shortBranding="this.$props.shortBranding"
    ></AppBrandLogo>
  </a>
  <router-link
    v-else
    :to="{ name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].HOME.NAME }"
  >
    <AppBrandLogo
      :color="this.$props.color"
      :height="this.$props.height"
      :useBoschLogo="this.$props.useBoschLogo"
      :shortBranding="this.$props.shortBranding"
    ></AppBrandLogo>
  </router-link>
</template>

<script>
import { ROUTER_DATA } from '@/constants/routerData'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import AppBrandLogo from '@/components/appBrand/AppBrandLogo'

export default {
  name: 'AppBrand',
  components: {
    AppBrandLogo,
  },
  props: {
    targetShopware: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
    height: {
      type: Number,
      default: 26,
    },
    useBoschLogo: {
      type: Boolean,
      default: false,
    },
    shortBranding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ROUTER_DATA,
      PERMISSION_GROUPS,
    }
  },
}
</script>

<style scoped></style>
