import { IMPORT_EXPORT } from '@/constants/importExport'
import {
  DATE_FORMAT,
  PANEL_TYPES,
  QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES,
  RFQ_TYPES,
  SUBMIT_TYPES,
} from '@/constants/constants'
import { FORM_ITEMS } from '@/constants/formItems'
import {
  API_OBJECT_GROUPS,
  API_OBJECT_KEY_NAMES,
  API_OBJECT_TYPES,
} from '@/constants/apiObjectKeyNames'
import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import cloneDeep from 'lodash/cloneDeep'
import routeMixins from '@/mixins/routeMixins'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import textManipulationMixins from '@/mixins/textManipulationMixins'
import currenciesMixins from '@/mixins/currenciesMixins'
import quotesMixins from '@/mixins/quotesMixins'

export default {
  mixins: [routeMixins, textManipulationMixins, currenciesMixins, quotesMixins],
  methods: {
    exportQuotaAllocationMixin(supplier, submitType) {
      let settings = supplier[KEYS_RFQ.SUPPLIER_SETTINGS]
      if (
        settings.selected ===
          QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES.NOT_SELECTED &&
        submitType === SUBMIT_TYPES.SUBMIT
      ) {
        return null
      }

      let data = { id: settings.info.offerId, supplierId: settings.info.id }
      let selection = cloneDeep(settings.selection)
      if (selection.selected instanceof Set) {
        selection.selected = Array.from(selection.selected)
      }
      data[API_OBJECT_KEY_NAMES.OFFER.QUOTA_ALLOCATION] = selection
      return data
    },
    exportInnerLayersObjectMixin() {
      let innerLayers = cloneDeep(this.$data[KEYS_GENERAL.INNER_LAYERS])
      if (innerLayers) {
        let layerPosition = 2
        let exportLayerJson = {
          topInnerLayers: {},
          bottomInnerLayers: {},
        }
        innerLayers.forEach((layerValue) => {
          exportLayerJson.topInnerLayers[`layer${layerPosition++}`] =
            parseInt(layerValue)
        })
        innerLayers = innerLayers.reverse()
        innerLayers.forEach((layerValue) => {
          exportLayerJson.bottomInnerLayers[`layer${layerPosition++}`] =
            parseInt(layerValue)
        })
        return exportLayerJson
      } else {
        return null
      }
    },
    exportGetDeliveriesMixin(form, delivery) {
      let sop
      if (form[KEYS_RFQ.PROJECT_DATA_PREFIX][KEYS_RFQ.PROJECT_DATA.SOP].value) {
        sop = new Date(
          form[KEYS_RFQ.PROJECT_DATA_PREFIX][KEYS_RFQ.PROJECT_DATA.SOP].value,
        )
      } else {
        sop = new Date()
      }

      let firstSamples
      if (
        form[KEYS_RFQ.PROJECT_DATA_PREFIX][KEYS_RFQ.PROJECT_DATA.FIRST_SAMPLE]
          .value
      ) {
        firstSamples = new Date(
          form[KEYS_RFQ.PROJECT_DATA_PREFIX][
            KEYS_RFQ.PROJECT_DATA.FIRST_SAMPLE
          ].value,
        )
      } else {
        firstSamples = new Date()
      }

      let rowLength = delivery.data.length

      if (rowLength > 0) {
        const RFQ_TYPE = Number(
          form[KEYS_RFQ.PROJECT_DATA_PREFIX][KEYS_RFQ.PROJECT_DATA.RFQ_TYPE]
            .value,
        )
        let deliveriesBody = []
        let columnLengthAdjustment = 1
        if (RFQ_TYPE === RFQ_TYPES.LOT_SIZES) {
          columnLengthAdjustment++
        }
        let columnsLength =
          parseInt(
            form[KEYS_RFQ.PROJECT_DATA_PREFIX][KEYS_RFQ.PROJECT_DATA.RFQ_YEARS]
              .value,
          ) + columnLengthAdjustment
        let newObject
        let newLocationsArray
        let typeNumber = 1
        let yearsOffset = 0
        for (let column = 0; column < columnsLength; column++) {
          newObject = {}
          newLocationsArray = []

          // Check if sample, annualForecast or normal event
          if (column === 0) {
            newObject.isSample = 1
            newObject.annualForecast = 0
            newObject.start = this.getFormatedDateMixin(
              firstSamples,
              DATE_FORMAT,
              false,
            )
          } else if (
            RFQ_TYPE === RFQ_TYPES.LOT_SIZES &&
            column === columnsLength - 1
          ) {
            newObject.isSample = 0
            newObject.annualForecast = 1
            newObject.start = this.getFormatedDateMixin(sop, DATE_FORMAT, false)
          } else {
            newObject.isSample = 0
            newObject.annualForecast = 0
            newObject.start = this.getFormatedDateMixin(
              this.addTimeToDateMixin(sop, {
                value: yearsOffset,
                unit: 'years',
              }),
              DATE_FORMAT,
              false,
            )
            yearsOffset++
          }
          for (let row = 0; row < rowLength; row++) {
            if (column === 0) {
              newLocationsArray.push({
                customerAddressId: delivery.data[row].location.value,
                amount: delivery.data[row].samples,
              })
            } else if (
              RFQ_TYPE === RFQ_TYPES.LOT_SIZES &&
              column === columnsLength - 1
            ) {
              newLocationsArray.push({
                customerAddressId: delivery.data[row].location.value,
                amount: delivery.data[row].annualForecast,
              })
            } else {
              newLocationsArray.push({
                customerAddressId: delivery.data[row].location.value,
                amount: delivery.data[row].sop[column - 1],
              })
            }
          }
          newObject.locations = cloneDeep(newLocationsArray)
          if (!newObject.isSample) {
            switch (RFQ_TYPE) {
              case RFQ_TYPES.SCENARIOS:
                newObject.scenarioNumber = typeNumber++
                break
              case RFQ_TYPES.LOT_SIZES:
                if (column !== columnsLength - 1) {
                  newObject.lotNumber = typeNumber++
                }
            }
          }
          deliveriesBody.push(cloneDeep(newObject))
        }
        return deliveriesBody
      } else {
        return []
      }
    },
    exportCanParseValueMixin(item, parentItem = undefined) {
      let canParseValue = true
      if (item.checked || parentItem) {
        canParseValue =
          item.checked === true ||
          (parentItem !== undefined &&
            ((!parentItem.checked && parentItem.radioValue === undefined) ||
              parentItem.checked === true ||
              (parentItem.radioValue !== undefined &&
                parentItem.radioValue === item.radioValueGroup)))
      }
      return canParseValue
    },
    exportSpecifiedLotSizesMixin(item, body) {
      if (item && item.keyName === KEYS_RFQ.SPECIFIED_LOT_SIZES) {
        body[item.apiObjectKeyNames[0]] = item.data
      }
      return body
    },
    exportGetParsedValueMixin(form, item, body, parentItem = undefined) {
      if (item.export !== false) {
        let parsedValue = null
        if (this.exportCanParseValueMixin(item, parentItem)) {
          if (
            item.apiObjectKeyNames.includes(
              API_OBJECT_KEY_NAMES.RFQ.SPECIFY_LOT_SIZES,
            )
          ) {
            return this.exportSpecifiedLotSizesMixin(item, body)
          } else if (
            item.apiObjectKeyNames.includes(
              API_OBJECT_KEY_NAMES.RFQ.NOTES_SALES,
            )
          ) {
            if (item.value.length) {
              body[API_OBJECT_KEY_NAMES.RFQ.NOTES_SALES] = [
                {
                  comment: item.value,
                },
              ]
            } else {
              body[API_OBJECT_KEY_NAMES.RFQ.NOTES_SALES] = []
            }
            return body
          } else if (
            item.apiObjectKeyNames.includes(API_OBJECT_KEY_NAMES.RFQ.DELIVERY)
          ) {
            parsedValue = this.exportGetDeliveriesMixin(form, item)
          } else if (
            item.apiObjectKeyNames.includes(
              API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.INNER_LAYER,
            )
          ) {
            parsedValue = this.exportInnerLayersObjectMixin()
          } else if (
            item.value !== null &&
            (item.exportCheckedValue ||
              (item.checked !== false && !parentItem) ||
              (parentItem && parentItem.checked !== false))
          ) {
            let targetValue
            if (item.checked !== undefined && !item.value) {
              targetValue = item.checked
            } else if (item.subSelectionActive === true) {
              targetValue = item.subItems[0].value
            } else if (parentItem && parentItem.subSelectionActive === false) {
              targetValue = parentItem.value
            } else {
              targetValue = item.value
            }

            if (targetValue || item.exportCheckedValue || targetValue === 0) {
              switch (item.parseType) {
                case IMPORT_EXPORT.PARSE_TYPE.CURRENCY_ID:
                  parsedValue = this.getCurrencyIdMixin(targetValue, false)
                  break
                case IMPORT_EXPORT.PARSE_TYPE.INT:
                  parsedValue = parseInt(targetValue)
                  break
                case IMPORT_EXPORT.PARSE_TYPE.DOUBLE:
                  parsedValue = parseFloat(targetValue)
                  break
                case IMPORT_EXPORT.PARSE_TYPE.BOOLEAN:
                  parsedValue = Boolean(targetValue)
                  break
                case IMPORT_EXPORT.PARSE_TYPE.DATE:
                  parsedValue = this.getFormatedDateMixin(
                    targetValue,
                    DATE_FORMAT,
                    false,
                  )
                  break
                case IMPORT_EXPORT.PARSE_TYPE.STRING:
                  parsedValue = String(targetValue.trim())
                  break
                default:
                  if (typeof targetValue === 'string') {
                    parsedValue = String(targetValue.trim())
                  }
              }
            }
          }

          if (
            parentItem &&
            item.advancedParams &&
            item.advancedParams.export &&
            item.radioValueGroup === parentItem.radioValue
          ) {
            item.advancedParams.export.forEach((param) => {
              let targetItem = null
              if (param.keyName) {
                parentItem.subItems.forEach((subItem) => {
                  if (subItem.keyName === param.keyName) {
                    targetItem = subItem
                  }
                })
              }

              switch (param.value) {
                case FORM_ITEMS.USE_ITEM_VALUE:
                  body[param.apiKey] = targetItem.value
                    ? targetItem.value
                    : null
                  break
                default:
                  body[param.apiKey] = param.value
              }
            })
          } else if (!item.preventParseWhenInactive) {
            item.apiObjectKeyNames.forEach((keyName) => {
              if (item.apiObjectGroup) {
                body = this.exportApiObjectGroupMixin(
                  item,
                  keyName,
                  parsedValue,
                  body,
                )
              } else {
                body[keyName] = parsedValue
              }
            })
          }
        } else if (parentItem && parentItem.checked === false) {
          // Make sure to null a subItem if the parent disabled it
          body[item.apiObjectKeyNames[0]] = null
        }
        // Reset values to null if flagged and selected
        if (item.resetAPIValues && parentItem) {
          parentItem.apiObjectKeyNames.forEach((apiKey) => {
            body[apiKey] = null
          })
        }
      }
      return body
    },
    exportApiObjectGroupMixin(item, keyName, parsedValue, body) {
      if (!body[item.apiObjectGroup.VALUE]) {
        item.apiObjectGroup.TYPE === API_OBJECT_TYPES.OBJECT
          ? (body[item.apiObjectGroup.VALUE] = {})
          : (body[item.apiObjectGroup.VALUE] = [])
      }
      if (item.singleLevelObject) {
        let clonedData = null
        switch (item.keyName) {
          case KEYS_RFQ.QUOTATIONS:
            this.adjustAllObjectDataMixin(
              (clonedData = cloneDeep(item.data)),
              item.parseType,
            )
            clonedData.forEach((panel) => {
              panel.panelType === PANEL_TYPES.CUSTOMER_SPECIFIED
                ? (body[item.apiObjectGroup.VALUE][
                    API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS.CUSTOMER_SPECIFIED
                  ] = panel[API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS.QUOTATION])
                : (body[item.apiObjectGroup.VALUE][
                    API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS.PROPOSED
                  ] = panel[API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS.QUOTATION])
            })
            break
          default:
            body[item.apiObjectGroup.VALUE] = item.data
        }
      } else if (
        item.apiObjectGroup.VALUE === API_OBJECT_GROUPS.CHARGES.VALUE
      ) {
        body = this.exportOfferChargesMixin(item, body, parsedValue)
      } else {
        body[item.apiObjectGroup.VALUE][keyName] = parsedValue
      }
      return body
    },
    exportOfferChargesMixin(item, body, parsedValue) {
      let foundCurrencyObject = false
      let currencyObjectIndex = 0
      const CURRENCY = API_OBJECT_KEY_NAMES.OFFER.CHARGES.CURRENCY
      for (let i = 0; i < body[item.apiObjectGroup.VALUE].length; i++) {
        if (
          item[CURRENCY].values.includes(
            body[item.apiObjectGroup.VALUE][i][CURRENCY],
          )
        ) {
          foundCurrencyObject = true
          currencyObjectIndex = i
          break
        }
      }

      if (!foundCurrencyObject) {
        item[API_OBJECT_KEY_NAMES.OFFER.CHARGES.CURRENCY].values.forEach(
          (currencyIndex) => {
            body[item.apiObjectGroup.VALUE].push({
              [item.apiObjectKeyNames[0]]: parsedValue,
              [API_OBJECT_KEY_NAMES.OFFER.CHARGES.CURRENCY]: currencyIndex,
            })
          },
        )
      } else {
        body[item.apiObjectGroup.VALUE][currencyObjectIndex][
          item.apiObjectKeyNames[0]
        ] = parsedValue
      }
      return body
    },
    prepareFormForExportMixin(form = undefined, target = KEYS_GENERAL.FORM) {
      let formCopy = cloneDeep(form)
      Object.keys(formCopy).forEach((key) => {
        if (!this.$route.meta.requiredForValidationComponents.includes(key)) {
          delete formCopy[key]
        }
      })
      return this.exportFormMixin(formCopy, target)
    },
    exportSupplierExpertSupplierForm(supplier, importedData) {
      let body = {
        supplierId: supplier.id,
      }
      if (importedData.scenarios.length) {
        body['rfqScenarioId'] = importedData.scenarios[0].id
      }
      Object.values(supplier.form).forEach((item) => {
        body = this.exportGetParsedValueMixin(supplier.form, item, body)
      })
      body[API_OBJECT_KEY_NAMES.OFFER.CURRENCY] = this.getCurrencyIdMixin(
        body[API_OBJECT_KEY_NAMES.OFFER.CURRENCY],
      )
      body[API_OBJECT_KEY_NAMES.OFFER.ADDITIONAL_CURRENCY] =
        this.getCurrencyIdMixin(
          body[API_OBJECT_KEY_NAMES.OFFER.ADDITIONAL_CURRENCY],
        )
      return body
    },
    exportIndividualScenariosMixin(form, supplierScenarios, importedData) {
      const DELIVERY = form[KEYS_RFQ.DELIVERY]
      const IMPORTED_SCENARIOS =
        importedData[
          API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.SCENARIOS_PREFIX
        ]
      const RFQ_TYPE = Number(
        form[KEYS_RFQ.PROJECT_DATA_PREFIX][KEYS_RFQ.PROJECT_DATA.RFQ_TYPE]
          .value,
      )

      let item = []
      let exportedScenario = {}
      if (DELIVERY.individualScenarios) {
        switch (RFQ_TYPE) {
          case RFQ_TYPES.SCENARIOS:
            exportedScenario.customScenario = {
              samples: Number(DELIVERY.individualScenarios.samples),
              scenarios: DELIVERY.individualScenarios.scenarios.map(Number),
            }
            exportedScenario.scenario = []
            break
          case RFQ_TYPES.YEARS:
            exportedScenario = {
              samples: Number(DELIVERY.individualScenarios.samples),
              years: DELIVERY.individualScenarios.years.map(Number),
            }
            break
          default:
            exportedScenario = {
              samples: Number(DELIVERY.individualScenarios.samples),
              lotSizes: DELIVERY.individualScenarios.lotSizes.map(Number),
              annualForecast: DELIVERY.individualScenarios.annualForecast,
            }
        }
      }

      if (RFQ_TYPE === RFQ_TYPES.SCENARIOS && supplierScenarios.data) {
        if (!exportedScenario.customScenario) {
          exportedScenario.scenario = []
        }
        supplierScenarios.data.forEach((customScenario) => {
          exportedScenario.scenario.push({
            annualQuantity: Number(customScenario.annualQuantity.value),
            scenarios: Array.from(customScenario.selectedScenarios.keys()),
          })
        })
      }

      if (Object.keys(exportedScenario).length) {
        item.push({ scenario: exportedScenario })
        if (IMPORTED_SCENARIOS.length) {
          item[0].id = IMPORTED_SCENARIOS[0].id
        }
      }
      return item
    },
    adjustExportedChargesMixin(body) {
      // Adjust the export of minOrderVolume and minOrderQuantity
      if (
        body[API_OBJECT_KEY_NAMES.OFFER.CHARGES_OBJECT] &&
        body[API_OBJECT_KEY_NAMES.OFFER.CHARGES_OBJECT].length
      ) {
        let minOrderQuantity = null
        body[API_OBJECT_KEY_NAMES.OFFER.CHARGES_OBJECT].forEach(
          (charge, index) => {
            if (
              charge[API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_QUANTITY] >=
                0 &&
              index === 0
            ) {
              minOrderQuantity =
                charge[API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_QUANTITY]
            }
            if (minOrderQuantity !== null) {
              charge[API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_QUANTITY] =
                minOrderQuantity
              charge[API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_VOLUME] = null
            } else {
              charge[API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_QUANTITY] =
                null
            }
          },
        )
      }
      return body
    },
    prepareQuoteForExportMixin(form, importedQuote) {
      let importedPanels = this.initializeQuotePanelsMixin(importedQuote)
      let formCopy = cloneDeep(form)
      Object.keys(formCopy).forEach((key) => {
        if (!this.$route.meta.requiredForValidationComponents.includes(key)) {
          delete formCopy[key]
        }
      })

      importedPanels[this.CUSTOMER_SPECIFIED_PANEL_INDEX] =
        this.exportFormMixin(
          formCopy,
          KEYS_GENERAL.FORM,
          importedPanels[this.CUSTOMER_SPECIFIED_PANEL_INDEX],
          IMPORT_EXPORT.API_TRANSFER_STYLE.CUSTOMER_SPECIFIED_PANEL,
        )
      importedPanels[this.PROPOSED_PANEL_INDEX][
        API_OBJECT_KEY_NAMES.QUOTATION.DELIVER_OPTIONS
      ] = this.$data.formActiveTableSpecifiedPanels

      importedPanels[this.PROPOSED_PANEL_INDEX] = this.exportFormMixin(
        formCopy,
        KEYS_GENERAL.FORM,
        importedPanels[this.PROPOSED_PANEL_INDEX],
        IMPORT_EXPORT.API_TRANSFER_STYLE.PROPOSED_PANEL,
      )
      importedPanels[this.CUSTOMER_SPECIFIED_PANEL_INDEX][
        API_OBJECT_KEY_NAMES.QUOTATION.DELIVER_OPTIONS
      ] = this.$data.formActiveTableProposedPanels

      importedPanels.forEach((panel) => {
        delete panel[API_OBJECT_KEY_NAMES.RFQ.QUOTES.QUOTE_FILE]
        panel[API_OBJECT_KEY_NAMES.QUOTATION.LOGISTIC_COSTS_CALCULATION_MODE] =
          this.$data.activeTableIsBasedOnFlatPrice ? 1 : 2
        panel[API_OBJECT_KEY_NAMES.QUOTATION.MASS_PRODUCTION_CALCULATION_MODE] =
          this.$data.activeTableIsBasedOnFlatPrice ? 1 : 2
      })

      let quote = {
        id: importedQuote.id,
        [API_OBJECT_KEY_NAMES.QUOTATION.PANEL_TYPE_TO_DISPLAY_TO_CUSTOMER]:
          this.$data.customerPanelSelection,
        quotePanels: importedPanels,
      }

      if (formCopy[KEYS_RFQ.SUPPLIER_NRE_PREFIX]) {
        Object.values(formCopy[KEYS_RFQ.SUPPLIER_NRE_PREFIX]).forEach(
          (item) => {
            quote[item.apiObjectKeyNames[0]] = parseFloat(item.value)
          },
        )
      }
      return {
        rfq: {},
        quote: quote,
      }
    },
    exportFormMixin(
      form = undefined,
      target = KEYS_GENERAL.FORM,
      body = null,
      apiTransferStyle = null,
    ) {
      if (!body) {
        body = { id: this.$store.getters.getActiveRFQID }
      }
      Object.entries(form).forEach(([key, group]) => {
        if (
          !this.isReadOnlyModeMixin(key) ||
          target === KEYS_GENERAL.FORM_COPY
        ) {
          if (group.singleLevelObject) {
            if (group.apiObjectKeyNames) {
              body = this.exportGetParsedValueMixin(form, group, body)
            }
          } else {
            Object.values(group).forEach((item) => {
              if (item.apiObjectKeyNames) {
                if (
                  (item.apiObjectKeyNames.length > 1 ||
                    item.radioValue !== undefined ||
                    item.checked === true ||
                    apiTransferStyle !== null) &&
                  item.subItems
                ) {
                  item.subItems.forEach((subItem) => {
                    if (
                      !subItem.apiTransferStyle ||
                      subItem.apiTransferStyle === apiTransferStyle
                    ) {
                      body = this.exportGetParsedValueMixin(
                        form,
                        subItem,
                        body,
                        item,
                      )
                    }
                  })
                } else {
                  body = this.exportGetParsedValueMixin(form, item, body)
                }
              }
            })
          }
        }
      })
      return body
    },
  },
}
