import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { FORM_ITEMS } from '@/constants/formItems'
import { IMPORT_EXPORT } from '@/constants/importExport'
import { FILE_TYPES } from '@/constants/fileTypes'
import { AXIOS } from '@/constants/axios'
import { VALIDATION_TYPE } from '@/constants/validation'
import {
  MAX_ALLOWED_TEXT_AREA_CHARACTERS,
  PLACEHOLDER,
} from '@/constants/constants'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { INFO_ANCHORS } from '@/constants/anchorTags/infoAnchorTags'
import anchorTagsMixins from '@/mixins/anchorTagsMixins'
import { INPUT_TYPES_ANCHORS } from '@/constants/anchorTags/inputTypeAnchorTags'

let itemTechConfirmed = {
  keyName: KEYS_RFQ.TECH_CSCC.TECH_CONFIRMED,
  value: '',
  checked: false,
  exportCheckedValue: true,
  formType: FORM_ITEMS.TYPES.CHECKBOX,
  validationTypes: [],
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_CONFIRMED.CONFIRMED_CHECKBOX,
  ],
  parseType: IMPORT_EXPORT.PARSE_TYPE.BOOLEAN,
  messages: {
    label: 'Technical feasibility confirmed',
  },
}

let itemTechConfirmedFile = {
  keyName: KEYS_RFQ.TECH_CSCC.TECH_CONFIRMATION_FILE,
  inputType: FORM_ITEMS.INPUT_TYPE.FILE,
  value: '',
  invalid: false,
  required: true,
  export: false,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.TECH_CONFIRMED.CONFIRMED_FILE],
  targetId: INFO_ANCHORS.TECH_CSCC.TECH_CONFIRMATION_FILE,
  file: {
    type: FILE_TYPES.TECH_CONFIRMED,
    name: null,
    url: {
      targetPlaceholderId: PLACEHOLDER.ID.OFFER,
      upload: AXIOS.API.POST.UPLOAD_TECH_CONFIRMED_FILE,
      download: AXIOS.API.GET.DOWNLOAD_TECH_CONFIRMED_FILE,
    },
    formats: [
      FILE_TYPES.FORMATS.ZIP,
      FILE_TYPES.FORMATS.SEVEN_ZIP,
      FILE_TYPES.FORMATS.PDF,
    ],
  },
  fileType: FILE_TYPES.TECH_CONFIRMED,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  messages: {
    placeholder: 'No file chosen',
    error: 'Please select a file',
    label: 'Upload feasibility confirmation, technical questions',
    notes: 'Accepted formats',
    status: '',
  },
}

let itemCSCCdFile = {
  keyName: KEYS_RFQ.TECH_CSCC.CSCC_FILE,
  inputType: FORM_ITEMS.INPUT_TYPE.FILE,
  value: '',
  invalid: false,
  export: false,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.TECH_CONFIRMED.CSCC_FILE],
  targetId: INFO_ANCHORS.TECH_CSCC.CSCC_FILE,
  file: {
    type: FILE_TYPES.TECH_CSCC,
    name: null,
    url: {
      targetPlaceholderId: PLACEHOLDER.ID.OFFER,
      upload: AXIOS.API.POST.UPLOAD_TECH_CSCC_FILE,
      download: AXIOS.API.GET.DOWNLOAD_TECH_CSCC_FILE,
    },
    formats: [
      FILE_TYPES.FORMATS.ZIP,
      FILE_TYPES.FORMATS.SEVEN_ZIP,
      FILE_TYPES.FORMATS.PDF,
    ],
  },
  fileType: FILE_TYPES.TECH_CSCC,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  messages: {
    placeholder: 'No file chosen',
    error: 'Please select a file',
    label: 'Upload CSCC',
    notes: 'Accepted formats',
    status: '',
  },
}

let itemTechComment = {
  keyName: KEYS_RFQ.TECH_CSCC.TECH_COMMENT,
  singleLevelObject: true,
  value: '',
  targetId: anchorTagsMixins.methods.getInputAnchorTagMixin(
    INFO_ANCHORS.TECH_CSCC.COMMENT,
    INPUT_TYPES_ANCHORS.TEXT_AREA,
  ),
  maxLength: MAX_ALLOWED_TEXT_AREA_CHARACTERS,
  validationTypes: [],
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.TECH_CONFIRMED.COMMENT],
  messages: {
    label: '',
    placeholder: 'Write a comment on technical feasibility',
  },
}

export let rfqTechCSCCTypes = {
  [itemTechConfirmed.keyName]: itemTechConfirmed,
  [itemTechConfirmedFile.keyName]: itemTechConfirmedFile,
  [itemCSCCdFile.keyName]: itemCSCCdFile,
  [itemTechComment.keyName]: itemTechComment,
}
