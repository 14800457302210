<template>
  <hr v-if="item.SEPARATOR" />
  <CNavTitle v-else-if="item.TITLE"
    >{{ this.getSidebarTitleMixin(item.ROUTER_DATA) }}
  </CNavTitle>
  <div
    v-else-if="item.GLOBAL_SWITCH"
    class="d-inline-flex mx-4 e-global-overview-switch form-check form-switch"
  >
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      :id="this.getSidebarAnchorTagMixin(ACTION_ANCHORS.GLOBAL_SWITCH, false)"
      @click="this.$store.commit('toggleGlobalOverviewStatus')"
      :disabled="this.$store.getters.getAxiosData.isProcessing"
    />
    <span class="ps-2">
      {{ item.LABEL }}
    </span>
  </div>

  <CNavItem v-else>
    <a
      v-if="item.EXTERNAL"
      :href="item.ROUTER_DATA.PATH"
      target="_blank"
      class="nav-link"
      :id="this.getSidebarAnchorTagMixin(item.ROUTER_DATA.ANCHOR, false)"
    >
      {{ this.getSidebarTitleMixin(item.ROUTER_DATA) }}
    </a>
    <router-link
      v-else
      :to="{ name: item.ROUTER_DATA.NAME }"
      class="nav-link"
      :id="this.getSidebarAnchorTagMixin(item.ROUTER_DATA.ANCHOR, true)"
    >
      <CIcon :icon="item.ICON" class="nav-icon icon-custom-size" />
      {{ this.getSidebarTitleMixin(item.ROUTER_DATA) }}
      <CSpinner
        size="sm"
        variant="grow"
        class="e-sidebar-badge-loading"
        v-if="
          this.gotSidebarCounterMixin(item.ROUTER_DATA.NAME) &&
          (this.$store.getters.sidebarData === null ||
            this.$store.getters.getAxiosData.isUpdatingSidebar)
        "
      />
      <CBadge
        class="e-sidebar-badge"
        v-else-if="
          !item.HIDE_COUNTER &&
          this.gotSidebarCounterMixin(item.ROUTER_DATA.NAME)
        "
        >{{ getSidebarCounterMixin(item.ROUTER_DATA.NAME) }}
      </CBadge>
    </router-link>
  </CNavItem>
</template>

<script>
import { STATES } from '@/constants/states'
import sidebarDataMixins from '@/mixins/sidebarDataMixins'
import anchorTagsMixins from '@/mixins/anchorTagsMixins'
import { NAVIGATION_ANCHORS } from '@/constants/anchorTags/navigationAnchorTags'
import { ACTION_ANCHORS } from '@/constants/anchorTags/actionAnchorTags'

export default {
  name: 'AppSidebarNavItem',
  computed: {
    ACTION_ANCHORS() {
      return ACTION_ANCHORS
    },
  },
  mixins: [sidebarDataMixins, anchorTagsMixins],
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      STATES,
      NAVIGATION_ANCHORS,
    }
  },
}
</script>

<style scoped>
.router-link-active,
.router-link-active svg {
  color: white;
}

.support-nav {
  bottom: 0;
}

.support-nav a {
  color: white;
  font-size: 14px;
}

.icon-custom-size {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 18px;
}

.nav-title {
  margin-top: 20px;
}

.e-business-title {
  margin-top: 30px;
}

hr {
  margin-left: 16px;
  margin-right: 16px;
}

.nav-link {
  padding-top: 11px;
  padding-bottom: 11px;
}

.e-sidebar-badge {
  margin-left: 5px;
  padding: 3px;
}

.support-nav {
  padding-bottom: 25px;
}

.support-nav a {
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
  opacity: 69%;
}

.e-global-overview-switch + .nav-title {
  margin-top: 0;
}

.e-global-overview-switch input,
.e-global-overview-switch input:focus {
  background-color: #ffffff40;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28206, 206, 206, 1%29%27/%3e%3c/svg%3e');
  border: none;
}

.e-global-overview-switch input:checked {
  background-color: #4ab07366;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%2874, 176, 115, 1%29%27/%3e%3c/svg%3e');
}
</style>
