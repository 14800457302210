import { PREFIX_ANCHORS } from '@/constants/anchorTags/prefixAnchorTags'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import capitalize from 'lodash/capitalize'
import { FORM_ITEMS } from '@/constants/formItems'
import {
  DATE_FORMAT_SHORT,
  DEFAULT_MASS_PRODUCTION_MARGIN,
  NOT_SPECIFIED,
  PANEL_TYPES,
  QUOTE_COMPARISON_TYPES,
  QUOTE_DELIVERY_OPTIONS,
  RFQ_TYPES,
} from '@/constants/constants'
import { IMPORT_EXPORT } from '@/constants/importExport'
import { itemRfQQuoteMassProductionMarginInput } from '@/assets/inputDataObjects/types/rfq/salesExpert/massProduction'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import cloneDeep from 'lodash/cloneDeep'

export default {
  computed: {
    LOT_SIZES_TEMPLATE() {
      let lotSizesTemplate = {}
      let rfqYears =
        this.$data[KEYS_GENERAL.IMPORT_DATA][
          API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_YEARS
        ]
      if (
        this.$data[KEYS_GENERAL.IMPORT_DATA][
          API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE
        ] === RFQ_TYPES.SCENARIOS &&
        this.$data[KEYS_GENERAL.IMPORT_DATA][
          API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.SCENARIOS_PREFIX
        ] &&
        this.$data[KEYS_GENERAL.IMPORT_DATA][
          API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.SCENARIOS_PREFIX
        ].length &&
        this.$data[KEYS_GENERAL.IMPORT_DATA][
          API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.SCENARIOS_PREFIX
        ][0].scenario.scenario.length
      ) {
        rfqYears =
          this.$data[KEYS_GENERAL.IMPORT_DATA][
            API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.SCENARIOS_PREFIX
          ][0].scenario.scenario.length
      }

      const SOP = new Date(
        this.$data[KEYS_GENERAL.IMPORT_DATA][
          API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.SOP
        ].date,
      ).getFullYear()
      const DEFAULT_MARGE = DEFAULT_MASS_PRODUCTION_MARGIN

      switch (
        this.$data[KEYS_GENERAL.IMPORT_DATA][
          API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE
        ]
      ) {
        case RFQ_TYPES.YEARS:
          for (let i = 0; i < rfqYears; i++) {
            lotSizesTemplate[SOP + i] = DEFAULT_MARGE
          }
          break
        case RFQ_TYPES.LOT_SIZES:
          lotSizesTemplate = DEFAULT_MARGE
          break
        case RFQ_TYPES.SCENARIOS:
          for (let i = 1; i <= rfqYears; i++) {
            lotSizesTemplate[`scenario${i}`] = DEFAULT_MARGE
          }
          break
      }
      return lotSizesTemplate
    },
    getMainEntityMixin() {
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SUPPLIER.VALUE:
          return this.$props.offer ? this.$props.offer : {}

        default:
          return this.$props.rfq ? this.$props.rfq : {}
      }
    },
    getMainEntityNameMixin() {
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SUPPLIER.VALUE:
          return 'quote'
        default:
          return 'RfQ'
      }
    },
    INPUT_VALUE_FACTOR() {
      // Force values to be rounded to a specific number of decimal digits
      return Math.pow(
        10,
        parseFloat(
          itemRfQQuoteMassProductionMarginInput.validationSettings.decimal
            .decimal_digits,
        ),
      )
    },
  },
  methods: {
    initializeMovMoqValuesMixin(offers, coqCurrency, nrPCB, piecePrice) {
      let highestMOV = -1
      let highestMOQ = -1

      if (isNaN(nrPCB)) {
        nrPCB = 1
      }
      if (isNaN(piecePrice)) {
        piecePrice = 1
      }

      offers.forEach((offer) => {
        let mainCurrencyCharge
        offer['charges'].forEach((charge) => {
          if (
            charge[API_OBJECT_KEY_NAMES.OFFER.CHARGES.CURRENCY] ===
            this.getCurrencyIdMixin(coqCurrency.value)
          ) {
            mainCurrencyCharge = cloneDeep(charge)
          }
        })
        if (mainCurrencyCharge) {
          if (
            mainCurrencyCharge[
              API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_VOLUME
            ] !== null &&
            mainCurrencyCharge[
              API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_VOLUME
            ] > highestMOV
          ) {
            highestMOV =
              mainCurrencyCharge[
                API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_VOLUME
              ]
          } else if (
            mainCurrencyCharge[
              API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_QUANTITY
            ] > highestMOQ
          ) {
            highestMOQ =
              mainCurrencyCharge[
                API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_QUANTITY
              ]
          }
        }
      })
      return this.setHighestMovMoqValuesMixin({
        values: {
          piecePrice: piecePrice,
          nrPCB: nrPCB,
          highestMOV: highestMOV,
          highestMOQ: highestMOQ,
        },
        mov: highestMOV,
        moq: highestMOQ,
      })
    },
    setHighestMovMoqValuesMixin(movMOQObject) {
      if (movMOQObject.values.highestMOV < 0) {
        // Calculate missing MOV
        movMOQObject.mov =
          movMOQObject.values.highestMOQ * movMOQObject.values.piecePrice
      } else {
        movMOQObject.mov = movMOQObject.values.highestMOV
      }
      movMOQObject.mov = isFinite(movMOQObject.mov) ? movMOQObject.mov : 0

      if (movMOQObject.values.highestMOQ < 0) {
        // Calculate missing MOQ
        movMOQObject.moq =
          Math.ceil(
            movMOQObject.values.highestMOV /
              (movMOQObject.values.piecePrice * movMOQObject.values.nrPCB) /
              movMOQObject.values.nrPCB,
          ) *
          movMOQObject.values.nrPCB *
          movMOQObject.values.nrPCB
      } else {
        movMOQObject.moq = movMOQObject.values.highestMOQ
      }
      movMOQObject.moq = isFinite(movMOQObject.moq) ? movMOQObject.moq : 0

      return movMOQObject
    },
    updateMovMoqValuesPiecePriceMixin(movMOQObject, piecePrice) {
      movMOQObject.values.piecePrice = piecePrice
      return this.setHighestMovMoqValuesMixin(movMOQObject)
    },
    adjustMoqStatusMixin(item) {
      if (item.moqTreshhold !== undefined) {
        const STYLE_SUBSTRING = ' e-yellow-moq-warning'
        if (parseFloat(item.value) < parseFloat(item.moqTreshhold)) {
          item.invalid = true
          item.style = item.style += STYLE_SUBSTRING
        } else {
          item.style = item.style.replace(STYLE_SUBSTRING, '')
        }
      }
      return item
    },
    adjustMarginStatusMixin(item) {
      const STYLE_SUBSTRING = ' e-yellow-margin-warning'
      if (
        isNaN(item.value) ||
        parseFloat(item.value) < parseFloat(item.margin.threshold.yellow) ||
        parseFloat(item.value) < parseFloat(item.inputMin) ||
        parseFloat(item.value) > parseFloat(item.inputMax)
      ) {
        item.invalid = true
        if (
          parseFloat(item.value) >= parseFloat(item.margin.threshold.red) &&
          parseFloat(item.value) < parseFloat(item.margin.threshold.yellow) &&
          !item.style.includes(STYLE_SUBSTRING)
        ) {
          item.style = item.style += STYLE_SUBSTRING
        }
      } else {
        item.style = item.style.replace(STYLE_SUBSTRING, '')
        item.invalid = false
      }
      return item
    },
    getAdjustedMassProductionMarginMixin(
      price,
      logisticCostPrice,
      lotSize,
      isBasedOnFlatPrice,
      priceDataIndex,
    ) {
      let adjustLotSizeValue = parseFloat(lotSize) ? parseFloat(lotSize) : 1
      let singlePiecePrice = this.getSinglePCBPriceMixin(
        isBasedOnFlatPrice
          ? QUOTE_COMPARISON_TYPES.FLAT
          : QUOTE_COMPARISON_TYPES.ANNUAL_PRICE_DOWN,
        priceDataIndex + 1,
      )
      let newValue =
        parseFloat(singlePiecePrice) +
        parseFloat(logisticCostPrice) / adjustLotSizeValue

      // handle price ranges
      if (price <= 0) {
        price = 0.01
      }
      return 100 - Math.round((newValue / price) * 100)
    },
    updateAllMassProductionTablePricesMixin(
      logisticCostTableData,
      massProductionTableData,
      isBasedOnFlatPrice,
    ) {
      logisticCostTableData.forEach((logisticCostTableItem) => {
        logisticCostTableItem.quoteScenarioPricingDeliveries.forEach(
          (pricingDelivery) => {
            let massProductionItem = massProductionTableData.get(
              `${logisticCostTableItem.mapKey}${pricingDelivery.deliveryType}`,
            )
            if (massProductionItem) {
              massProductionItem.maxPrice =
                this.getAdjustedMassProductionPricesMixin(
                  massProductionItem.price,
                  massProductionItem.lotSizes,
                  pricingDelivery.logisticCost,
                  logisticCostTableItem.quantity,
                  isBasedOnFlatPrice,
                  this.$data[KEYS_GENERAL.IMPORT_DATA][
                    API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE
                  ],
                  true,
                )
              massProductionItem.price =
                this.getAdjustedMassProductionPricesMixin(
                  massProductionItem.price,
                  massProductionItem.lotSizes,
                  pricingDelivery.logisticCost,
                  logisticCostTableItem.quantity,
                  isBasedOnFlatPrice,
                  this.$data[KEYS_GENERAL.IMPORT_DATA][
                    API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE
                  ],
                  false,
                )
            }
          },
        )
      })
    },
    getCalculatedMassProductionPriceMixin(
      logisticCostPrice,
      lotSizeValue,
      marginValue,
      isBasedOnFlatPrice,
      priceDataIndex = 1,
      rfqType,
      forceMaxPrice,
    ) {
      let adjustLotSizeValue = parseFloat(lotSizeValue)
        ? parseFloat(lotSizeValue)
        : 1
      let singlePiecePrice = this.getSinglePCBPriceMixin(
        isBasedOnFlatPrice
          ? QUOTE_COMPARISON_TYPES.FLAT
          : QUOTE_COMPARISON_TYPES.ANNUAL_PRICE_DOWN,
        rfqType === RFQ_TYPES.SCENARIOS ? undefined : priceDataIndex + 1,
      )
      let adjustedMarginValue = parseFloat(marginValue)
      if (adjustedMarginValue >= 100 || forceMaxPrice) {
        adjustedMarginValue = 99.99
      }
      let marginDivision = 1 - (1 / 100) * adjustedMarginValue
      let newValue =
        parseFloat(singlePiecePrice) +
        parseFloat(logisticCostPrice) / adjustLotSizeValue
      newValue = newValue / marginDivision
      return (
        Math.round(newValue * this.INPUT_VALUE_FACTOR) / this.INPUT_VALUE_FACTOR
      )
    },
    getAdjustedMassProductionPricesMixin(
      priceData,
      marginData,
      logisticCostPrice,
      lotSizeValue,
      isBasedOnFlatPrice,
      rfqType,
      forceMaxPrice = false,
    ) {
      let newPriceData = cloneDeep(priceData)
      //Prevent dividing by zero
      if (newPriceData instanceof Object) {
        let marginDataArray = cloneDeep(Object.values(marginData))
        Object.keys(newPriceData).forEach((key, priceDataIndex) => {
          newPriceData[key] = this.getCalculatedMassProductionPriceMixin(
            logisticCostPrice,
            lotSizeValue,
            marginDataArray[priceDataIndex],
            isBasedOnFlatPrice,
            priceDataIndex,
            rfqType,
            forceMaxPrice,
          )
        })
      } else {
        newPriceData = this.getCalculatedMassProductionPriceMixin(
          logisticCostPrice,
          lotSizeValue,
          marginData,
          isBasedOnFlatPrice,
          0,
          rfqType,
          forceMaxPrice,
        )
      }
      return newPriceData
    },
    setInternalPanelSelectionBitMixin(value, panelType) {
      const BIT_MASK = 1 << (panelType - 1)
      switch (value) {
        case true:
          this.$data.formInternalPanelSelection |= BIT_MASK
          break
        case false:
          this.$data.formInternalPanelSelection &= ~BIT_MASK
          break
      }
    },
    isPanelTypeEnabledMixin(apiTransferStyle) {
      let panelType
      switch (apiTransferStyle) {
        case IMPORT_EXPORT.API_TRANSFER_STYLE.PROPOSED_PANEL:
          panelType = PANEL_TYPES.PROPOSED
          break
        default:
          panelType = PANEL_TYPES.CUSTOMER_SPECIFIED
          break
      }
      return this.isInternalPanelSelectionActiveMixin(
        this.$data.formInternalPanelSelection,
        panelType,
      )
    },
    isInternalPanelSelectionActiveMixin(internalPanelSelection, panelType) {
      const BIT_MASK = 1 << (panelType - 1)
      return internalPanelSelection & BIT_MASK
    },
    getDeliveryOptionBitValueMixin(deliveryOption) {
      switch (deliveryOption) {
        case QUOTE_DELIVERY_OPTIONS.SEA:
          return 1
        case QUOTE_DELIVERY_OPTIONS.AIR:
          return 2
        case QUOTE_DELIVERY_OPTIONS.EXPRESS:
          return 3
        default:
          return 0
      }
    },
    getSupplierNameMixin(offerId) {
      if (
        this.$store.getters.getActiveOffersHashmap &&
        this.$store.getters.getActiveOffersHashmap.has(offerId)
      ) {
        return this.$store.getters.getActiveOffersHashmap.get(offerId).supplier
          .company
      } else {
        return ''
      }
    },
    getReadOnlyValueMixin(item, formatValue = true) {
      let value = item.value
      if (value === FORM_ITEMS.SELECTION_SUB_ITEM) {
        value = item.subItems[0].value
      } else if (
        item.detailSettings &&
        item.detailSettings.singleSubItemDisplay &&
        item.subItems
      ) {
        if (item.radioValue) {
          value = item.subItems[0].value
        } else {
          return item.detailSettings.disabledValue
        }
      } else {
        if (item.formType) {
          switch (item.formType) {
            case FORM_ITEMS.TYPES.RADIO:
              if (item.options) {
                if (!formatValue) {
                  return item.value
                }
                return item.options[item.value]
                  ? item.options[item.value].label
                  : ''
              }
              break
            case FORM_ITEMS.TYPES.DATE:
              if (this.isValidDateMixin(value)) {
                if (!formatValue) {
                  return value
                }
                return this.getFormatedDateMixin(
                  value,
                  item.dateFormat ? item.dateFormat : DATE_FORMAT_SHORT,
                )
              } else {
                return '-'
              }
            case FORM_ITEMS.TYPES.CHECKBOX:
            case FORM_ITEMS.TYPES.SWITCH:
              if (!formatValue) {
                return item.checked
              }
              if (item.checked) {
                return 'Yes'
              } else {
                return 'No'
              }
            default:
              if (!formatValue) {
                return item.value
              }
              if (
                item.overwriteDetailsOutput &&
                item.overwriteDetailsOutput.replacement &&
                item.overwriteDetailsOutput.value === item.checked
              ) {
                return item.overwriteDetailsOutput.replacement
              } else if (item.checked === false) {
                return 'No'
              } else {
                value = item.value
              }
          }
        }
      }
      if (!formatValue) {
        return value
      }
      if (
        item.overwriteDetailsOutput &&
        item.overwriteDetailsOutput.value === value
      ) {
        return item.overwriteDetailsOutput.replacement
      }
      if (!value) {
        return NOT_SPECIFIED
      }
      if (item.unit) {
        value = `${value} ${item.unit}`
      }
      return value
    },
    capitalizeStringMixin(string) {
      return capitalize(string)
    },
    getScrollspyTargetName(target, replaceAnchor = true) {
      return replaceAnchor
        ? `${PREFIX_ANCHORS.SCROLLSPY_TARGET}${target.replace(
            PREFIX_ANCHORS.SCROLLSPY_ANCHOR,
            '',
          )}`
        : `${PREFIX_ANCHORS.SCROLLSPY_TARGET}${target}`
    },
    getScrollspyAnchorName(target) {
      return `${PREFIX_ANCHORS.SCROLLSPY_ANCHOR}${target}`
    },
    getFormatedDateMixin(date, format, toLocalTime = true) {
      if (toLocalTime) {
        dayjs.extend(utc)
        return dayjs.utc(date).local().format(format)
      } else {
        return dayjs(date).format(format)
      }
    },
    isValidDateMixin(date) {
      return dayjs(date).isValid()
    },
    convertToDateMixin(date) {
      return this.isValidDateMixin(date) ? dayjs(date).toDate() : null
    },
    addTimeToDateMixin(date, settings, format = undefined) {
      if (format) {
        return dayjs(date).add(settings.value, settings.unit).format(format)
      } else {
        return dayjs(date).add(settings.value, settings.unit).toDate()
      }
    },
    subtractTimeFromDateMixin(date, settings, format = undefined) {
      if (format) {
        return dayjs(date)
          .subtract(settings.value, settings.unit)
          .format(format)
      } else {
        return dayjs(date).subtract(settings.value, settings.unit).toDate()
      }
    },
  },
}
