import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { rfqTechCSCCTypes } from '@/assets/inputDataObjects/types/rfq/techCSCC'
import { rfqCommunicationTypes } from '@/assets/inputDataObjects/types/rfq/communication'
import { rfqNREMOV } from '@/assets/inputDataObjects/types/rfq/nreMov'
import { itemQuotations } from '@/assets/inputDataObjects/types/rfq/quotation'
import { rfqSupplierSelectionTypes } from '@/assets/inputDataObjects/types/rfq/supplierSelection'
import { rfqOfferUploadTypes } from '@/assets/inputDataObjects/types/rfq/offerUpload'
import { rfqPricingDeliveryTypes } from '@/assets/inputDataObjects/types/rfq/pricingDelivery'
import { rfqTechInformationLeadtimeTypes } from '@/assets/inputDataObjects/types/rfq/techInformationLeadtime'
import { rfqContractDetailsTypes } from '@/assets/inputDataObjects/types/rfq/contractDetails'
import { itemSupplierSettings } from '@/assets/inputDataObjects/types/rfq/supplierSettings'

export let supplierExpertSupplierOffers = {
  [KEYS_RFQ.SUPPLIER_SETTINGS]: itemSupplierSettings,
  [KEYS_RFQ.CONTRACT_DETAILS_PREFIX]: rfqContractDetailsTypes,
  [KEYS_RFQ.PRICING_DELIVERY_PREFIX]: rfqPricingDeliveryTypes,
  [KEYS_RFQ.TECH_CSCC_PREFIX]: rfqTechCSCCTypes,
  [KEYS_RFQ.COM_PREFIX]: rfqCommunicationTypes,
  [KEYS_RFQ.NRE_MOV_PREFIX]: rfqNREMOV,
  [KEYS_RFQ.QUOTATIONS]: itemQuotations,
  [KEYS_RFQ.SUPPLIER_SELECTION_PREFIX]: rfqSupplierSelectionTypes,
  [KEYS_RFQ.OFFER_UPLOAD_PREFIX]: rfqOfferUploadTypes,
  [KEYS_RFQ.ADDITIONAL_DATA_PREFIX]: rfqTechInformationLeadtimeTypes,
  [KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX]: rfqTechInformationLeadtimeTypes,
}
