<template>
  <CNav class="flex-column main-nav sidebar-nav">
    <AppSidebarNavItem
      :key="
        navItem.SEPARATOR || navItem.GLOBAL_SWITCH
          ? `navSeparator${index}`
          : navItem.ROUTER_DATA.TITLE
      "
      v-for="(navItem, index) in SIDEBAR_NAV_DATA[
        this.$store.getters.getActivePermissionGroup
      ]"
      :item="navItem"
    >
    </AppSidebarNavItem>
  </CNav>

  <CNav class="flex-column support-nav">
    <AppSidebarNavItem
      :key="navItem.ROUTER_DATA.TITLE"
      v-for="navItem in SIDEBAR_NAV_DATA.SUPPORT"
      :item="navItem"
    >
    </AppSidebarNavItem>
  </CNav>
</template>

<script>
import axiosMixins from '@/mixins/axiosMixins'
import { ROUTER_DATA } from '@/constants/routerData'
import { SIDEBAR_NAV_DATA } from '@/constants/sidebarNavData'
import AppSidebarNavItem from '@/components/appSidebar/AppSidebarNavItem'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'

export default {
  mixins: [axiosMixins],
  components: { AppSidebarNavItem },
  name: 'AppSidebarNav',
  data() {
    return {
      ROUTER_DATA,
      SIDEBAR_NAV_DATA,
    }
  },
  watch: {
    '$store.getters.getGlobalOverviewStatus'() {
      this.axiosUpdateSidebarMixin()
    },
    $route() {
      if (
        this.$store.getters.getActivePermissionGroup !==
        PERMISSION_GROUPS.NO_PERMISSION.VALUE
      ) {
        this.axiosUpdateSidebarMixin()
      }
    },
  },
  mounted() {
    this.axiosUpdateSidebarMixin()
  },
}
</script>

<style scoped>
.main-nav {
  display: block;
}

.router-link-active,
.router-link-active svg {
  color: white;
}

.support-nav {
  bottom: 0;
}

.support-nav a {
  color: white;
  font-size: 14px;
}

.icon-custom-size {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 18px;
}

.nav-title {
  margin-top: 20px;
}

.e-business-title {
  margin-top: 30px;
}

.nav-link {
  padding-top: 11px;
  padding-bottom: 11px;
}

.e-sidebar-badge {
  margin-left: 5px;
  padding: 3px;
}

.support-nav {
  padding-bottom: 25px;
}

.support-nav a {
  font-size: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
  opacity: 69%;
}
</style>
