const PREFIX = 'PasswordCheck'

const CHECK_TYPES = {
  MINIMUM_LENGTH: `${PREFIX}MinimumLength`,
  REQUIRED_CHARACTERS: `${PREFIX}RequiredCharacters`,
  NO_EMAIL: `${PREFIX}NoEmail`,
  LOWERCASE: `${PREFIX}LowercaseCharacter`,
  UPPERCASE: `${PREFIX}UppercaseCharacter`,
  NUMBER: `${PREFIX}Number`,
}

const MINIMUM_LENGTH = 12

let requiredCharacters = {
  [CHECK_TYPES.LOWERCASE]: 1,
  [CHECK_TYPES.UPPERCASE]: 1,
  [CHECK_TYPES.NUMBER]: 1,
}

function getRequiredCharactersMessage() {
  let message = 'At least '

  let loopIndex = 0
  Object.entries(requiredCharacters).forEach((requirement) => {
    if (loopIndex !== 0) {
      if (loopIndex < Object.keys(requiredCharacters).length - 1) {
        message += ', '
      } else {
        message += ' and '
      }
    }

    switch (requirement[0]) {
      case CHECK_TYPES.LOWERCASE:
        message += `${requirement[1]} lowercase`
        break
      case CHECK_TYPES.UPPERCASE:
        message += `${requirement[1]} uppercase`
        break
      case CHECK_TYPES.NUMBER:
        message += `${requirement[1]} number`
        break
    }
    loopIndex++
  })
  return message
}

let itemNoEmail = {
  type: CHECK_TYPES.NO_EMAIL,
  message:
    'The e-mail address must not be part of the password. This concerns the characters before the @',
}

let itemMinimumLength = {
  type: CHECK_TYPES.MINIMUM_LENGTH,
  value: MINIMUM_LENGTH,
  message: `Minimum length of ${MINIMUM_LENGTH} characters`,
}

let itemRequiredCharacters = {
  type: CHECK_TYPES.REQUIRED_CHARACTERS,
  required: requiredCharacters,
  message: getRequiredCharactersMessage(),
}

// Status
// 0 = Triggered
// 1 = Waiting
let remoteEmailValidation = 1

export let passwordCheckTypes = {
  itemNoEmail,
  itemMinimumLength,
  itemRequiredCharacters,
  CHECK_TYPES,
  MINIMUM_LENGTH,
  remoteEmailValidation,
}
