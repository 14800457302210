import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { FORM_ITEMS } from '@/constants/formItems'
import { VALIDATION_TYPE } from '@/constants/validation'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { IMPORT_EXPORT } from '@/constants/importExport'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { NOT_RELEVANT, NOT_SPECIFIED } from '@/constants/constants'
import { AUTO_CORRECT_VALUE } from '@/constants/autoCorrectSettings'
import { BASE_CU_OUTER_LAYER_OPTIONS } from '@/constants/optionsAndValidations/baseCuThickness'

let itemInnerLayer = {
  keyName: KEYS_RFQ.COPPER_CRITERIA.INNER_LAYER,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.INNER_LAYER],
  style: '',
  value: '18',
  importPlaceholder: '18',
  formType: FORM_ITEMS.TYPES.SELECT,
  parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
  unit: FORM_ITEMS.UNITS.MICROMETRE,
  overwriteDetailsOutput: {
    value: '0',
    replacement: NOT_RELEVANT,
  },
  required: true,
  invalid: false,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  defaultOption: 0,
  options: [
    {
      label: '18µm',
      value: '18',
    },
    {
      label: '35µm',
      value: '35',
    },
    {
      label: '70µm',
      value: '70',
    },
    {
      label: '105µm',
      value: '105',
    },
    {
      label: '140µm',
      value: '140',
    },
    {
      label: '175µm',
      value: '175',
    },
    {
      label: '210µm',
      value: '210',
    },
  ],
  messages: {
    error: 'Please select a Base Cu thickness inner layer',
    label: 'Base Cu thickness inner layer',
  },
}

let itemOuterLayer = {
  keyName: KEYS_RFQ.COPPER_CRITERIA.OUTER_LAYER,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.OUTER_LAYER],
  style: '',
  value: '18',
  importPlaceholder: '18',
  formType: FORM_ITEMS.TYPES.SELECT,
  parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
  unit: FORM_ITEMS.UNITS.MICROMETRE,
  defaultOption: 1,
  options: BASE_CU_OUTER_LAYER_OPTIONS.map((value) => {
    return {
      label: `${value}µm`,
      value: value,
    }
  }),
  required: true,
  invalid: false,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  messages: {
    error: 'Please select a Base Cu thickness outer layer',
    label: 'Base Cu thickness outer layer',
  },
}

let itemFinishedCU = {
  keyName: KEYS_RFQ.COPPER_CRITERIA.FINISHED_CU,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.FINISHED_CU],
  style: '',
  value: '',
  defaultValue: 'no minimum request',
  toggle: {
    type: 'switch',
  },
  formType: FORM_ITEMS.TYPES.TOGGLE_SWITCH,
  required: true,
  invalid: false,
  checked: false,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
  validationSettings: {
    formatNumber: true,
    decimal: { decimal_digits: '1' },
  },
  autoCorrectSettings: [
    {
      refKey: AUTO_CORRECT_VALUE.TARGET.SELF,
      targetKey: AUTO_CORRECT_VALUE.TARGET.SELF,
      event: AUTO_CORRECT_VALUE.EVENT.BLUR,
      rule: AUTO_CORRECT_VALUE.RULE.MIN_REQUIRED_SIZE,
      value: 0,
    },
  ],
  parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
  unit: FORM_ITEMS.UNITS.MICROMETRE,
  overwriteDetailsOutput: {
    switchLabel: true,
    value: false,
    replacement: NOT_SPECIFIED,
  },
  messages: {
    label: 'Specify in µm',
    placeholder: 'Specify in µm',
    error: 'Please specify the layer',
    switch: 'Finished Cu thickness outer layer',
  },
}

let itemSpacing = {
  keyName: KEYS_RFQ.COPPER_CRITERIA.SPACING,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.MIN_LINE_WIDTH,
    API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.SPACING,
  ],
  style: 'd-flex justify-content-between align-items-center',
  value: '',
  radioValue: 0,
  formType: FORM_ITEMS.TYPES.RADIO,
  required: true,
  invalid: false,
  subItems: [
    {
      apiObjectKeyNames: [],
      keyNameGroup: KEYS_RFQ.COPPER_CRITERIA_PREFIX,
      keyNameParent: KEYS_RFQ.COPPER_CRITERIA.SPACING,
      keyName: KEYS_RFQ.COPPER_CRITERIA.SPACING + KEYS_GENERAL.SUB_ITEM_1,
      resetAPIValues: true,
      required: true,
      radioValueGroup: 0,
      overwriteDetailsOutput: {
        value: '',
        replacement: '',
      },
      validationTypes: [],
      messages: {
        label: 'Take from Gerber Data',
      },
    },
    {
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.MIN_LINE_WIDTH,
      ],
      keyNameGroup: KEYS_RFQ.COPPER_CRITERIA_PREFIX,
      keyNameParent: KEYS_RFQ.COPPER_CRITERIA.SPACING,
      keyName: KEYS_RFQ.COPPER_CRITERIA.SPACING + KEYS_GENERAL.SUB_ITEM_2,
      required: true,
      radioValueGroup: 1,
      inputMin: '50',
      inputMax: '5000',
      detailSettings: {
        colMdSize: 6,
      },
      unit: FORM_ITEMS.UNITS.MICROMETRE,
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
      validationSettings: {
        formatNumber: true,
        decimal: { decimal_digits: '1' },
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
      messages: {
        placeholder: 'Min. Line Width in µm',
        label: 'Min. Line Width in µm',
        error: 'Please specify the Min. Line Width in µm',
      },
    },
    {
      apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.SPACING],
      keyNameGroup: KEYS_RFQ.COPPER_CRITERIA_PREFIX,
      keyNameParent: KEYS_RFQ.COPPER_CRITERIA.SPACING,
      keyName: KEYS_RFQ.COPPER_CRITERIA.SPACING + KEYS_GENERAL.SUB_ITEM_3,
      required: true,
      radioValueGroup: 1,
      inputMin: '50',
      inputMax: '5000',
      detailSettings: {
        colMdSize: 6,
      },
      unit: FORM_ITEMS.UNITS.MICROMETRE,
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
      validationSettings: {
        formatNumber: true,
        decimal: { decimal_digits: '1' },
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
      messages: {
        placeholder: 'Min. Spacing in µm',
        label: 'Min. Spacing in µm',
        error: 'Please specify the Min. Spacing in µm',
      },
    },
  ],
  options: [
    {
      keyName: KEYS_RFQ.COPPER_CRITERIA.SPACING + 'TakeFromGerberData',
      label: 'Take from Gerber Data',
      selected: true,
      subItemTargets: [
        KEYS_RFQ.COPPER_CRITERIA.SPACING + KEYS_GENERAL.SUB_ITEM_1,
      ],
    },
    {
      keyName: KEYS_RFQ.COPPER_CRITERIA.SPACING + 'AddIndividualData',
      label: 'Add individual Data',
      subItemTargets: [
        KEYS_RFQ.COPPER_CRITERIA.SPACING + KEYS_GENERAL.SUB_ITEM_2,
        KEYS_RFQ.COPPER_CRITERIA.SPACING + KEYS_GENERAL.SUB_ITEM_3,
      ],
    },
  ],
  detailsSettings: {
    gotGroupTitle: true,
    disableLabel: true,
    groupTitle: 'Min. Line Width / Spacing (µm)',
  },
  messages: {
    label: 'Min. Line Width / Spacing (µm)',
    error: 'Please specify the Min. Line Width / Spacing (µm)',
  },
}

let itemPTH = {
  keyName: KEYS_RFQ.COPPER_CRITERIA.PTH,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.PTH],
  style: '',
  value: '20',
  importPlaceholder: '20',
  formType: FORM_ITEMS.TYPES.SELECT,
  defaultOption: 0,
  validationTypes: [VALIDATION_TYPE.INTEGER],
  parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
  unit: FORM_ITEMS.UNITS.MICROMETRE,
  options: [
    {
      label: '20µm',
      value: '20',
    },
    {
      label: '25µm',
      value: '25',
    },
    {
      label: '30µm',
      value: '30',
    },
  ],
  required: true,
  invalid: false,
  overwriteDetailsOutput: {
    value: 0,
    replacement: 0,
  },
  messages: {
    tooltip:
      'Standard is 20µm and suits for most applications. Advanced 25µm. 30µm is special requirement with high cost impact.',
    error: 'Please select a Base Cu thickness inner layer',
    label: 'PTH (min. Cu thickness mechanical drill)',
  },
}

export let rfqCopperCriteriaTypes = {
  [itemInnerLayer.keyName]: itemInnerLayer,
  [itemOuterLayer.keyName]: itemOuterLayer,
  [itemFinishedCU.keyName]: itemFinishedCU,
  [itemSpacing.keyName]: itemSpacing,
  [itemPTH.keyName]: itemPTH,
}
