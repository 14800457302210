export const INPUT_TYPES_ANCHORS = {
  FIELD: 'inputField',
  FIELD_FILE: 'inputFile',
  RADIO: 'radio',
  SWITCH: 'switch',
  CHECKBOX: 'checkbox',
  TEXT_AREA: 'textArea',
  CALENDAR: 'calendar',
  RANGE: 'range',
  SELECT: 'select',
  FILE: 'file',
}
