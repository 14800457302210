<template>
  <router-view />
</template>
<script>
import { APP_TITLE } from '@/constants/constants'

export default {
  setup() {
    document.title = APP_TITLE
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
