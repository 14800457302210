import { DATE_FORMAT_MINUTES } from '@/constants/constants'
import utilMixins from '@/mixins/utilMixins'
import { MODALS } from '@/constants/modals'

export default {
  mixins: [utilMixins],
  methods: {
    modalSetVisible(target) {
      this.$store.commit('setModalTypeStatusVisible', target)
    },
    modalSetInvisible(target) {
      this.$store.commit('setModalTypeStatusInVisible', target)
      if (target === MODALS.ERROR_HANDLING) {
        this.$store.commit('setErrorLog', null)
      }
    },
    modalTypeIsVisible(target) {
      return this.$store.getters.getModalTypeStatus[target]
    },
    modalGetDateMixin(data) {
      if (data) {
        return this.getFormatedDateMixin(data.date, DATE_FORMAT_MINUTES)
      }
    },
  },
}
