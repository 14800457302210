import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { VALIDATION_TYPE } from '@/constants/validation'
import { FORM_ITEMS } from '@/constants/formItems'
import { IMPORT_EXPORT } from '@/constants/importExport'
import {
  API_OBJECT_GROUPS,
  API_OBJECT_KEY_NAMES,
} from '@/constants/apiObjectKeyNames'
import {
  CURRENCY_TYPE,
  MAX_ALLOWED_TEXT_AREA_CHARACTERS,
} from '@/constants/constants'
import anchorTagsMixins from '@/mixins/anchorTagsMixins'
import { INFO_ANCHORS } from '@/constants/anchorTags/infoAnchorTags'
import { INPUT_TYPES_ANCHORS } from '@/constants/anchorTags/inputTypeAnchorTags'

let itemNREMOVToolingCharge = {
  keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_TOOLING_CHARGE,
  apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.CHARGES.TOOLING_CHARGE,
    API_OBJECT_KEY_NAMES.OFFER.CHARGES.TOOLING_CHARGE,
  ],
  invalid: false,
  required: true,
  currency: [],
  subItems: [
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_TOOLING_CHARGE,
      keyName:
        KEYS_RFQ.NRE_MOV.NRE_MOV_TOOLING_CHARGE + KEYS_GENERAL.SUB_ITEM_1,
      style: 'e-large-table-input',
      value: '0',
      required: true,
      invalid: false,
      noStar: true,
      floatingLabel: false,
      currency: {
        type: CURRENCY_TYPE.MAIN,
        values: [],
      },
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CHARGES.TOOLING_CHARGE],
      inputMin: '0',
      inputMax: '99999',
      validationSettings: {
        formatNumber: true,
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
      messages: {
        tooltip: 'Punching die cost',
      },
    },
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_TOOLING_CHARGE,
      keyName:
        KEYS_RFQ.NRE_MOV.NRE_MOV_TOOLING_CHARGE + KEYS_GENERAL.SUB_ITEM_2,
      style: 'e-large-table-input',
      value: '0',
      required: true,
      invalid: false,
      noStar: true,
      floatingLabel: false,
      currency: {
        type: CURRENCY_TYPE.ADDITIONAL,
        values: [],
      },
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CHARGES.TOOLING_CHARGE],
      inputMin: '0',
      inputMax: '99999',
      validationSettings: {
        formatNumber: true,
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
      messages: {
        tooltip: 'Punching die cost',
      },
    },
  ],
  messages: {
    error: 'Please set a tooling charge',
    label: 'Tooling Charge',
  },
}

let itemNREMOVTesterCharge = {
  keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_TESTER_CHARGE,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.CHARGES.TESTER_CHARGE,
    API_OBJECT_KEY_NAMES.OFFER.CHARGES.TESTER_CHARGE_COMMENT,
  ],
  invalid: false,
  required: true,
  subItems: [
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_TESTER_CHARGE,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_TESTER_CHARGE + KEYS_GENERAL.SUB_ITEM_1,
      style: 'e-large-table-input',
      value: '0',
      required: true,
      invalid: false,
      noStar: true,
      floatingLabel: false,
      currency: {
        type: CURRENCY_TYPE.MAIN,
        values: [],
      },
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CHARGES.TESTER_CHARGE],
      inputMin: '0',
      inputMax: '99999',
      validationSettings: {
        formatNumber: true,
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
      messages: {},
    },
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_TESTER_CHARGE,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_TESTER_CHARGE + KEYS_GENERAL.SUB_ITEM_2,
      style: 'e-large-table-input',
      value: '0',
      required: true,
      invalid: false,
      noStar: true,
      floatingLabel: false,
      currency: {
        type: CURRENCY_TYPE.ADDITIONAL,
        values: [],
      },
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CHARGES.TESTER_CHARGE],
      inputMin: '0',
      inputMax: '99999',
      validationSettings: {
        formatNumber: true,
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
      messages: {},
    },
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_TESTER_CHARGE,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_TESTER_CHARGE + KEYS_GENERAL.SUB_ITEM_3,
      style: '',
      value: '',
      required: false,
      invalid: false,
      noStar: true,
      validationTypes: [],
      currency: {
        type: CURRENCY_TYPE.MAIN,
        values: [],
      },
      targetId: anchorTagsMixins.methods.getInputAnchorTagMixin(
        INFO_ANCHORS.NRE_MOV.TESTER_CHARGE + 1,
        INPUT_TYPES_ANCHORS.TEXT_AREA,
      ),
      formType: FORM_ITEMS.TYPES.TEXTAREA,
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.OFFER.CHARGES.TESTER_CHARGE_COMMENT,
      ],
      maxLength: MAX_ALLOWED_TEXT_AREA_CHARACTERS,
      messages: {
        label: 'Reason for increasing e-tester charge',
        placeholder: 'Reason for increasing e-tester charge',
      },
    },
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES + KEYS_GENERAL.SUB_ITEM_4,
      style: '',
      value: '',
      required: false,
      invalid: false,
      noStar: true,
      validationTypes: [],
      targetId: anchorTagsMixins.methods.getInputAnchorTagMixin(
        INFO_ANCHORS.NRE_MOV.TESTER_CHARGE + 2,
        INPUT_TYPES_ANCHORS.TEXT_AREA,
      ),
      formType: FORM_ITEMS.TYPES.TEXTAREA,
      maxLength: MAX_ALLOWED_TEXT_AREA_CHARACTERS,
      currency: {
        type: CURRENCY_TYPE.ADDITIONAL,
        values: [],
      },
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.OFFER.CHARGES.TESTER_CHARGE_COMMENT,
      ],
      messages: {
        label: 'Reason for increasing e-tester charge',
        placeholder: 'Reason for increasing e-tester charge',
      },
    },
  ],
  messages: {
    error: 'Please set a e-tester charge',
    label: 'E-Tester Charge',
  },
}

let itemNREMOVOtherCharges = {
  keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.CHARGES.OTHER_CHARGE,
    API_OBJECT_KEY_NAMES.OFFER.CHARGES.OTHER_CHARGE_COMMENT,
  ],
  invalid: false,
  required: true,
  subItems: [
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES + KEYS_GENERAL.SUB_ITEM_1,
      style: 'e-large-table-input',
      value: '0',
      importPlaceholder: '0',
      required: true,
      invalid: false,
      noStar: true,
      floatingLabel: false,
      currency: {
        type: CURRENCY_TYPE.MAIN,
        values: [],
      },
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CHARGES.OTHER_CHARGE],
      inputMin: '0',
      inputMax: '99999',
      validationSettings: {
        formatNumber: true,
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
      messages: {},
    },
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES + KEYS_GENERAL.SUB_ITEM_2,
      style: 'e-large-table-input',
      value: '0',
      importPlaceholder: '0',
      required: true,
      invalid: false,
      noStar: true,
      floatingLabel: false,
      currency: {
        type: CURRENCY_TYPE.ADDITIONAL,
        values: [],
      },
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CHARGES.OTHER_CHARGE],
      inputMin: '0',
      inputMax: '99999',
      validationSettings: {
        formatNumber: true,
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
      messages: {},
    },
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES + KEYS_GENERAL.SUB_ITEM_3,
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.OFFER.CHARGES.OTHER_CHARGE_COMMENT,
      ],
      style: '',
      value: '',
      required: false,
      invalid: false,
      noStar: true,
      validationTypes: [],
      currency: {
        type: CURRENCY_TYPE.MAIN,
        values: [],
      },
      targetId: anchorTagsMixins.methods.getInputAnchorTagMixin(
        INFO_ANCHORS.NRE_MOV.OTHER_CHARGES + 1,
        INPUT_TYPES_ANCHORS.TEXT_AREA,
      ),
      formType: FORM_ITEMS.TYPES.TEXTAREA,
      maxLength: MAX_ALLOWED_TEXT_AREA_CHARACTERS,
      messages: {
        label: 'Please specify type of charges',
        placeholder: 'Please specify type of charges',
      },
    },
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_OTHER_CHARGES + KEYS_GENERAL.SUB_ITEM_4,
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.OFFER.CHARGES.OTHER_CHARGE_COMMENT,
      ],
      style: '',
      value: '',
      required: false,
      invalid: false,
      noStar: true,
      validationTypes: [],
      targetId: anchorTagsMixins.methods.getInputAnchorTagMixin(
        INFO_ANCHORS.NRE_MOV.OTHER_CHARGES + 2,
        INPUT_TYPES_ANCHORS.TEXT_AREA,
      ),
      formType: FORM_ITEMS.TYPES.TEXTAREA,
      maxLength: MAX_ALLOWED_TEXT_AREA_CHARACTERS,
      currency: {
        type: CURRENCY_TYPE.ADDITIONAL,
        values: [],
      },
      messages: {
        label: 'Please specify type of charges',
        placeholder: 'Please specify type of charges',
      },
    },
  ],
  messages: {
    error: 'Please set other charges',
    label: 'Other Charges',
  },
}

let itemNREMOVMinOrder = {
  keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_VOLUME,
    API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_QUANTITY,
  ],
  invalid: false,
  radioValue: 0,
  formType: FORM_ITEMS.TYPES.RADIO,
  currency: [],
  subItems: [
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER + KEYS_GENERAL.SUB_ITEM_1,
      style: 'e-large-table-input',
      value: '0',
      required: true,
      invalid: false,
      noStar: true,
      floatingLabel: false,
      radioValueGroup: 0,
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_VOLUME],
      currency: {
        type: CURRENCY_TYPE.MAIN,
        values: [],
      },
      validationSettings: {
        decimal: { decimal_digits: '4' },
        formatNumber: true,
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
      messages: {},
    },
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER + KEYS_GENERAL.SUB_ITEM_2,
      style: 'e-large-table-input',
      value: '0',
      required: true,
      invalid: false,
      noStar: true,
      floatingLabel: false,
      currency: {
        type: CURRENCY_TYPE.ADDITIONAL,
        values: [],
      },
      radioValueGroup: 0,
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_VOLUME],
      validationSettings: {
        decimal: { decimal_digits: '4' },
        formatNumber: true,
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
      messages: {},
    },
    {
      keyNameGroup: KEYS_RFQ.NRE_MOV_PREFIX,
      keyNameParent: KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER,
      keyName: KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER + KEYS_GENERAL.SUB_ITEM_3,
      style: 'e-large-table-input',
      value: '0',
      required: true,
      invalid: false,
      noStar: true,
      floatingLabel: false,
      radioValueGroup: 1,
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
      apiObjectGroup: API_OBJECT_GROUPS.CHARGES,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.OFFER.CHARGES.MIN_ORDER_QUANTITY,
      ],
      currency: {
        type: CURRENCY_TYPE.BOTH,
        values: [],
      },
      validationSettings: {
        decimal: { decimal_digits: '4' },
        formatNumber: true,
      },
      parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
      messages: {},
    },
  ],
  options: [
    {
      label: 'Min. Order Volume',
      selected: true,
      subItemTargets: [
        KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER + KEYS_GENERAL.SUB_ITEM_1,
        KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER + KEYS_GENERAL.SUB_ITEM_2,
      ],
    },
    {
      label: 'Min. Order Quantity',
      subItemTargets: [
        KEYS_RFQ.NRE_MOV.NRE_MOV_MIN_ORDER + KEYS_GENERAL.SUB_ITEM_3,
      ],
    },
  ],
  messages: {},
}

export let rfqNREMOV = {
  [itemNREMOVToolingCharge.keyName]: itemNREMOVToolingCharge,
  [itemNREMOVTesterCharge.keyName]: itemNREMOVTesterCharge,
  [itemNREMOVOtherCharges.keyName]: itemNREMOVOtherCharges,
  [itemNREMOVMinOrder.keyName]: itemNREMOVMinOrder,
}
