export const deliveryObject = {
  location: {
    label: '',
    value: '',
  },
  samples: 0,
  annualForecast: 0,
  sop: [0, 0, 0, 0, 0, 0, 0, 0],
}

export const DELIVERY_TYPE = {
  SOP: 0,
  SAMPLES: 1,
  ANNUAL_FORECAST: 2,
}
