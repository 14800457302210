const RFQ = {
  INTERNAL_ID: 'internalId',
  OFFER_PHASE_NUMBER: 'offerPhaseNumber',
  STATE: 'state',
  COMMENT: 'comment',
  LOCKED_BY: 'lockedBy',
  LOCKED_AT: 'lockedAt',
  LOCKED_BY_FIRST_NAME: 'lockedByFirstName',
  LOCKED_BY_LAST_NAME: 'lockedByLastName',
  OFFER_COMMENTS: 'offerComments', // Read only for the Supplier Communication section
  DELIVERY_SOURCE: 'deliverySource',
  DELIVERY_REGION: 'deliveryRegion',
  ADDITIONAL_DATA_PREFIX: 'additionalData',
  ADDITIONAL_DATA: {
    QUOTE_COMPARISON: 'quoteComparison',
  },
  FILES_FOR_SUPPLIERS: 'filesForSuppliers',
  IS_INSTANT_QUOTE: 'isBypassed',
  PROJECT_DATA: {
    PROJECT_NAME: 'name',
    RFQ_SECTOR: 'industrySector',
    PRODUCT: 'product',
    QUOTE_EXPECTED_BY: 'quoteExpectedBy',
    FIRST_SAMPLE: 'firstSamples',
    SOP: 'sop',
    RFQ_YEARS: 'typeCount',
    INCOTERM: 'incoterm',
    RFQ_TYPE: 'type',
    EXPECTED_LIFETIME: 'expectedLifetime',
    SCENARIO_NUMBER: 'scenarioNumber',
    LOT_NUMBER: 'lotNumber',
    SPECIFY_LOT_SIZES: 'lotSizes',
  },
  CUSTOMER_QUOTE: {
    FILE: 'quotationFileName',
  },
  DOCUMENTS: {
    GERBER_FILE: 'gerberFileName',
    SPECIFICATION: 'specificationFileName',
    PANEL_DRAWING: 'panelDrawingFileName',
    OTHERS: 'othersFileName',
  },
  BASE_TECHNOLOGY: {
    BASIC_TECHNOLOGY: 'pcbBasicTechnology',
    LAYER: 'pcbBasicLayer',
    LAMINATE_TEMP: 'pcbBasicLaminateTemp',
    LAMINATE: 'pcbBasicLaminate',
    SOLDER_MASK: 'pcbBasicColor',
    COMPONENT_MARK: 'pcbBasicComponentMark',
    PROFILING: 'pcbBasicProfiling',
  },
  COPPER_CRITERIA: {
    INNER_LAYER: 'pcbCopperBaseThicknessInner',
    OUTER_LAYER: 'pcbCopperBaseThicknessOuter',
    FINISHED_CU: 'pcbCopperFinishedThicknessOuter',
    MIN_LINE_WIDTH: 'pcbCopperMinLineWidth',
    SPACING: 'pcbCopperSpacing',
    PTH: 'pcbCopperPth',
  },
  PCB_DIMENSIONS: {
    PANEL_WIDTH_MIN: 'pcbDimensionPanelWidthMin',
    PANEL_WIDTH_MAX: 'pcbDimensionPanelWidthMax',
    PANEL_LENGTH_MIN: 'pcbDimensionPanelLengthMin',
    PANEL_LENGTH_MAX: 'pcbDimensionPanelLengthMax',
    NR_PCB: 'pcbDimensionNumber',
    BOARD_THICKNESS: 'pcbDimensionBoardThickness',
    BOARD_TOLERANCE_IS_PERCENT: 'pcbDimensionBoardThicknessToleranceIsPercent',
    BOARD_TOLERANCE: 'pcbDimensionBoardThicknessToleranceValue',
    SURFACE_FINISHING: 'pcbDimensionSurfaceFinishing',
  },
  OTHERS: {
    IPC_CLASS: 'pcbOthersIpc',
    CTI_VALUE: 'pcbOthersCti',
    HALOGEN_FREE: 'pcbOthersHalogenFree',
    CAF_RESISTANCE: 'pcbOthersCafResistance',
    IMPEDANCE_CONTROL_OHM: 'pcbOthersImpedanceControlValue',
    IMPEDANCE_CONTROL_TOLERANCE: 'pcbOthersImpedanceControlTolerance',
    PRESS_FIT_HOLES: 'pcbOthersPressFitHoles',
    ROHS_CONFORM: 'pcbOthersRohsConform',
    VIA_PLUG: 'pcbOthersViaPlug',
    BLIND_VIA: 'pcbOthersBlindVias',
    BURIED_VIA: 'pcbOthersBuriedVias',
    CROSS_OUT_PANEL: 'pcbOthersCrossOutPanel',
    CROSS_OUT_SHIPPING: 'pcbOthersCrossOutShipping',
    UL_MARKING: 'pcbOthersUlMarking',
  },
  ADDITIONAL_INFO: 'additionalSpecifications',
  QUOTATION_NOTES: {
    GENERAL_CONDITIONS: 'notesGeneralConditions',
    FURTHER_REMARKS: 'notesFurtherRemarks',
  },
  NOTES_SALES: 'comments',
  COMMENTS: 'comments',
  NOTES_FOR_ME: 'customerSpecificComment',
  INTERNAL_COMMENTS_BOSCH: 'internalComments',
  DELIVERY: 'deliveries',
  QUOTES_GROUP: 'quotes',
  QUOTES: {
    QUOTE_SUMMARY_FILE: 'quotationSummaryFileName',
    QUOTE_FILE: 'quotationFileName',
    QUOTE_FRAME_CONTRACT_FILE: 'frameContractFileName',
    QUOTE_TERMS_CONDITION_FILE: 'termsConditionsFileName',
  },
  CHANGE_REQUEST: {
    DATE: 'changeRequestedAt',
    COMMENT: 'changeRequestComment',
  },
  REJECTED: {
    DATE: 'declinedAt',
    COMMENT: 'declinedComment',
  },
  COST_INDICATION: {
    LOWER_BOUND_COST: 'lowerBoundCost',
    UPPER_BOUND_COST: 'upperBoundCost',
    MIND_POINT_COST: 'midPointCost',
  },
  PRICE_INDICATION: {
    LOWER_BOUND: 'lowerBoundPrice',
    UPPER_BOUND: 'upperBoundPrice',
    MID_POINT: 'midPointPrice',
    UPPER_BOUND_CUSTOMER: 'customerUpperBoundPrice',
    MID_POINT_CUSTOMER: 'customerMidPointPrice',
    COST_MATRIX_PRICE: 'priceIndicationPrice',
    COST_MATRIX_SUPPLIER: 'priceIndicationSupplierName',
    COST_MATRIX_COMMENT: 'priceIndicationComment',
  },
  CUSTOMER: 'customer',
  CREATOR: 'creator',
  UPDATED_BY: 'updatedBy',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  COM: {
    COM_FILE: 'communicationFile',
  },
  CONTRACT_DETAILS: {
    CONTRACT_FILE: 'contractDetailsFile',
    ADDENDUM_FILE: 'addendumFile',
  },
  SUPPLIER_EXPERT_DATA: {
    CSCC: 'cscc',
    DETAILED_FEASIBILITY: 'detailedFeasibility',
    OFFER_PRICING_PREFIX: 'offerPricing',
    OFFER_PRICING: {
      REDUCTION_YEARS: 'reductionYears',
      REDUCTION_PERCENTAGE: 'reductionPercentage',
      INCOTERM: 'incoterm',
      NAMED_PLACE: 'namedPlace',
      COQ_CURRENCY: 'coqCurrency',
      MIN_ORDER_VALUE: 'minOrderValue',
      EXCHANGE_RATE_CNY_TO_USD: 'currencyConversionCnyToUsd',
      EXCHANGE_RATE_EUR_TO_USD: 'currencyConversionEurToUsd',
    },
    SCENARIO: 'scenario',
    SCENARIOS_PREFIX: 'scenarios',
    QUOTE_DUE: 'quoteDue',
    VALID_UNTIL: 'validUntil',
  },
  SALES_EXPERT_DATA: {
    QUOTATION_DELIVERY: {
      FLAT_PRICE: 'needFlatPrice',
      ANNUAL_PRICE_DOWN: 'needAnnualPriceDown',
    },
  },
  SPECIFY_LOT_SIZES: 'lotSizes',
  ORIGINAL_STATE: 'originalState',
  ASSIGNMENTS: 'assignments',
  PANEL_DIMENSIONS_FOR_SUPPLIER: {
    WIDTH: 'pcbDimensionWidthSpecific',
    LENGTH: 'pcbDimensionLengthSpecific',
    NR_PCB: 'pcbDimensionNumberSpecific',
  },
  OFFERS: 'offers',
  ASSIGNED_TO_ME: 'assignedToMe',
}

const ADDRESS = {
  CUSTOM_FIELDS: {
    CATEGORY: 'custom_fields',
    VAT_ID: 'elio_address_vat_id',
    NICKNAME: 'elio_address_nickname',
  },
}

const USER = {
  HASH_VALID: 'hashValid',
  PASSWORD_VAlID: 'passwordValid',
}

const OFFER = {
  RFQ: 'rfq',
  ACTIVE_FOR_NEXT_PHASE: 'activeForNextPhase',
  QUOTA_ALLOCATION: 'quotaAllocation',
  CONTRACT: {
    ADDENDUM_FILE: 'offerAddendumFilename',
    CONTRACT_FILE: 'offerContractFilename',
  },
  PRICING_DELIVERY: {
    ANNUAL_PRICE_DOWN: 'annualPriceDown',
    FLAT_PRICE: 'flatPrice',
    INCOTERM: 'incoterm',
    SPECIFY_ANNUAL_PRICE: 'specifyAnnualPriceDown',
    CURRENCY: 'currency',
    ADDITIONAL_CURRENCY: 'additionalCurrency',
    NAMED_PLACE: 'namedPlace',
  },
  OFFER_UPLOAD: {
    OFFER_UPLOAD_FILE: 'offerFilename',
  },
  QUOTATIONS: {
    PREFIX: 'quotations',
    QUOTATION: 'quotation',
    FLAT: 'flat',
    ANNUAL_PRICE_DOWN: 'annualPriceDown',
    SCENARIOS: 'scenarios',
    YEARS: 'years',
    LOT_SIZES: 'lotSizes',
    SAMPLES: 'samples',
    SAMPLE: 'sample',
    SERIES: 'series',
    PANEL_TYPE: 'panelType',
    CUSTOMER_SPECIFIED: 'customerSpecified',
    PROPOSED: 'proposed',
  },
  TECH_CONFIRMED: {
    CONFIRMED_FILE: 'feasibilityConfirmationFilename',
    CSCC_FILE: 'csccFilename',
    CONFIRMED_CHECKBOX: 'feasibilityConfirmed',
    COMMENT: 'feasibilityComment',
  },
  CURRENCY: 'currency',
  ADDITIONAL_CURRENCY: 'additionalCurrency',
  YEARS: {
    FLAT: {
      SAMPLES: 'samples',
      YEARS: 'years',
    },
    ANNUAL_PRICE_DOWN: {
      SAMPLES: 'samples',
      YEARS: 'years',
    },
  },
  SCENARIOS: {
    FLAT: {
      SAMPLES: 'samples',
      YEARS: 'years',
    },
    ANNUAL_PRICE_DOWN: {
      SAMPLES: 'samples',
      YEARS: 'years',
    },
  },
  LOT_SIZE: {
    SAMPLE: 'sample',
    SERIES: 'series',
    ANNUAL_FORECAST: 'annualForecast',
  },
  CHARGES_OBJECT: 'charges',
  CHARGES: {
    CURRENCY: 'currency',
    TOOLING_CHARGE: 'toolingCharge',
    TESTER_CHARGE: 'testerCharge',
    TESTER_CHARGE_COMMENT: 'testerChargeComment',
    OTHER_CHARGE: 'otherCharge',
    OTHER_CHARGE_COMMENT: 'otherChargeComment',
    MIN_ORDER_QUANTITY: 'minOrderQuantity',
    MIN_ORDER_VOLUME: 'minOrderVolume',
  },
  TECH_INFORMATION_LEADTIME_OBJECT: 'proposedPanels',
  TECH_INFORMATION_LEADTIME: {
    UTILIZATION_RATE_SPECIFIED: 'utilizationRateSpecified',
    UTILIZATION_RATE_PROPOSED: 'utilizationRateProposed',
    SIZE_LENGTH: 'sizeLength',
    SIZE_WIDTH: 'sizeWidth',
    UNITS_PER_PANEL: 'unitsPerPanel',
    PRODUCTION_LEAD_TIME: 'productionLeadtime',
    MATERIAL_LEAD_TIME: 'materialLeadtime',
    SAMPLE_LEAD_TIME: 'sampleLeadtime',
    BASE_MATERIAL: 'baseMaterial',
    SOLDER_MASK: 'solderMask',
  },
}

const SCENARIO = {
  LOT_SIZES: {
    SAMPLES: 'samples',
    LOT_SIZES: 'lotSizes',
    ANNUAL_FORECAST: 'annualForecast',
  },
  YEARS: {
    SAMPLES: 'samples',
    YEARS: 'years',
  },
  SCENARIOS: {
    scenarios: 'scenarios',
  },
}

const QUOTATION = {
  GROUP: 'quote',
  QUOTE_PANELS: 'quotePanels',
  QUOTE_SCENARIO_PRICES: 'quoteScenarioPrices',
  PANEL_TYPE_TO_DISPLAY_TO_CUSTOMER: 'panelTypeToDisplayToCustomer',
  QUOTE_PREPARATION: {
    SHIPPING_COST: 'sampleShippingCost',
    LOGISTIC_COSTS: 'quoteScenarioPrices',
    MASS_PRODUCTION: 'massProduction',
  },
  SUPPLIER_NRE: {
    TOOLING_CHARGE: 'supplierToolingCharge',
    TESTER_CHARGE: 'supplierTesterCharge',
    OTHER_CHARGES: 'supplierOtherCharge',
  },
  LEADTIMES: {
    SAMPLES: 'leadtimeSamples',
    AIR: 'leadtimeAir',
    SEA: 'leadtimeSea',
    EXPRESS: 'leadtimeExpress',
  },
  SAMPLES_NRE: {
    MARGIN_SAMPLE: 'marginSample',
    MARGIN_NRE: 'marginNre',
  },
  TECH_OFFER_BASE_CUSTOMER: {
    PANEL_WIDTH: 'panelDimensionsWidth',
    PANEL_LENGTH: 'panelDimensionsLength',
    UNITS_PER_PANEL: 'unitsPerPanel',
    BASE_MATERIAL: 'baseMaterial',
    BASE_SOLDER_MASK: 'solderMask',
    TEXT: 'otherRemarks',
  },
  MASS_PRODUCTION_CALCULATION_MODE: 'massProductionCalculationMode',
  LOGISTIC_COSTS_CALCULATION_MODE: 'logisticCostsCalculationMode',
  DELIVER_OPTIONS: 'deliveryOptions',
}
export const API_OBJECT_TYPES = {
  ARRAY: 'array',
  OBJECT: 'object',
}

export const API_OBJECT_GROUPS = {
  QUOTATIONS: {
    VALUE: 'quotations',
    TYPE: API_OBJECT_TYPES.OBJECT,
  },
  CHARGES: {
    VALUE: 'charges',
    TYPE: API_OBJECT_TYPES.ARRAY,
  },
  PROPOSED_PANEL: {
    VALUE: 'proposedPanel',
    TYPE: API_OBJECT_TYPES.OBJECT,
  },
  OFFER_PRICINGS: {
    VALUE: 'offerPricings',
    TYPE: API_OBJECT_TYPES.OBJECT,
    IMPORT: true,
  },
  QUOTES: {
    VALUE: 'quotes',
    TYPE: API_OBJECT_TYPES.OBJECT,
    IMPORT: true,
  },
}

const COPY_CREATE_NEW_WHITELIST = [
  RFQ.PROJECT_DATA.PROJECT_NAME,
  RFQ.PROJECT_DATA.RFQ_SECTOR,
  RFQ.PROJECT_DATA.PRODUCT,
  RFQ.PROJECT_DATA.RFQ_YEARS,
  RFQ.PROJECT_DATA.RFQ_TYPE,
  RFQ.PROJECT_DATA.EXPECTED_LIFETIME,
  RFQ.PROJECT_DATA.INCOTERM,
  RFQ.PROJECT_DATA.SPECIFY_LOT_SIZES,
  RFQ.DELIVERY,
  RFQ.BASE_TECHNOLOGY.BASIC_TECHNOLOGY,
  RFQ.BASE_TECHNOLOGY.LAYER,
  RFQ.BASE_TECHNOLOGY.LAMINATE_TEMP,
  RFQ.BASE_TECHNOLOGY.LAMINATE,
  RFQ.BASE_TECHNOLOGY.SOLDER_MASK,
  RFQ.BASE_TECHNOLOGY.COMPONENT_MARK,
  RFQ.BASE_TECHNOLOGY.PROFILING,
  RFQ.COPPER_CRITERIA.INNER_LAYER,
  RFQ.COPPER_CRITERIA.OUTER_LAYER,
  RFQ.COPPER_CRITERIA.FINISHED_CU,
  RFQ.COPPER_CRITERIA.MIN_LINE_WIDTH,
  RFQ.COPPER_CRITERIA.SPACING,
  RFQ.COPPER_CRITERIA.PTH,
  RFQ.PCB_DIMENSIONS.PANEL_WIDTH_MIN,
  RFQ.PCB_DIMENSIONS.PANEL_WIDTH_MAX,
  RFQ.PCB_DIMENSIONS.PANEL_LENGTH_MIN,
  RFQ.PCB_DIMENSIONS.PANEL_LENGTH_MAX,
  RFQ.PCB_DIMENSIONS.NR_PCB,
  RFQ.PCB_DIMENSIONS.BOARD_THICKNESS,
  RFQ.PCB_DIMENSIONS.BOARD_TOLERANCE_IS_PERCENT,
  RFQ.PCB_DIMENSIONS.BOARD_TOLERANCE,
  RFQ.PCB_DIMENSIONS.SURFACE_FINISHING,
  RFQ.OTHERS.IPC_CLASS,
  RFQ.OTHERS.CTI_VALUE,
  RFQ.OTHERS.HALOGEN_FREE,
  RFQ.OTHERS.CAF_RESISTANCE,
  RFQ.OTHERS.IMPEDANCE_CONTROL_OHM,
  RFQ.OTHERS.IMPEDANCE_CONTROL_TOLERANCE,
  RFQ.OTHERS.PRESS_FIT_HOLES,
  RFQ.OTHERS.ROHS_CONFORM,
  RFQ.OTHERS.VIA_PLUG,
  RFQ.OTHERS.BLIND_VIA,
  RFQ.OTHERS.BURIED_VIA,
  RFQ.OTHERS.CROSS_OUT_PANEL,
  RFQ.OTHERS.CROSS_OUT_SHIPPING,
  RFQ.OTHERS.UL_MARKING,
]

export const API_OBJECT_KEY_NAMES = {
  RFQ,
  COPY_CREATE_NEW_WHITELIST,
  ADDRESS,
  USER,
  OFFER,
  SCENARIO,
  QUOTATION,
}
