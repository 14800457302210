export const MODAL_ANCHORS = {
  ABORT: 'Abort',
  ADDRESS: 'Address',
  CANCEL_RFQ: 'CancelRfQ',
  CHANGE_REQUEST: 'ChangeRequest',
  DECLINE_QUOTE: 'DeclineQuote',
  DELETE_DELIVERY_LOCATION: 'DeleteDeliveryLocation',
  DELETE_SHIPPING_ADDRESS: 'DeleteShippingAddress',
  DELETE_BILLING_ADDRESS: 'DeleteBillingAddress',
  DELETE_RFQ_DRAFT: 'DeleteRfQDraft',
  DELETE_USER: 'DeleteUser',
  EXIT_RFQ_EDITOR: 'ExitRfQEditor',
  QUOTE_SAMPLE_MAIL: 'QuoteSampleMail',
  SELECT_DELIVERY_LOCATION: 'SelectDeliveryLocation',
  SELECT_USER: 'SelectUser',
  UNLOCK_RFQ: 'UnlockRfQ',
  USER: 'User',
  VIEW_CHANGE_REQUEST: 'ViewChangeRequest',
  VIEW_REJECTION: 'ViewRejection',
  ADJUST_DELIVERY_LOCATION: 'AdjustDeliveryLocation',
  REJECT_OFFER: 'RejectOffer',
  REJECT_RFQ: 'RejectRfQ',
  CLARIFICATION_REQUEST: 'ClarificationRequest',
  SUPPLIER_SELECTION: 'SupplierSelection',
  PRICING_DELIVERY_STRATEGY: 'PricingDeliveryStrategy',
  ERROR_HANDLING: 'ErrorHandling',
  SET_NEW_DUE_DATE_FOR_RFQ: 'SetNewDueDateForRfQ',
  RECALL_RFQ: 'RecallRfQ',
  REQUEST_REWORK_RFQ: 'RequestReworkRfQ',
  ADD_NEW_SUPPLIER: 'AddNewSupplier',
  REQUEST_SUPPLIER_EXPERT_REWORK_RFQ: 'RequestSupplierExpertReworkRfQ',
  DELETE_LOGISTIC_COST_DATA: 'DeleteLogisticCostData',
}
