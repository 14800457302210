import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'

export default {
  methods: {
    gotActiveDummyDataMixin() {
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE:
        case PERMISSION_GROUPS.SALES_MANAGER.VALUE:
        case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
          return true
      }
    },
  },
}
