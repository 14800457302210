import { ROUTER_DATA } from '@/constants/routerData'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { NAVIGATION_ANCHORS } from '@/constants/anchorTags/navigationAnchorTags'

export const SIDEBAR_NAV_DATA = {
  [PERMISSION_GROUPS.SUPER_ADMIN.VALUE]: [
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].DASHBOARD,
      ICON: 'cilSpeedometer',
    },
  ],
  [PERMISSION_GROUPS.CUSTOMER.VALUE]: [
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD,
      ICON: 'cilSpeedometer',
    },
    {
      TITLE: true,
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.HOME,
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.NEW,
      ICON: 'cilPlus',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW,
      ICON: 'cilList',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.IN_PROGRESS,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.CLARIFICATION,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.QUOTE,
      ICON: 'cilFile',
    },
    {
      SEPARATOR: true,
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.DRAFTS,
      ICON: 'cilFolderOpen',
    },
    {
      TITLE: true,
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.HOME,
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.OVERVIEW,
      ICON: 'cilList',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.ACCEPTED,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS
          .CONTRACT_PREPARATION,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.DELIVERY,
      ICON: 'cilFile',
    },
    {
      SEPARATOR: true,
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].ARCHIVE,
      ICON: 'cilFolderOpen',
    },
  ],
  [PERMISSION_GROUPS.SUPPLIER.VALUE]: [
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].DASHBOARD,
      ICON: 'cilSpeedometer',
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].CONTRACTS,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].ARCHIVE,
      ICON: 'cilFolderOpen',
    },
  ],
  [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: [
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].DASHBOARD,
      ICON: 'cilSpeedometer',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS,
      ICON: 'cilFile',
    },
    {
      SEPARATOR: true,
    },
    {
      GLOBAL_SWITCH: true,
      LABEL: 'Global Overview',
    },
    {
      TITLE: true,
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].MY_RFQS,
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OVERVIEW,
      ICON: 'cilList',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_PREPARATION,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OFFER_COLLECTION,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RUNNING_RFQ,
      ICON: 'cilFile',
    },
    {
      TITLE: true,
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].MY_CONTRACTS,
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .SOURCING_CONTRACTING,
      ICON: 'cilList',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].CONTRACTS,
      ICON: 'cilFile',
    },
    {
      SEPARATOR: true,
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].ARCHIVE,
      ICON: 'cilFolderOpen',
    },
  ],
  [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: [
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD,
      ICON: 'cilSpeedometer',
    },
  ],
  [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: [
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD,
      ICON: 'cilSpeedometer',
      HIDE_COUNTER: true,
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].NEW_RFQS,
      ICON: 'cilFile',
    },
    {
      SEPARATOR: true,
    },
    {
      GLOBAL_SWITCH: true,
      LABEL: 'Global Overview',
    },
    {
      TITLE: true,
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].MY_RFQS,
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].OVERVIEW,
      ICON: 'cilList',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_VALIDATION,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].QUOTE_PREPARATION,
      ICON: 'cilFile',
    },
    {
      TITLE: true,
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].MY_CONTRACTS,
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACTS_OVERVIEW,
      ICON: 'cilList',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACT_PREPARATION,
      ICON: 'cilFile',
    },
    {
      ROUTER_DATA:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_CONTRACT,
      ICON: 'cilFile',
    },
    {
      SEPARATOR: true,
    },
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].ARCHIVE,
      ICON: 'cilFolderOpen',
    },
  ],
  [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: [
    {
      ROUTER_DATA: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD,
      ICON: 'cilSpeedometer',
    },
  ],
  SUPPORT: [
    {
      EXTERNAL: true,
      ROUTER_DATA: ROUTER_DATA.SHARED.LEGAL_NOTICE,
      ANCHOR: NAVIGATION_ANCHORS.SUPPORT.IMPRINT,
    },
    {
      EXTERNAL: true,
      ROUTER_DATA: ROUTER_DATA.SHARED.PRIVACY,
      ANCHOR: NAVIGATION_ANCHORS.SUPPORT.DATA_PROTECTION_NOTICE,
    },
    {
      EXTERNAL: true,
      ROUTER_DATA: ROUTER_DATA.SHARED.TERMS_CONDITIONS,
      ANCHOR: NAVIGATION_ANCHORS.SUPPORT.GENERAL_TERMS,
    },
    {
      EXTERNAL: true,
      ROUTER_DATA: ROUTER_DATA.SHARED.TERMS_OF_USE,
      ANCHOR: NAVIGATION_ANCHORS.SUPPORT.TERMS_OF_USE,
    },
  ],
}
