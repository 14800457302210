const PREFIX = 'registration'

const COMPANY_NAME = `${PREFIX}RegisteredCompanyName`
const VAT_NUMBER = `${PREFIX}VatNumber`
const SALUTATIONS = `${PREFIX}Salutations`
const TITLE = `${PREFIX}Title`
const FIRST_NAME = `${PREFIX}FirstName`
const LAST_NAME = `${PREFIX}LastName`
const NEW_PASSWORD = `${PREFIX}NewPassword`
const REPEATED_PASSWORD = `${PREFIX}RepeatedPassword`
const EMAIL = `${PREFIX}EMail`
const EMAIL_REPEATED = `${PREFIX}EMailRepeated`
const DEPARTMENT = `${PREFIX}Department`
const PHONE_NUMBER = `${PREFIX}PhoneNumber`
const STREET_ADDRESS = `${PREFIX}StreetAddress`
const CITY = `${PREFIX}City`
const ZIP = `${PREFIX}Zip`
const COUNTRY = `${PREFIX}Country`
const ADDRESS_NICKNAME = `${PREFIX}AddressNickname`

export const KEYS_REGISTRATION = {
  COMPANY_NAME,
  VAT_NUMBER,
  SALUTATIONS,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  NEW_PASSWORD,
  REPEATED_PASSWORD,
  EMAIL,
  EMAIL_REPEATED,
  DEPARTMENT,
  PHONE_NUMBER,
  STREET_ADDRESS,
  CITY,
  ZIP,
  COUNTRY,
  ADDRESS_NICKNAME,
}
