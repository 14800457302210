import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { KEYS_REGISTRATION } from '@/constants/objectKeys/registration'

const PREFIX = 'ValidationType'

const NEW_PASSWORD = `${PREFIX}NewPassword`
const REPEATED_PASSWORD = `${PREFIX}dRepeatedPassword`
const EMAIL = `${PREFIX}EMail`
const EMAIL_REPEAT = `${PREFIX}EmailRepeat`
const MINIMUM_LENGTH = `${PREFIX}MinimumLength`
const NOT_EMPTY = `${PREFIX}NotEmpty`
const INTEGER = `${PREFIX}Integer`
const INTEGER_OPTIONAL = `${PREFIX}IntegerOptional`
const DECIMAL = `${PREFIX}Decimal`
const DECIMAL_OPTIONAL = `${PREFIX}DecimalOptional`
const NO_NUMBERS = `${PREFIX}NoNumbers`
const CHECKED = `${PREFIX}Checked`
const DATE_START = `${PREFIX}DateStart`
const INPUT_RANGE = `${PREFIX}InputRange`
const DATE_START_MONTH = `${PREFIX}DateStartMonth`

export const VALIDATION_TYPE = {
  NEW_PASSWORD,
  REPEATED_PASSWORD,
  EMAIL,
  EMAIL_REPEAT,
  MINIMUM_LENGTH,
  NOT_EMPTY,
  INTEGER,
  INTEGER_OPTIONAL,
  DECIMAL,
  DECIMAL_OPTIONAL,
  NO_NUMBERS,
  CHECKED,
  DATE_START,
  INPUT_RANGE,
  DATE_START_MONTH,
}

export const VALIDATION_TYPES_WITH_RANGE = [
  INTEGER,
  INTEGER_OPTIONAL,
  DECIMAL,
  DECIMAL_OPTIONAL,
]

export const VALIDATION_FORM_KEYS = {
  CUSTOMER: {
    NEW_RFQ: [
      KEYS_RFQ.DELIVERY,
      KEYS_RFQ.PROJECT_DATA_PREFIX,
      KEYS_RFQ.SPECIFIED_LOT_SIZES,
      KEYS_RFQ.DOCUMENTS_PREFIX,
      KEYS_RFQ.BASIC_FACTOR_PREFIX,
      KEYS_RFQ.COPPER_CRITERIA_PREFIX,
      KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
      KEYS_RFQ.OTHERS_PREFIX,
      KEYS_RFQ.ADDITIONAL_INFO,
      KEYS_RFQ.NOTES_SALES,
      KEYS_RFQ.NOTES_FOR_ME,
    ],
    ADDRESS: [
      KEYS_REGISTRATION.COMPANY_NAME,
      KEYS_REGISTRATION.STREET_ADDRESS,
      KEYS_REGISTRATION.CITY,
      KEYS_REGISTRATION.ZIP,
      KEYS_REGISTRATION.COUNTRY,
      KEYS_REGISTRATION.ADDRESS_NICKNAME,
      KEYS_REGISTRATION.VAT_NUMBER,
      KEYS_REGISTRATION.PHONE_NUMBER,
    ],
  },
  SUPPLIER: {
    OFFER: [
      KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
      KEYS_RFQ.TECH_CSCC_PREFIX,
      KEYS_RFQ.COM_PREFIX,
      KEYS_RFQ.NRE_MOV_PREFIX,
      KEYS_RFQ.QUOTATIONS,
      KEYS_RFQ.OFFER_UPLOAD_PREFIX,
    ],
  },
  SUPPLIER_EXPERT: {
    SUPPLIER_SELECTION_MODAL: [
      KEYS_RFQ.PRICING_DELIVERY.ANNUAL_PRICE,
      KEYS_RFQ.PRICING_DELIVERY.NEED_FLAT_PRICE,
      KEYS_RFQ.PRICING_DELIVERY.REDUCTION_YEARS,
      KEYS_RFQ.PRICING_DELIVERY.REDUCTION_PERCENTAGE,
      KEYS_RFQ.PRICING_DELIVERY.QUOTATION_CURRENCY,
      KEYS_RFQ.PRICING_DELIVERY.ADDITIONAL_QUOTATION_CURRENCY,
      KEYS_RFQ.PRICING_DELIVERY.SUPPLIER_INCOTERM,
      KEYS_RFQ.PRICING_DELIVERY.NAMED_PLACE,
      KEYS_RFQ.REWORK_SUPPLIER,
    ],
    NEW: [
      KEYS_RFQ.INDIVIDUAL_SCENARIO,
      KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
      KEYS_RFQ.SUPPLIER_FORMS,
      KEYS_RFQ.SUPPLIER_SCENARIOS,
      KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
    ],
    CALCULATE_COQ: [KEYS_RFQ.SUPPLIER_FORMS],
    PREPARE_CONTRACTS: [KEYS_RFQ.CONTRACT_DETAILS_PREFIX],
  },
  SALES_EXPERT: {
    VALIDATED: [
      KEYS_RFQ.PRICE_INDICATION_PREFIX,
      KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
      KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
      KEYS_RFQ.SUPPLIER_OFFER_STRATEGY_PREFIX,
      KEYS_RFQ.DELIVERY_STRATEGY_PREFIX,
      KEYS_RFQ.SUPPLIER_SCENARIOS,
    ],
    QUOTE_CALCULATION: [
      KEYS_RFQ.QUOTE_PREPARATION_PREFIX,
      KEYS_RFQ.MASS_PRODUCTION_PREFIX,
      KEYS_RFQ.TECH_OFFER_BASE_CUSTOMER_PREFIX,
      KEYS_RFQ.LEADTIMES_PREFIX,
      KEYS_RFQ.QUOTATION_NOTES_PREFIX,
      KEYS_RFQ.SAMPLES_NRE_PREFIX,
      KEYS_RFQ.CUSTOMER_QUOTE_PREFIX,
    ],
    QUOTE_CALCULATION_INSTANT_QUOTE: [
      KEYS_RFQ.SUPPLIER_NRE_PREFIX,
      KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      KEYS_RFQ.QUOTE_PREPARATION_PREFIX,
      KEYS_RFQ.MASS_PRODUCTION_PREFIX,
      KEYS_RFQ.TECH_OFFER_BASE_CUSTOMER_PREFIX,
      KEYS_RFQ.LEADTIMES_PREFIX,
      KEYS_RFQ.QUOTATION_NOTES_PREFIX,
      KEYS_RFQ.SAMPLES_NRE_PREFIX,
      KEYS_RFQ.CUSTOMER_QUOTE_PREFIX,
    ],
  },
}
