import { DATE_FORMAT_MINUTES, STORE } from '@/constants/constants'
import utilMixins from '@/mixins/utilMixins'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'

export default {
  mixins: [utilMixins],
  methods: {
    getUserPermissionGroupTranslationMixin() {
      let result = Object.values(PERMISSION_GROUPS).filter((groupData) => {
        return groupData.VALUE === this.$store.getters.getActivePermissionGroup
      })
      return result[0] ? result[0].TRANSLATION : null
    },
    gotLastLoginDateMixin() {
      let currentUserData = this.$store.getters.currentUserData
      return (
        currentUserData &&
        currentUserData.customFields &&
        currentUserData.customFields.elioAiBeePasswordPreviousLogin &&
        currentUserData.customFields.elioAiBeePasswordPreviousLogin.date
      )
    },
    getLastLoginMixin(formatDate = true) {
      if (this.$store.getters.currentUserData !== null) {
        let currentUserData = this.$store.getters.currentUserData
        if (this.gotLastLoginDateMixin()) {
          return formatDate
            ? `(Last Login: ${this.getFormatedDateMixin(
                currentUserData.customFields.elioAiBeePasswordPreviousLogin
                  .date,
                DATE_FORMAT_MINUTES,
              )})`
            : currentUserData.customFields.elioAiBeePasswordPreviousLogin.date
        }
      }
      return ''
    },
    getUserNameMixin() {
      if (this.$store.getters.currentUserData !== null) {
        let currentUserData = this.$store.getters.currentUserData
        let title = currentUserData.title !== null ? currentUserData.title : ''
        return `${title} ${currentUserData.firstName} ${currentUserData.lastName}`
      }
      return ''
    },
    setCurrentUserDataMixin(currentUserData) {
      this.$store.commit(
        STORE.MUTATIONS.UPDATE_CURRENT_USER_DATA,
        currentUserData,
      )
    },
  },
}
