import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { FORM_ITEMS } from '@/constants/formItems'
import { VALIDATION_TYPE } from '@/constants/validation'
import { FILE_TYPES } from '@/constants/fileTypes'
import { AXIOS } from '@/constants/axios'
import { PLACEHOLDER } from '@/constants/constants'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'

let itemContract = {
  keyName: KEYS_RFQ.CONTRACT_DETAILS.CONTRACT_FILE,
  inputType: FORM_ITEMS.INPUT_TYPE.FILE,
  value: '',
  invalid: false,
  export: false,
  required: true,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CONTRACT.CONTRACT_FILE],
  file: {
    type: FILE_TYPES.CONTRACT,
    name: null,
    url: {
      targetPlaceholderId: PLACEHOLDER.ID.OFFER,
      upload: AXIOS.API.POST.UPLOAD_CONTRACT_FILE,
      download: AXIOS.API.GET.DOWNLOAD_CONTRACT_FILE,
    },
    formats: [FILE_TYPES.FORMATS.PDF, FILE_TYPES.FORMATS.DOCX],
  },
  messages: {
    placeholder: 'No file chosen',
    error: 'Please select a file',
    label: 'Nomination Letter',
    notes: 'Accepted formats',
    status: '',
  },
}

let itemAddendum = {
  keyName: KEYS_RFQ.CONTRACT_DETAILS.ADDENDUM_FILE,
  inputType: FORM_ITEMS.INPUT_TYPE.FILE,
  value: '',
  invalid: false,
  export: false,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.OFFER.CONTRACT.ADDENDUM_FILE],
  file: {
    type: FILE_TYPES.ADDENDUM,
    name: null,
    url: {
      targetPlaceholderId: PLACEHOLDER.ID.OFFER,
      upload: AXIOS.API.POST.UPLOAD_ADDENDUM_FILE,
      download: AXIOS.API.GET.DOWNLOAD_ADDENDUM_FILE,
    },
    formats: [FILE_TYPES.FORMATS.PDF, FILE_TYPES.FORMATS.DOCX],
  },
  messages: {
    placeholder: 'No file chosen',
    error: 'Please select a file',
    label: 'Addendum',
    notes: 'Accepted formats',
    status: '',
  },
}

export let rfqContractDetailsTypes = {
  [itemContract.keyName]: itemContract,
  [itemAddendum.keyName]: itemAddendum,
}
