import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { NAVIGATION_ANCHORS } from '@/constants/anchorTags/navigationAnchorTags'
import { INPUT_TYPES_ANCHORS } from '@/constants/anchorTags/inputTypeAnchorTags'

export default {
  methods: {
    getQuotaAllocationAnchorTagMixin(
      target,
      supplierIndex,
      scenarioIndex,
      row,
    ) {
      return `quotaAllocation${target}Supplier${supplierIndex}Scenario${scenarioIndex}Row${row}`
    },
    getRouteTitleAnchorTagMixin(target) {
      return `routeTitle${target}`
    },
    getAutoCorrectAnchorTagMixin(target) {
      return `autoCorrectMessage${target}`
    },
    getErrorMessageAnchorTagMixin(target) {
      return `errorMessage${target}`
    },
    getCategoryTabAnchorTagMixin(category, tab) {
      return `category${category}Tab${tab}`
    },
    getCommunicationInfoAnchorTagMixin(category, item, entry) {
      let string = `infoCommunicationCategory${category}`
      if (entry) {
        string += `Entry${entry}`
      }
      return (string += `Item${item}`)
    },
    getBreadcrumbsAnchorTagMixin(target) {
      return `breadcrumb${target}`
    },
    getQuoteActionAnchorTagMixin(entry, type) {
      return `entry${entry}QuoteAction${type}`
    },
    getPopoverAnchorTagMixin(target, item) {
      return `popover${target}Item${item}`
    },
    getInputAnchorTagMixin(target, anchorType = INPUT_TYPES_ANCHORS.FIELD) {
      return `${anchorType}${target}`
    },
    getModalAnchorTagMixin(modalName) {
      return `modal${modalName}`
    },
    getModalActionAnchorTagMixin(modalName, actionName) {
      return `modal${modalName}Action${actionName}`
    },
    getEntryAnchorTagMixin(entryName, entryNumber) {
      return `entry${entryName}Number${entryNumber}`
    },
    getEntryActionAnchorTagMixin(entryName, entryNumber, actionName) {
      return `entry${entryName}Number${entryNumber}Action${actionName}`
    },
    getEntryInfoAnchorTagMixin(entryName, entryNumber, infoName) {
      return `entry${entryName}Number${entryNumber}Info${infoName}`
    },
    getHeaderUserInfoAnchorTagMixin(info) {
      return `infoHeaderUser${info}`
    },
    getInfoAnchorTagMixin(name) {
      return `info${name}`
    },
    getInfoQuoteAnchorTagMixin(index, name) {
      return `infoEntry${index}${name}`
    },
    getQuotationAnchorDropdownTagMixin(index) {
      return `actionQuotationEntry${index}Dropdown`
    },
    getQuotationAnchorDropdownActionTagMixin(index, action) {
      return `actionQuotationEntry${index}DropdownAction${action}`
    },
    getInfoPriceIndicationAnchorTagMixin(name) {
      return `infoPriceIndication${name}`
    },
    getActionAnchorTagMixin(name) {
      return `action${name}`
    },
    getActionDownloadAnchorTagMixin(name) {
      return `actionDownload${name}`
    },
    getTableAnchorTagMixin(tableName) {
      return `table${tableName}`
    },
    getTablePaginationAnchorTagMixin(tableName) {
      return `table${tableName}Pagination`
    },
    getTableCellInfoAnchorTagMixin(tableName, rowNumber, columnName, infoName) {
      let string = `table${tableName}`
      if (rowNumber) {
        string += `Row${rowNumber}`
      }
      if (columnName) {
        string += `Column${columnName}`
      }
      if (infoName) {
        string += `Info${infoName}`
      }
      return string
    },
    getTableCellInputAnchorTagMixin(
      tableName,
      rowNumber,
      columnName,
      anchorType = INPUT_TYPES_ANCHORS.FIELD,
    ) {
      return `table${tableName}Row${rowNumber}${
        anchorType[0].toUpperCase() + anchorType.substring(1)
      }${columnName}`
    },
    getTableSortColumnAnchorTagMixin(tableName, columnName) {
      return `table${tableName}SortColumn${columnName}`
    },
    getTableActionAnchorIndividualScenarioTagMixin(
      tableName,
      columnNumber = 0,
      actionName,
    ) {
      return columnNumber === 0
        ? `table${tableName}SampleAction${actionName}`
        : `table${tableName}Action${actionName}${columnNumber}`
    },
    getTableActionAnchorTagMixin(tableName, rowNumber, actionName) {
      return `table${tableName}Row${rowNumber}Action${actionName}`
    },
    getTableActionDropdownAnchorTagMixin(tableName, rowNumber) {
      return `table${tableName}Row${rowNumber}ActionDropdown`
    },
    getTableActionDropdownActionAnchorTagMixin(
      tableName,
      rowNumber,
      actionName,
    ) {
      return `table${tableName}Row${rowNumber}ActionDropdownAction${actionName}`
    },
    getLoginHeaderAnchorTagMixin(target) {
      return `headerLink${target}`
    },
    getLoginFooterAnchorTagMixin(target) {
      return `footerLink${target}`
    },
    getHeaderDropdownAnchorTagMixin(target = '') {
      return `headerNavigationDropdown${target}`
    },
    getAccordionAnchorTagMixin() {
      return 'accordionItem'
    },
    getSidebarAnchorTagMixin(target, addUserRole = false) {
      let userRole = ''
      if (addUserRole) {
        switch (this.$store.getters.getActivePermissionGroup) {
          case PERMISSION_GROUPS.CUSTOMER.VALUE:
            userRole = NAVIGATION_ANCHORS.CUSTOMER.GROUP
            break
          case PERMISSION_GROUPS.SUPPLIER.VALUE:
            userRole = NAVIGATION_ANCHORS.SUPPLIER.GROUP
            break
          case PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE:
            userRole = NAVIGATION_ANCHORS.SUPPLIER_EXPERT.GROUP
            break
          case PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE:
            userRole = NAVIGATION_ANCHORS.SUPPLIER_MANAGER.GROUP
            break
          case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
            userRole = NAVIGATION_ANCHORS.SALES_EXPERT.GROUP
            break
          case PERMISSION_GROUPS.SALES_MANAGER.VALUE:
            userRole = NAVIGATION_ANCHORS.SALES_MANAGER.GROUP
            break
        }
      }
      return `sidebarLink${userRole}${target}`
    },
    getDashboardAnchorTagMixin(target, index, column) {
      if (column) {
        return `dashboard${target}Column${column}Details${index}`
      } else {
        return `dashboard${target}Details${index}`
      }
    },
  },
}
