<template>
  <div class="min-vh-100 d-flex flex-column">
    <AppHeader />
    <CContainer class="my-5 my-md-auto">
      <CRow class="justify-content-center">
        <router-view />
      </CRow>
    </CContainer>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'

export default {
  name: 'NoPermissionLayout',
  components: { AppHeader, AppFooter },
}
</script>

<style scoped></style>
