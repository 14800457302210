export const ACTION_ANCHORS = {
  CREATE_YOUR_RFQ: 'CreateYourRfQ',
  GO_TO_THE_OVERVIEW: 'GoToTheOverview',
  CANCEL: 'Cancel',
  USE_EXISTING_DRAFT: 'UseExistingDraft',
  CREATE_NEW_RfQ: 'CreateNewRfQ',
  CANCEL_RFQ: 'CancelRfQ',
  REQUEST_CHANGE: 'RequestChange',
  COPY_AND_CREATE_NEW: 'CopyCreateNew',
  VIEW_CHANGE_REQUEST: 'ViewChangeRequest',
  EDIT_DRAFT: 'EditDraft',
  DELETE_DRAFT: 'DeleteDraft',
  LOCK_RFQ: 'LockRfQ',
  UNLOCK_RFQ: 'UnlockRfQ',
  RFQ_INFO_DROPDOWN: 'RfQInfoDropdown',
  VIEW_DETAILS: 'ViewDetails',
  ACCEPT_QUOTE: 'AcceptQuote',
  VIEW_REJECTION: 'ViewRejection',
  ORDER_SAMPLES: 'OrderSamples',
  DOWNLOAD_QUOTE: 'DownloadQuote',
  DECLINE_QUOTE: 'DeclineQuote',
  REJECT_OFFER: 'RejectOffer',
  REJECT_RFQ: 'RejectRfQ',
  CLARIFICATION_REQUEST: 'ClarificationRequest',
  ASSIGN_TO: 'AssignTo',
  ASSIGN_TO_ME: 'AssignToMe',
  CONTACT: 'Contact',
  SAVE_AS_DRAFT: 'SaveAsDraft',
  SUBMIT_DRAFT: 'SubmitDraft',
  SUBMIT_OFFER: 'SubmitOffer',
  SAVE_EDIT_MODE: 'SaveEditMode',
  QUIZ_BACK_SALES_EXPERT: 'QuizBackSalesExpert',
  REQUEST_OFFER: 'RequestOffer',
  SAVE_SUPPLIER_RFQ: 'SaveSupplierRfQ',
  DOWNLOAD_DATA: 'DownloadData',
  ENTER_QUOTE: 'EnterQuote',
  OFFER_DUE: 'OfferDue',
  CANCEL_MESSAGE: 'CancelMessage',
  SEND_MESSAGE: 'SendMessage',
  EDIT_NOTES: 'EditNotes',
  SAVE_NOTES: 'SaveNotes',
  CANCEL_NOTES: 'CancelNotes',
  EDIT_USER: 'EditUser',
  DELETE_USER: 'DeleteUser',
  ADD_NEW_USER: 'AddNewUser',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'ForgotPassword',
  APPLY_NOW: 'ApplyNow',
  ALREADY_HAVE_AN_ACCOUNT: 'AlreadyHaveAnAccount',
  APPLY_ACCOUNT: 'ApplyAccount',
  RESET_PASSWORD: 'ResetPassword',
  SET_PASSWORD: 'SetPassword',
  CHANGE_PASSWORD: 'ChangePassword',
  ADD_NEW_DELIVERY_ADDRESS: 'AddNewDeliveryAddress',
  ADD_NEW_BILLING_ADDRESS: 'AddNewBillingAddress',
  EDIT_ADDRESS: 'EditAddress',
  DELETE_ADDRESS: 'DeleteAddress',
  SUBMIT_ADDRESS: 'SubmitAddress',
  SELECT_ADDRESS: 'SelectAddress',
  ABORT: 'Abort',
  SUBMIT_CHANGE_REQUEST: 'SubmitChangeRequest',
  REJECT_QUOTE: 'RejectQuote',
  DELETE_LOCATION: 'DeleteLocation',
  EXIT_WITHOUT_SAVING: 'ExitWithoutSaving',
  EXIT_AND_DELETE_DRAFT: 'ExitAndDeleteDraft',
  ORDER_SAMPLES_VIA_MAIL: 'OrderSamplesViaMail',
  SUBMIT_USER: 'SubmitUser',
  VIEW_RFQ: 'ViewRfQ',
  ADD_LOCATION: 'AddLocation',
  EDIT_DELIVERY_LOCATION: 'EditDeliveryLocation',
  DELETE_DELIVERY_LOCATION: 'DeleteDeliveryLocation',
  DOWNLOAD_QUOTE_FILE: 'DownloadQuoteFile',
  DOWNLOAD_QUOTATION_FILE: 'DownloadQuotationFile',
  DOWNLOAD_FRAME_CONTRACT_FILE: 'DownloadFrameContractFile',
  DOWNLOAD_TERM_CONDITIONS_FILE: 'DownloadTermConditionsFile',
  SHOW_DETAILS: 'ShowDetails',
  ADJUST_DELIVERY_LOCATION: 'AdjustDeliveryLocation',
  COMMUNICATION: 'Communication',
  ACCEPT_CONTRACT: 'AcceptContract',
  ENTER_EDIT_MODE: 'EnterEditMode',
  CANCEL_EDIT_MODE: 'CancelEditMode',
  ADD_SUPPLIER: 'AddSupplier',
  PRICING_DELIVERY_STRATEGY: 'EditPricingDeliveryStrategy',
  CREATE_ERROR_LOG: 'CreateErrorLog',
  SET_DUE_DATE: 'SetDueDate',
  PREPARE_SET_NEW_DUE_DATE: 'PrepareSetNewDueDate',
  PREPARE_INSTANT_QUOTE: 'PrepareInstantQuote',
  RECALL_RFQ: 'RecallRfQ',
  PREPARE_RECALL_RFQ: 'PrepareRecallRfQ',
  REQUEST_REWORK_RFQ: 'RequestReworkRfQ',
  PREPARE_REQUEST_REWORK_RFQ: 'PrepareRequestReworkRfQ',
  REQUEST_OFFER_FOR_ADDITIONAL_SUPPLIER: 'RequestOfferForAdditionalSupplier',
  PREPARE_ADD_NEW_SUPPLIER: 'PrepareAddNewSupplier',
  ADD_NEW_SUPPLIER: 'AddNewSupplier',
  ACTIONS_DROPDOWN: 'ActionsDropdown',
  ADD_NEW_SUPPLIER_FORM: 'AddNewSupplierForm',
  DELETE_SUPPLIER_FORM: 'DeleteSupplierForm',
  CANCEL_DRAFT: 'CancelDraft',
  DEFINE_RFQ_SCENARIOS_FOR_SUPPLIER: 'DefineRfQScenarioForSupplier',
  ADD_RFQ_SCENARIO_FOR_SUPPLIER: 'AddRfQScenarioForSupplier',
  DELETE_RFQ_SCENARIO_FOR_SUPPLIER: 'DeleteRfQScenarioForSupplier',
  DELETE_CUSTOM_SCENARIO: 'DeleteCustomScenario',
  ADD_CUSTOM_SCENARIO: 'AddCustomScenario',
  ADD_SUPPLIER_SELECTION: 'AddSupplierSelection',
  GENERATE_CONTRACT: 'GenerateContract',
  PREPARE_CONTRACTS: 'PrepareContracts',
  PREPARE_COQ: 'PrepareCOQ',
  REOPEN_OFFERS: 'ReopenOffers',
  SEND_OUT_CONTRACTS: 'SendOutContracts',
  GLOBAL_SWITCH: 'GlobalSwitch',
  SEE_ALL_NEW_RFQS: 'SeeAllNewRfQs',
  OVERVIEW_IN_VALIDATION: 'OverviewInValidation',
  OVERVIEW_QUOTE_PREPARATION: 'OverviewQuotePreparation',
  OVERVIEW_CONTRACT_PREPARATION: 'OverviewContractPreparation',
  VALIDATE_RFQ: 'ValidateRfQ',
  SELECT_SUPPLIERS: 'SelectSuppliers',
  CREATE_QUOTE: 'CalculateQuote',
  SEND_QUOTE: 'SendQuote',
  GENERATE_QUOTE_DOCUMENT: 'GenerateQuoteDocument',
  PREPARE_REQUEST_SUPPLIER_EXPERT_TO_REWORK:
    'PrepareRequestSupplierExpertToRework',
  REQUEST_SUPPLIER_EXPERT_TO_REWORK: 'RequestSupplierExpertToRework',
  BEGIN_SUPPLIER_SELECTION: 'BeginSupplierSelection',
  UPDATE_TABLES: 'UpdateTables',
  ADD_LOGISTIC_COSTS_ROW: 'AddLogisticCostsRow',
  DELETE_LOGISTIC_COSTS_ROW: 'DeleteLogisticCostsRow',
  SUPPLIER_SELECTION_SELECT_SUPPLIER: 'SupplierSelectionSelectSupplier',
  SUPPLIER_SELECTION_EDIT_SUPPLIER: 'SupplierSelectionEditSupplier',
  SUPPLIER_SELECTION_DELETE_SUPPLIER: 'SupplierSelectionDeleteSupplier',
  DELETE_LOGISTIC_COST_DATA: 'DeleteLogisticCostData',
  TOGGLE_SIDEBAR: 'ToggleSidebar',
  PROVIDE_RFQ_INFORMATION: 'ProvideRfQInformation',
  PREPARE_PROVIDE_RFQ_INFORMATION: 'PrepareProvideRfQInformation',
}
