import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES } from '@/constants/constants'
import { VALIDATION_TYPE } from '@/constants/validation'
import { IMPORT_EXPORT } from '@/constants/importExport'

export let itemSupplierSettings = {
  keyName: KEYS_RFQ.SUPPLIER_SETTINGS,
  singleLevelObject: true,
  info: {
    company: '',
    id: '',
    index: -1,
  },
  selection: {
    selected: QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES.NOT_SELECTED,
    comment: '',
    quota: {},
  },
}

export let itemSupplierQuota = {
  keyName: KEYS_RFQ.SUPPLIER_QUOTA,
  apiObjectKeyNames: [],
  style: 'mb-0 e-table-input',
  value: '100',
  required: false,
  invalid: false,
  floatingLabel: false,
  inputMin: '0',
  inputMax: '100',
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
  validationSettings: {
    formatNumber: true,
    decimal: { decimal_digits: '1' },
  },
  parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
  messages: {
    error: 'Please set a quota',
  },
}
