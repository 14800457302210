<template>
  <div v-if="this.$props.useBoschLogo">
    <img class="e-bosch-logo" src="/bosch_logo.svg" />
  </div>
  <div v-else-if="this.$props.shortBranding">
    <img class="e-bosch-logo-sidebar" src="/bosch_sidebar_logo.svg" />
  </div>
  <div v-else>
    <strong class="e-right-branding"> {{ $eAppTitle }}</strong>
  </div>
</template>

<script>
export default {
  name: 'AppBrandLogo',
  props: {
    color: {
      type: String,
    },
    height: {
      type: Number,
    },
    shortBranding: {
      type: Boolean,
      default: false,
    },
    useBoschLogo: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.e-bosch-logo {
  height: 45px;
}

.e-bosch-logo-sidebar {
  margin-top: 35px;
  margin-bottom: 32px;
  height: 45px;
}

.e-right-branding {
  font-size: 21px;
  line-height: 24px;
  margin-top: -4px;
}
</style>
