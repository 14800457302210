import { TABLE_ANCHORS } from '@/constants/anchorTags/tableAnchorTags'
import { TABLE_COLUMN_ANCHORS } from '@/constants/anchorTags/tableColumnAnchorTags'
import anchorTagsMixins from '@/mixins/anchorTagsMixins'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'

const RFQ_INTERNAL_ID_COLUMN = {
  key: 'internalId',
  label: 'RfQ ID',
  api: {
    default: 'internalId',
    offer: 'rfq.internalId',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.RFQ_ID,
    ),
  },
}

const RFQ_CREATED_AT_COLUMN = {
  key: 'createdAt',
  label: 'RfQ Date',
  api: {
    default: 'createdAt',
  },
  useOffersValueForSort: [PERMISSION_GROUPS.SUPPLIER.VALUE],
  overrideGroupBlacklist: [PERMISSION_GROUPS.SUPPLIER.VALUE],
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.RFQ_DATE,
    ),
  },
}

const RFQ_COMPANY_COLUMN = {
  key: 'customer',
  label: 'Customer',
  api: {
    default: 'customerSortingData.company',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.COMPANY,
    ),
  },
}

const NAME_COLUMN = {
  key: 'name',
  label: 'Name',
  api: {
    default: 'name',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.RFQ_NAME,
    ),
  },
}

const FIRST_SAMPLES_COLUMN = {
  key: 'firstSamples',
  label: 'First Sample',
  api: {
    default: 'firstSamples',
    offer: 'rfq.firstSamples',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.FIRST_SAMPLE,
    ),
  },
}

const SOP_COLUMN = {
  key: 'sop',
  label: 'SOP',
  api: {
    default: 'sop',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.SOP,
    ),
  },
}

const TOTAL_QUANTITY_COLUMN = {
  key: 'totalAmount',
  label: 'Total Quantity',
  api: {
    default: 'totalAmount',
  },
  useOffersValueForSort: [PERMISSION_GROUPS.SUPPLIER.VALUE],
  overrideGroupBlacklist: [PERMISSION_GROUPS.SUPPLIER.VALUE],
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.TOTAL_QUANTITY,
    ),
  },
}

const PCB_TECH_COLUMN = {
  key: 'pcbBasicTechnology',
  label: 'Technology',
  api: {
    default: 'pcbBasicTechnology',
    offer: 'rfq.pcbBasicTechnology',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.TECHNOLOGY,
    ),
  },
}

const STATUS_COLUMN = {
  key: 'state',
  label: 'RfQ Status',
  api: {
    default: 'state',
  },
  overrideGroupBlacklist: [PERMISSION_GROUPS.SUPPLIER.VALUE],
  useOffersValueForSort: [PERMISSION_GROUPS.SUPPLIER.VALUE],
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.RFQ_STATUS,
    ),
  },
}

const RFQ_DETAILS_COLUMN = {
  key: 'rfq_details',
  label: '',
  filter: false,
  sorter: false,
}

const SAMPLE_STATUS_COLUMN = {
  key: 'samplesState',
  label: 'Samples',
  api: {
    default: 'samplesState',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.RFQ_STATUS,
    ),
  },
}

const CONTRACT_STATUS_COLUMN = {
  key: 'contractState',
  label: 'Contract Status',
  api: {
    default: 'contractState',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.CONTRACT_STATUS,
    ),
  },
}

const INDUSTRY_COLUMN = {
  key: 'industrySector',
  label: 'Industry',
  api: {
    default: 'industrySector',
    offer: 'rfq.industrySector',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.INDUSTRY,
    ),
  },
}

const PRODUCT_COLUMN = {
  key: 'product',
  label: 'Product',
  api: {
    default: 'product',
    offer: 'rfq.product',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.PRODUCT,
    ),
  },
}

const QUOTE_DUE_COLUMN = {
  key: 'quoteExpectedBy',
  label: 'Quote due',
  api: {
    default: 'quoteExpectedBy',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.QUOTE_DUE,
    ),
  },
}

const VALID_UNTIL_COLUMN = {
  key: 'validUntil',
  label: 'Due date',
  api: {
    default: 'validUntil',
  },
  useOffersValueForSort: [PERMISSION_GROUPS.SUPPLIER.VALUE],
  overrideGroupBlacklist: [PERMISSION_GROUPS.SUPPLIER.VALUE],
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.VALID_UNTIL,
    ),
  },
}

const FIRST_NAME_COLUMN = {
  key: 'firstName',
  label: 'First name',
  api: {
    default: 'firstName',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.USER_MANAGEMENT,
      TABLE_COLUMN_ANCHORS.FIRST_NAME,
    ),
  },
}

const LAST_NAME_COLUMN = {
  key: 'lastName',
  label: 'Last name',
  api: {
    default: 'lastName',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.USER_MANAGEMENT,
      TABLE_COLUMN_ANCHORS.LAST_NAME,
    ),
  },
}

const EMAIL_COLUMN = {
  key: 'email',
  label: 'Email',
  api: {
    default: 'email',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.USER_MANAGEMENT,
      TABLE_COLUMN_ANCHORS.EMAIL,
    ),
  },
}

const USER_ROLE_COLUMN = {
  key: 'role',
  label: 'User role',
  api: {
    default: 'role',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.USER_MANAGEMENT,
      TABLE_COLUMN_ANCHORS.ROLE,
    ),
  },
}

const USER_ACTIVE_COLUMN = {
  key: 'active',
  label: 'State',
  api: {
    default: 'active',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.USER_MANAGEMENT,
      TABLE_COLUMN_ANCHORS.ACTIVE,
    ),
  },
}

const USER_DETAILS_COLUMN = {
  key: 'user_details',
  label: '',
  filter: false,
  sorter: false,
}

const ASSIGNMENT_COLUMN = {
  key: 'assignment',
  label: 'Assignment',
  api: {
    default: 'assignmentsCustomerSortingData.firstName',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.ASSIGNMENT,
    ),
  },
}

const ASSIGNEE_COLUMN = {
  key: 'assignee',
  label: 'Assignee',
  api: {
    default: 'assignmentsCustomerSortingData.firstName',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.ASSIGNEE,
    ),
  },
}

const TOTAL_VOLUME_COLUMN = {
  key: 'totalVolume',
  label: 'Total Volumen $',
  api: {
    default: 'totalVolume',
  },
  _props: {
    id: anchorTagsMixins.methods.getTableSortColumnAnchorTagMixin(
      TABLE_ANCHORS.RFQ_LISTING,
      TABLE_COLUMN_ANCHORS.TOTAL_VOLUME,
    ),
  },
}

const COMPANY = {
  key: 'company',
  label: 'Company',
}

const CITY = {
  key: 'city',
  label: 'City',
}

const ZIPCODE = {
  key: 'zipcode',
  label: 'Zipcode',
}

const STREET = {
  key: 'street',
  label: 'Street address',
}

const COUNTRY = {
  key: 'country',
  label: 'Country',
}

const CUSTOMER_NUMBER = {
  key: 'customerNumber',
  label: 'Customer number',
}

const COMPANY_NAME = {
  key: 'companyName',
  label: 'Company name',
}

const CUSTOMER_MY_RFQS_LAYOUT_DATA = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  NAME_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  PCB_TECH_COLUMN,
  STATUS_COLUMN,
  RFQ_DETAILS_COLUMN,
]

const CUSTOMER_MY_ARCHIVES_LAYOUT_DATA = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  NAME_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  PCB_TECH_COLUMN,
  STATUS_COLUMN,
  SAMPLE_STATUS_COLUMN,
  RFQ_DETAILS_COLUMN,
]

const CUSTOMER_MY_BUSINESS_LAYOUT_DATA = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  NAME_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  PCB_TECH_COLUMN,
  STATUS_COLUMN,
  CONTRACT_STATUS_COLUMN,
  SAMPLE_STATUS_COLUMN,
  RFQ_DETAILS_COLUMN,
]

const SUPPLIER_LAYOUT_DATA = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  INDUSTRY_COLUMN,
  PRODUCT_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  PCB_TECH_COLUMN,
  VALID_UNTIL_COLUMN,
  RFQ_DETAILS_COLUMN,
]

const SUPPLIER_RUNNING_RFQS_DATA = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  INDUSTRY_COLUMN,
  PRODUCT_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  PCB_TECH_COLUMN,
  STATUS_COLUMN,
  RFQ_DETAILS_COLUMN,
]

const USER_MANAGEMENT_LAYOUT_DATA = [
  FIRST_NAME_COLUMN,
  LAST_NAME_COLUMN,
  EMAIL_COLUMN,
  USER_ROLE_COLUMN,
  USER_ACTIVE_COLUMN,
  USER_DETAILS_COLUMN,
]

const SUPPLIER_EXPERT_NEW_RFQS_DATA = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  TOTAL_VOLUME_COLUMN,
  PCB_TECH_COLUMN,
  QUOTE_DUE_COLUMN,
  ASSIGNMENT_COLUMN,
  RFQ_DETAILS_COLUMN,
]

const SUPPLIER_EXPERT_IN_PROCESS_DATA = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  PCB_TECH_COLUMN,
  TOTAL_VOLUME_COLUMN,
  QUOTE_DUE_COLUMN,
  STATUS_COLUMN,
  RFQ_DETAILS_COLUMN,
]

const SALES_EXPERT_DATA_NEW_RFQS = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  RFQ_COMPANY_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  PCB_TECH_COLUMN,
  QUOTE_DUE_COLUMN,
  ASSIGNMENT_COLUMN,
  RFQ_DETAILS_COLUMN,
]

const SALES_EXPERT_DATA = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  RFQ_COMPANY_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  PCB_TECH_COLUMN,
  QUOTE_DUE_COLUMN,
  STATUS_COLUMN,
]

const SALES_EXPERT_DATA_GLOBAL = [
  RFQ_INTERNAL_ID_COLUMN,
  RFQ_CREATED_AT_COLUMN,
  RFQ_COMPANY_COLUMN,
  FIRST_SAMPLES_COLUMN,
  SOP_COLUMN,
  TOTAL_QUANTITY_COLUMN,
  PCB_TECH_COLUMN,
  QUOTE_DUE_COLUMN,
  STATUS_COLUMN,
  ASSIGNEE_COLUMN,
  RFQ_DETAILS_COLUMN,
]

const SUPPLIERS_TABLE_DATA = [COMPANY, CITY, ZIPCODE, STREET, COUNTRY]

const SUPPORT_USERS_TABLE_DATA = [COMPANY_NAME, CUSTOMER_NUMBER]

export const SMART_TABLE_LAYOUTS = {
  CUSTOMER: {
    DEFAULT: 'CustomerDefault',
    MY_BUSINESS: 'CustomerMyBusiness',
  },
  SUPPLIER: {
    DEFAULT: 'SupplierDefault',
    RUNNING_RFQS: 'SupplierRunningRfQs',
  },
  USER_MANAGEMENT: {
    DEFAULT: 'UserManagementDefault',
  },
  SUPPLIER_EXPERT: {
    DEFAULT: 'SupplierExpertDefault',
    ALT: 'SupplierExpertAlt',
  },
  SUPPLIERS_TABLE: {
    DEFAULT: 'SuppliersTableDefault',
  },
  SUPPORT_USERS: {
    DEFAULT: 'SupportUsersDefault',
  },
  SALES_EXPERT: {
    NEW_RFQS: 'SalesExpertNewRfQs',
    DEFAULT: 'SalesExpertDefault',
    DEFAULT_GLOBAL: 'SalesExpertDefaultGlobal',
  },
}
export const SMART_TABLE_LAYOUT_DATA = {
  [SMART_TABLE_LAYOUTS.CUSTOMER.DEFAULT]: CUSTOMER_MY_RFQS_LAYOUT_DATA,
  [SMART_TABLE_LAYOUTS.CUSTOMER.MY_BUSINESS]: CUSTOMER_MY_ARCHIVES_LAYOUT_DATA,
  [SMART_TABLE_LAYOUTS.CUSTOMER.MY_BUSINESS]: CUSTOMER_MY_BUSINESS_LAYOUT_DATA,
  [SMART_TABLE_LAYOUTS.SUPPLIER.DEFAULT]: SUPPLIER_LAYOUT_DATA,
  [SMART_TABLE_LAYOUTS.SUPPLIER.RUNNING_RFQS]: SUPPLIER_RUNNING_RFQS_DATA,
  [SMART_TABLE_LAYOUTS.USER_MANAGEMENT.DEFAULT]: USER_MANAGEMENT_LAYOUT_DATA,
  [SMART_TABLE_LAYOUTS.SUPPLIER_EXPERT.DEFAULT]: SUPPLIER_EXPERT_NEW_RFQS_DATA,
  [SMART_TABLE_LAYOUTS.SUPPLIER_EXPERT.ALT]: SUPPLIER_EXPERT_IN_PROCESS_DATA,
  [SMART_TABLE_LAYOUTS.SUPPLIERS_TABLE.DEFAULT]: SUPPLIERS_TABLE_DATA,
  [SMART_TABLE_LAYOUTS.SUPPORT_USERS.DEFAULT]: SUPPORT_USERS_TABLE_DATA,
  [SMART_TABLE_LAYOUTS.SALES_EXPERT.DEFAULT]: SALES_EXPERT_DATA,
  [SMART_TABLE_LAYOUTS.SALES_EXPERT.DEFAULT_GLOBAL]: SALES_EXPERT_DATA_GLOBAL,
  [SMART_TABLE_LAYOUTS.SALES_EXPERT.NEW_RFQS]: SALES_EXPERT_DATA_NEW_RFQS,
}
export const SMART_TABLE_TYPES = {
  LISTING: 0,
  SUPPLIER_SELECTION: 1,
  SUPPORT_USER_SELECTION: 2,
}

export const BLACKLISTED_KEYS_FOR_FILTERING = [
  RFQ_CREATED_AT_COLUMN.key,
  FIRST_SAMPLES_COLUMN.key,
  SOP_COLUMN.key,
  STATUS_COLUMN.key,
  RFQ_DETAILS_COLUMN.key,
  SAMPLE_STATUS_COLUMN.key,
  CONTRACT_STATUS_COLUMN.key,
  QUOTE_DUE_COLUMN.key,
  VALID_UNTIL_COLUMN.key,
  USER_ACTIVE_COLUMN.key,
  USER_DETAILS_COLUMN.key,
  TOTAL_QUANTITY_COLUMN.key,
]
