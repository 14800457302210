import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { VALIDATION_TYPE } from '@/constants/validation'
import { IMPORT_EXPORT } from '@/constants/importExport'

export let rfqScenariosForSupplierAnnualQuantity = {
  apiObjectKeyNames: [],
  keyName: KEYS_RFQ.SCENARIO_FOR_SUPPLIER_ANNUAL_QUANTITY,
  style: 'e-table-input',
  value: '0',
  required: true,
  radioValueGroup: 0,
  inputMin: '1',
  inputMax: '40000000',
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
  parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
  validationSettings: {
    formatNumber: true,
  },
  floatingLabel: false,
  noStar: true,
  messages: {
    label: '',
    error: 'Please fill out this field',
  },
}
