import { KEYS_CUSTOMER } from '@/constants/objectKeys/customer'
import countriesMixins from '@/mixins/countriesMixins'
import salutationsMixins from '@/mixins/salutationsMixins'
import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import importMixins from '@/mixins/ImportMixins'
import deliveryMixins from '@/mixins/deliveryMixins'
import router from '@/router'
import { STATE_TYPE, STATES } from '@/constants/states'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { ROUTE_TYPES, ROUTER_DATA } from '@/constants/routerData'
import { AXIOS } from '@/constants/axios'
import { DATA_MIXIN } from '@/constants/data'
import exportMixins from '@/mixins/exportMixins'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { KEYS_USER } from '@/constants/objectKeys/user'
import utilMixins from '@/mixins/utilMixins'
import {
  FILE_TRANSFER_STATUS,
  FRONTEND_VALUES,
  TABLE_ROW_INDEX,
} from '@/constants/constants'
import { KEYS_REGISTRATION } from '@/constants/objectKeys/registration'
import { passwordCheckTypes } from '@/assets/inputDataObjects/types/passwordCheck'
import { USER_STATUS } from '@/constants/userStatus'
import { NOTIFICATIONS } from '@/constants/notifications'
import { SMART_TABLE_LAYOUT_DATA } from '@/constants/smartTableLayouts'
import { MODALS } from '@/constants/modals'
import cloneDeep from 'lodash/cloneDeep'
import routeMixins from '@/mixins/routeMixins'
import { FILE_TYPES } from '@/constants/fileTypes'

export default {
  mixin: [
    countriesMixins,
    salutationsMixins,
    deliveryMixins,
    exportMixins,
    utilMixins,
    importMixins,
    routeMixins,
  ],
  methods: {
    dataExitEditorMixin: function () {
      this.getDetailsRouteMixin(null, false, false, false)
    },
    dataSetFormOptionsMixin: function () {
      this.createCountriesOptionsMixin()
      this.insertCountriesOptionsMixin()
      this.createSalutationsOptionsMixin()
      this.insertSalutationsOptionsMixin()
    },
    dataSetBillingAddressesMixin: function (billing) {
      if (billing.addresses) {
        billing.addresses.forEach((item, index) => {
          item[TABLE_ROW_INDEX] = index + 1
        })
        this.$data[KEYS_CUSTOMER.BILLING_ADDRESSES] = billing.addresses
      }
    },
    dataSetShippingAddressesMixin: function (shipping) {
      if (shipping.addresses) {
        shipping.addresses.forEach((item, index) => {
          item[TABLE_ROW_INDEX] = index + 1
        })
        this.$data[KEYS_CUSTOMER.SHIPPING_ADDRESSES] = shipping.addresses
      }
    },
    dataSetSalutationsMixin: function (salutations) {
      this.$store.commit('updateSalutations', salutations)
    },
    dataSetCountriesMixin: function (countries) {
      this.$store.commit('updateCountries', countries)
    },
    dataSetCurrentUserMixin: function (currentUser) {
      this.$store.commit('updateCurrentUserData', currentUser)
    },
    dataLoginUserMixin: function (authData) {
      this.$store.commit('saveLoginToLocalStorage', authData)
      this.$store.commit('triggerLogin', { authData })
    },
    dataRegisterUserMixin: function () {
      this.$store.commit('completedRegistration')
      this.$router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.NAME,
      })
    },
    dataMadeOfferMixin: function () {
      this.$store.commit('activeRFQStatus', STATES.OFFER_MADE.VALUE)
      if (this.$route.meta.redirectTarget) {
        this.$router.push({ name: this.$route.meta.redirectTarget })
      }
    },
    dataAcceptedContractMixin: function () {
      this.$store.commit('activeRFQStatus', STATES.OFFER_CONTRACTED.VALUE)
      if (this.$route.meta.redirectTarget) {
        this.$router.push({ name: this.$route.meta.redirectTarget })
      }
    },
    dataRejectRFQMixin: function () {
      this.$store.commit('activeRFQStatus', STATES.REJECTED.VALUE)
      this.$store.commit('preventNextRfqStatusUpdate')
      this.modalSetInvisible(MODALS.REJECT_RFQ)
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
          this.$router.push({
            name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].ARCHIVE
              .NAME,
          })
          break
        default:
          if (this.$route.meta.redirectTarget) {
            this.$router.push({ name: this.$route.meta.redirectTarget })
          }
      }
    },
    dataRejectOfferMixin: function () {
      this.$store.commit('activeRFQStatus', STATES.OFFER_RFQ_REJECTED.VALUE)
      this.$store.commit('preventNextRfqStatusUpdate')
      this.modalSetInvisible(MODALS.REJECT_OFFER)
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SUPPLIER.VALUE:
          this.$router.push({
            name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].ARCHIVE.NAME,
          })
          break
        default:
          if (this.$route.meta.redirectTarget) {
            this.$router.push({ name: this.$route.meta.redirectTarget })
          }
      }
    },
    dataSetNewRFQMixin: function (rfqData) {
      this.$router.push({
        name: ROUTER_DATA.SHARED.RFQ_NEW.NAME,
        params: { id: rfqData.id },
      })
    },
    dataStartExecuteNextCommandMixin(
      nextCommandsList,
      action,
      target,
      triggersRouteChange = true,
    ) {
      let lastCommand = nextCommandsList[nextCommandsList.length - 1]

      let newAction = {
        value: action,
        triggersRouteChange: triggersRouteChange,
      }
      lastCommand.targets.data = target
      lastCommand.action = {
        value: action,
        triggersRouteChange: triggersRouteChange,
      }
      nextCommandsList[0].action = newAction
      this.dataExecuteNextCommandMixin({
        nextCommandsList: nextCommandsList.reverse(),
      })
    },
    dataExecuteNextCommandMixin(command) {
      if (command.nextCommandsList.length) {
        let newCommand = command.nextCommandsList.pop()
        newCommand.showProcessingStatus = true
        newCommand.nextCommandsList = command.nextCommandsList

        switch (newCommand.type) {
          case AXIOS.TYPES.END_COMMAND_CHAIN:
            this.axiosSetResponseTargetMixin(newCommand)
            break
          case AXIOS.TYPES.POST:
            this.axiosPostMixin(newCommand)
            break
          case AXIOS.TYPES.GET:
            this.axiosGetMixin(newCommand)
            break
          case AXIOS.TYPES.PUT:
            this.axiosPutMixin(newCommand)
            break
          case AXIOS.TYPES.PATCH:
            this.axiosPatchMixin(newCommand)
            break
          case AXIOS.TYPES.DELETE:
            this.axiosDeleteMixin(newCommand)
        }
      }
    },
    dataExportRfQCopyMixin(rfqData) {
      let exportRfQData = this.exportFormMixin(rfqData, KEYS_GENERAL.FORM_COPY)
      let body = {}
      let deliveries = []

      //Check if all addresses are valid before sending data to the backend
      API_OBJECT_KEY_NAMES.COPY_CREATE_NEW_WHITELIST.forEach((keyName) => {
        switch (keyName) {
          case API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.PROJECT_NAME:
            if (exportRfQData[keyName]) {
              body[keyName] = `(New copy) ${exportRfQData[keyName]}`
            } else {
              body[keyName] = `(New copy) of RfQ ID: ${
                exportRfQData[API_OBJECT_KEY_NAMES.RFQ.INTERNAL_ID]
              }`
            }
            break
          case API_OBJECT_KEY_NAMES.RFQ.DELIVERY:
            exportRfQData[keyName].forEach((delivery) => {
              delete delivery.id
              delete delivery.rfqId
              delete delivery.createdAt
              delete delivery.updatedAt
              if (delivery.scenarioNumber === null) {
                delete delivery.scenarioNumber
              }
              if (delivery.lotNumber === null) {
                delete delivery.lotNumber
              }

              let newLocations = []

              for (let i = 0; i < delivery.locations.length; i++) {
                delete delivery.locations[i].id
                delete delivery.locations[i].rfqDeliveryId
                delete delivery.locations[i].createdAt
                delete delivery.locations[i].updatedAt
                delete delivery.locations[i].lastChange
                newLocations.push(cloneDeep(delivery.locations[i]))
              }
              delivery.locations = newLocations
              deliveries.push(cloneDeep(delivery))
            })
            body[keyName] = deliveries
            break
          case API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.SOP:
            if (exportRfQData[keyName]) {
              body[keyName] = exportRfQData[keyName].date
            } else {
              body[keyName] = exportRfQData[keyName]
            }
            break
          default:
            body[keyName] = cloneDeep(exportRfQData[keyName])
        }
      })
      let newCommand = this.axiosGetNewCommandObjectMixin()
      newCommand.body = body
      newCommand.path = AXIOS.API.POST.CREATE_RFQ
      newCommand.targets.data = DATA_MIXIN.RFQ_COPY
      newCommand.isPreloadDependency = true
      this.axiosPostMixin(newCommand)
    },
    dataGenerateRfQCopyMixin(rfqData) {
      this.dataSetAvailableLocationsMixin(true)
      /* Check if the delivery information is still valid
      /  valid = Proceed with creating a copy
      /  invalid = Open the Delivery location editor
      */
      let deliveryErrorCount = 0
      this.validateDeliveryLocationsMixin(rfqData[KEYS_RFQ.DELIVERY])
      Object.entries(rfqData[KEYS_RFQ.DELIVERY].errorCount).forEach(
        ([key, value]) => {
          if (key === 'years') {
            deliveryErrorCount += value.sum
          } else {
            deliveryErrorCount += value
          }
        },
      )
      if (deliveryErrorCount) {
        this.modalSetVisible(MODALS.ADJUST_DELIVERY_LOCATION)
        this.$store.commit('setAxiosDataProcessingStatus', false)
      } else {
        this.dataExportRfQCopyMixin(rfqData)
      }
    },
    dataPrepareRFQCopyAndCreateNewMixin: function (rfqData) {
      this.$store.commit('setActiveRFQCopyData', rfqData)
    },
    dataSetRFQCopyMixin: function (newRFQ) {
      this.$store.commit('setActiveRFQCopyData', null)
      this.$router.push({
        name: ROUTER_DATA.SHARED.RFQ_NEW.NAME,
        params: { id: newRFQ.id },
      })
    },
    dataSetCurrenciesMixin: function (currenciesData) {
      this.$store.commit('setCurrencies', currenciesData)
    },
    dataSetRedirectAfterOffersRecallMixin() {
      this.$store.commit('setRfQActionStatus', STATES.RECALLED.VALUE)
      this.$router.go()
    },
    dataAddNewSupplierMixin() {
      this.$store.commit('setRfQActionStatus', STATES.ADDED_SUPPLIER.VALUE)
      this.$router.go()
    },
    dataReopenOffersMixin() {
      this.$router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_PREPARATION_DETAILS.NAME,
      })
    },
    dataRequestOfferMixin() {
      this.$store.commit(
        'activeRFQStatus',
        STATES.SUPPLIER_OFFER_REQUESTED.VALUE,
      )
      this.$router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .OFFER_COLLECTION.NAME,
      })
    },
    dataSubmitOffersMixin() {
      this.$store.commit('activeRFQStatus', STATES.QUOTE_CALCULATION.VALUE)
      this.$store.commit('preventNextRfqStatusUpdate')
      this.$router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RUNNING_RFQ
          .NAME,
      })
    },
    dataSubmitOffersInstantQuoteMixin() {
      this.$store.commit(
        'activeRFQStatus',
        STATES.QUOTE_ACCEPTED_INSTANT_QUOTE.VALUE,
      )
      this.$store.commit('preventNextRfqStatusUpdate')
      this.$router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .SOURCING_CONTRACTING.NAME,
      })
    },
    dataPrepareContractsMixin() {
      this.$store.commit(
        'activeRFQStatus',
        STATES.OFFER_CONTRACT_APPROVAL.VALUE,
      )
      this.$router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .SOURCING_CONTRACTING.NAME,
      })
    },
    dataSendOutContractsMixin() {
      this.$store.commit(
        'activeRFQStatus',
        STATES.WAITING_FOR_CONFIRMATION.VALUE,
      )
      this.$router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .SOURCING_CONTRACTING.NAME,
      })
    },
    dataGenerateContractMixin(response) {
      const URL = window.URL.createObjectURL(new Blob([response.data]))
      const CONTENT_DISPOSITION = response.headers['content-disposition']
      let fileName = 'nomination_letter.xlsx'
      if (CONTENT_DISPOSITION) {
        fileName = CONTENT_DISPOSITION.split('filename=')[1].replace(
          new RegExp('"', 'g'),
          '',
        )
      }
      let link = document.createElement('a')
      link.setAttribute('href', URL)
      link.setAttribute('target', '_blank')
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    },
    dataUpdateImportedQuoteMixin(data, command) {
      this.$data[KEYS_GENERAL.IMPORT_DATA][
        API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP
      ][0] = data
      if (command.targets.excuteNextCommand) {
        this.dataExecuteNextCommandMixin(command)
      }
    },
    dataGenerateQuoteDocumentMixin(response) {
      const URL = window.URL.createObjectURL(new Blob([response.data]))
      const CONTENT_DISPOSITION = response.headers['content-disposition']
      let fileName = 'quote.docx'
      let link = document.createElement('a')
      if (CONTENT_DISPOSITION) {
        fileName = CONTENT_DISPOSITION.split('filename=')[1].replace(
          new RegExp('"', 'g'),
          '',
        )
      }
      link.setAttribute('href', URL)
      link.setAttribute('target', '_blank')
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    },
    dataRequestClarificationMixin() {
      this.$store.commit('activeRFQStatus', STATES.CUSTOMER_CLARIFICATION.VALUE)
      this.$store.commit('preventNextRfqStatusUpdate')
      this.$router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].OVERVIEW.NAME,
      })
    },
    dataProvideRfQInformationMixin() {
      this.$store.commit(
        'activeRFQStatus',
        STATES.PROVIDE_RFQ_INFORMATION.VALUE,
      )
      this.$store.commit('preventNextRfqStatusUpdate')
      this.$router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW
          .NAME,
      })
    },
    dataRequestOfferReworkMixin() {
      this.$store.commit(
        'setRfQActionStatus',
        STATES.REQUEST_SUPPLIER_TO_REWORK.VALUE,
      )
      this.$router.go()
    },
    dataSetNewDueDateForOffersMixin() {
      this.$store.commit('setRfQActionStatus', STATES.SET_NEW_DUE_DATE.VALUE)
      this.$router.go()
    },
    dataAddRfqOfferMixin: function (offerData, command) {
      this.importSupplierOffersFormDataMixin(
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_FORMS].data,
        offerData,
        command.offerIndex,
      )
      this.$store.commit('updateActiveOffersHashmap', offerData)
    },
    dataSetEditRFQMixin: function (rfqData) {
      this.$data[KEYS_GENERAL.IMPORT_DATA] = rfqData
      this.$data[KEYS_GENERAL.IMPORT_DATA][FRONTEND_VALUES.STATE_TYPE] =
        STATE_TYPE.RFQ
      this.$store.commit('updateActiveRFQIDs', rfqData)
      this.$store.commit('updateLockedStatus', rfqData)
      this.$store.commit('setActiveEntityData', rfqData)
      this.importFormDataMixin(this.$data[KEYS_GENERAL.FORM], rfqData)

      this.importSupplierScenariosFormDataMixin(
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_SCENARIOS],
        rfqData,
      )
      //Disable the SOP if First Samples got not set on RFQ Editors
      if (this.$route.meta.routeType === ROUTE_TYPES.RFQ_EDITOR) {
        this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.PROJECT_DATA_PREFIX][
          KEYS_RFQ.PROJECT_DATA.SOP
        ].disabled =
          !rfqData[API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.FIRST_SAMPLE]
      }

      // Run Autocorrect if data got imported in edit mode
      if (this.$route.name === ROUTER_DATA.SHARED.RFQ_EDIT.NAME) {
        let autoCorrectItem
        if (
          this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.PROJECT_DATA_PREFIX][
            KEYS_RFQ.PROJECT_DATA.QUOTE_EXPECTED_BY
          ].value
        ) {
          autoCorrectItem =
            this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.PROJECT_DATA_PREFIX][
              KEYS_RFQ.PROJECT_DATA.QUOTE_EXPECTED_BY
            ]
        } else if (
          this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.PROJECT_DATA_PREFIX][
            KEYS_RFQ.PROJECT_DATA.FIRST_SAMPLE
          ].value
        ) {
          autoCorrectItem =
            this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.PROJECT_DATA_PREFIX][
              KEYS_RFQ.PROJECT_DATA.FIRST_SAMPLE
            ]
        }
        if (autoCorrectItem && autoCorrectItem.autoCorrectSettings) {
          autoCorrectItem.autoCorrectSettings.forEach((autoCorrectSetting) => {
            this.autoCorrectSettingsMixin(autoCorrectItem, autoCorrectSetting)
          })
        }
      }
      if (
        !this.getDetailsRouteMixin(
          rfqData,
          false,
          this.$route.meta.routeType === ROUTE_TYPES.RFQ_EDITOR,
        )
      ) {
        this.dataSetAvailableLocationsMixin()
        if (
          rfqData[API_OBJECT_KEY_NAMES.RFQ.OFFERS] &&
          rfqData[API_OBJECT_KEY_NAMES.RFQ.OFFERS].length
        ) {
          rfqData[API_OBJECT_KEY_NAMES.RFQ.OFFERS].forEach((offer, index) => {
            this.dataAddRfqOfferMixin(offer, { offerIndex: index })
          })
        }
      }
    },
    dataSetEditOfferMixin: function (offerData) {
      if (
        !this.getDetailsRouteMixin(
          offerData,
          false,
          this.$route.meta.routeType === ROUTE_TYPES.RFQ_EDITOR,
        )
      ) {
        this.$store.commit('updateLockedStatus', offerData)
        this.$data[KEYS_GENERAL.IMPORT_DATA] = offerData.rfq
        this.$data[KEYS_GENERAL.IMPORT_DATA][FRONTEND_VALUES.STATE_TYPE] =
          STATE_TYPE.RFQ
        this.$data[KEYS_GENERAL.IMPORT_OFFER_DATA] = offerData
        this.$data[KEYS_GENERAL.IMPORT_OFFER_DATA][FRONTEND_VALUES.STATE_TYPE] =
          STATE_TYPE.OFFER
        this.$store.commit(
          'updateActiveRFQIDs',
          this.$data[KEYS_GENERAL.IMPORT_DATA],
        )
        this.$store.commit(
          'updateActiveOfferID',
          this.$data[KEYS_GENERAL.IMPORT_OFFER_DATA],
        )
        this.importSupplierScenariosFormDataMixin(
          this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_SCENARIOS],
          this.$data[KEYS_GENERAL.IMPORT_DATA],
        )
        this.$store.commit(
          'setActiveEntityData',
          this.$data[KEYS_GENERAL.IMPORT_OFFER_DATA],
        )
        this.importFormDataMixin(
          this.$data[KEYS_GENERAL.FORM],
          this.$data[KEYS_GENERAL.IMPORT_DATA],
        )
        this.importFormDataMixin(
          this.$data[KEYS_GENERAL.FORM_OFFER],
          this.$data[KEYS_GENERAL.IMPORT_OFFER_DATA],
        )
      }
    },
    dataSetAvailableLocationsMixin: function (isCopy = false) {
      if (this.$data[KEYS_CUSTOMER.SHIPPING_ADDRESSES] !== null) {
        let locations = this.initializeTranslatedLocationsMixin(
          this.$data[KEYS_CUSTOMER.SHIPPING_ADDRESSES],
        )
        let form = isCopy
          ? this.$data[KEYS_GENERAL.FORM_COPY]
          : this.$data[KEYS_GENERAL.FORM]
        form[KEYS_RFQ.DELIVERY].locations = locations
        form[KEYS_RFQ.DELIVERY].availableLocations =
          this.initializeAvailableLocationsMixin(
            locations,
            this.$data[KEYS_GENERAL.IMPORT_DATA],
          )
      }
    },
    dataGetRFQAndCreateCopyMixin: function (data) {
      this.$store.commit('toggleForceRFQEditorExit', true)
      let newCommand = this.axiosGetNewCommandObjectMixin()
      newCommand.path = this.axiosActiveRFQPathMixin(data.id)
      newCommand.isPreloadDependency = true
      newCommand.targets.data = DATA_MIXIN.RFQ_COPY_AND_CREATE_NEW
      this.axiosGetMixin(newCommand)
    },
    dataCheckPasswordMixin(data, checkPassword = false) {
      if (
        data[API_OBJECT_KEY_NAMES.USER.HASH_VALID] === true &&
        checkPassword
      ) {
        this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.NEW_PASSWORD][
          passwordCheckTypes.CHECK_TYPES.NO_EMAIL
        ] = data[API_OBJECT_KEY_NAMES.USER.PASSWORD_VAlID]
        this.$data[KEYS_GENERAL.REMOTE_EMAIL_VALIDATION] = false
        if (data[API_OBJECT_KEY_NAMES.USER.PASSWORD_VAlID]) {
          this.$data[KEYS_GENERAL.LAST_BACKEND_RESPONSE] = new Date()
        }
        this.$store.commit('setAxiosDataProcessingStatus', false)
      } else if (data[API_OBJECT_KEY_NAMES.USER.HASH_VALID] === false) {
        this.dataSetHashInvalidResponseMixin()
      }
    },
    dataSetHashInvalidResponseMixin: function () {
      this.$store.commit('activeUserStatus', USER_STATUS.INVALID_HASH)
      router.push({
        name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].HOME.NAME,
      })
      this.$store.commit('setAxiosDataErrorMessage', NOTIFICATIONS.INVALID_HASH)
      this.$store.commit('setAxiosDataProcessingStatus', false)
    },
    dataSaveRFQDraftMixin: function () {
      this.$store.commit('activeRFQStatus', STATES.DRAFT.VALUE)
      this.$store.commit('toggleForceRFQEditorExit', true)
      router.push({
        name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup].MY_RFQS
          .DRAFTS.NAME,
      })
    },
    dataSaveRFQAsNewMixin: function () {
      this.$store.commit('activeRFQStatus', STATES.NEW.VALUE)
      this.$store.commit('toggleForceRFQEditorExit', true)
      router.push({
        name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup].MY_RFQS
          .OVERVIEW.NAME,
      })
    },
    setFileUploadDataMixin: function (data, command, message, isError = false) {
      command.item.messages.status = message
      if (isError) {
        command.item.file.status = FILE_TRANSFER_STATUS.FAILED
      } else {
        command.item.file.status = FILE_TRANSFER_STATUS.SUCCESSFUL
        command.item.value = data.filename
        command.item.file.name = data.filename
        command.item.invalid = false
        if (
          [FILE_TYPES.CONTRACT, FILE_TYPES.ADDENDUM].includes(
            command.targets.data,
          )
        ) {
          let offerHashmap = this.$store.getters.getActiveOffersHashmap
          let offer = offerHashmap.get(command.item.file.url.targetId)
          if (command.targets.data === FILE_TYPES.CONTRACT) {
            offer[API_OBJECT_KEY_NAMES.OFFER.CONTRACT.CONTRACT_FILE] =
              data.filename
          } else {
            offer[API_OBJECT_KEY_NAMES.OFFER.CONTRACT.ADDENDUM_FILE] =
              data.filename
          }
          this.$store.commit('updateActiveOffersHashmap', offer)
        }
        this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM])
      }
      command.item.file.transferInProgress = false
    },
    dataSetSidebarMixin: function (data) {
      this.$store.commit('setSidebarData', data)
    },
    dataSetSupportUsersMixin: function (data) {
      data.forEach((item, index) => {
        item[TABLE_ROW_INDEX] = index + 1
      })
      this.$data[KEYS_USER.SUPPORT_USERS].data = data
    },
    dataSetB2BContactsMixin: function (data) {
      data.contacts.forEach((item, index) => {
        item[TABLE_ROW_INDEX] = index + 1
      })
      this.$data[KEYS_USER.B2B_CONTACTS] = data
    },
    dataValidateRFQMixin() {
      this.$store.commit('activeRFQStatus', STATES.VALIDATED.VALUE)
      this.$store.commit('preventNextRfqStatusUpdate')
      router.push({
        name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
          .IN_VALIDATION.NAME,
      })
    },
    dataSelectSuppliersMixin() {
      this.$store.commit('activeRFQStatus', STATES.SUPPLIER_SELECTION.VALUE)
      this.$store.commit('preventNextRfqStatusUpdate')
      router.push({
        name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
          .QUOTE_PREPARATION.NAME,
      })
    },
    dataCalculateInstantQuoteMixin() {
      this.$store.commit(
        'activeRFQStatus',
        STATES.CALCULATE_INSTANT_QUOTE.VALUE,
      )
      this.$store.commit('preventNextRfqStatusUpdate')
      router.push({
        name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
          .QUOTE_PREPARATION.NAME,
      })
    },
    dataSendQuoteMixin() {
      this.$store.commit('activeRFQStatus', STATES.QUOTE_SENT.VALUE)
      this.$store.commit('preventNextRfqStatusUpdate')
      router.push({
        name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
          .CONTRACT_PREPARATION.NAME,
      })
    },
    dataRequestQuoteRework() {
      this.$store.commit('activeRFQStatus', STATES.QUOTE_TO_REWORK.VALUE)
      this.$store.commit('preventNextRfqStatusUpdate')
      router.push({
        name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
          .CONTRACT_PREPARATION.NAME,
      })
    },
    dataBeginSupplierSelection() {
      this.$store.commit(
        'activeRFQStatus',
        STATES.BEGIN_SUPPLIER_SELECTION.VALUE,
      )
      this.$store.commit('preventNextRfqStatusUpdate')
      router.push({
        name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup].NEW_RFQS
          .NAME,
      })
    },
    sendFileUploadDataMixin(fileItem) {
      this.manageFileSelectionMixin(fileItem.item, fileItem.value)
    },
    dataCreateQuoteMixin() {
      this.$store.commit('activeRFQStatus', STATES.QUOTE_TO_APPROVE.VALUE)
      this.$store.commit('preventNextRfqStatusUpdate')
      router.push({
        name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
          .QUOTE_PREPARATION.NAME,
      })
    },
    dataSetDashboardDataMixin: function (data) {
      this.$data[KEYS_GENERAL.DASHBOARD_DATA] = data.dashboardData
      this.$store.commit('setAxiosUpdateDashboardStatus', false)
    },
    /**
     * Modify the  result to compensate for the lack of natural sorting and special sort values in the App Smarttable
     * @param data
     * @returns {*}
     */
    adjustDataForNaturalSortingMixin(data) {
      const TOTAL_AMOUNT = 'totalAmount'
      let longestDigit = 0
      data.forEach((item) => {
        if (item[TOTAL_AMOUNT] && item[TOTAL_AMOUNT].length > longestDigit) {
          longestDigit = item[TOTAL_AMOUNT].length
        }
        if (
          item[API_OBJECT_KEY_NAMES.RFQ.ASSIGNMENTS] &&
          item[API_OBJECT_KEY_NAMES.RFQ.ASSIGNMENTS].length
        ) {
          let userRole = Object.values(PERMISSION_GROUPS).filter(
            (groupData) => {
              return (
                groupData.VALUE === this.$store.getters.getActivePermissionGroup
              )
            },
          )
          userRole = userRole[0] ? userRole[0].TRANSLATION : null
          item[API_OBJECT_KEY_NAMES.RFQ.ASSIGNMENTS].forEach((assigneeData) => {
            if (assigneeData.role === userRole) {
              item.assignment = assigneeData.assignedUser.name
              item.assignee = assigneeData.assignedUser.name
            }
          })
        } else {
          item.assignment = null
          item.assignee = null
        }
      })
      data.forEach((item) => {
        if (item[TOTAL_AMOUNT]) {
          item[TOTAL_AMOUNT] = String(item[TOTAL_AMOUNT])
          item[TOTAL_AMOUNT] = item[TOTAL_AMOUNT].padStart(longestDigit, '0')
        }
      })
      return data
    },
    dataSetRFQListingMixin: function (listings) {
      listings.data.forEach((item, index) => {
        item[TABLE_ROW_INDEX] = index + 1
        item[FRONTEND_VALUES.STATE_TYPE] = STATE_TYPE.RFQ
      })
      this.$data[KEYS_RFQ.LISTING] = this.adjustDataForNaturalSortingMixin(
        listings.data,
      )
      this.$data[KEYS_RFQ.TOTAL_RESULTS] = listings.total
      this.$store.commit('setAxiosUpdateListingStatus', false)
    },
    dataSetOfferListingMixin: function (listings, layoutVariant) {
      listings.data.forEach((item, index) => {
        item[TABLE_ROW_INDEX] = index + 1
        item.offerId = item.id
        item.id = item.rfq.id
        item[FRONTEND_VALUES.STATE_TYPE] = STATE_TYPE.OFFER
        SMART_TABLE_LAYOUT_DATA[layoutVariant].forEach((column) => {
          let canModifyValue = true
          if (
            column.overrideGroupBlacklist &&
            column.overrideGroupBlacklist.includes(
              this.$store.getters.getActivePermissionGroup,
            )
          ) {
            canModifyValue = false
          }

          if (canModifyValue) {
            item[column.key] = item.rfq[column.key]
          }
        })
      })
      this.$data[KEYS_RFQ.LISTING] = this.adjustDataForNaturalSortingMixin(
        listings.data,
      )
      this.$data[KEYS_RFQ.TOTAL_RESULTS] = listings.total
      this.$store.commit('setAxiosUpdateListingStatus', false)
    },
    dataSetRecoveryMailStatusMixin(statusCode) {
      this.$store.commit('setRecoveryMailStatus', statusCode)
      switch (statusCode) {
        case 1:
        case 2:
        case 3:
          router.push({
            name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.NAME,
          })
          break
      }
    },
    dataChangedPasswordMixin(data) {
      this.$store.commit('changedPassword', data.contextToken)
    },
    dataSetLastContactStatus(statusCode = 0) {
      this.$emit('updateStatusCode', statusCode)
    },
    dataRedirectSaveEditMixin() {
      this.getDetailsRouteMixin(null, false, false, false)
    },
    dataUpdateCommentsMixin(command, data) {
      if (
        command.formTarget[command.activeTab].formObject.keyName ===
        KEYS_RFQ.NOTES_FOR_ME
      ) {
        this.$data.isEditingNotes = false
      } else {
        if (command.targetId) {
          let offerData = this.$store.getters.getActiveOffersHashmap.get(
            command.targetId,
          )
          offerData.comments = data[command.apiKey]
          this.$store.commit('updateActiveOffersHashmap', offerData)
        } else {
          command.formTarget[command.activeTab].formObject.comments =
            data[command.apiKey]
        }
        command.formTarget[command.activeTab].formObject.value = ''
      }

      this.$store.commit('setAxiosDataProcessingStatus', false)
    },
    dataSwitchToSupportUserMixin(data) {
      this.$store.commit('switchToSupportUser', data)
    },
    dataSetSuppliersMixin(data) {
      let updatedSupplierList = []
      if (data.elements.length) {
        this.$data[KEYS_RFQ.TOTAL_RESULTS] = data.total
        data.elements.forEach((supplierData) => {
          updatedSupplierList.push({
            id: supplierData.id,
            company: supplierData.company,
            city: supplierData.defaultBillingAddress.city,
            zipcode: supplierData.defaultBillingAddress.zipcode,
            street: supplierData.defaultBillingAddress.street,
            country: this.getCountryTranslationMixin(
              supplierData.defaultBillingAddress.countryId,
            ),
          })
        })
      }
      this.$data[KEYS_GENERAL.SUPPLIER_LISTING] = updatedSupplierList
    },
    dataSetAssignToMeMixin() {
      switch (this.$route.meta.routeType) {
        case ROUTE_TYPES.LISTING:
          this.$emit('updateListing')
          break
        default:
          this.$emit('updateRfQ')
      }
    },
  },
}
