const PREFIX = 'data'
const SUFFIX = 'Mixin'

export const DATA_MIXIN = {
  SET_ADDRESSES: `${PREFIX}SetAddresses${SUFFIX}`,
  SET_SHIPPING_ADDRESSES: `${PREFIX}SetShippingAddresses${SUFFIX}`,
  SET_BILLING_ADDRESSES: `${PREFIX}SetBillingAddresses${SUFFIX}`,
  SET_SALUTATIONS: `${PREFIX}SetSalutations${SUFFIX}`,
  SET_COUNTRIES: `${PREFIX}SetCountries${SUFFIX}`,
  SET_CURRENT_USER: `${PREFIX}SetCurrentUser${SUFFIX}`,
  LOGIN_USER: `${PREFIX}LoginUser${SUFFIX}`,
  REGISTER_USER: `${PREFIX}RegisterUser${SUFFIX}`,
  RFQ_LISTING: `${PREFIX}RFQListing${SUFFIX}`,
  RFQ_SAVE_DRAFT: `${PREFIX}RFQSaveDraft${SUFFIX}`,
  RFQ_SAVE_DRAFT_AND_COPY: `${PREFIX}RFQSaveDraftAndCopy${SUFFIX}`,
  RFQ_CREATE_NEW: `${PREFIX}RFQNew${SUFFIX}`,
  RFQ_SAVE_AS_NEW: `${PREFIX}RFQSaveAsNew${SUFFIX}`,
  RFQ_EDIT: `${PREFIX}RFQEdit${SUFFIX}`,
  RFQ_COPY_AND_CREATE_NEW: `${PREFIX}RFQCopyAndCreateNew${SUFFIX}`,
  RFQ_COPY: `${PREFIX}RFQCopy${SUFFIX}`,
  SEND_COMMENT: `${PREFIX}SendComment${SUFFIX}`,
  GERBER_FILE: `${PREFIX}GerberFile${SUFFIX}`,
  SET_SIDEBAR: `${PREFIX}Sidebar${SUFFIX}`,
  B2B_ROLES: `${PREFIX}B2BRoles${SUFFIX}`,
  B2B_CONTACTS: `${PREFIX}B2BContacts${SUFFIX}`,
  SEND_PASSWORD_RECOVERY_MAIL: `${PREFIX}SendPasswordRecoveryMail${SUFFIX}`,
  RESET_PASSWORD: `${PREFIX}ResetPassword${SUFFIX}`,
  CHANGE_PASSWORD: `${PREFIX}ChangePassword${SUFFIX}`,
  B2B_REGISTER_CONTACT: `${PREFIX}B2BRegisterContact${SUFFIX}`,
  B2B_EDIT_CONTACT: `${PREFIX}B2BEditContact${SUFFIX}`,
  CHECK_PASSWORD: `${PREFIX}CheckPassword${SUFFIX}`,
  CHECK_HASH: `${PREFIX}CheckHash${SUFFIX}`,
  SET_INITIAL_PASSWORD: `${PREFIX}SetInitialPassword${SUFFIX}`,
  OFFERS_LISTING: `${PREFIX}OffersListing${SUFFIX}`,
  OFFER_EDIT: `${PREFIX}OfferEdit${SUFFIX}`,
  SAVE_EDIT_MODE: `${PREFIX}SaveEditMode${SUFFIX}`,
  SUPPLIER_MAKE_OFFER: `${PREFIX}SupplierMakeOffer${SUFFIX}`,
  ACCEPT_CONTRACT: `${PREFIX}AcceptContract${SUFFIX},`,
  UPDATE_COMMENTS: `${PREFIX}UpdateComments${SUFFIX}`,
  REJECT_RFQ: `${PREFIX}RejectRFQ${SUFFIX}`,
  REQUEST_CLARIFICATION: `${PREFIX}RequestClarification${SUFFIX}`,
  REJECT_OFFER: `${PREFIX}RejectOffer${SUFFIX}`,
  EXECUTE_NEXT_COMMANDS: `${PREFIX}ExecuteNextCommands${SUFFIX}`,
  OFFER_COMMENT_FOR_SUPPLIER: `${PREFIX}OfferCommentForSupplier${SUFFIX}`,
  SWITCH_TO_SUPPORT_USER: `${PREFIX}SwitchToSupportUser${SUFFIX}`,
  SUPPLIER_LISTING: `${PREFIX}SupplierListing${SUFFIX}`,
  ASSIGN_TO_ME: `${PREFIX}AssignToMe${SUFFIX}`,
  EXIT_RFQ_EDITOR: `${PREFIX}ExitRfQEditor${SUFFIX}`,
  ADD_RFQ_OFFER: `${PREFIX}AddRfQOffer${SUFFIX}`,
  REDIRECT_AFTER_OFFERS_RECALL: `${PREFIX}RedirectAfterOffersRecall${SUFFIX}`,
  SET_NEW_DUE_DATE_FOR_OFFERS: `${PREFIX}SetNewDueDateForOffers${SUFFIX}`,
  REQUEST_OFFER_REWORK: `${PREFIX}RequestOfferRework${SUFFIX}`,
  ADD_NEW_SUPPLIER: `${PREFIX}AddNewSupplier${SUFFIX}`,
  GENERATE_CONTRACT: `${PREFIX}GenerateContract${SUFFIX}`,
  REQUEST_OFFER: `${PREFIX}RequestOffer${SUFFIX}`,
  PREPARE_CONTRACTS: `${PREFIX}PrepareContracts${SUFFIX}`,
  SEND_OUT_CONTRACTS: `${PREFIX}SendOutContracts${SUFFIX}`,
  REOPEN_OFFERS: `${PREFIX}ReopenOffers${SUFFIX}`,
  DASHBOARD: `${PREFIX}Dashboard${SUFFIX}`,
  VALIDATE_RFQ: `${PREFIX}ValidateRFQ${SUFFIX}`,
  SELECT_SUPPLIERS: `${PREFIX}SelectSuppliers${SUFFIX}`,
  CALCULATE_INSTANT_QUOTE: `${PREFIX}CalculateInstantQuote${SUFFIX}`,
  CREATE_QUOTE: `${PREFIX}CalculateQuote${SUFFIX}`,
  SEND_QUOTE: `${PREFIX}SendQuote${SUFFIX}`,
  GENERATE_QUOTE_DOCUMENT: `${PREFIX}GenerateQuoteDocument${SUFFIX}`,
  REDIRECT_AFTER_SUPPLIER_EXPERT_REWORK_REQUEST: `${PREFIX}RedirectAfterSupplierExpertReworkRequest${SUFFIX}`,
  INITIALIZE_QUOTE_PANELS_BEFORE_FILE_UPLOAD: `${PREFIX}InitializeQuotePanelsBeforeFileUpload${SUFFIX}`,
  BEGIN_SUPPLIER_SELECTION: `${PREFIX}BeginSupplierSelection${SUFFIX}`,
  UPDATE_QUOTE: `${PREFIX}UpdateQuote${SUFFIX}`,
  SUBMIT_OFFERS: `${PREFIX}SubmitOffers${SUFFIX}`,
  SUBMIT_OFFERS_INSTANT_QUOTE: `${PREFIX}SubmitOffersInstantQuote${SUFFIX}`,
  PROVIDE_RFQ_INFORMATION: `${PREFIX}ProvideRfQInformation${SUFFIX}`,
}
