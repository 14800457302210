import {
  EDIT_MODE_ROUTES,
  ROUTE_TYPES,
  ROUTER_DATA,
} from '@/constants/routerData'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { STATES } from '@/constants/states'
import { ACTIONS } from '@/constants/constants'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'

export default {
  methods: {
    canDisplayComponentsMixin() {
      return !(
        this.$route.name ===
          ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD.NAME &&
        this.$store.getters.getAxiosData.isUpdatingDashboard
      )
    },
    isEditModeRouteMixin: function () {
      return EDIT_MODE_ROUTES.includes(this.$route.name)
    },
    isReadOnlyModeMixin: function (keyName) {
      if (this.$route.meta.readOnlyComponents) {
        return this.$route.meta.readOnlyComponents.includes(keyName)
      }
      return false
    },
    requiredForValidationMixin: function (keyName, firstCall = true) {
      if (this.$data[KEYS_GENERAL.OVERRIDE_VALIDATION_COMPONENTS]) {
        return this.$data[KEYS_GENERAL.OVERRIDE_VALIDATION_COMPONENTS].includes(
          keyName,
        )
      } else if (
        this.$route.meta.requiredForValidationComponents &&
        firstCall
      ) {
        return this.$route.meta.requiredForValidationComponents.includes(
          keyName,
        )
      }
      return true
    },
    getDetailsRouteMixin(
      item = null,
      returnValue,
      isEditMode = false,
      triggerValidation = false,
    ) {
      let state
      let isInstantQuote = false
      if (item === null && this.$store.getters.getActiveEntityData) {
        state = this.$store.getters.getActiveEntityData[
          API_OBJECT_KEY_NAMES.RFQ.ORIGINAL_STATE
        ]
          ? this.$store.getters.getActiveEntityData[
              API_OBJECT_KEY_NAMES.RFQ.ORIGINAL_STATE
            ]
          : this.$store.getters.getActiveEntityData.state
        isInstantQuote =
          this.$store.getters.getActiveEntityData[
            API_OBJECT_KEY_NAMES.RFQ.IS_INSTANT_QUOTE
          ]
      } else if (item) {
        state = item[API_OBJECT_KEY_NAMES.RFQ.ORIGINAL_STATE]
          ? item[API_OBJECT_KEY_NAMES.RFQ.ORIGINAL_STATE]
          : item.state
        isInstantQuote = item[API_OBJECT_KEY_NAMES.RFQ.IS_INSTANT_QUOTE]
      }
      let routeData = {
        name: '',
        params: {
          id: item ? item.id : this.$route.params.id,
        },
      }

      if (
        this.$store.getters.getActivePermissionGroup ===
        PERMISSION_GROUPS.SUPPLIER.VALUE
      ) {
        if (
          [ROUTE_TYPES.RFQ_DETAILS, ROUTE_TYPES.RFQ_EDITOR].includes(
            this.$route.meta.routeType,
          )
        ) {
          routeData.params = {
            id: item ? item.rfq.id : this.$route.params.id,
            offerId: item ? item.id : this.$route.params.offerId,
          }
        } else {
          routeData.params.offerId = item
            ? item.offerId
            : this.$route.params.offerId
        }
      }

      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE:
          switch (state) {
            case STATES.SUPPLIER_SELECTION.VALUE:
            case STATES.SUPPLIER_OFFER_REQUESTED.VALUE:
              routeData.name = isEditMode
                ? ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_DETAILS_EDIT.NAME
                : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_DETAILS.NAME
              break
            case STATES.SUPPLIER_OFFERS_COMPLETED.VALUE:
            case STATES.QUOTE_CALCULATION.VALUE:
              routeData.name = isEditMode
                ? ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_OFFER_REVIEW_DETAILS_EDIT.NAME
                : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_OFFER_REVIEW_DETAILS.NAME
              break
            default:
              routeData.name = isEditMode
                ? ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_UPLOAD_CONTRACT_DETAILS_EDIT.NAME
                : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_UPLOAD_CONTRACT_DETAILS.NAME
              break
          }
          break
        case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
          switch (state) {
            case STATES.NEW.VALUE:
              routeData.name =
                ROUTER_DATA[
                  this.$store.getters.getActivePermissionGroup
                ].RFQ_DETAILS.NAME
              break
            case STATES.VALIDATED.VALUE:
            case STATES.SUPPLIER_SELECTION.VALUE:
            case STATES.SUPPLIER_OFFER_REQUESTED.VALUE:
            case STATES.SUPPLIER_OFFERS_COMPLETED.VALUE:
              routeData.name = isEditMode
                ? ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_DETAILS_EDIT.NAME
                : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_DETAILS.NAME
              break
            default:
              if (isInstantQuote) {
                routeData.name = isEditMode
                  ? ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                      .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE_EDIT.NAME
                  : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                      .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE.NAME
              } else {
                routeData.name = isEditMode
                  ? ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                      .RFQ_DETAILS_CALCULATION_EDIT.NAME
                  : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                      .RFQ_DETAILS_CALCULATION.NAME
              }
              break
          }
          break
        case PERMISSION_GROUPS.SUPPLIER.VALUE:
          switch (state) {
            case STATES.OFFER_MADE.VALUE:
            case STATES.OFFER_NOT_SELECTED.VALUE:
            case STATES.OFFER_SELECTED.VALUE:
              routeData.name = isEditMode
                ? ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_DETAILS_OFFER_MADE_EDIT.NAME
                : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_DETAILS_OFFER_MADE.NAME
              break
            case STATES.OFFER_UNDER_CONTRACTING.VALUE:
            case STATES.OFFER_CONTRACTED.VALUE:
              routeData.name =
                ROUTER_DATA[
                  this.$store.getters.getActivePermissionGroup
                ].RFQ_CONTRACT_DETAILS.NAME
              break
            default:
              routeData.name = isEditMode
                ? ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_DETAILS_EDIT.NAME
                : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                    .RFQ_DETAILS.NAME
              break
          }
          break
        case PERMISSION_GROUPS.CUSTOMER.VALUE:
          if (
            this.$route.name ===
            ROUTER_DATA[this.$store.getters.getActivePermissionGroup].MY_RFQS
              .NEW.NAME
          ) {
            routeData.name = ROUTER_DATA.SHARED.RFQ_NEW.NAME
          } else if (this.$route.name !== ROUTER_DATA.SHARED.RFQ_NEW.NAME) {
            routeData.name = isEditMode
              ? ROUTER_DATA.SHARED.RFQ_EDIT.NAME
              : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                  .RFQ_DETAILS.NAME
          }
          break
        case PERMISSION_GROUPS.NO_PERMISSION.VALUE:
          routeData.name =
            ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].HOME.NAME
          break
        default:
          routeData.name = isEditMode
            ? ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                .RFQ_DETAILS_EDIT.NAME
            : ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                .RFQ_DETAILS.NAME
          break
      }

      if (triggerValidation) {
        routeData.query = {
          [ACTIONS.TRIGGER_VALIDATION]: true,
        }
      }

      if (routeData.name !== this.$route.name) {
        if (returnValue) {
          return routeData
        }
        this.$router.push(routeData)
      }
    },
  },
}
