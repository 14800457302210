import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { FORM_ITEMS } from '@/constants/formItems'
import { IMPORT_EXPORT } from '@/constants/importExport'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'

let itemSupplierSelectionSupplier = {
  keyName: KEYS_RFQ.SUPPLIER_SELECTION.SUPPLIER_SELECTION_SUPPLIER,
  [API_OBJECT_KEY_NAMES.OFFER.ACTIVE_FOR_NEXT_PHASE]: true,
  required: true,
  formType: FORM_ITEMS.TYPES.SUPPLIER_SELECTION,
  data: [],
  activeIndex: -1,
  selectedIDs: [],
  supplierOfferMapping: new Map(),
  supplierScenarioMapping: new Map(),
}

let itemCSCCRequired = {
  keyName: KEYS_RFQ.SUPPLIER_SELECTION.SUPPLIER_SELECTION_CSCC_REQUIRED,
  keyNameGroup: KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
  apiObjectKeyNames: [API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.CSCC],
  value: '',
  required: false,
  checked: false,
  style: 'me-3',
  validationTypes: [],
  detailSettings: {
    colMdSize: 4,
  },
  exportCheckedValue: true,
  formType: FORM_ITEMS.TYPES.CHECKBOX,
  parseType: IMPORT_EXPORT.PARSE_TYPE.BOOLEAN,
  messages: {
    label: 'CSCC required?',
  },
}

let itemConfirmationRequired = {
  keyName: KEYS_RFQ.SUPPLIER_SELECTION.SUPPLIER_SELECTION_CONFIRMATION_REQUIRED,
  keyNameGroup: KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.DETAILED_FEASIBILITY,
  ],
  value: '',
  required: false,
  checked: false,
  validationTypes: [],
  detailSettings: {
    colMdSize: 4,
  },
  exportCheckedValue: true,
  formType: FORM_ITEMS.TYPES.CHECKBOX,
  parseType: IMPORT_EXPORT.PARSE_TYPE.BOOLEAN,
  messages: {
    label: 'Detailed feasibility confirmation required?',
  },
}

export let rfqSupplierSelectionTypes = {
  [itemSupplierSelectionSupplier.keyName]: itemSupplierSelectionSupplier,
  [itemCSCCRequired.keyName]: itemCSCCRequired,
  [itemConfirmationRequired.keyName]: itemConfirmationRequired,
}
