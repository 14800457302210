import { PLACEHOLDER } from '@/constants/constants'

const STORE_API = /store-api/

export const AXIOS = {
  API: {
    GET: {
      FETCH_B2B_SHIPPING_ADDRESSES: `${STORE_API}b2b/address/type/shipping`,
      FETCH_B2B_BILLING_ADDRESSES: `${STORE_API}b2b/address/type/billing`,
      FETCH_B2B_ADDRESS: `${STORE_API}b2b/address/${PLACEHOLDER.ID.ADDRESS}`,
      FETCH_SALUTATIONS: `${STORE_API}salutation`,
      FETCH_COUNTRIES: `${STORE_API}country`,
      FETCH_CONTEXT: `${STORE_API}context`,
      FETCH_CURRENT_USER: `${STORE_API}account/customer`,
      GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_CUSTOMER: `${STORE_API}rfq/get-entity/${PLACEHOLDER.ID.RFQ}`,
      GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SALES_EXPERT: `${STORE_API}rfq/get-entity-for-sales-expert/${PLACEHOLDER.ID.RFQ}`,
      GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SALES_MANAGER: `${STORE_API}rfq/get-entity-for-sales-manager/${PLACEHOLDER.ID.RFQ}`,
      GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SUPPLIER: `${STORE_API}rfq/get-entity-for-supplier/${PLACEHOLDER.ID.RFQ}`,
      GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SUPPLIER_EXPERT: `${STORE_API}rfq/get-entity-for-supplier-expert/${PLACEHOLDER.ID.RFQ}`,
      GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SUPPLIER_MANAGER: `${STORE_API}rfq/get-entity-for-supplier-manager/${PLACEHOLDER.ID.RFQ}`,
      GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SALES_MANAGER: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}/get-entity-for-sales-manager`,
      GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SALES_EXPERT: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}/get-entity-for-sales-expert`,
      GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SUPPLIER_MANAGER: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}/get-entity-for-supplier-manager`,
      GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SUPPLIER: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}/get-entity-for-supplier`,
      GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SUPPLIER_EXPERT: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}/get-entity-for-supplier-expert`,
      DOWNLOAD_CUSTOMER_QUOTE_SPECIFIED_FILE: `${STORE_API}elio/download/quote/quotation-file/specified/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_CUSTOMER_QUOTE_PROPOSED_FILE: `${STORE_API}elio/download/quote/quotation-file/proposed/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_GERBER_FILE: `${STORE_API}elio/download/gerber-file/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_SPECIFICATION_FILE: `${STORE_API}elio/download/specification-file/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_PANEL_DRAWING_FILE: `${STORE_API}elio/download/panel-drawing-file/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_PANEL_OTHER_FILE: `${STORE_API}elio/download/other-file/${PLACEHOLDER.ID.RFQ}`,
      SIDEBAR: `${STORE_API}sidebar`,
      DOWNLOAD_QUOTE_FILE: `${STORE_API}elio/download/quote/quotation-file/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_QUOTE_SUMMARY_FILE: `${STORE_API}elio/download/quote/quotation-summary-file/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_QUOTE_FRAME_CONTRACT_FILE: `${STORE_API}elio/download/quote/frame-contract-file/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_QUOTE_TERMS_CONDITION_FILE: `${STORE_API}elio/download/quote/terms-condition-file/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_TECH_CONFIRMED_FILE: `${STORE_API}elio/download/offer/feasibility-confirmation-file/${PLACEHOLDER.ID.OFFER}`,
      DOWNLOAD_QUOTATION_FILE: `${STORE_API}elio/download/quote/quotation-file/${PLACEHOLDER.ID.RFQ}`,
      DOWNLOAD_TECH_CSCC_FILE: `${STORE_API}elio/download/offer/cscc-file/${PLACEHOLDER.ID.OFFER}`,
      DOWNLOAD_CONTRACT_FILE: `${STORE_API}elio/download/offer/contract-file/${PLACEHOLDER.ID.OFFER}`,
      DOWNLOAD_ADDENDUM_FILE: `${STORE_API}elio/download/offer/addendum-file/${PLACEHOLDER.ID.OFFER}`,
      DOWNLOAD_COMMUNICATION_FILE: `${STORE_API}elio/download/offer/`,
      DOWNLOAD_OFFER_FILE: `${STORE_API}elio/download/offer/offer-file/${PLACEHOLDER.ID.OFFER}`,
      DOWNLOAD_RFQ_DATA_FOR_SUPPLIER: `${STORE_API}elio/download-rfq-data-for-supplier/${PLACEHOLDER.ID.RFQ}`,
      B2B_CONTACT_LIST: `${STORE_API}elio-b2b/contacts-with-role`,
      B2B_ROLE_LIST: `${STORE_API}b2b/role`,
      LIST_SUPPORT_USERS: `${STORE_API}elio/super-admin/support-users`,
    },
    POST: {
      CREATE_B2B_SHIPPING_ADDRESS: `${STORE_API}b2b/address/type/shipping`,
      CREATE_B2B_BILLING_ADDRESS: `${STORE_API}b2b/address/type/billing`,
      FETCH_CUSTOMER_ADDRESSES: `${STORE_API}account/list-address`,
      FETCH_CURRENCIES: `${STORE_API}currency`,
      CUSTOMER_ADDRESS: `${STORE_API}account/address`,
      REGISTER_USER: `${STORE_API}account/register`,
      LOGIN_USER: `${STORE_API}account/login`,
      LOGOUT_USER: `${STORE_API}account/logout`,
      RFQ_LISTING_CUSTOMER: `${STORE_API}rfq/get-entities-for-customer`,
      RFQ_LISTING_SALES_EXPERT: `${STORE_API}rfq/get-entities-for-sales-expert`,
      RFQ_LISTING_SALES_MANAGER: `${STORE_API}rfq/get-entities-for-sales-manager`,
      RFQ_LISTING_SUPPLIER: `${STORE_API}rfq/get-entities-for-supplier`,
      RFQ_LISTING_SUPPLIER_EXPERT: `${STORE_API}rfq/get-entities-for-supplier-expert`,
      RFQ_LISTING_SUPPLIER_MANAGER: `${STORE_API}rfq/get-entities-for-supplier-manager`,
      CREATE_RFQ: `${STORE_API}rfq/create`,
      UPSERT_RFQ: `${STORE_API}rfq/upsert`,
      UPDATE_QUOTE: `${STORE_API}rfq-quote/update`,
      UPSERT_OFFER: `${STORE_API}rfq/${PLACEHOLDER.ID.RFQ}/offer/upsert`,
      UPLOAD_CUSTOMER_QUOTE_FILE: `${STORE_API}elio/upload-customer-quote-file/${PLACEHOLDER.ID.RFQ}`,
      UPLOAD_GERBER_FILE: `${STORE_API}elio/upload-gerber-file/${PLACEHOLDER.ID.RFQ}`,
      UPLOAD_SPECIFICATION_FILE: `${STORE_API}elio/upload-specification-file/${PLACEHOLDER.ID.RFQ}`,
      UPLOAD_PANEL_DRAWING_FILE: `${STORE_API}elio/upload-panel-drawing-file/${PLACEHOLDER.ID.RFQ}`,
      UPLOAD_OTHER_FILE: `${STORE_API}elio/upload-other-file/${PLACEHOLDER.ID.RFQ}`,
      UPLOAD_TECH_CONFIRMED_FILE: `${STORE_API}elio/upload-feasibility-confirmation-file/${PLACEHOLDER.ID.OFFER}`,
      UPLOAD_TECH_CSCC_FILE: `${STORE_API}elio/upload-cscc-file/${PLACEHOLDER.ID.OFFER}`,
      UPLOAD_COMMUNICATION_FILE: `${STORE_API}elio/upload-communication-file/${PLACEHOLDER.ID.RFQ}`,
      UPLOAD_CONTRACT_FILE: `${STORE_API}elio/upload-contract-file/${PLACEHOLDER.ID.OFFER}`,
      UPLOAD_ADDENDUM_FILE: `${STORE_API}elio/upload-addendum-file/${PLACEHOLDER.ID.OFFER}`,
      UPLOAD_QUOTATION_FILE_SPECIFIED: `${STORE_API}elio/upload-quotation-file/specified/${PLACEHOLDER.ID.RFQ}`,
      UPLOAD_QUOTATION_FILE_PROPOSED: `${STORE_API}elio/upload-quotation-file/proposed/${PLACEHOLDER.ID.RFQ}`,
      UPLOAD_OFFER_FILE: `${STORE_API}elio/upload-offer-file/${PLACEHOLDER.ID.OFFER}`,
      UPLOAD_FILE_TO_PDF_PARSER: `${STORE_API}rfq/upload-file-for-rfq-generation`,
      REGISTER_DOUBLE_OPT_IN: `${STORE_API}elio/register-confirm`,
      RFQ_SAVE_AS_NEW: `${STORE_API}rfq/save-as-new`,
      RFQ_STATE_CHANGE: `${STORE_API}rfq/transfer/${PLACEHOLDER.ID.RFQ}/state/${PLACEHOLDER.STATE}`,
      RFQ_DELETE: `${STORE_API}rfq/delete/${PLACEHOLDER.ID.RFQ}`,
      RFQ_CANCEL: `${STORE_API}rfq/cancel/`,
      REJECT_RFQ: `${STORE_API}rfq/reject/${PLACEHOLDER.ID.RFQ}`,
      RFQ_CHANGE_REQUEST: `${STORE_API}rfq/request-change/${PLACEHOLDER.ID.RFQ}`,
      RFQ_CHANGED: `${STORE_API}rfq/rfq-changed/${PLACEHOLDER.ID.RFQ}`,
      B2B_EDIT_CONTACT: `${STORE_API}b2b/contact/${PLACEHOLDER.ID.CONTACT}`,
      B2B_REGISTER_CONTACT: `${STORE_API}registration/request-contact-account`,
      SEND_PASSWORD_RECOVERY_MAIL: `${STORE_API}account/recovery-password`,
      RESET_PASSWORD: `${STORE_API}account/recovery-password-confirm`,
      CHANGE_PASSWORD: `${STORE_API}account/change-password`,
      ADD_COMMENT: `${STORE_API}rfq/add-comment/${PLACEHOLDER.ID.RFQ}`,
      REQUEST_CLARIFICATION: `${STORE_API}rfq/request-information/${PLACEHOLDER.ID.RFQ}`,
      SUPPLIER_SALES_EXPERT_COMMENT: `${STORE_API}rfq-offer/add-comment-for-supplier/${PLACEHOLDER.ID.OFFER}`,
      ACCEPT_CONTRACT: `${STORE_API}rfq-offer/accept-contract/${PLACEHOLDER.ID.OFFER}`,
      SUPPLIER_MAKE_OFFER: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}/make-offer`,
      RFQ_LOCK: `${STORE_API}rfq/lock/${PLACEHOLDER.ID.RFQ}`,
      RFQ_UNLOCK: `${STORE_API}rfq/unlock/${PLACEHOLDER.ID.RFQ}`,
      OFFER_LOCK: `${STORE_API}rfq-offer/lock/${PLACEHOLDER.ID.OFFER}`,
      OFFER_UNLOCK: `${STORE_API}rfq-offer/unlock/${PLACEHOLDER.ID.OFFER}`,
      CHECK_PASSWORD: `${STORE_API}elio/check-password`,
      INITIALIZE_PASSWORD: `${STORE_API}elio-b2b/contact-password-activate`,
      OFFERS_LISTING_SUPPLIER: `${STORE_API}rfq-offer/get-entities-for-supplier`,
      OFFERS_LISTING_SUPPLIER_EXPERT: `${STORE_API}rfq-offer/get-entities-for-supplier-expert`,
      OFFERS_LISTING_SALES_EXPERT: `${STORE_API}rfq-offer/get-entities-for-sales-expert`,
      ADD_CUSTOMER_SPECIFIC_COMMENT: `${STORE_API}rfq/add-customer-specific-comment/${PLACEHOLDER.ID.RFQ}`,
      ADD_INTERNAL_COMMENT: `${STORE_API}rfq/add-internal-comment/${PLACEHOLDER.ID.RFQ}`,
      ADD_COMMENT_FOR_SUPPLIER: `${STORE_API}rfq-offer/add-comment-for-supplier/${PLACEHOLDER.ID.OFFER}`,
      ADD_INTERNAL_COMMENT_FOR_SALES_EXPERT: `${STORE_API}rfq/add-internal-comment-for-sales-expert/${PLACEHOLDER.ID.RFQ}`,
      ADD_INTERNAL_COMMENT_FOR_SALES_MANAGER: `${STORE_API}rfq/add-internal-comment-for-sales-manager/${PLACEHOLDER.ID.RFQ}`,
      ADD_INTERNAL_COMMENT_FOR_SUPPLIER_EXPERT: `${STORE_API}rfq/add-internal-comment-for-supplier-expert/${PLACEHOLDER.ID.RFQ}`,
      ADD_INTERNAL_COMMENT_FOR_SUPPLIER_MANAGER: `${STORE_API}rfq/add-internal-comment-for-supplier-manager/${PLACEHOLDER.ID.RFQ}`,
      ADD_COMMENT_FOR_SUPPLIER_EXPERT: `${STORE_API}rfq-offer/add-comment-for-supplier-expert/${PLACEHOLDER.ID.OFFER}`,
      ADD_COMMENT_TO_OFFER: `${STORE_API}rfq/add-comment-to-offers/${PLACEHOLDER.ID.OFFER}`,
      REJECT_OFFER: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}/reject-offer`,
      LOGIN_WITHOUT_PASSWORD: `${STORE_API}elio/super-admin/support-users/${PLACEHOLDER.ID.CUSTOMER}/login`,
      LIST_SUPPLIERS: `${STORE_API}elio/list-suppliers`,
      ASSIGN_TO_ME: `${STORE_API}rfq/${PLACEHOLDER.ID.RFQ}/assign-to-me`,
      PUBLISH_OFFERS: `${STORE_API}rfq/${PLACEHOLDER.ID.RFQ}/submit-offers`,
      RECALL_OFFERS: `${STORE_API}rfq/${PLACEHOLDER.ID.RFQ}/recall-offers`,
      SET_NEW_DUE_DATE_FOR_OFFERS: `${STORE_API}rfq/${PLACEHOLDER.ID.RFQ}/offers/change-valid-until`,
      SEND_OFFER_TO_REWORK: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}/send-to-rework`,
      OFFER_CONTRACTS_FOR_APPROVAL: `${STORE_API}rfq/offer-contract-for-approval/${PLACEHOLDER.ID.RFQ}`,
      GENERATE_CONTRACT: `${STORE_API}elio/generate-file/contract`,
      GENERATE_QUOTE: `${STORE_API}elio/generate-file/quote`,
      START_CONTRACTING: `${STORE_API}rfq/start-contracting/${PLACEHOLDER.ID.RFQ}`,
      CREATE_QUOTE: `${STORE_API}rfq/create-quote/${PLACEHOLDER.ID.RFQ}`,
      SEND_QUOTE: `${STORE_API}rfq/send-quote/${PLACEHOLDER.ID.RFQ}`,
      SUBMIT_QUOTA_ALLOCATION: `${STORE_API}rfq-offer/submit-quota-allocation/${PLACEHOLDER.ID.RFQ}`,
      REOPEN_OFFERS: `${STORE_API}rfq/${PLACEHOLDER.ID.RFQ}/reopen-offers`,
      UPDATE_ACTIVE_FOR_NEXT_PHASE: `${STORE_API}rfq/${PLACEHOLDER.ID.RFQ}/offer/update-active-for-next-phase-values`,
      SELECT_OFFER: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}/select`,
      GET_DASHBOARD_DATA: `${STORE_API}elio/get-dashboard-data`,
      VALIDATE_RFQ: `${STORE_API}rfq/is-valid/${PLACEHOLDER.ID.RFQ}`,
      SELECT_SUPPLIERS: `${STORE_API}rfq/select-suppliers/${PLACEHOLDER.ID.RFQ}`,
      CALCULATE_INSTANT_QUOTE: `${STORE_API}rfq/calculate-instant-quote/${PLACEHOLDER.ID.RFQ}`,
      REWORK_QUOTE: `${STORE_API}rfq/rework-quote/${PLACEHOLDER.ID.RFQ}`,
      NEW_OFFERS_NEEDED: `${STORE_API}rfq/new-offers-needed/${PLACEHOLDER.ID.RFQ}`,
      SELECT_SUPPLIERS_FOR_INSTANT_QUOTE: `${STORE_API}rfq/select-suppliers-for-instant-quote/${PLACEHOLDER.ID.RFQ}`,
    },
    PATCH: {
      MODIFY_CUSTOMERS_ADDRESS: `${STORE_API}account/address/`,
      SET_CUSTOMERS_SHIPPING_ADDRESS: `${STORE_API}account/address/default-shipping/`,
      SET_CUSTOMERS_BILLING_ADDRESS: `${STORE_API}account/address/default-billing/`,
    },
    PUT: {
      MODIFY_B2B_SHIPPING_ADDRESS: `${STORE_API}b2b/address/${PLACEHOLDER.ID.ADDRESS}/type/shipping`,
      MODIFY_B2B_BILLING_ADDRESS: `${STORE_API}b2b/address/${PLACEHOLDER.ID.ADDRESS}/type/billing`,
      UPDATE_B2B_CONTACT: `${STORE_API}b2b/contact/${PLACEHOLDER.ID.CONTACT}`,
    },
    DELETE: {
      DELETE_B2B_ADDRESS: `${STORE_API}b2b/address/${PLACEHOLDER.ID.ADDRESS}`,
      DELETE_CUSTOMER_ADDRESS: `${STORE_API}account/address/`,
      DELETE_B2B_CONTACT: `${STORE_API}b2b/contact/${PLACEHOLDER.ID.CONTACT}`,
      DELETE_OFFER: `${STORE_API}rfq-offer/${PLACEHOLDER.ID.OFFER}`,
    },
  },
  HEADERS: {
    KEYS: {
      ACCESS_KEY: 'sw-access-key',
      CONTEXT_TOKEN: 'sw-context-token',
      CONTEXT_TYPE: 'Context-Type',
    },
    VALUE: {
      ACCESS_KEY: process.env.VUE_APP_SW_ACCESS_KEY,
      DUMMY_CONTEXT_TOKEN: 'Dummy content.',
      CONTEXT_TYPE: 'application/json',
    },
  },
  COMMANDS: {
    path: null,
    body: {},
    targets: {
      emitter: null,
      refresh: null,
      data: null,
    },
    showProcessingStatus: true,
    isPreloadDependency: false,
    forceRefresh: false,
    type: null,
  },
  TYPES: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
    END_COMMAND_CHAIN: 'endCommandChain',
  },
}
