import { KEYS_REGISTRATION } from '@/constants/objectKeys/registration'
import { DEPENDENCIES } from '@/constants/constants'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'

export default {
  props: {
    countriesHashmap: {
      type: Map,
      default: new Map(),
    },
  },
  data() {
    return {
      [DEPENDENCIES.COUNTRIES]: [],
    }
  },
  watch: {
    [DEPENDENCIES.COUNTRIES]() {
      this.createCountriesOptionsMixin()
    },
  },
  methods: {
    setCountriesTranslationMixin(data) {
      this.$data[KEYS_GENERAL.COUNTRIES_HASHMAP] = new Map()
      data.elements.forEach((country) => {
        this.$data[KEYS_GENERAL.COUNTRIES_HASHMAP].set(
          country.id,
          country.translated.name,
        )
      })
    },
    getCountryTranslationMixin: function (countryId) {
      if (this.$props.countriesHashmap) {
        return this.$props.countriesHashmap.get(countryId)
      } else {
        return this.$data[KEYS_GENERAL.COUNTRIES_HASHMAP].get(countryId)
      }
    },
    insertCountriesOptionsMixin: function () {
      if (
        this.$data[KEYS_GENERAL.FORM] &&
        this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.COUNTRY] &&
        this.$data[KEYS_GENERAL.COUNTRIES_OPTIONS]
      ) {
        this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.COUNTRY].options =
          this.$data[KEYS_GENERAL.COUNTRIES_OPTIONS]
      }
    },
    createCountriesOptionsMixin: function () {
      if (
        this.$data[KEYS_GENERAL.FORM] &&
        this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.COUNTRY] &&
        this.$store.getters.countries !== null
      ) {
        let countries = this.$store.getters.countries

        if (countries.elements) {
          let options = [
            {
              label:
                this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.COUNTRY]
                  .messages.placeholder,
            },
          ]
          countries.elements = countries.elements.sort((a, b) =>
            a.name > b.name ? 1 : -1,
          )
          this.$data[KEYS_GENERAL.COUNTRIES_OPTIONS] = options.concat(
            countries.elements.map((country) => {
              return {
                label: country.name,
                value: country.id,
              }
            }),
          )
        }
      }
    },
  },
}
