import { ACTION_ANCHORS } from '@/constants/anchorTags/actionAnchorTags'

const PREFIX = 'rfqAction'

export const RFQ_ACTIONS_VISIBILITY = {
  DETAILS_RIGHT: 0,
  DROPDOWN: 1,
  DETAILS_TOP: 2,
  DETAILS_RIGHT_ACTIONS_DROPDOWN: 4,
}
export const RFQ_ACTIONS = {
  LOCK_RFQ: {
    VALUE: `${PREFIX}LockRFQ`,
    LABEL: 'Lock RfQ',
    COLOR: 'light',
    VARIANT: null,
    ANCHOR: ACTION_ANCHORS.LOCK_RFQ,
  },
  UNLOCK_RFQ: {
    VALUE: `${PREFIX}UnLockRFQ`,
    LABEL: 'Lock RfQ',
    COLOR: 'light',
    VARIANT: null,
    ANCHOR: ACTION_ANCHORS.UNLOCK_RFQ,
  },
  RFQ_INFO_DROPDOWN: {
    VALUE: `${PREFIX}RfqInfoDropdown`,
    LABEL: 'Other actions',
    COLOR: 'light',
    VARIANT: null,
    isDropdownButton: true,
    ANCHOR: ACTION_ANCHORS.RFQ_INFO_DROPDOWN,
  },
  EDIT_DRAFT: {
    VALUE: `${PREFIX}EditDraft`,
    LABEL: 'Edit draft',
    COLOR: 'primary',
    VARIANT: null,
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT,
    ],
    ANCHOR: ACTION_ANCHORS.EDIT_DRAFT,
  },
  DELETE_DRAFT: {
    VALUE: `${PREFIX}DeleteDraft`,
    LABEL: 'Delete draft',
    COLOR: 'danger',
    VARIANT: 'outline',
    DROPDOWN: {
      COLOR: 'danger',
    },
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT,
    ],
    ANCHOR: ACTION_ANCHORS.DELETE_DRAFT,
  },
  SWITCH_TO_SUPPORT_USER: {
    VALUE: `${PREFIX}SwitchToSupportUser`,
  },
  VIEW_DETAILS: {
    VALUE: `${PREFIX}ViewDetails`,
    LABEL: 'View Details',
    COLOR: 'dark',
    VARIANT: 'outline',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DROPDOWN],
    ANCHOR: ACTION_ANCHORS.VIEW_DETAILS,
  },
  CANCEL_RFQ: {
    VALUE: `${PREFIX}CancelRFQ`,
    LABEL: 'Cancel RfQ',
    COLOR: 'danger',
    VARIANT: 'outline',
    DROPDOWN: {
      COLOR: 'danger',
    },
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN,
    ],
    ANCHOR: ACTION_ANCHORS.CANCEL_RFQ,
  },
  COPY_AND_CREATE_NEW: {
    VALUE: `${PREFIX}CopyAndCreateNew`,
    LABEL: 'Copy & create new',
    COLOR: 'dark',
    VARIANT: 'outline',
    DROPDOWN: {
      DIVIDER: true,
    },
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN,
    ],
    ANCHOR: ACTION_ANCHORS.COPY_AND_CREATE_NEW,
  },
  REQUEST_CHANGE: {
    VALUE: `${PREFIX}RequestChange`,
    LABEL: 'Request change',
    COLOR: 'dark',
    VARIANT: 'outline',
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN,
    ],
    ANCHOR: ACTION_ANCHORS.REQUEST_CHANGE,
  },
  PROVIDE_RFQ_INFORMATION: {
    VALUE: `${PREFIX}ProvideRfQInformation`,
    LABEL: 'Provide information',
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.PROVIDE_RFQ_INFORMATION,
  },
  PREPARE_PROVIDE_RFQ_INFORMATION: {
    VALUE: `${PREFIX}PrepareProvideRfQInformation`,
    LABEL: 'Provide information',
    COLOR: 'primary',
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT,
    ],
    ANCHOR: ACTION_ANCHORS.PREPARE_PROVIDE_RFQ_INFORMATION,
  },
  ACCEPT_QUOTE: {
    VALUE: `${PREFIX}AcceptQuote`,
    LABEL: 'Accept quote',
    COLOR: 'dark',
    VARIANT: 'outline',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    ANCHOR: ACTION_ANCHORS.ACCEPT_QUOTE,
  },
  VIEW_REJECTION: {
    VALUE: `${PREFIX}ViewRejection`,
    LABEL: 'View rejection',
    COLOR: 'dark',
    VARIANT: 'outline',
    DROPDOWN: {
      DIVIDER: true,
    },
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT,
    ],
    ANCHOR: ACTION_ANCHORS.VIEW_REJECTION,
  },
  VIEW_CHANGE_REQUEST: {
    VALUE: `${PREFIX}ViewChangeRequest`,
    LABEL: 'View change request',
    COLOR: 'dark',
    VARIANT: 'outline',
    DROPDOWN: {
      DIVIDER: true,
    },
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT,
    ],
    ANCHOR: ACTION_ANCHORS.VIEW_CHANGE_REQUEST,
  },
  ORDER_SAMPLES: {
    VALUE: `${PREFIX}OrderSamples`,
    LABEL: 'Order samples',
    COLOR: 'dark',
    VARIANT: 'outline',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    ANCHOR: ACTION_ANCHORS.ORDER_SAMPLES,
  },
  DOWNLOAD_QUOTE: {
    VALUE: `${PREFIX}DownloadQuote`,
    LABEL: 'Download quote',
    COLOR: 'dark',
    VARIANT: 'outline',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    ANCHOR: ACTION_ANCHORS.DOWNLOAD_QUOTE,
  },
  DECLINE_QUOTE: {
    VALUE: `${PREFIX}DeclineQuote`,
    LABEL: 'Decline quote',
    COLOR: 'dark',
    VARIANT: 'outline',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    ANCHOR: ACTION_ANCHORS.DECLINE_QUOTE,
  },
  REJECT_RFQ: {
    VALUE: `${PREFIX}RejectRfQ`,
    LABEL: `Reject RfQ`,
    COLOR: 'danger',
    DROPDOWN: {
      COLOR: 'danger',
    },
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN,
    ],
    ANCHOR: ACTION_ANCHORS.REJECT_RFQ,
  },
  CLARIFICATION_REQUEST: {
    VALUE: `${PREFIX}ClarificationRequest`,
    LABEL: `Request clarification`,
    COLOR: 'secondary',
    DROPDOWN: {
      COLOR: 'secondary',
    },
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN,
    ],
    ANCHOR: ACTION_ANCHORS.CLARIFICATION_REQUEST,
  },
  REJECT_OFFER: {
    VALUE: `${PREFIX}RejectOffer`,
    LABEL: `Reject RfQ`,
    COLOR: 'danger',
    DROPDOWN: {
      COLOR: 'danger',
    },
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN,
    ],
    ANCHOR: ACTION_ANCHORS.REJECT_OFFER,
  },
  ASSIGN_TO: {
    VALUE: `${PREFIX}AssignTo`,
    LABEL: 'Assign to…',
    COLOR: 'dark',
    VARIANT: 'outline',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_TOP],
    ANCHOR: ACTION_ANCHORS.ASSIGN_TO,
  },
  ASSIGN_TO_ME: {
    VALUE: `${PREFIX}AssignToMe`,
    LABEL: 'Assign to me',
    COLOR: 'dark',
    VARIANT: 'outline',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_TOP],
    ANCHOR: ACTION_ANCHORS.ASSIGN_TO_ME,
  },
  CONTACT: {
    VALUE: `${PREFIX}Contact`,
    LABEL: 'Contact copperdot',
    COLOR: 'primary',
    VARIANT: 'outline',
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN,
    ],
    ANCHOR: ACTION_ANCHORS.CONTACT,
  },
  SAVE_AS_DRAFT: {
    VALUE: `${PREFIX}SaveAsDraft`,
    LABEL: 'Save as draft',
    COLOR: 'light',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    ANCHOR: ACTION_ANCHORS.SAVE_AS_DRAFT,
  },
  SUBMIT_DRAFT: {
    VALUE: `${PREFIX}SubmitDraft`,
    LABEL: 'Submit',
    COLOR: 'primary',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    ANCHOR: ACTION_ANCHORS.SUBMIT_DRAFT,
  },
  SUBMIT_OFFER: {
    VALUE: `${PREFIX}SubmitOffer`,
    LABEL: 'Submit quote',
    COLOR: 'primary',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    ANCHOR: ACTION_ANCHORS.SUBMIT_OFFER,
  },
  QUIZ_BACK_SALES_EXPERT: {
    VALUE: `${PREFIX}QuizBackSalesExpert`,
    LABEL: 'Quiz back Sales Expert',
    COLOR: 'primary',
    VARIANT: 'outline',
    WIP: true,
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DROPDOWN],
    ANCHOR: ACTION_ANCHORS.QUIZ_BACK_SALES_EXPERT,
  },
  REQUEST_OFFER: {
    VALUE: `${PREFIX}RequestOffer`,
    LABEL: 'Save and request offer',
    COLOR: 'primary',
    VARIANT: 'outline',
    PLACEHOLDER: 'Offer due date for Supplier',
    DATE_PICKER: true,
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    ANCHOR: ACTION_ANCHORS.REQUEST_OFFER,
  },
  SAVE_SUPPLIER_RFQ: {
    VALUE: `${PREFIX}SaveSupplierRFQ`,
    LABEL: 'Save RFQ',
    COLOR: 'primary',
    VARIANT: 'outline',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    ANCHOR: ACTION_ANCHORS.SAVE_SUPPLIER_RFQ,
  },
  ENTER_QUOTE: {
    VALUE: `${PREFIX}EnterQuote`,
    LABEL: 'Enter quote',
    COLOR: 'primary',
    VARIANT: 'outline',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DROPDOWN],
    ANCHOR: ACTION_ANCHORS.ENTER_QUOTE,
  },
  DOWNLOAD_DATA: {
    VALUE: `${PREFIX}DownloadData`,
    LABEL: 'Download request',
    COLOR: 'primary',
    VARIANT: 'outline',
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN,
    ],
    ANCHOR: ACTION_ANCHORS.DOWNLOAD_DATA,
  },
  OFFER_DUE: {
    VALUE: `${PREFIX}RFQOfferDue`,
    LABEL: 'Quote due',
    WIP: true,
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_TOP],
    ANCHOR: ACTION_ANCHORS.OFFER_DUE,
  },
  COMMUNICATION: {
    VALUE: `${PREFIX}RFQCommunication`,
    LABEL: 'Send',
    ANCHOR: ACTION_ANCHORS.COMMUNICATION,
  },
  ACCEPT_CONTRACT: {
    VALUE: `${PREFIX}AcceptContract`,
    LABEL: 'Accept nomination',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.ACCEPT_CONTRACT,
  },
  ENTER_EDIT_MODE: {
    VALUE: `${PREFIX}EnterEditMode`,
    LABEL: 'Enter quote',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.ENTER_EDIT_MODE,
  },
  CANCEL_EDIT_MODE: {
    VALUE: `${PREFIX}CancelEditMode`,
    LABEL: 'Cancel',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    VARIANT: 'outline',
    ANCHOR: ACTION_ANCHORS.CANCEL_EDIT_MODE,
  },
  SAVE_EDIT_MODE: {
    VALUE: `${PREFIX}SaveEditMode`,
    LABEL: 'Save',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'secondary',
    ANCHOR: ACTION_ANCHORS.SAVE_EDIT_MODE,
  },
  ADJUST_DELIVERY_LOCATION: {
    VALUE: `${PREFIX}AdjustDeliveryLocation`,
    LABEL: 'Copy & create new',
    VISIBILITY: [
      RFQ_ACTIONS_VISIBILITY.DROPDOWN,
      RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT,
    ],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.ADJUST_DELIVERY_LOCATION,
  },
  USE_EXISTING_DRAFT: {
    VALUE: `${PREFIX}UseExistingDraft`,
    LABEL: 'Use existing draft',
    COLOR: 'primary',
    VARIANT: 'outline',
    ANCHOR: ACTION_ANCHORS.USE_EXISTING_DRAFT,
  },
  CREATE_NEW_RFQ: {
    VALUE: `${PREFIX}CreateNewRFQ`,
    LABEL: 'Create new RfQ',
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.CREATE_NEW_RfQ,
  },
  ADD_SUPPLIER: {
    VALUE: `${PREFIX}AddSupplier`,
    LABEL: 'Add Supplier',
    VISIBILITY: [],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.ADD_SUPPLIER,
  },
  PRICING_DELIVERY_STRATEGY: {
    VALUE: `${PREFIX}PricingDeliveryStrategy`,
    LABEL: 'Save',
    VISIBILITY: [],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.PRICING_DELIVERY_STRATEGY,
  },
  PREPARE_SET_NEW_DUE_DATE: {
    VALUE: `${PREFIX}PrepareSetNewDueDate`,
    LABEL: 'Set new due date',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN],
    COLOR: 'secondary',
    ANCHOR: ACTION_ANCHORS.PREPARE_SET_NEW_DUE_DATE,
  },
  PREPARE_RECALL_RFQ: {
    VALUE: `${PREFIX}PrepareRecallRfQ`,
    LABEL: 'Recall',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN],
    COLOR: 'danger',
    DROPDOWN: {
      COLOR: 'danger',
    },
    ANCHOR: ACTION_ANCHORS.RECALL_RFQ,
  },
  PREPARE_REQUEST_REWORK_RFQ: {
    VALUE: `${PREFIX}PrepareRequestReworkRfQ`,
    LABEL: 'Request single supplier to rework',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN],
    COLOR: 'secondary',
    ANCHOR: ACTION_ANCHORS.REQUEST_REWORK_RFQ,
  },
  PREPARE_ADD_NEW_SUPPLIER: {
    VALUE: `${PREFIX}AddNewSupplier`,
    LABEL: 'Add new supplier',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN],
    COLOR: 'secondary',
    ANCHOR: ACTION_ANCHORS.PREPARE_ADD_NEW_SUPPLIER,
  },
  CANCEL_DRAFT: {
    VALUE: `${PREFIX}CancelDraft`,
    LABEL: 'Cancel',
    VISIBILITY: [],
    COLOR: 'danger',
    VARIANT: 'outline',
    ANCHOR: ACTION_ANCHORS.CANCEL_DRAFT,
  },
  DEFINE_RFQ_SCENARIOS_FOR_SUPPLIER: {
    VALUE: `${PREFIX}DefineRfQScenarioForSupplier`,
    LABEL: 'Define RfQ Scenarios for Supplier',
    VISIBILITY: [],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.DEFINE_RFQ_SCENARIOS_FOR_SUPPLIER,
  },
  REOPEN_OFFERS: {
    VALUE: `${PREFIX}ReopenOffers`,
    LABEL: 'Reopen RfQ',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'light',
    ANCHOR: ACTION_ANCHORS.REOPEN_OFFERS,
  },
  PREPARE_COQ: {
    VALUE: `${PREFIX}PrepareCoq`,
    LABEL: 'Prepare COQ',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.PREPARE_COQ,
  },
  PREPARE_CONTRACTS: {
    VALUE: `${PREFIX}PrepareContracts`,
    LABEL: 'Prepare contracts',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.PREPARE_CONTRACTS,
  },
  SEND_OUT_CONTRACTS: {
    VALUE: `${PREFIX}SendOutContracts`,
    LABEL: 'Submit to Sales Expert',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.SEND_OUT_CONTRACTS,
  },
  VALIDATE_RFQ: {
    VALUE: `${PREFIX}ValidateRfQ`,
    LABEL: 'Validate RfQ',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.VALIDATE_RFQ,
  },
  SELECT_SUPPLIERS: {
    VALUE: `${PREFIX}SelectSuppliers`,
    LABEL: 'Request supplier quote',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.SELECT_SUPPLIERS,
  },
  CREATE_QUOTE: {
    VALUE: `${PREFIX}CreateQuote`,
    LABEL: 'Submit for approval',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.CREATE_QUOTE,
  },
  SEND_QUOTE: {
    VALUE: `${PREFIX}SendQuote`,
    LABEL: 'Submit to customer',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.SEND_QUOTE,
  },
  GENERATE_QUOTE_DOCUMENT: {
    VALUE: `${PREFIX}GenerateQuoteDocument`,
    LABEL: 'Save and generate quote',
    VISIBILITY: [],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.GENERATE_QUOTE_DOCUMENT,
  },
  PREPARE_REQUEST_SUPPLIER_EXPERT_TO_REWORK: {
    VALUE: `${PREFIX}PrepareRequestSupplierExpertToRework`,
    LABEL: 'Request supplier offer update',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'light',
    ANCHOR: ACTION_ANCHORS.PREPARE_REQUEST_SUPPLIER_EXPERT_TO_REWORK,
  },
  REQUEST_SUPPLIER_EXPERT_TO_REWORK: {
    VALUE: `${PREFIX}RequestSupplierExpertToRework`,
    LABEL: 'Request rework',
    VISIBILITY: [],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.REQUEST_SUPPLIER_EXPERT_TO_REWORK,
  },
  PREPARE_INSTANT_QUOTE: {
    VALUE: `${PREFIX}PrepareInstantQuote`,
    LABEL: 'Instant quote preparation',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT_ACTIONS_DROPDOWN],
    COLOR: 'secondary',
    ANCHOR: ACTION_ANCHORS.PREPARE_INSTANT_QUOTE,
  },
  BEGIN_SUPPLIER_SELECTION: {
    VALUE: `${PREFIX}BeginSupplierSelection`,
    LABEL: 'Begin Supplier selection',
    VISIBILITY: [RFQ_ACTIONS_VISIBILITY.DETAILS_RIGHT],
    COLOR: 'primary',
    ANCHOR: ACTION_ANCHORS.BEGIN_SUPPLIER_SELECTION,
  },
}

export const RFQ_ACTIONS_FOR_LISTING = [RFQ_ACTIONS.ENTER_QUOTE.VALUE]

export const RFQ_ACTIONS_FOR_INSTANT_QUOTE = [
  RFQ_ACTIONS.BEGIN_SUPPLIER_SELECTION.VALUE,
]
export const RFQ_ACTIONS_FOR_NORMAL_QUOTE = [
  RFQ_ACTIONS.ENTER_QUOTE.VALUE,
  RFQ_ACTIONS.PREPARE_CONTRACTS.VALUE,
  RFQ_ACTIONS.PREPARE_REQUEST_SUPPLIER_EXPERT_TO_REWORK.VALUE,
]

export const RFQ_ACTIONS_FOR_EDIT_MODE = [
  RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
  RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
  RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
  RFQ_ACTIONS.CREATE_QUOTE.VALUE,
]

export const RFQ_ACTIONS_FOR_READ_ONLY_MODE = [
  RFQ_ACTIONS.PREPARE_REQUEST_SUPPLIER_EXPERT_TO_REWORK.VALUE,
]

export const RFQ_ACTIONS_BLACKLIST_ON_LOCK = [
  RFQ_ACTIONS.DELETE_DRAFT.VALUE,
  RFQ_ACTIONS.EDIT_DRAFT.VALUE,
  RFQ_ACTIONS.ENTER_EDIT_MODE.VALUE,
  RFQ_ACTIONS.CANCEL_EDIT_MODE.VALUE,
  RFQ_ACTIONS.SAVE_EDIT_MODE.VALUE,
  RFQ_ACTIONS.SUBMIT_OFFER.VALUE,
  RFQ_ACTIONS.ACCEPT_QUOTE.VALUE,
  RFQ_ACTIONS.ENTER_QUOTE.VALUE,
  RFQ_ACTIONS.REJECT_OFFER.VALUE,
  RFQ_ACTIONS.PREPARE_CONTRACTS.VALUE,
  RFQ_ACTIONS.REOPEN_OFFERS.VALUE,
]
