import { REQUIRE_ACTION_STATES, STATES } from '@/constants/states'

export default {
  methods: {
    getSidebarTitleMixin(data) {
      if (data.NAV_TITLE) {
        return data.NAV_TITLE
      } else {
        return data.TITLE
      }
    },
    gotSidebarCounterMixin(target) {
      return STATES[target]
    },
    getSidebarCounterMixin(target, filterRequiredAction = false) {
      let sum = 0
      if (this.$store.getters.sidebarData !== null) {
        const sidebarData = this.$store.getters.sidebarData.states
        const statusGroup = STATES[target]
        if (statusGroup) {
          statusGroup.forEach((status) => {
            if (
              sidebarData[status] &&
              (!filterRequiredAction ||
                REQUIRE_ACTION_STATES[
                  this.$store.getters.getActivePermissionGroup
                ].includes(status))
            ) {
              sum += sidebarData[status]
            }
          })
        }
      }
      return sum
    },
  },
}
