<template>
  <CDropdown
    variant="nav-item"
    @click.prevent=""
    v-if="
      this.$store.getters.getActivePermissionGroup !==
      PERMISSION_GROUPS.NO_PERMISSION.VALUE
    "
  >
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CRow>
        <CCol>
          <CRow>
            <CCol
              class="e-account-company"
              :id="
                getHeaderUserInfoAnchorTagMixin(INFO_ANCHORS.ADDRESS.COMPANY)
              "
              :data-backend-value="companyName"
              ><strong>{{ companyName }}</strong></CCol
            >
          </CRow>
          <CRow>
            <CCol
              class="e-account-name"
              :id="getHeaderUserInfoAnchorTagMixin(INFO_ANCHORS.USER.NAME)"
              :data-backend-value="this.getUserNameMixin()"
            >
              {{ this.getUserNameMixin() }}
              <CIcon
                v-if="!this.gotLastLoginDateMixin()"
                icon="cilChevronBottom"
              >
              </CIcon>
            </CCol>
          </CRow>
          <CRow v-if="this.gotLastLoginDateMixin()">
            <CCol
              class="e-account-last-login"
              :id="
                getHeaderUserInfoAnchorTagMixin(INFO_ANCHORS.USER.LAST_LOGIN)
              "
              :data-backend-value="this.getLastLoginMixin(false)"
              :data-frontend-value="this.getLastLoginMixin()"
            >
              {{ this.getLastLoginMixin() }}
              <CIcon icon="cilChevronBottom"></CIcon>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0 e-header-dropdown">
      <!--<CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem>
        <router-link to="notifications" class="nav-link">
          Notifications
          <CBadge color="info-gradient" class="ms-auto">{{
            itemsCount
          }}</CBadge></router-link
        >
      </CDropdownItem>-->
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader>
      <!--<CDropdownItem>
        <router-link :to="{ name: ROUTER_DATA.SHARED.PROFILE.NAME }" class="nav-link">
          {{ ROUTER_DATA.SHARED.PROFILE.TITLE }}</router-link
        >
      </CDropdownItem>-->
      <CDropdownItem v-if="this.canAccessAddressManagement()">
        <router-link
          :to="{
            name: `${this.$store.getters.getActivePermissionGroup}${ROUTER_DATA.SHARED.ADDRESS_MANAGEMENT.NAME}`,
          }"
          :id="
            getHeaderDropdownAnchorTagMixin(
              HEADER_DROPDOWN_ANCHORS.ADDRESS_MANAGEMENT,
            )
          "
          class="nav-link"
        >
          {{ ROUTER_DATA.SHARED.ADDRESS_MANAGEMENT.TITLE }}
        </router-link>
      </CDropdownItem>
      <CDropdownItem>
        <router-link
          :to="{
            name: `${this.$store.getters.getActivePermissionGroup}${ROUTER_DATA.SHARED.CHANGE_PASSWORD.NAME}`,
          }"
          :id="
            getHeaderDropdownAnchorTagMixin(
              HEADER_DROPDOWN_ANCHORS.CHANGE_PASSWORD,
            )
          "
          class="nav-link"
        >
          {{ ROUTER_DATA.SHARED.CHANGE_PASSWORD.TITLE }}
        </router-link>
      </CDropdownItem>
      <CDropdownItem v-if="this.canAccessUserManagement()">
        <router-link
          :to="{
            name: `${this.$store.getters.getActivePermissionGroup}${ROUTER_DATA.SHARED.USER_MANAGEMENT.NAME}`,
          }"
          :id="
            getHeaderDropdownAnchorTagMixin(
              HEADER_DROPDOWN_ANCHORS.USER_MANAGEMENT,
            )
          "
          class="nav-link"
        >
          {{ ROUTER_DATA.SHARED.USER_MANAGEMENT.TITLE }}
        </router-link>
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem>
        <div
          @click="executeLogout()"
          class="nav-link"
          :id="getHeaderDropdownAnchorTagMixin(HEADER_DROPDOWN_ANCHORS.LOGOUT)"
        >
          Logout
        </div>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import axiosMixins from '@/mixins/axiosMixins'
import userMixins from '@/mixins/userMixins'
import { ROUTER_DATA } from '@/constants/routerData'
import store from '@/store'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { USER_STATUS } from '@/constants/userStatus'
import { HEADER_DROPDOWN_ANCHORS } from '@/constants/anchorTags/headerDropdownAnchorTags'
import anchorTagsMixins from '@/mixins/anchorTagsMixins'
import { INFO_ANCHORS } from '@/constants/anchorTags/infoAnchorTags'

export default {
  name: 'AppHeaderDropdownAccnt',
  mixins: [axiosMixins, userMixins, anchorTagsMixins],
  setup() {
    return {
      itemsCount: 0,
    }
  },
  data() {
    return {
      ROUTER_DATA,
      currentUserData: null,
      PERMISSION_GROUPS,
      HEADER_DROPDOWN_ANCHORS,
    }
  },
  methods: {
    executeLogout() {
      if (this.$store.getters.getSuperAdminContextToken) {
        this.$store.commit('switchToSuperAdmin')
      } else {
        if (this.$route.name === ROUTER_DATA.SHARED.RFQ_EDIT.NAME) {
          store.commit('showRFQEditorModal', true)
        } else if (this.$route.name === ROUTER_DATA.SHARED.RFQ_NEW.NAME) {
          store.commit('showRFQEditorDeleteModal', true)
        } else {
          store.commit('triggerLogout')
        }
      }
    },
    canAccessAddressManagement() {
      return USER_STATUS.ADDRESS_MANAGEMENT_ACCESS.includes(
        this.$store.getters.getActivePermissionGroup,
      )
    },
    canAccessUserManagement() {
      return (
        USER_STATUS.USER_MANAGEMENT_ACCESS.includes(
          this.$store.getters.getActivePermissionGroup,
        ) && this.$store.getters.isDebtor
      )
    },
  },
  watch: {
    '$this.store.getters.isLoggedIn': {
      handler() {
        if (this.$store.getters.isLoggedIn === true) {
          this.axiosUpdateCurrentUserMixin()
        }
      },
      deep: true,
    },
  },
  computed: {
    INFO_ANCHORS() {
      return INFO_ANCHORS
    },
    companyName: function () {
      if (this.$store.getters.currentUserData !== null) {
        return this.$store.getters.currentUserData.company
      }
      return ''
    },
  },
}
</script>

<style scoped>
.nav-link {
  cursor: pointer;
}

.dropdown-menu {
  padding-bottom: 0;
}

.dropdown-divider {
  margin: 0;
}

.e-account-company {
  font-size: 14px;
  line-height: 19px;
}

.e-account-name,
.e-account-last-login {
  font-size: 11px;
  line-height: 15px;
  margin-right: 5px;
}
</style>
