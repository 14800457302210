export const TABLE_ANCHORS = {
  RFQ_LISTING: 'RfQListing',
  USER_MANAGEMENT: 'UserManagement',
  DELIVERY_LOCATION: 'DeliveryLocation',
  SUPPORT_USERS: 'SupportUsers',
  LOT_SIZES: 'LotSizes',
  LIST_SUPPLIERS: 'ListSuppliers',
  LOGISTIC_COSTS: 'LogisticCosts',
  MASS_PRODUCTION: 'MassProduction',
  PCB_COSTS: 'PcbCosts',
  DELIVERY_QUANTITY: 'DeliveryQuantity',
}
