const ANCHOR_LIST = 'anchorList'
const FORM = 'form'
const FORM_COPY = 'formCopy'
const FORM_OFFER = 'formOffer'
const DEPENDENCIES = 'dependencies'
const DASHBOARD_DATA = 'dashboardData'
const IMPORT_DATA = 'importData'
const IMPORT_OFFER_DATA = 'importOfferData'
const COUNTRIES_OPTIONS = 'countriesOptions'
const CURRENCIES_OPTIONS = 'currenciesOptions'
const SALUTATIONS_OPTIONS = 'salutationsOptions'
const INNER_LAYERS = 'innerLayers'
const SIMPLIFIED_INNER_LAYERS_EDITOR = 'simplifiedInnerLayersEditor'
const SUB_ITEM_1 = 'Subitem1'
const SUB_ITEM_2 = 'Subitem2'
const SUB_ITEM_3 = 'Subitem3'
const SUB_ITEM_4 = 'Subitem4'
const SUB_ITEM_5 = 'Subitem5'
const CURRENT_PASSWORD = 'currentPassword'
const READ_ONLY_MODE = 'isReadOnlyMode'
const ERROR_SUM = 'errorSum'
const SHOW_VALIDATION_ERRORS = 'showValidationErrors'
const ERROR_COUNTERS = 'errorCounters'
const SUPPLIER_FORMS = 'SupplierForms'
const SUPPLIER_LISTING = 'SupplierListing'
const SUPPLIER_SELECTION = 'SupplierSelection'
const SMART_TABLE_LAYOUT = 'smartTableLayout'
const REMOTE_EMAIL_VALIDATION = 'remoteEmailValidation'
const LAST_BACKEND_RESPONSE = 'lastBackendResponse'
const SCENARIOS = 'scenarios'
const CURRENT_INDEX = 'currentIndex'
const GLOBAL = 'global'
const CUSTOMER_SCENARIOS = 'customerScenarios'
const INDIVIDUAL_SCENARIOS = 'individualScenarios'
const COUNTRIES_HASHMAP = 'countriesHashmap'
const SUPPLIER_NAME_HASHMAP = 'supplierNameHashmap'
const ACTIVE_SUPPLIER_IDS = 'activeSupplierIds'
const GOT_PROPOSED_PANEL = `gotProposedPanel`
const INSTANT_QUOTE_TYPE = `formInstantQuoteType`
const OVERRIDE_VALIDATION_COMPONENTS = 'overrideValidationComponents'
const MOV_MOQ_DATA = 'calculatedMovMoqData'
export const KEYS_GENERAL = {
  ANCHOR_LIST,
  FORM,
  FORM_COPY,
  FORM_OFFER,
  DEPENDENCIES,
  DASHBOARD_DATA,
  IMPORT_DATA,
  SALUTATIONS_OPTIONS,
  COUNTRIES_OPTIONS,
  CURRENCIES_OPTIONS,
  INNER_LAYERS,
  SIMPLIFIED_INNER_LAYERS_EDITOR,
  SUB_ITEM_1,
  SUB_ITEM_2,
  SUB_ITEM_3,
  SUB_ITEM_4,
  SUB_ITEM_5,
  CURRENT_PASSWORD,
  READ_ONLY_MODE,
  ERROR_SUM,
  SHOW_VALIDATION_ERRORS,
  ERROR_COUNTERS,
  SUPPLIER_FORMS,
  SMART_TABLE_LAYOUT,
  REMOTE_EMAIL_VALIDATION,
  LAST_BACKEND_RESPONSE,
  IMPORT_OFFER_DATA,
  SCENARIOS,
  CURRENT_INDEX,
  GLOBAL,
  CUSTOMER_SCENARIOS,
  INDIVIDUAL_SCENARIOS,
  COUNTRIES_HASHMAP,
  SUPPLIER_LISTING,
  SUPPLIER_SELECTION,
  SUPPLIER_NAME_HASHMAP,
  ACTIVE_SUPPLIER_IDS,
  GOT_PROPOSED_PANEL,
  INSTANT_QUOTE_TYPE,
  OVERRIDE_VALIDATION_COMPONENTS,
  MOV_MOQ_DATA,
}
