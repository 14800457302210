const PREFIX = 'RfQ'

const PROJECT_DATA_PREFIX = `${PREFIX}ProjectData`
const PROJECT_NAME = `${PROJECT_DATA_PREFIX}ProjectName`
const CUSTOMER_NAME = `${PROJECT_DATA_PREFIX}CustomerName`
const RFQ_SECTOR = `${PROJECT_DATA_PREFIX}RFQSector`
const PRODUCT = `${PROJECT_DATA_PREFIX}Product`
const QUOTE_EXPECTED_BY = `${PROJECT_DATA_PREFIX}QuoteExpectedBy`
const FIRST_SAMPLE = `${PROJECT_DATA_PREFIX}FirstSample`
const SOP = `${PROJECT_DATA_PREFIX}SOP`
const RFQ_YEARS = `${PROJECT_DATA_PREFIX}RFQYears`
const INCOTERM = `${PROJECT_DATA_PREFIX}Incoterm`
const RFQ_TYPE = `${PROJECT_DATA_PREFIX}RFQType`
const EXPECTED_LIFETIME = `${PROJECT_DATA_PREFIX}ExpectedLifetime`
const SCENARIO_NUMBER = `${PROJECT_DATA_PREFIX}ScenarioNumber`
const LOT_NUMBER = `${PROJECT_DATA_PREFIX}LotNumber`
const SPECIFY_LOT_SIZES = `${PROJECT_DATA_PREFIX}SpecifyLotSizes`
const SPECIFIED_LOT_SIZES = `specifiedLotSizes`

const PROJECT_DATA = {
  PROJECT_NAME,
  CUSTOMER_NAME,
  RFQ_SECTOR,
  PRODUCT,
  QUOTE_EXPECTED_BY,
  FIRST_SAMPLE,
  SOP,
  RFQ_YEARS,
  RFQ_TYPE,
  EXPECTED_LIFETIME,
  SCENARIO_NUMBER,
  LOT_NUMBER,
  INCOTERM,
  SPECIFY_LOT_SIZES,
}

const DOCUMENTS_PREFIX = `${PREFIX}Documents`
const GERBER_FILE = `${DOCUMENTS_PREFIX}GerberFile`
const SPECIFICATION = `${DOCUMENTS_PREFIX}Specification`
const PANEL_DRAWING = `${DOCUMENTS_PREFIX}PanelDrawing`
const OTHER = `${DOCUMENTS_PREFIX}Other`
const DOCUMENTS = {
  GERBER_FILE,
  SPECIFICATION,
  PANEL_DRAWING,
  OTHER,
}

const BASIC_FACTOR_PREFIX = `${PREFIX}BasicFactor`
const BASIC_TECHNOLOGY = `${BASIC_FACTOR_PREFIX}BasicTechnology`
const LAYER = `${BASIC_FACTOR_PREFIX}Layer`
const LAMINATE_TEMP = `${BASIC_FACTOR_PREFIX}LaminateTemp`
const LAMINATE = `${BASIC_FACTOR_PREFIX}Laminate`
const SOLDER_MASK = `${BASIC_FACTOR_PREFIX}SolderMask`
const COMPONENT_MARK = `${BASIC_FACTOR_PREFIX}ComponentMark`
const PROFILING = `${BASIC_FACTOR_PREFIX}Profiling`
const BASIC_FACTOR = {
  BASIC_TECHNOLOGY,
  LAYER,
  LAMINATE_TEMP,
  LAMINATE,
  SOLDER_MASK,
  COMPONENT_MARK,
  PROFILING,
}

const COPPER_CRITERIA_PREFIX = `${PREFIX}CopperCriteria`
const INNER_LAYER = `${COPPER_CRITERIA_PREFIX}InnerLayer`
const OUTER_LAYER = `${COPPER_CRITERIA_PREFIX}OuterLayer`
const FINISHED_CU = `${COPPER_CRITERIA_PREFIX}FinishedCU`
const SPACING = `${COPPER_CRITERIA_PREFIX}Spacing`
const PTH = `${COPPER_CRITERIA_PREFIX}PTH`
const COPPER_CRITERIA = {
  INNER_LAYER,
  OUTER_LAYER,
  FINISHED_CU,
  SPACING,
  PTH,
}

const PCB_DIMENSIONS_PREFIX = `${PREFIX}PCBDimensions`
const PANEL_WIDTH = `${PCB_DIMENSIONS_PREFIX}PanelWidth`
const PANEL_WIDTH_MIN = `${PCB_DIMENSIONS_PREFIX}PanelWidthMin`
const PANEL_WIDTH_MAX = `${PCB_DIMENSIONS_PREFIX}PanelWidthMax`
const PANEL_LENGTH = `${PCB_DIMENSIONS_PREFIX}PanelLength`
const PANEL_LENGTH_MIN = `${PCB_DIMENSIONS_PREFIX}PanelLengthMin`
const PANEL_LENGTH_MAX = `${PCB_DIMENSIONS_PREFIX}PanelLengthMax`
const NR_PCB = `${PCB_DIMENSIONS_PREFIX}NRPCB`
const BOARD_THICKNESS = `${PCB_DIMENSIONS_PREFIX}BoardThickness`
const BOARD_TOLERANCE = `${PCB_DIMENSIONS_PREFIX}BoardTolerance`
const PCB_DIMENSIONS = {
  PANEL_WIDTH,
  PANEL_WIDTH_MIN,
  PANEL_WIDTH_MAX,
  PANEL_LENGTH,
  PANEL_LENGTH_MIN,
  PANEL_LENGTH_MAX,
  NR_PCB,
  BOARD_THICKNESS,
  BOARD_TOLERANCE,
}

const OTHERS_PREFIX = `${PREFIX}Others`
const IPC_CLASS = `${OTHERS_PREFIX}IPCClass`
const CTI_VALUE = `${OTHERS_PREFIX}CTIValue`
const SURFACE_FINISHING = `${OTHERS_PREFIX}SurfaceFinishing`
const HALOGEN_FREE = `${OTHERS_PREFIX}HalogenFree`
const CAF_RESISTANCE = `${OTHERS_PREFIX}CAFResistance`
const IMPEDANCE_CONTROL = `${OTHERS_PREFIX}ImpedanceControl`
const VIA_PLUG = `${OTHERS_PREFIX}ViaPlug`
const PRESS_FIT_HOLES = `${OTHERS_PREFIX}PressFitHoles`
const BLIND_VIA = `${OTHERS_PREFIX}BlindVia`
const BURIED_VIA = `${OTHERS_PREFIX}BuriedVia`
const CROSS_OUT = `${OTHERS_PREFIX}CrossOut`
const ROHS_CONFORM = `${OTHERS_PREFIX}RohsConform`
const UL_MARKING = `${OTHERS_PREFIX}ULMarking`

const OTHERS = {
  IPC_CLASS,
  CTI_VALUE,
  SURFACE_FINISHING,
  HALOGEN_FREE,
  CAF_RESISTANCE,
  IMPEDANCE_CONTROL,
  VIA_PLUG,
  PRESS_FIT_HOLES,
  ROHS_CONFORM,
  BLIND_VIA,
  BURIED_VIA,
  CROSS_OUT,
  UL_MARKING,
}

const ADDITIONAL_INFO = `${PREFIX}AdditionalInfo`
const NOTES_SALES = `${PREFIX}NotesSales`
const NOTES_FOR_ME = `${PREFIX}NotesForMe`
const DELIVERY = `${PREFIX}delivery`
const QUOTES = `${PREFIX}quotes`

const QUOTE_FILE = `${PREFIX}quoteFile`
const QUOTATION_FILE = `${PREFIX}quotationFile`
const FRAME_CONTRACT_FILE = `${PREFIX}frameContractFile`
const TERMS_CONDITION_FILE = `${PREFIX}termsConditionFile`

const QUOTATIONS = `${PREFIX}Quotations`

const LISTING = `${PREFIX}Listing`
const FILTER = `${PREFIX}Filter`
const TOTAL_RESULTS = `${PREFIX}TotalResults`

const UTIL_RATE_SPECIFIC = `${PREFIX}UtilRateSpecific`
const UTIL_RATE_PROPOSED = `${PREFIX}UtilRateProposed`
const SIZE_PROPOSED = `${PREFIX}SizeProposed`
const UNITS_PER_PANEL = `${PREFIX}UnitsPerPanel`
const USED_LAMINATE = `${PREFIX}UsedLaminate`
const USED_SOLDER_MASK = `${PREFIX}UsedSolderMask`
const PRODUCTION_LEAD_TIME = `${PREFIX}ProductionLeadTime`
const MATERIAL_LEAD_TIME = `${PREFIX}MaterialLeadTime`
const SAMPLE_LEAD_TIME = `${PREFIX}SampleLeadTime`
const TECH_INFORMATION_LEADTIME_PREFIX = `${PREFIX}TechInformationLeadtime`
const TECH_INFORMATION_LEADTIME = {
  UTIL_RATE_SPECIFIC,
  UTIL_RATE_PROPOSED,
  SIZE_PROPOSED,
  UNITS_PER_PANEL,
  USED_LAMINATE,
  USED_SOLDER_MASK,
  PRODUCTION_LEAD_TIME,
  MATERIAL_LEAD_TIME,
  SAMPLE_LEAD_TIME,
}

const ANNUAL_PRICE = `${PREFIX}AnnualPriceDown`
const NEED_FLAT_PRICE = `${PREFIX}NeedFlatPrice`
const SUPPLIER_INCOTERM = `${PREFIX}SupplierIncoterm`
const SPECIFY_ANNUAL_PRICE = `${PREFIX}SpecifyAnnualPriceDown`
const NAMED_PLACE = `${PREFIX}NamedPlace`
const QUOTATION_CURRENCY = `${PREFIX}QuotationCurrency`
const ADDITIONAL_QUOTATION_CURRENCY = `${PREFIX}AdditionalQuotationCurrency`
const EXPECTED_PROJECT_LIFETIME = `${PREFIX}ExpectedProjectLifetime`
const PRICING_DELIVERY_PREFIX = `${PREFIX}PricingDelivery`
const REDUCTION_YEARS = `${PREFIX}ReductionYears`
const REDUCTION_PERCENTAGE = `${PREFIX}ReductionPercentage`
const QUOTATION_AND_DELIVERY_STRATEGY_PREFIX = `${PREFIX}QuotationAndDeliveryStrategy`
const MIN_ORDER_VOLUME = `${PREFIX}MinOderVolume`

const REGION = `${PREFIX}Region`
const SOURCE = `${PREFIX}Source`
const QUOTATION_AND_DELIVERY_STRATEGY = {
  REGION,
  SOURCE,
}

const PRICING_DELIVERY = {
  ANNUAL_PRICE,
  NEED_FLAT_PRICE,
  SUPPLIER_INCOTERM,
  SPECIFY_ANNUAL_PRICE,
  NAMED_PLACE,
  QUOTATION_CURRENCY,
  ADDITIONAL_QUOTATION_CURRENCY,
  EXPECTED_PROJECT_LIFETIME,
  REDUCTION_YEARS,
  REDUCTION_PERCENTAGE,
  MIN_ORDER_VOLUME,
}

const TECH_CONFIRMED = `${PREFIX}TechConfirmed`
const TECH_CONFIRMATION_FILE = `${PREFIX}TechConfirmationFile`
const CSCC_FILE = `${PREFIX}CSCCFile`
const TECH_COMMENT = `${PREFIX}TechComment`

const TECH_CSCC_PREFIX = `${PREFIX}TechCSCC`
const TECH_CSCC = {
  TECH_CONFIRMED,
  TECH_CONFIRMATION_FILE,
  CSCC_FILE,
  TECH_COMMENT,
}

const NRE_MOV_TOOLING_CHARGE = `${PREFIX}NREMOVToolingCharge`
const NRE_MOV_TESTER_CHARGE = `${PREFIX}NREMOVTesterCharge`
const NRE_MOV_OTHER_CHARGES = `${PREFIX}NREMOVOtherCharges`
const NRE_MOV_MIN_ORDER = `${PREFIX}NREMOVMinOrder`
const NRE_MOV_PREFIX = `${PREFIX}NREMOV`
const NRE_MOV = {
  NRE_MOV_TOOLING_CHARGE,
  NRE_MOV_TESTER_CHARGE,
  NRE_MOV_OTHER_CHARGES,
  NRE_MOV_MIN_ORDER,
}

const COM_PREFIX = `${PREFIX}Com`
const COM_WRITE_COMMENT = `${PREFIX}ComWriteComment`
const COM_COMMENTS = `${PREFIX}ComComments`
const COM_COMMENTS_CUSTOMER = `${PREFIX}ComCommentsCustomer`
const COM_COMMENTS_SUPPLIER = `${PREFIX}ComCommentsSupplier`
const COM_COMMENTS_INTERNAL = `${PREFIX}ComCommentsInternal`
const COM_INTERNAL_COMMENTS_BOSCH = `${PREFIX}ComInternalCommentsBosch`
const COM_BOSCH_SUPPLIER_COMMENTS = `${PREFIX}ComBoschSupplierComments`
const COM = {
  COM_WRITE_COMMENT,
  COM_COMMENTS,
  COM_COMMENTS_CUSTOMER,
  COM_COMMENTS_SUPPLIER,
  COM_COMMENTS_INTERNAL,
  COM_INTERNAL_COMMENTS_BOSCH,
  COM_BOSCH_SUPPLIER_COMMENTS,
}

const COST_INDICATION_PREFIX = `${PREFIX}CostIndication`
const PRICE_INDICATION_PREFIX = `${PREFIX}PriceIndication`
const PRICE_INDICATION_COST_MATRIX_PREFIX = `${PREFIX}PriceIndicationCostMatrix`
const UPPER_BOUND = `${PREFIX}UpperBoundPrice`
const LOWER_BOUND = `${PREFIX}LowerBoundPrice`
const MID_POINT = `${PREFIX}MidPointPrice`
const UPPER_BOUND_CUSTOMER = `${PREFIX}UpperBoundCustomerPrice`
const MID_POINT_CUSTOMER = `${PREFIX}MidPointCustomerPrice`
const UPPER_BOUND_COST = `${PREFIX}UpperBoundCost`
const LOWER_BOUND_COST = `${PREFIX}LowerBoundCost`
const MID_POINT_COST = `${PREFIX}MidPointCost`
const COST_MATRIX_PRICE = `${PREFIX}CostMatrixPrice`
const COST_MATRIX_SUPPLIER = `${PREFIX}CostMatrixSupplier`
const COST_MATRIX_COMMENT = `${PREFIX}CostMatrixComment`

const COST_INDICATION = {
  UPPER_BOUND_COST,
  LOWER_BOUND_COST,
  MID_POINT_COST,
}

const PRICE_INDICATION = {
  UPPER_BOUND,
  LOWER_BOUND,
  MID_POINT,
  UPPER_BOUND_CUSTOMER,
  MID_POINT_CUSTOMER,
}

const PRICE_INDICATION_COST_MATRIX = {
  COST_MATRIX_PRICE,
  COST_MATRIX_SUPPLIER,
  COST_MATRIX_COMMENT,
}

const CONTRACT_DETAILS_PREFIX = `${PREFIX}ContractDetails`
const CONTRACT_FILE = `${PREFIX}ContractFile`
const ADDENDUM_FILE = `${PREFIX}AddendumFile`

const CONTRACT_DETAILS = {
  CONTRACT_FILE,
  ADDENDUM_FILE,
}

const SUPPLIER_SELECTION_PREFIX = `${PREFIX}SupplierSelection`
const SUPPLIER_SELECTION_SUPPLIER = `${PREFIX}SupplierSelectionSupplier`
const SUPPLIER_SELECTION_CSCC_REQUIRED = `${PREFIX}SupplierSelectionCSCCRequired`
const SUPPLIER_SELECTION_CONFIRMATION_REQUIRED = `${PREFIX}SupllierSelectionConfirmationRequired`

const SUPPLIER_SELECTION = {
  SUPPLIER_SELECTION_PREFIX,
  SUPPLIER_SELECTION_SUPPLIER,
  SUPPLIER_SELECTION_CSCC_REQUIRED,
  SUPPLIER_SELECTION_CONFIRMATION_REQUIRED,
}

const ASSIGNMENTS_PREFIX = `${PREFIX}Assignments`
const ASSIGNMENTS_ASSIGNEES = `${PREFIX}AssignmentsAssignees`
const ASSIGNMENTS = {
  ASSIGNMENTS_ASSIGNEES,
}

const PCB_TECHNOLOGY_DATA_GROUP = `${PREFIX}PCBTechnologyDataGroup`

const DETAILS_GROUP_PREFIX = `${PREFIX}DetailsGroup`
const DETAILS_GROUP_SCENARIOS_FOR_SUPPLIERS = `${PREFIX}DetailsGroupScenariosForSuppliers`
const QUOTATION_DETAILS_GROUP_PREFIX = `${PREFIX}QuotationsDetailsGroup`
const PROJECT_DATA_SUBGROUP = `${PREFIX}DetailsProjectDataGroup`
const DOCUMENTS_SUBGROUP = `${PREFIX}DetailsDocumentsGroup`
const PCB_SUBGROUP = `${PREFIX}DetailPCBGroup`
const ADDITIONAL_INFORMATION_SUBGROUP = `${PREFIX}DetailAdditionalInformationGroup`
const QUOTATION_OF_SUPPLIERS_SUBGROUP = `${PREFIX}DetailsQuotationOfSuppliersGroup`
const DELIVERY_SUBGROUP = `${PREFIX}DetailDeliveryGroup`
const COMPARISON_OF_OFFERS_SUBGROUP = `${PREFIX}DetailsComparisonOfOffersGroup`
const DETAILS_GROUP = {
  PROJECT_DATA_SUBGROUP,
  DOCUMENTS_SUBGROUP,
  PCB_SUBGROUP,
  ADDITIONAL_INFORMATION_SUBGROUP,
  QUOTATION_OF_SUPPLIERS_SUBGROUP,
  DELIVERY_SUBGROUP,
  COMPARISON_OF_OFFERS_SUBGROUP,
}

const COST_OVERVIEW_GROUP_PREFIX = `${PREFIX}CostOverviewGroup`
const PROPOSED_PANEL_SUBGROUP = `${PREFIX}ProposedPanelSubgroup`
const CUSTOMER_SPECIFIED_PANEL_SUBGROUP = `${PREFIX}CustomerSpecifiedPanelSubgroup`
const COST_OVERVIEW_GROUP = {
  PROPOSED_PANEL_SUBGROUP,
  CUSTOMER_SPECIFIED_PANEL_SUBGROUP,
}

const COMPARISON_OF_QUOTES_CONTAINER = `${PREFIX}ComparisonOfQuotesContainer`
const COQ_CURRENCY_EXCHANGE_RATE_PREFIX = `${PREFIX}COQCurrencyExchangeRate`
const DEFINE_COQ_CURRENCY = `${PREFIX}DefineCOQCurrency`
const COQ_CURRENCY_EXCHANGE_ONE = `${PREFIX}COQCurrencyExchangeOne`
const COQ_CURRENCY_EXCHANGE_TWO = `${PREFIX}COQCurrencyExchangeTwo`
const COQ_CURRENCY_EXCHANGE_RATE = {
  DEFINE_COQ_CURRENCY,
  COQ_CURRENCY_EXCHANGE_ONE,
  COQ_CURRENCY_EXCHANGE_TWO,
}

const COST_OVERVIEW_PREFIX = `${PREFIX}CostOverview`
const QUOTA_ALLOCATION = `${PREFIX}QuotaAllocation`
const PCB_COST_PREFIX = `${PREFIX}PCBCost`

const OVERVIEW_ANNUAL_PRICE_DOWN = `${PREFIX}OverviewAnnualPriceDown`
const OVERVIEW_FLAT_PRICE = `${PREFIX}OverviewFlatPrice`
const OVERVIEW_NRE_MOV = `${PREFIX}OverviewNREMOV`
const OVERVIEW_ADDITIONAL_DATA = `${PREFIX}OverviewAdditionalData`
const OVERVIEW_SUPPLIER_SELECTION = `${PREFIX}OverviewSupplierSelection`
const OVERVIEW_QUOTA_CALCULATION = `${PREFIX}OverviewQuotaCalculation`
const OVERVIEW_PCB_COST = `${PREFIX}OverviewPCBCost`
const SUPPLIER_OVERVIEW = {
  OVERVIEW_ANNUAL_PRICE_DOWN,
  OVERVIEW_FLAT_PRICE,
  OVERVIEW_NRE_MOV,
  OVERVIEW_ADDITIONAL_DATA,
  OVERVIEW_SUPPLIER_SELECTION,
  OVERVIEW_QUOTA_CALCULATION,
  OVERVIEW_PCB_COST,
}

const ADDITIONAL_DATA_PREFIX = `${PREFIX}AdditionalData`
const SUPPLIER_COMMENT = `${PREFIX}SupplierComment`

const OFFER_UPLOAD_PREFIX = `${PREFIX}OfferUpload`
const OFFER_UPLOAD_FILE = `${PREFIX}OfferUploadFile`
const OFFER_UPLOAD = {
  OFFER_UPLOAD_FILE,
}

const REJECT_OFFER = `${PREFIX}RejectOffer`
const PDF_PARSER = `${PREFIX}PDFParser`

const PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX = `${PREFIX}PanelDimensionsForSupplier`
const PANEL_DIMENSIONS_DATA_GROUP = `${PREFIX}PanelDimensionsDataGroup`
const PANEL_DIMENSIONS_FOR_SUPPLIER = {
  PANEL_WIDTH: `${PREFIX}PanelWidth`,
  PANEL_LENGTH: `${PREFIX}PanelLength`,
  NR_PCB: `${PREFIX}NrPCB`,
}

const OFFERS_OF_SUPPLIERS = `${PREFIX}OffersOfSuppliers`

const SET_NEW_DUE_DATE = `${PREFIX}SetNewDueDate`

const RECALL_RFQ = `${PREFIX}RecallRfQ`
const REQUEST_REWORK_RFQ = `${PREFIX}RequestReworkRfQ`
const REWORK_SUPPLIER = `${PREFIX}ReworkSupplier`

const SCENARIO_FOR_SUPPLIER_ANNUAL_QUANTITY = `${PREFIX}ScenarioForSupplierAnnualQuantity`
const INDIVIDUAL_SCENARIO = `${PREFIX}IndividualScenario`

const SUPPLIER_SETTINGS = `${PREFIX}SupplierSettings`
const SUPPLIER_QUOTA = `${PREFIX}SupplierQuota`
const SUPPLIER_SELECTION_QUOTA = `${PREFIX}SupplierSelectionQuota`
const SUPPLIER_FORMS = `${PREFIX}SupplierForms`

const PROVIDE_RFQ_INFORMATION = `${PREFIX}ProvideRfQInformation`
const CHANGE_REQUEST = `${PREFIX}ChangeRequest`
const PURCHASING_COSTS = `${PREFIX}PurchasingCosts`
const SALES_PRICE = `${PREFIX}SalesPrice`
const MASS_PRODUCTION_PREFIX = `${PREFIX}MassProductionPrefix`
const LOGISTIC_COSTS = `${PREFIX}LogisticCosts`
const QUOTATION_SUMMARY = `${PREFIX}QuotationSummary`
const QUOTATION_NOTES_PREFIX = `${PREFIX}QuotationNotes`
const GENERAL_CONDITIONS = `${QUOTATION_NOTES_PREFIX}GeneralConditions`
const FURTHER_REMARKS = `${QUOTATION_NOTES_PREFIX}FurtherRemarks`
const QUOTATION_NOTES = {
  GENERAL_CONDITIONS,
  FURTHER_REMARKS,
}

const QUOTE_PREPARATION_PREFIX = `${PREFIX}QuotePreparationPrefix`
const SHIPPING_COST = `${QUOTE_PREPARATION_PREFIX}ShippingCost`
const QUOTE_PREPARATION = {
  SHIPPING_COST,
}

const SUPPLIER_OFFER_STRATEGY_PREFIX = `${PREFIX}SupplierOfferStrategy`
const DELIVERY_STRATEGY_PREFIX = `${PREFIX}DeliveryStrategy`

const MODAL_REJECT_RfQ_COMMENT = `${PREFIX}ModalRejectRfQComment`
const MODAL_REJECT_OFFER_COMMENT = `${PREFIX}ModalRejectOfferComment`
const MODAL_REQUEST_CLARIFICATION_COMMENT = `${PREFIX}ModalRequestClarificationComment`

const CUSTOMER_QUOTE_PREFIX = `${PREFIX}CustomerQuote`
const CUSTOMER_QUOTE_PANEL_SELECTION = `${CUSTOMER_QUOTE_PREFIX}PanelSelection`
const CUSTOMER_QUOTE_FILE = `${CUSTOMER_QUOTE_PREFIX}File`
const CUSTOMER_QUOTE_FILE_SPECIFIED = `${CUSTOMER_QUOTE_PREFIX}FileSpecified`
const CUSTOMER_QUOTE_FILE_PROPOSED = `${CUSTOMER_QUOTE_PREFIX}FileProposed`
const CUSTOMER_QUOTE = {
  CUSTOMER_QUOTE_FILE,
  CUSTOMER_QUOTE_FILE_SPECIFIED,
  CUSTOMER_QUOTE_FILE_PROPOSED,
}
const INTERNAL_QUOTE_PANEL_SELECTION = `InternalQuotePanelSelection`

const SUPPLIER_NRE_PREFIX = `${PREFIX}SupplierNRE`
const SUPPLIER_NRE_TOOLING_CHARGE = `${SUPPLIER_NRE_PREFIX}ToolingCharge`
const SUPPLIER_NRE_TESTER_CHARGE = `${SUPPLIER_NRE_PREFIX}TesterCharge`
const SUPPLIER_NRE_OTHER_CHARGES = `${SUPPLIER_NRE_PREFIX}OtherCharges`
const SUPPLIER_NRE = {
  SUPPLIER_NRE_TOOLING_CHARGE,
  SUPPLIER_NRE_TESTER_CHARGE,
  SUPPLIER_NRE_OTHER_CHARGES,
}

const LEADTIMES_PREFIX = `${PREFIX}Leadtimes`
const LEADTIME_SAMPLES = `${LEADTIMES_PREFIX}Samples`
const LEADTIME_AIR = `${LEADTIMES_PREFIX}Air`
const LEADTIME_SEA = `${LEADTIMES_PREFIX}Sea`
const LEADTIME_EXPRESS = `${LEADTIMES_PREFIX}Express`
const LEADTIMES = {
  LEADTIME_SAMPLES,
  LEADTIME_AIR,
  LEADTIME_SEA,
  LEADTIME_EXPRESS,
}
const SALES_EXPERT_PCB_COST_PREFIX = `${PREFIX}SalesExpertPCBCost`
const SALES_EXPERT_PCB_COST_MOV_MOQ = `${SALES_EXPERT_PCB_COST_PREFIX}MOVMOQ`
const SALES_EXPERT_PCB_COST = {
  SALES_EXPERT_PCB_COST_MOV_MOQ,
}

const SAMPLES_NRE_PREFIX = `${PREFIX}SamplesNRE`
const SAMPLES_NRE_MARGIN_SAMPLE = `${SAMPLES_NRE_PREFIX}MarginSample`
const SAMPLES_NRE_MARGIN_NRE = `${SAMPLES_NRE_PREFIX}MarginNRE`
const SAMPLES_NRE_PRICE_SAMPLE = `${SAMPLES_NRE_PREFIX}PriceSample`
const SAMPLES_NRE_PRICE_NRE = `${SAMPLES_NRE_PREFIX}PriceNRE`
const SAMPLES_NRE = {
  SAMPLES_NRE_MARGIN_SAMPLE,
  SAMPLES_NRE_MARGIN_NRE,
  SAMPLES_NRE_PRICE_SAMPLE,
  SAMPLES_NRE_PRICE_NRE,
}

const TECH_OFFER_BASE_CUSTOMER_PREFIX = `${PREFIX}TechOfferBaseCustomer`
const TECH_OFFER_BASE_PANEL_DIMENSIONS_LENGTH = `${TECH_OFFER_BASE_CUSTOMER_PREFIX}PanelDimensionsLength`
const TECH_OFFER_BASE_PANEL_DIMENSIONS_WIDTH = `${TECH_OFFER_BASE_CUSTOMER_PREFIX}PanelDimensionsWidth`
const TECH_OFFER_BASE_UNITS_PER_PANEL = `${TECH_OFFER_BASE_CUSTOMER_PREFIX}UnitsPerPanel`
const TECH_OFFER_BASE_BASE_MATERIAL = `${TECH_OFFER_BASE_CUSTOMER_PREFIX}BaseMaterial`
const TECH_OFFER_BASE_SOLDER_MASK = `${TECH_OFFER_BASE_CUSTOMER_PREFIX}SolderMask`
const TECH_OFFER_BASE_FREE_TEXT = `${TECH_OFFER_BASE_CUSTOMER_PREFIX}FreeText`
const TECH_OFFER_BASE_CUSTOMER = {
  TECH_OFFER_BASE_PANEL_DIMENSIONS_LENGTH,
  TECH_OFFER_BASE_PANEL_DIMENSIONS_WIDTH,
  TECH_OFFER_BASE_UNITS_PER_PANEL,
  TECH_OFFER_BASE_BASE_MATERIAL,
  TECH_OFFER_BASE_SOLDER_MASK,
  TECH_OFFER_BASE_FREE_TEXT,
}
const REQUEST_SUPPLIER_EXPERT_REWORK = `${PREFIX}RequestSupplierExpertRework`
const LOGISTIC_COSTS_TABLES = `${PREFIX}LogisticCostsTables`
const MASS_PRODUCTION_TABLES = `${PREFIX}MassProductionTables`
const PROPOSE_OPTIMIZED_PANEL = `${PREFIX}ProposeOptimizedPanel`
const SUPPLIER_SCENARIOS = `${PREFIX}SupplierScenarios`

export const KEYS_RFQ = {
  PROJECT_DATA_PREFIX,
  PROJECT_DATA,
  DOCUMENTS_PREFIX,
  DOCUMENTS,
  BASIC_FACTOR_PREFIX,
  BASIC_FACTOR,
  COPPER_CRITERIA_PREFIX,
  COPPER_CRITERIA,
  PCB_DIMENSIONS_PREFIX,
  PCB_DIMENSIONS,
  OTHERS_PREFIX,
  OTHERS,
  ADDITIONAL_INFO,
  NOTES_SALES,
  NOTES_FOR_ME,
  DELIVERY,
  LISTING,
  FILTER,
  TOTAL_RESULTS,
  QUOTES,
  QUOTATIONS,
  NRE_MOV_PREFIX,
  NRE_MOV,
  TECH_INFORMATION_LEADTIME_PREFIX,
  TECH_INFORMATION_LEADTIME,
  PRICING_DELIVERY_PREFIX,
  PRICING_DELIVERY,
  TECH_CSCC_PREFIX,
  TECH_CSCC,
  COM_PREFIX,
  COM,
  PRICE_INDICATION_PREFIX,
  PRICE_INDICATION,
  CONTRACT_DETAILS_PREFIX,
  CONTRACT_DETAILS,
  SUPPLIER_SELECTION,
  SUPPLIER_SELECTION_PREFIX,
  ASSIGNMENTS_PREFIX,
  ASSIGNMENTS,
  PCB_TECHNOLOGY_DATA_GROUP,
  DETAILS_GROUP_PREFIX,
  DETAILS_GROUP_SCENARIOS_FOR_SUPPLIERS,
  DETAILS_GROUP,
  COMPARISON_OF_QUOTES_CONTAINER,
  COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
  COST_OVERVIEW_PREFIX,
  QUOTA_ALLOCATION,
  PCB_COST_PREFIX,
  COQ_CURRENCY_EXCHANGE_RATE,
  COST_OVERVIEW_GROUP_PREFIX,
  COST_OVERVIEW_GROUP,
  SUPPLIER_OVERVIEW,
  ADDITIONAL_DATA_PREFIX,
  SUPPLIER_COMMENT,
  OFFER_UPLOAD_PREFIX,
  OFFER_UPLOAD,
  QUOTE_FILE,
  QUOTATION_FILE,
  FRAME_CONTRACT_FILE,
  TERMS_CONDITION_FILE,
  REJECT_OFFER,
  QUOTATION_DETAILS_GROUP_PREFIX,
  PDF_PARSER,
  PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
  PANEL_DIMENSIONS_FOR_SUPPLIER,
  PANEL_DIMENSIONS_DATA_GROUP,
  OFFERS_OF_SUPPLIERS,
  SET_NEW_DUE_DATE,
  RECALL_RFQ,
  REQUEST_REWORK_RFQ,
  REWORK_SUPPLIER,
  SPECIFIED_LOT_SIZES,
  SCENARIO_FOR_SUPPLIER_ANNUAL_QUANTITY,
  SUPPLIER_SETTINGS,
  INDIVIDUAL_SCENARIO,
  CHANGE_REQUEST,
  QUOTATION_AND_DELIVERY_STRATEGY_PREFIX,
  QUOTATION_AND_DELIVERY_STRATEGY,
  SUPPLIER_QUOTA,
  SUPPLIER_SELECTION_QUOTA,
  SUPPLIER_FORMS,
  PURCHASING_COSTS,
  SUPPLIER_NRE_PREFIX,
  SUPPLIER_NRE,
  SALES_PRICE,
  MASS_PRODUCTION_PREFIX,
  LOGISTIC_COSTS,
  QUOTATION_SUMMARY,
  QUOTATION_NOTES_PREFIX,
  QUOTATION_NOTES,
  CUSTOMER_QUOTE_PREFIX,
  CUSTOMER_QUOTE,
  QUOTE_PREPARATION,
  QUOTE_PREPARATION_PREFIX,
  SUPPLIER_OFFER_STRATEGY_PREFIX,
  DELIVERY_STRATEGY_PREFIX,
  COST_INDICATION_PREFIX,
  COST_INDICATION,
  MODAL_REJECT_RfQ_COMMENT,
  MODAL_REJECT_OFFER_COMMENT,
  MODAL_REQUEST_CLARIFICATION_COMMENT,
  LEADTIMES_PREFIX,
  LEADTIMES,
  TECH_OFFER_BASE_CUSTOMER_PREFIX,
  TECH_OFFER_BASE_CUSTOMER,
  REQUEST_SUPPLIER_EXPERT_REWORK,
  LOGISTIC_COSTS_TABLES,
  MASS_PRODUCTION_TABLES,
  CUSTOMER_QUOTE_PANEL_SELECTION,
  PROPOSE_OPTIMIZED_PANEL,
  INTERNAL_QUOTE_PANEL_SELECTION,
  SAMPLES_NRE,
  SAMPLES_NRE_PREFIX,
  SALES_EXPERT_PCB_COST_PREFIX,
  SALES_EXPERT_PCB_COST,
  SUPPLIER_SCENARIOS,
  PRICE_INDICATION_COST_MATRIX_PREFIX,
  PRICE_INDICATION_COST_MATRIX,
  PROVIDE_RFQ_INFORMATION,
}

export const COMMUNICATION_ITEMS = [
  COM_WRITE_COMMENT,
  COM_COMMENTS,
  COM_COMMENTS_CUSTOMER,
  COM_COMMENTS_SUPPLIER,
  COM_COMMENTS_INTERNAL,
  COM_INTERNAL_COMMENTS_BOSCH,
  COM_BOSCH_SUPPLIER_COMMENTS,
  NOTES_FOR_ME,
  NOTES_SALES,
  ADDITIONAL_INFO,
]
