export const CURRENCIES = {
  NONE: {
    LABEL: 'None',
    VALUE: null,
    CURRENCY: null,
    SYMBOL: null,
  },
  USD: {
    LABEL: 'USD',
    VALUE: 'usd',
    CURRENCY: 'USD $',
    SYMBOL: '$',
  },
  EUR: {
    LABEL: 'EUR',
    VALUE: 'eur',
    CURRENCY: 'EUR €',
    SYMBOL: '€',
  },
  CNY: {
    LABEL: 'CNY',
    VALUE: 'cny',
    CURRENCY: 'CNY ¥',
    SYMBOL: '¥',
  },
}

export const CURRENCY_TYPES = {
  LABEL: 'LABEL',
  VALUE: 'VALUE',
  CURRENCY: 'CURRENCY',
  SYMBOL: 'SYMBOL',
}
