import { APP_TITLE, PLACEHOLDER } from '@/constants/constants'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { NAVIGATION_ANCHORS } from '@/constants/anchorTags/navigationAnchorTags'

export const ROUTE_TYPES = {
  NORMAL: 0,
  LISTING: 1,
  RFQ_DETAILS: 2,
  RFQ_EDITOR: 3,
  DASHBOARD: 4,
}

export const ROUTER_DATA = {
  DEBUG: {
    BADGES: {
      PATH: `debug/badges`,
      NAME: 'debugBadges',
      TITLE: 'Debug badges',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.MISC.DEBUG_BADGES,
    },
  },
  SHARED: {
    PROFILE: {
      PATH: 'profile',
      NAME: 'profile',
      TITLE: 'Profile',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.MISC.PROFILE,
    },
    SETTINGS: {
      PATH: 'settings',
      NAME: 'settings',
      TITLE: 'Settings',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.MISC.SETTINGS,
    },
    ADDRESS_MANAGEMENT: {
      PATH: 'address-management',
      NAME: 'addressManagement',
      TITLE: 'Address Management',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.MISC.ADDRESS_MANAGEMENT,
    },
    USER_MANAGEMENT: {
      PATH: 'user-management',
      NAME: 'userManagement',
      TITLE: 'User Management',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.MISC.USER_MANAGEMENT,
    },
    NOTIFICATIONS: {
      PATH: 'notifications',
      NAME: 'notifications',
      TITLE: 'Notifications',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.MISC.NOTIFICATIONS,
    },
    RFQ_NEW: {
      PATH: `rfqs-new/${PLACEHOLDER.ID.RFQ}`,
      NAME: 'rfqNew',
      TITLE: 'New RFQ',
      ANCHOR: NAVIGATION_ANCHORS.MISC.RFQ_NEW,
    },
    RFQ_EDIT: {
      PATH: `rfqs-edit/${PLACEHOLDER.ID.RFQ}`,
      NAME: 'rfqEdit',
      TITLE: 'Edit RfQ',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_EDITOR,
      ANCHOR: NAVIGATION_ANCHORS.MISC.RFQ_EDIT,
    },
    TERMS_OF_USE: {
      PATH: `${process.env.VUE_APP_LANDING_PAGE_URL}/terms-of-use`,
      TITLE: 'Terms of use',
      ANCHOR: NAVIGATION_ANCHORS.SUPPORT.TERMS_OF_USE,
      EXTERNAL: true,
    },
    LEGAL_NOTICE: {
      PATH: `${process.env.VUE_APP_LANDING_PAGE_URL}/corporate-information`,
      TITLE: 'Imprint',
      ANCHOR: NAVIGATION_ANCHORS.SUPPORT.IMPRINT,
      EXTERNAL: true,
    },
    PRIVACY: {
      PATH: `${process.env.VUE_APP_LANDING_PAGE_URL}/data-protection-notice`,
      TITLE: 'Data protection notice',
      ANCHOR: NAVIGATION_ANCHORS.SUPPORT.DATA_PROTECTION_NOTICE,
      EXTERNAL: true,
    },
    TERMS_CONDITIONS: {
      PATH: `${process.env.VUE_APP_LANDING_PAGE_URL}/general-terms-and-conditions`,
      TITLE: 'General terms and conditions',
      ANCHOR: NAVIGATION_ANCHORS.SUPPORT.GENERAL_TERMS,
      EXTERNAL: true,
    },
    APPLY_NOW: {
      PATH: `${process.env.VUE_APP_LANDING_PAGE_URL}/registration/`,
      TITLE: 'Apply now',
      ANCHOR: NAVIGATION_ANCHORS.NO_PERMISSION.REGISTER,
      EXTERNAL: true,
    },
    CHANGE_PASSWORD: {
      PATH: `change-password`,
      NAME: 'changePassword',
      TITLE: 'Change Password',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.MISC.CHANGE_PASSWORD,
    },
  },
  [PERMISSION_GROUPS.NO_PERMISSION.VALUE]: {
    HOME: {
      PATH: '/',
      NAME: `${PERMISSION_GROUPS.NO_PERMISSION.VALUE}Home`,
      TITLE: APP_TITLE,
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
    },
    LOGIN: {
      PATH: 'login',
      NAME: `${PERMISSION_GROUPS.NO_PERMISSION.VALUE}Login`,
      TITLE: 'Login',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.NO_PERMISSION.LOGIN,
    },
    REGISTER_CONFIRM: {
      PATH: 'register/confirm',
      NAME: `${PERMISSION_GROUPS.NO_PERMISSION.VALUE}registerConfirm`,
      TITLE: 'Confirm Registration',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.NO_PERMISSION.REGISTER_CONFIRM,
    },
    RESET_PASSWORD: {
      PATH: 'account/reset/password',
      NAME: `${PERMISSION_GROUPS.NO_PERMISSION.VALUE}resetPassword`,
      TITLE: 'Reset your password',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.NO_PERMISSION.RESET_PASSWORD,
    },
    CREATE_NEW_PASSWORD: {
      PATH: 'account/recover/password',
      NAME: `${PERMISSION_GROUPS.NO_PERMISSION.VALUE}createNewPassword`,
      TITLE: 'Create new password',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.NO_PERMISSION.CREATE_NEW_PASSWORD,
    },
    SET_INITIAL_B2B_PASSWORD: {
      PATH: 'b2bcontactpasswordactivation',
      NAME: `${PERMISSION_GROUPS.NO_PERMISSION.VALUE}setInitialB2BPassword`,
      TITLE: 'Set initial password',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
    },
  },
  [PERMISSION_GROUPS.SUPER_ADMIN.VALUE]: {
    HOME: {
      PATH: `/${PERMISSION_GROUPS.SUPER_ADMIN.VALUE}`,
      NAME: `${PERMISSION_GROUPS.SUPER_ADMIN.VALUE}home`,
      TITLE: APP_TITLE,
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
    },
    DASHBOARD: {
      PATH: `/${PERMISSION_GROUPS.SUPER_ADMIN.VALUE}`,
      NAME: `${PERMISSION_GROUPS.SUPER_ADMIN.VALUE}Dashboard`,
      TITLE: 'Dashboard',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.SUPER_ADMIN.DASHBOARD,
    },
  },
  [PERMISSION_GROUPS.CUSTOMER.VALUE]: {
    HOME: {
      PATH: `/${PERMISSION_GROUPS.CUSTOMER.VALUE}`,
      NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}home`,
      TITLE: APP_TITLE,
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
    },
    DASHBOARD: {
      PATH: 'dashboard',
      NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}Dashboard`,
      TITLE: 'Dashboard',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.DASHBOARD,
    },
    MY_RFQS: {
      HOME: {
        PATH: 'my-rfqs',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRFQS`,
        TITLE: 'My RfQs',
        REDIRECT: '/rfqs-new',
        ROUTE_TYPE: ROUTE_TYPES.NORMAL,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MY_RFQS.CATEGORY,
      },
      NEW: {
        PATH: 'rfqs-new',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRFQSNew`,
        TITLE: 'New RfQ',
        ROUTE_TYPE: ROUTE_TYPES.NORMAL,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MY_RFQS.NEW_RFQ,
      },
      OVERVIEW: {
        PATH: 'rfqs-overview',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRFQSOverview`,
        TITLE: 'Overview',
        ROUTE_TYPE: ROUTE_TYPES.LISTING,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MY_RFQS.OVERVIEW,
      },
      IN_PROGRESS: {
        PATH: 'rfqs-in-progress',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRFQSInProgress`,
        TITLE: 'In Progress',
        ROUTE_TYPE: ROUTE_TYPES.LISTING,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MY_RFQS.IN_PROGRESS,
      },
      CLARIFICATION: {
        PATH: 'rfqs-clarification',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRFQSClarification`,
        TITLE: 'In Clarification',
        ROUTE_TYPE: ROUTE_TYPES.LISTING,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MY_RFQS.IN_CLARIFICATION,
      },
      QUOTE: {
        PATH: 'rfqs-quote',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRFQSQuote`,
        TITLE: 'Quote received',
        ROUTE_TYPE: ROUTE_TYPES.LISTING,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MY_RFQS.QUOTE_RECEIVED,
      },
      DRAFTS: {
        PATH: 'rfqs-drafts',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRFQSDrafts`,
        TITLE: 'Drafts',
        ROUTE_TYPE: ROUTE_TYPES.LISTING,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MY_RFQS.DRAFTS,
      },
    },
    MY_BUSINESS: {
      HOME: {
        PATH: 'my-running-business',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRunningBusiness`,
        TITLE: 'My Running Business',
        REDIRECT: '/business-supplierOverview',
        ROUTE_TYPE: ROUTE_TYPES.NORMAL,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MRB.CATEGORY,
      },
      OVERVIEW: {
        PATH: 'business-overview',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRunningBusinessOverview`,
        TITLE: 'Overview',
        ROUTE_TYPE: ROUTE_TYPES.LISTING,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MRB.OVERVIEW,
      },
      ACCEPTED: {
        PATH: 'business-accepted',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRunningBusinessAccepted`,
        TITLE: 'Orders',
        ROUTE_TYPE: ROUTE_TYPES.LISTING,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MRB.ORDERS,
      },
      CONTRACT_PREPARATION: {
        PATH: 'business-preparation',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRunningBusinessPreparation`,
        TITLE: 'Contracts',
        ROUTE_TYPE: ROUTE_TYPES.LISTING,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MRB.CONTRACTS,
      },
      DELIVERY: {
        PATH: 'business-delivery',
        NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}MyRunningBusinessDelivery`,
        TITLE: 'Series Delivery',
        ROUTE_TYPE: ROUTE_TYPES.LISTING,
        ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.MRB.SERIES_DELIVERY,
      },
    },
    ARCHIVE: {
      PATH: 'rfqs-archive',
      NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}Archive`,
      TITLE: 'Archive',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.ARCHIVE,
    },
    RFQ_DETAILS: {
      PATH: `rfq-details/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.CUSTOMER.VALUE}rfqDetails`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.CUSTOMER.RFQ_DETAILS,
    },
  },
  [PERMISSION_GROUPS.SUPPLIER.VALUE]: {
    HOME: {
      PATH: `/${PERMISSION_GROUPS.SUPPLIER.VALUE}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}Home`,
      TITLE: APP_TITLE,
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
    },
    DASHBOARD: {
      PATH: 'dashboard',
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}Dashboard`,
      TITLE: 'Dashboard',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.DASHBOARD,
    },
    RFQ_INBOX: {
      PATH: 'rfq-inbox',
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}RFQInbox`,
      TITLE: 'RfQ Inbox',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.RFQ_INBOX,
    },
    RUNNING_RFQS: {
      PATH: 'running-rfqs',
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}RunningRFQs`,
      TITLE: 'Running RfQs',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.RUNNING_RFQS,
    },
    CONTRACTS: {
      PATH: 'nomination',
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}Contract`,
      TITLE: 'Nominations',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.CONTRACTS,
    },
    ARCHIVE: {
      PATH: 'archive',
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}Archive`,
      TITLE: 'Archive',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.ARCHIVE,
    },
    RFQ_DETAILS: {
      PATH: `rfq-details/${PLACEHOLDER.ID.RFQ}/offer/${PLACEHOLDER.ID.OFFER}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}rfqDetails`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.RFQ_DETAILS,
    },
    RFQ_DETAILS_EDIT: {
      PATH: `rfq-details-edit/${PLACEHOLDER.ID.RFQ}/offer/${PLACEHOLDER.ID.OFFER}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}rfqDetailsEdit`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_EDITOR,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.RFQ_DETAILS,
    },
    RFQ_DETAILS_OFFER_MADE: {
      PATH: `rfq-details-offer-made/${PLACEHOLDER.ID.RFQ}/offer/${PLACEHOLDER.ID.OFFER}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}rfqDetailsOfferMade`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.RFQ_DETAILS,
    },
    RFQ_DETAILS_OFFER_MADE_EDIT: {
      PATH: `rfq-details-offer-made-edit/${PLACEHOLDER.ID.RFQ}/offer/${PLACEHOLDER.ID.OFFER}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}rfqDetailsOfferMadeEdit`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_EDITOR,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.RFQ_DETAILS,
    },
    RFQ_CONTRACT_DETAILS: {
      PATH: `rfq-nomination-details/${PLACEHOLDER.ID.RFQ}/offer/${PLACEHOLDER.ID.OFFER}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER.VALUE}rfqContractDetails`,
      TITLE: 'RfQ Nomination Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.RFQ_CONTRACT_DETAILS,
    },
  },
  [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]: {
    HOME: {
      PATH: `/${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}Home`,
      TITLE: APP_TITLE,
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
    },
    DASHBOARD: {
      PATH: 'dashboard',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}Dashboard`,
      TITLE: 'Dashboard',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.DASHBOARD,
    },
    MY_RFQS: {
      PATH: 'my-rfqs',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}MyRFQS`,
      TITLE: 'My RfQs',
      REDIRECT: '/overview',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
    },
    NEW_RFQS: {
      PATH: 'new-rfqs',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}NewRFQS`,
      TITLE: 'New RfQs',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.NEW_RFQS,
    },
    MY_CONTRACTS: {
      PATH: 'my-nominations',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}MyContracts`,
      TITLE: 'MY NOMINATIONS',
      REDIRECT: '/sourcing-nominating',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.MY_RFQS.RFQ_PREPARATION,
    },
    OVERVIEW: {
      PATH: 'rfq-overview',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}RFQOverview`,
      TITLE: 'Overview',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.MY_RFQS.OVERVIEW,
    },
    RFQ_PREPARATION: {
      PATH: 'rfq-preparation',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}RFQPreparation`,
      TITLE: 'RfQ Preparation',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.MY_RFQS.RFQ_PREPARATION,
    },
    RUNNING_RFQ: {
      PATH: 'running-rfq',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}RunningRFQ`,
      TITLE: 'Running RfQ',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.MY_RFQS.RUNNING_RFQ,
    },
    OFFER_COLLECTION: {
      PATH: 'offer-preparation',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}COQPreparation`,
      TITLE: 'Offer Collection',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.MY_RFQS.OFFER_COLLECTION,
    },
    SOURCING_CONTRACTING: {
      PATH: 'sourcing-nominating',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}SourcingContracting`,
      TITLE: 'Sourcing & Nominating',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR:
        NAVIGATION_ANCHORS.SUPPLIER_EXPERT.MY_CONTRACTS.SOURCING_CONTRACTING,
    },
    CONTRACTS: {
      PATH: 'nominations',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}Contracts`,
      TITLE: 'Nominations',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.MY_CONTRACTS.CONTRACTS,
    },
    ARCHIVE: {
      PATH: 'archive',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}Archive`,
      TITLE: 'Archive',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.MY_CONTRACTS.ARCHIVE,
    },
    RFQ_DETAILS: {
      PATH: `rfq-details/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}RFQDetails`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.RFQ_DETAILS,
    },
    RFQ_DETAILS_EDIT: {
      PATH: `rfq-details-edit/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}RFQDetailsEdit`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_EDITOR,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.RFQ_DETAILS,
    },
    RFQ_OFFER_PREPARATION_DETAILS: {
      PATH: `offer-preparation/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}OfferPreparation`,
      TITLE: 'Offer Preparation',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.RFQ_DETAILS,
    },
    RFQ_OFFER_REVIEW_DETAILS: {
      PATH: `offer-review/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}OfferReview`,
      TITLE: 'Offer review',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.RFQ_DETAILS,
    },
    RFQ_OFFER_REVIEW_DETAILS_EDIT: {
      PATH: `offer-review-edit/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}OfferReviewEdit`,
      TITLE: 'Offer review',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_EDITOR,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.RFQ_DETAILS,
    },
    RFQ_UPLOAD_CONTRACT_DETAILS: {
      PATH: `upload-contract/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}UploadContract`,
      TITLE: 'Upload Contract',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_EXPERT.RFQ_DETAILS,
    },
    RFQ_UPLOAD_CONTRACT_DETAILS_EDIT: {
      PATH: `upload-contract-edit/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE}rfqContractDetailsEdit`,
      TITLE: 'RfQ Contract Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_EDITOR,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER.RFQ_DETAILS,
    },
  },
  [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE]: {
    HOME: {
      PATH: `/${PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE}Home`,
      TITLE: APP_TITLE,
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
    },
    DASHBOARD: {
      PATH: 'dashboard',
      NAME: `${PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE}Dashboard`,
      TITLE: 'Dashboard',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_MANAGER.DASHBOARD,
    },
    RFQ_DETAILS: {
      PATH: `rfq-details/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE}rfqDetails`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SUPPLIER_MANAGER.RFQ_DETAILS,
    },
  },
  [PERMISSION_GROUPS.SALES_EXPERT.VALUE]: {
    HOME: {
      PATH: `/${PERMISSION_GROUPS.SALES_EXPERT.VALUE}`,
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}Home`,
      TITLE: APP_TITLE,
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
    },
    DASHBOARD: {
      PATH: 'dashboard',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}Dashboard`,
      TITLE: 'Dashboard',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.DASHBOARD,
    },
    NEW_RFQS: {
      PATH: 'new-rfqs',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}NewRFQS`,
      TITLE: 'New RfQs',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.NEW_RFQS,
    },
    MY_RFQS: {
      PATH: 'my-rfqs',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}MyRFQS`,
      TITLE: 'My RfQs',
      REDIRECT: '/overview',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
    },
    OVERVIEW: {
      PATH: 'rfq-overview',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}RFQOverview`,
      TITLE: 'Overview',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.MY_RFQS.OVERVIEW,
    },
    IN_VALIDATION: {
      PATH: 'in-validation',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}InValidation`,
      TITLE: 'In Validation',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.MY_RFQS.IN_VALIDATION,
    },
    QUOTE_PREPARATION: {
      PATH: 'quote-preparation',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}QuotePreparation`,
      TITLE: 'Quote Preparation',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.MY_RFQS.QUOTE_PREPARATION,
    },
    MY_CONTRACTS: {
      PATH: 'my-contracts',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}MyContracts`,
      TITLE: 'My Contracts',
      REDIRECT: '/contracts-overview',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
    },
    CONTRACTS_OVERVIEW: {
      PATH: 'contracts-overview',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}ContractsOverview`,
      TITLE: 'Overview',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.MY_CONTRACTS.OVERVIEW,
    },
    CONTRACT_PREPARATION: {
      PATH: 'contract-preparation',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}ContractPreparation`,
      TITLE: 'Contract Preparation',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.MY_CONTRACTS.CONTRACT_PREPARATION,
    },
    IN_CONTRACT: {
      PATH: 'in-contract',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}InContract`,
      TITLE: 'In Contract',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.MY_CONTRACTS.IN_CONTRACT,
    },
    ARCHIVE: {
      PATH: 'archive',
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}Archive`,
      TITLE: 'Archive',
      ROUTE_TYPE: ROUTE_TYPES.LISTING,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.ARCHIVE,
    },
    RFQ_DETAILS: {
      PATH: `rfq-details/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}RFQDetails`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.RFQ_DETAILS,
    },
    RFQ_DETAILS_EDIT: {
      PATH: `rfq-details-edit/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}RFQDetailsEdit`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_EDITOR,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.RFQ_DETAILS,
    },
    RFQ_DETAILS_CALCULATION: {
      PATH: `rfq-details-calculation/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}RFQDetailsCalculation`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.RFQ_DETAILS,
    },
    RFQ_DETAILS_CALCULATION_EDIT: {
      PATH: `rfq-details-calculation-edit/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}RFQDetailsCalculationEdit`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_EDITOR,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.RFQ_DETAILS,
    },
    RFQ_DETAILS_CALCULATION_INSTANT_QUOTE: {
      PATH: `rfq-details-calculation-instant-quote/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}RFQDetailsCalculationInstantQuote`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.RFQ_DETAILS,
    },
    RFQ_DETAILS_CALCULATION_INSTANT_QUOTE_EDIT: {
      PATH: `rfq-details-calculation-instant-quote-edit/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SALES_EXPERT.VALUE}RFQDetailsCalculationInstantQuoteEdit`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_EDITOR,
      ANCHOR: NAVIGATION_ANCHORS.SALES_EXPERT.RFQ_DETAILS,
    },
  },
  [PERMISSION_GROUPS.SALES_MANAGER.VALUE]: {
    HOME: {
      PATH: `/${PERMISSION_GROUPS.SALES_MANAGER.VALUE}`,
      NAME: `${PERMISSION_GROUPS.SALES_MANAGER.VALUE}Home`,
      TITLE: APP_TITLE,
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
    },
    DASHBOARD: {
      PATH: 'dashboard',
      NAME: `${PERMISSION_GROUPS.SALES_MANAGER.VALUE}Dashboard`,
      TITLE: 'Dashboard',
      ROUTE_TYPE: ROUTE_TYPES.NORMAL,
      ANCHOR: NAVIGATION_ANCHORS.SALES_MANAGER.DASHBOARD,
    },
    RFQ_DETAILS: {
      PATH: `rfq-details/${PLACEHOLDER.ID.RFQ}`,
      NAME: `${PERMISSION_GROUPS.SALES_MANAGER.VALUE}rfqDetails`,
      TITLE: 'RfQ Details',
      ROUTE_TYPE: ROUTE_TYPES.RFQ_DETAILS,
      ANCHOR: NAVIGATION_ANCHORS.SALES_MANAGER.RFQ_DETAILS,
    },
  },
}

export const EDIT_MODE_ROUTES = [
  ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_EDIT.NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_OFFER_MADE_EDIT
    .NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS_EDIT.NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
    .RFQ_UPLOAD_CONTRACT_DETAILS_EDIT.NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
    .RFQ_OFFER_REVIEW_DETAILS_EDIT.NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS_EDIT.NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS_CALCULATION_EDIT
    .NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
    .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE_EDIT.NAME,
]

export const COMPARISON_OF_OFFERS_ROUTES = [
  ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
    .RFQ_UPLOAD_CONTRACT_DETAILS.NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_OFFER_REVIEW_DETAILS
    .NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
    .RFQ_UPLOAD_CONTRACT_DETAILS_EDIT.NAME,
  ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
    .RFQ_OFFER_REVIEW_DETAILS_EDIT.NAME,
]
