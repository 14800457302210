import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store'
import { ROUTE_TYPES, ROUTER_DATA } from '@/constants/routerData'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import RouteWrapper from '@/components/RouteWrapper'
import NoPermissionLayout from '@/layouts/NoPermissionLayout'
import cloneDeep from 'lodash/cloneDeep'
import { USER_STATUS } from '@/constants/userStatus'
import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { VALIDATION_FORM_KEYS } from '@/constants/validation'
import { IS_DEV_MODE } from '@/constants/constants'

const SUPER_ADMIN_ROUTES = [
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].DASHBOARD.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].DASHBOARD.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].DASHBOARD.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPER_ADMIN.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].DASHBOARD.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].DASHBOARD.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].DASHBOARD.ANCHOR,
    },
    component: () => import('@/views/pages/superAdmin/Dashboard.vue'),
  },
]

const CUSTOMER_ROUTES = [
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD.TITLE,
      requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
      history: [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD.NAME],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD.ROUTE_TYPE,
      anchorTag: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD.ANCHOR,
    },
    component: () => import('@/views/pages/customer/Dashboard.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW.NAME,
    },
    component: RouteWrapper,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.HOME.TITLE,
      requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.HOME.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.HOME.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.HOME.ANCHOR,
    },
    children: [
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.NEW.PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.NEW.NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.NEW.TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.NEW.NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.NEW
              .ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.NEW.ANCHOR,
        },
        component: () => import('@/views/pages/customer/myRFQs/New.vue'),
      },
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW
          .PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW
          .NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW
              .TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW.NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW
              .ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.OVERVIEW
              .ANCHOR,
        },
        component: () => import('@/views/pages/customer/myRFQs/Overview.vue'),
      },
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.IN_PROGRESS
          .PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.IN_PROGRESS
          .NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.IN_PROGRESS
              .TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.IN_PROGRESS
              .NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.IN_PROGRESS
              .ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.IN_PROGRESS
              .ANCHOR,
        },
        component: () => import('@/views/pages/customer/myRFQs/InProgress.vue'),
      },
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS
          .CLARIFICATION.PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS
          .CLARIFICATION.NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.CLARIFICATION
              .TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.CLARIFICATION
              .NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.CLARIFICATION
              .ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.CLARIFICATION
              .ANCHOR,
        },
        component: () =>
          import('@/views/pages/customer/myRFQs/Clarification.vue'),
      },
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.QUOTE.PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.QUOTE.NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.QUOTE.TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.QUOTE.NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.QUOTE
              .ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.QUOTE.ANCHOR,
        },
        component: () => import('@/views/pages/customer/myRFQs/Quote.vue'),
      },
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.DRAFTS.PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.DRAFTS.NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.DRAFTS.TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.DRAFTS.NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.DRAFTS
              .ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.DRAFTS.ANCHOR,
        },
        component: () => import('@/views/pages/customer/myRFQs/Drafts.vue'),
      },
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].ARCHIVE.PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].ARCHIVE.NAME,
        meta: {
          title: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].ARCHIVE.TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].ARCHIVE.NAME],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].ARCHIVE.ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].ARCHIVE.ANCHOR,
        },
        component: () => import('@/views/pages/customer/Archive.vue'),
      },
    ],
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.OVERVIEW
        .NAME,
    },
    component: RouteWrapper,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.HOME.TITLE,
      requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
      history: [],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.HOME
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.HOME.ANCHOR,
    },
    children: [
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.OVERVIEW
          .PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.OVERVIEW
          .NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.OVERVIEW
              .TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.OVERVIEW
              .NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.OVERVIEW
              .ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.OVERVIEW
              .ANCHOR,
        },
        component: () => import('@/views/pages/customer/business/Overview.vue'),
      },
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.ACCEPTED
          .PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.ACCEPTED
          .NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.ACCEPTED
              .TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.ACCEPTED
              .NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.ACCEPTED
              .ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.ACCEPTED
              .ANCHOR,
        },
        component: () => import('@/views/pages/customer/business/Accepted.vue'),
      },
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS
          .CONTRACT_PREPARATION.PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS
          .CONTRACT_PREPARATION.NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS
              .CONTRACT_PREPARATION.TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS
              .CONTRACT_PREPARATION.NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS
              .CONTRACT_PREPARATION.ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS
              .CONTRACT_PREPARATION.ANCHOR,
        },
        component: () =>
          import('@/views/pages/customer/business/Preparation.vue'),
      },
      {
        path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.DELIVERY
          .PATH,
        name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.DELIVERY
          .NAME,
        meta: {
          title:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.DELIVERY
              .TITLE,
          requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
          history: [
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.DELIVERY
              .NAME,
          ],
          routeType:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.DELIVERY
              .ROUTE_TYPE,
          anchorTag:
            ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_BUSINESS.DELIVERY
              .ANCHOR,
        },
        component: () => import('@/views/pages/customer/business/Delivery.vue'),
      },
    ],
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].RFQ_DETAILS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].RFQ_DETAILS.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].RFQ_DETAILS.TITLE,
      requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
      history: [ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].RFQ_DETAILS.NAME],
      readOnlyComponents: [KEYS_RFQ.DELIVERY, KEYS_RFQ.SPECIFIED_LOT_SIZES],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].RFQ_DETAILS.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].RFQ_DETAILS.ANCHOR,
    },
    component: () => import('@/views/pages/customer/RFQDetails.vue'),
  },
  {
    path: ROUTER_DATA.SHARED.RFQ_NEW.PATH,
    name: ROUTER_DATA.SHARED.RFQ_NEW.NAME,
    meta: {
      title: ROUTER_DATA.SHARED.RFQ_NEW.TITLE,
      requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
      history: [ROUTER_DATA.SHARED.RFQ_NEW.NAME],
      routeType: ROUTER_DATA.SHARED.RFQ_EDIT.ROUTE_TYPE,
      anchorTag: ROUTER_DATA.SHARED.RFQ_EDIT.ANCHOR,
      requiredForValidationComponents: VALIDATION_FORM_KEYS.CUSTOMER.NEW_RFQ,
    },
    component: () => import('@/views/pages/shared/RFQEditor.vue'),
  },
  {
    path: ROUTER_DATA.SHARED.RFQ_EDIT.PATH,
    name: ROUTER_DATA.SHARED.RFQ_EDIT.NAME,
    meta: {
      title: ROUTER_DATA.SHARED.RFQ_EDIT.TITLE,
      requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
      history: [ROUTER_DATA.SHARED.RFQ_EDIT.NAME],
      routeType: ROUTER_DATA.SHARED.RFQ_EDIT.ROUTE_TYPE,
      anchorTag: ROUTER_DATA.SHARED.RFQ_EDIT.ANCHOR,
      requiredForValidationComponents: VALIDATION_FORM_KEYS.CUSTOMER.NEW_RFQ,
    },
    component: () => import('@/views/pages/shared/RFQEditor.vue'),
  },
]

const SUPPLIER_ROUTES = [
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].DASHBOARD.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].DASHBOARD.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].DASHBOARD.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].DASHBOARD.NAME],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].DASHBOARD.ROUTE_TYPE,
      anchorTag: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].DASHBOARD.ANCHOR,
    },
    component: () => import('@/views/pages/supplier/Dashboard.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX.NAME],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX.ROUTE_TYPE,
      anchorTag: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX.ANCHOR,
      requiredForValidationComponents: VALIDATION_FORM_KEYS.CUSTOMER.NEW_RFQ,
    },
    component: () => import('@/views/pages/supplier/RFQInbox.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS.ANCHOR,
    },
    component: () => import('@/views/pages/supplier/RunningRFQs.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].CONTRACTS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].CONTRACTS.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].CONTRACTS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].CONTRACTS.NAME],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].CONTRACTS.ROUTE_TYPE,
      anchorTag: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].CONTRACTS.ANCHOR,
    },
    component: () => import('@/views/pages/supplier/Contracts.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].ARCHIVE.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].ARCHIVE.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].ARCHIVE.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].ARCHIVE.NAME],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].ARCHIVE.ROUTE_TYPE,
      anchorTag: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].ARCHIVE.ANCHOR,
    },
    component: () => import('@/views/pages/supplier/Archive.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS.NAME],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS.ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PRICING_DELIVERY_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.PROPOSE_OPTIMIZED_PANEL,
      ],
      requiredForValidationComponents: VALIDATION_FORM_KEYS.SUPPLIER.OFFER,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS.ANCHOR,
      redirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX.NAME,
    },
    component: () => import('@/views/pages/supplier/RFQDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_EDIT.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_EDIT.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_EDIT.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_EDIT.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_EDIT
          .ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PRICING_DELIVERY_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
      ],
      requiredForValidationComponents: VALIDATION_FORM_KEYS.SUPPLIER.OFFER,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_EDIT.ANCHOR,
      redirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_INBOX.NAME,
      lockRedirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS.NAME,
    },
    component: () => import('@/views/pages/supplier/RFQDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_OFFER_MADE
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_OFFER_MADE
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_OFFER_MADE
          .TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_OFFER_MADE
          .NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_OFFER_MADE
          .ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PRICING_DELIVERY_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
      ],
      requiredForValidationComponents: VALIDATION_FORM_KEYS.SUPPLIER.OFFER,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_OFFER_MADE
          .ANCHOR,
      redirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS.NAME,
    },
    component: () => import('@/views/pages/supplier/RFQDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE]
      .RFQ_DETAILS_OFFER_MADE_EDIT.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE]
      .RFQ_DETAILS_OFFER_MADE_EDIT.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE]
          .RFQ_DETAILS_OFFER_MADE_EDIT.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE]
          .RFQ_DETAILS_OFFER_MADE_EDIT.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE]
          .RFQ_DETAILS_OFFER_MADE_EDIT.ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PRICING_DELIVERY_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
      ],
      requiredForValidationComponents: VALIDATION_FORM_KEYS.SUPPLIER.OFFER,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE]
          .RFQ_DETAILS_OFFER_MADE_EDIT.ANCHOR,
      redirectRoute:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RUNNING_RFQS.NAME,
      lockRedirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS.NAME,
    },
    component: () => import('@/views/pages/supplier/RFQDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_CONTRACT_DETAILS
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_CONTRACT_DETAILS
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_CONTRACT_DETAILS
          .TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_CONTRACT_DETAILS.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_CONTRACT_DETAILS
          .ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PRICING_DELIVERY_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.CONTRACT_DETAILS_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
      ],
      requiredForValidationComponents: [],
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_CONTRACT_DETAILS
          .ANCHOR,
      redirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].CONTRACTS.NAME,
    },
    component: () =>
      import('@/views/pages/supplier/RFQContractDetailsContainer.vue'),
  },
]

const SUPPLIER_EXPERT_ROUTES = [
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].DASHBOARD.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].DASHBOARD.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].DASHBOARD.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].DASHBOARD.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].DASHBOARD
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].DASHBOARD.ANCHOR,
    },
    component: () => import('@/views/pages/supplierExpert/Dashboard.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS.ANCHOR,
    },
    component: () => import('@/views/pages/supplierExpert/NewRFQS.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OVERVIEW.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OVERVIEW.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OVERVIEW.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OVERVIEW.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OVERVIEW
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OVERVIEW.ANCHOR,
    },
    component: () => import('@/views/pages/supplierExpert/myRFQs/Overview.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_PREPARATION
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_PREPARATION
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_PREPARATION
          .TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_PREPARATION
          .NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_PREPARATION
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_PREPARATION
          .ANCHOR,
    },
    component: () =>
      import('@/views/pages/supplierExpert/myRFQs/RFQPreparation.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RUNNING_RFQ.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RUNNING_RFQ.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RUNNING_RFQ.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RUNNING_RFQ.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RUNNING_RFQ
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RUNNING_RFQ.ANCHOR,
    },
    component: () =>
      import('@/views/pages/supplierExpert/myRFQs/RunningRFQ.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OFFER_COLLECTION
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OFFER_COLLECTION
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OFFER_COLLECTION
          .TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OFFER_COLLECTION
          .NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OFFER_COLLECTION
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].OFFER_COLLECTION
          .ANCHOR,
    },
    component: () =>
      import('@/views/pages/supplierExpert/myRFQs/OfferCollection.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .SOURCING_CONTRACTING.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .SOURCING_CONTRACTING.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .SOURCING_CONTRACTING.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .SOURCING_CONTRACTING.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .SOURCING_CONTRACTING.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .SOURCING_CONTRACTING.ANCHOR,
    },
    component: () =>
      import('@/views/pages/supplierExpert/contracts/SourcingContracting.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].CONTRACTS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].CONTRACTS.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].CONTRACTS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].CONTRACTS.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].CONTRACTS
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].CONTRACTS.ANCHOR,
    },
    component: () =>
      import('@/views/pages/supplierExpert/contracts/Contracts.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].ARCHIVE.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].ARCHIVE.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].ARCHIVE.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].ARCHIVE.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].ARCHIVE.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].ARCHIVE.ANCHOR,
    },
    component: () => import('@/views/pages/supplierExpert/Archive.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS
          .ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.COST_INDICATION_PREFIX,
        KEYS_RFQ.SUPPLIER_OFFER_STRATEGY_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      requiredForValidationComponents: VALIDATION_FORM_KEYS.SUPPLIER_EXPERT.NEW,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS.ANCHOR,
    },
    component: () => import('@/views/pages/supplierExpert/RFQDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS_EDIT
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS_EDIT
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS_EDIT
          .TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS_EDIT
          .NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS_EDIT
          .ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.COST_INDICATION_PREFIX,
        KEYS_RFQ.SUPPLIER_OFFER_STRATEGY_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      requiredForValidationComponents: VALIDATION_FORM_KEYS.SUPPLIER_EXPERT.NEW,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS.ANCHOR,
      lockRedirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].RFQ_DETAILS.NAME,
    },
    component: () => import('@/views/pages/supplierExpert/RFQDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_OFFER_PREPARATION_DETAILS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_OFFER_PREPARATION_DETAILS.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_PREPARATION_DETAILS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_PREPARATION_DETAILS.NAME,
      ],
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_PREPARATION_DETAILS.ANCHOR,
      redirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS.NAME,
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_PREPARATION_DETAILS.ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PRICING_DELIVERY_PREFIX,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.ASSIGNMENTS_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.COST_INDICATION_PREFIX,
        KEYS_RFQ.SUPPLIER_OFFER_STRATEGY_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
    },
    component: () =>
      import('@/views/pages/supplierExpert/RFQViewOfferDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_OFFER_REVIEW_DETAILS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_OFFER_REVIEW_DETAILS.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_REVIEW_DETAILS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_REVIEW_DETAILS.NAME,
      ],
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_REVIEW_DETAILS.ANCHOR,
      redirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS.NAME,
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_REVIEW_DETAILS.ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PRICING_DELIVERY_PREFIX,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.ASSIGNMENTS_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.SUPPLIER_SELECTION_QUOTA,
        KEYS_RFQ.COST_INDICATION_PREFIX,
        KEYS_RFQ.SUPPLIER_OFFER_STRATEGY_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SUPPLIER_EXPERT.CALCULATE_COQ,
    },
    component: () =>
      import('@/views/pages/supplierExpert/RFQViewOfferDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_OFFER_REVIEW_DETAILS_EDIT.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_OFFER_REVIEW_DETAILS_EDIT.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_REVIEW_DETAILS_EDIT.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_REVIEW_DETAILS_EDIT.NAME,
      ],
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_REVIEW_DETAILS_EDIT.ANCHOR,
      redirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].NEW_RFQS.NAME,
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_OFFER_REVIEW_DETAILS_EDIT.ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PRICING_DELIVERY_PREFIX,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.ASSIGNMENTS_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.COST_INDICATION_PREFIX,
        KEYS_RFQ.SUPPLIER_OFFER_STRATEGY_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SUPPLIER_EXPERT.CALCULATE_COQ,
    },
    component: () =>
      import('@/views/pages/supplierExpert/RFQViewOfferDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_UPLOAD_CONTRACT_DETAILS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_UPLOAD_CONTRACT_DETAILS.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_UPLOAD_CONTRACT_DETAILS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_UPLOAD_CONTRACT_DETAILS.NAME,
      ],
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_UPLOAD_CONTRACT_DETAILS.ANCHOR,
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_UPLOAD_CONTRACT_DETAILS.ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.CONTRACT_DETAILS_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.SUPPLIER_SELECTION_QUOTA,
        KEYS_RFQ.COST_INDICATION_PREFIX,
        KEYS_RFQ.SUPPLIER_OFFER_STRATEGY_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SUPPLIER_EXPERT.PREPARE_CONTRACTS,
    },
    component: () =>
      import('@/views/pages/supplierExpert/RFQUploadContractDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_UPLOAD_CONTRACT_DETAILS_EDIT.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
      .RFQ_UPLOAD_CONTRACT_DETAILS_EDIT.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_UPLOAD_CONTRACT_DETAILS_EDIT.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_UPLOAD_CONTRACT_DETAILS_EDIT.NAME,
      ],
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_UPLOAD_CONTRACT_DETAILS_EDIT.ANCHOR,
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_UPLOAD_CONTRACT_DETAILS_EDIT.ROUTE_TYPE,
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.SUPPLIER_SELECTION_QUOTA,
        KEYS_RFQ.COST_INDICATION_PREFIX,
        KEYS_RFQ.SUPPLIER_OFFER_STRATEGY_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      lockRedirectTarget:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE]
          .RFQ_UPLOAD_CONTRACT_DETAILS.NAME,
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SUPPLIER_EXPERT.PREPARE_CONTRACTS,
    },
    component: () =>
      import('@/views/pages/supplierExpert/RFQUploadContractDetails.vue'),
  },
]

const SUPPLIER_MANAGER_ROUTES = [
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].DASHBOARD.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].DASHBOARD.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].DASHBOARD.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].DASHBOARD.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].DASHBOARD
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].DASHBOARD.ANCHOR,
    },
    component: () => import('@/views/pages/supplierManager/Dashboard.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].RFQ_DETAILS
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].RFQ_DETAILS
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].RFQ_DETAILS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].RFQ_DETAILS.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].RFQ_DETAILS
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].RFQ_DETAILS
          .ANCHOR,
    },
    component: () => import('@/views/pages/supplierManager/RFQDetails.vue'),
  },
]

const SALES_MANAGER_ROUTES = [
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].DASHBOARD.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].DASHBOARD.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].DASHBOARD.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_MANAGER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].DASHBOARD.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].DASHBOARD.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].DASHBOARD.ANCHOR,
    },
    component: () => import('@/views/pages/salesManager/Dashboard.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].RFQ_DETAILS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].RFQ_DETAILS.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].RFQ_DETAILS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_MANAGER.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].RFQ_DETAILS.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].RFQ_DETAILS
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].RFQ_DETAILS.ANCHOR,
    },
    component: () => import('@/views/pages/salesManager/RFQDetails.vue'),
  },
]

const SALES_EXPERT_ROUTES = [
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD.ANCHOR,
    },
    component: () => import('@/views/pages/salesExpert/Dashboard.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].NEW_RFQS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].NEW_RFQS.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].NEW_RFQS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].NEW_RFQS.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].NEW_RFQS.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].NEW_RFQS.ANCHOR,
    },
    component: () => import('@/views/pages/salesExpert/NewRFQS.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].OVERVIEW.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].OVERVIEW.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].OVERVIEW.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].OVERVIEW.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].OVERVIEW.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].OVERVIEW.ANCHOR,
    },
    component: () => import('@/views/pages/salesExpert/myRFQs/Overview.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_VALIDATION.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_VALIDATION.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_VALIDATION.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_VALIDATION.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_VALIDATION
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_VALIDATION.ANCHOR,
    },
    component: () => import('@/views/pages/salesExpert/myRFQs/Validation.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].QUOTE_PREPARATION
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].QUOTE_PREPARATION
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].QUOTE_PREPARATION
          .TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].QUOTE_PREPARATION
          .NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].QUOTE_PREPARATION
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].QUOTE_PREPARATION
          .ANCHOR,
    },
    component: () =>
      import('@/views/pages/salesExpert/myRFQs/QuotePreparation.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACTS_OVERVIEW
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACTS_OVERVIEW
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACTS_OVERVIEW
          .TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACTS_OVERVIEW
          .NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACTS_OVERVIEW
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACTS_OVERVIEW
          .ANCHOR,
    },
    component: () => import('@/views/pages/salesExpert/contracts/Overview.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACT_PREPARATION
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACT_PREPARATION
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACT_PREPARATION
          .TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACT_PREPARATION
          .NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACT_PREPARATION
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].CONTRACT_PREPARATION
          .ANCHOR,
    },
    component: () =>
      import('@/views/pages/salesExpert/contracts/ContractPreparation.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_CONTRACT.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_CONTRACT.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_CONTRACT.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_CONTRACT.NAME,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_CONTRACT
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].IN_CONTRACT.ANCHOR,
    },
    component: () =>
      import('@/views/pages/salesExpert/contracts/InContract.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].ARCHIVE.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].ARCHIVE.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].ARCHIVE.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].ARCHIVE.NAME],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].ARCHIVE.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].ARCHIVE.ANCHOR,
    },
    component: () => import('@/views/pages/salesExpert/Archive.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS.NAME,
      ],
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.CONTRACT_DETAILS_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.SUPPLIER_SELECTION_QUOTA,
        KEYS_RFQ.QUOTATION_AND_DELIVERY_STRATEGY_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS.ANCHOR,
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SALES_EXPERT.VALIDATED,
    },
    component: () => import('@/views/pages/salesExpert/RFQDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS_EDIT
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS_EDIT
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS_EDIT
          .TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS_EDIT.NAME,
      ],
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.CONTRACT_DETAILS_PREFIX,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.SUPPLIER_SELECTION_QUOTA,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS_EDIT
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].RFQ_DETAILS_EDIT
          .ANCHOR,
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SALES_EXPERT.VALIDATED,
    },
    component: () => import('@/views/pages/salesExpert/RFQDetails.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
      .RFQ_DETAILS_CALCULATION.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
      .RFQ_DETAILS_CALCULATION.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION.NAME,
      ],
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.CONTRACT_DETAILS_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.SUPPLIER_SELECTION_QUOTA,
        KEYS_RFQ.SUPPLIER_NRE,
        KEYS_RFQ.PCB_COST_PREFIX,
        KEYS_RFQ.QUOTE_PREPARATION,
        KEYS_RFQ.SALES_PRICE,
        KEYS_RFQ.MASS_PRODUCTION_PREFIX,
        KEYS_RFQ.QUOTATION_SUMMARY,
        KEYS_RFQ.LEADTIMES_PREFIX,
        KEYS_RFQ.QUOTATION_NOTES,
        KEYS_RFQ.CUSTOMER_QUOTE_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
        KEYS_RFQ.QUOTATION_AND_DELIVERY_STRATEGY_PREFIX,
        KEYS_RFQ.QUOTATION_NOTES_PREFIX,
        KEYS_RFQ.TECH_OFFER_BASE_CUSTOMER_PREFIX,
        KEYS_RFQ.SUPPLIER_NRE_PREFIX,
        KEYS_RFQ.QUOTE_PREPARATION_PREFIX,
        KEYS_RFQ.CUSTOMER_QUOTE_PANEL_SELECTION,
        KEYS_RFQ.INTERNAL_QUOTE_PANEL_SELECTION,
        KEYS_RFQ.SAMPLES_NRE_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION.ANCHOR,
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SALES_EXPERT.QUOTE_CALCULATION,
    },
    component: () =>
      import('@/views/pages/salesExpert/RFQDetailsCalculation.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
      .RFQ_DETAILS_CALCULATION_EDIT.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
      .RFQ_DETAILS_CALCULATION_EDIT.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_EDIT.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_EDIT.NAME,
      ],
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.CONTRACT_DETAILS_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.SUPPLIER_SELECTION_QUOTA,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
        KEYS_RFQ.QUOTATION_AND_DELIVERY_STRATEGY_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_EDIT.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_EDIT.ANCHOR,
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SALES_EXPERT.QUOTE_CALCULATION,
    },
    component: () =>
      import('@/views/pages/salesExpert/RFQDetailsCalculation.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
      .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
      .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE.NAME,
      ],
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.CONTRACT_DETAILS_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.SUPPLIER_SELECTION_QUOTA,
        KEYS_RFQ.SUPPLIER_NRE,
        KEYS_RFQ.PCB_COST_PREFIX,
        KEYS_RFQ.QUOTE_PREPARATION,
        KEYS_RFQ.SALES_PRICE,
        KEYS_RFQ.MASS_PRODUCTION_PREFIX,
        KEYS_RFQ.QUOTATION_SUMMARY,
        KEYS_RFQ.LEADTIMES_PREFIX,
        KEYS_RFQ.QUOTATION_NOTES,
        KEYS_RFQ.CUSTOMER_QUOTE_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
        KEYS_RFQ.QUOTATION_AND_DELIVERY_STRATEGY_PREFIX,
        KEYS_RFQ.QUOTATION_NOTES_PREFIX,
        KEYS_RFQ.TECH_OFFER_BASE_CUSTOMER_PREFIX,
        KEYS_RFQ.SUPPLIER_NRE_PREFIX,
        KEYS_RFQ.QUOTE_PREPARATION_PREFIX,
        KEYS_RFQ.CUSTOMER_QUOTE_PANEL_SELECTION,
        KEYS_RFQ.INTERNAL_QUOTE_PANEL_SELECTION,
        KEYS_RFQ.SAMPLES_NRE_PREFIX,
        KEYS_RFQ.SALES_EXPERT_PCB_COST_PREFIX,
        KEYS_RFQ.PRICE_INDICATION_COST_MATRIX_PREFIX,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE.ANCHOR,
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SALES_EXPERT.QUOTE_CALCULATION_INSTANT_QUOTE,
    },
    component: () =>
      import('@/views/pages/salesExpert/RFQDetailsCalculation.vue'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
      .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE_EDIT.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
      .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE_EDIT.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE_EDIT.TITLE,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
      history: [
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE_EDIT.NAME,
      ],
      readOnlyComponents: [
        KEYS_RFQ.PROJECT_DATA_PREFIX,
        KEYS_RFQ.SPECIFIED_LOT_SIZES,
        KEYS_RFQ.DOCUMENTS_PREFIX,
        KEYS_RFQ.DELIVERY,
        KEYS_RFQ.QUOTES,
        KEYS_RFQ.BASIC_FACTOR_PREFIX,
        KEYS_RFQ.COPPER_CRITERIA_PREFIX,
        KEYS_RFQ.PCB_DIMENSIONS_PREFIX,
        KEYS_RFQ.OTHERS_PREFIX,
        KEYS_RFQ.ADDITIONAL_INFO,
        KEYS_RFQ.NOTES_FOR_ME,
        KEYS_RFQ.NOTES_SALES,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.COQ_CURRENCY_EXCHANGE_RATE_PREFIX,
        KEYS_RFQ.QUOTATIONS,
        KEYS_RFQ.TECH_CSCC_PREFIX,
        KEYS_RFQ.OFFER_UPLOAD_PREFIX,
        KEYS_RFQ.NRE_MOV_PREFIX,
        KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
        KEYS_RFQ.PANEL_DIMENSIONS_FOR_SUPPLIER_PREFIX,
        KEYS_RFQ.SUPPLIER_SELECTION_PREFIX,
        KEYS_RFQ.CONTRACT_DETAILS_PREFIX,
        KEYS_RFQ.INDIVIDUAL_SCENARIO,
        KEYS_RFQ.SUPPLIER_SCENARIOS,
        KEYS_RFQ.QUOTA_ALLOCATION,
        KEYS_RFQ.SUPPLIER_SELECTION_QUOTA,
        KEYS_RFQ.PRICE_INDICATION_PREFIX,
        KEYS_RFQ.QUOTATION_AND_DELIVERY_STRATEGY_PREFIX,
        KEYS_RFQ.SALES_EXPERT_PCB_COST_PREFIX,
      ],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE_EDIT.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE]
          .RFQ_DETAILS_CALCULATION_INSTANT_QUOTE_EDIT.ANCHOR,
      requiredForValidationComponents:
        VALIDATION_FORM_KEYS.SALES_EXPERT.QUOTE_CALCULATION_INSTANT_QUOTE,
    },
    component: () =>
      import('@/views/pages/salesExpert/RFQDetailsCalculation.vue'),
  },
]

const DEBUG_ROUTES = [
  {
    path: ROUTER_DATA.DEBUG.BADGES.PATH,
    name: ROUTER_DATA.DEBUG.BADGES.NAME,
    meta: {
      title: ROUTER_DATA.DEBUG.BADGES.TITLE,
      history: [ROUTER_DATA.DEBUG.BADGES.NAME],
      routeType: ROUTER_DATA.DEBUG.BADGES.ROUTE_TYPE,
      anchorTag: ROUTER_DATA.DEBUG.BADGES.ANCHOR,
    },
    component: () => import('@/views/pages/debug/DebugBadges.vue'),
  },
]

const SHARED_USER_ROUTES = [
  {
    path: ROUTER_DATA.SHARED.PROFILE.PATH,
    name: ROUTER_DATA.SHARED.PROFILE.NAME,
    meta: {
      title: ROUTER_DATA.SHARED.PROFILE.TITLE,
      history: [ROUTER_DATA.SHARED.PROFILE.NAME],
      routeType: ROUTER_DATA.SHARED.PROFILE.ROUTE_TYPE,
      anchorTag: ROUTER_DATA.SHARED.PROFILE.ANCHOR,
    },
    component: () => import('@/views/pages/shared/Profile.vue'),
  },
  {
    path: ROUTER_DATA.SHARED.SETTINGS.PATH,
    name: ROUTER_DATA.SHARED.SETTINGS.NAME,
    meta: {
      title: ROUTER_DATA.SHARED.SETTINGS.TITLE,
      history: [ROUTER_DATA.SHARED.SETTINGS.NAME],
      routeType: ROUTER_DATA.SHARED.SETTINGS.ROUTE_TYPE,
      anchorTag: ROUTER_DATA.SHARED.SETTINGS.ANCHOR,
    },
    component: () => import('@/views/pages/shared/Settings.vue'),
  },
  {
    path: ROUTER_DATA.SHARED.ADDRESS_MANAGEMENT.PATH,
    name: ROUTER_DATA.SHARED.ADDRESS_MANAGEMENT.NAME,
    meta: {
      title: ROUTER_DATA.SHARED.ADDRESS_MANAGEMENT.TITLE,
      history: [ROUTER_DATA.SHARED.ADDRESS_MANAGEMENT.NAME],
      requiredRole: USER_STATUS.ADDRESS_MANAGEMENT_ACCESS,
      routeType: ROUTER_DATA.SHARED.ADDRESS_MANAGEMENT.ROUTE_TYPE,
      anchorTag: ROUTER_DATA.SHARED.ADDRESS_MANAGEMENT.ANCHOR,
      requiredForValidationComponents: VALIDATION_FORM_KEYS.CUSTOMER.ADDRESS,
    },
    component: () => import('@/views/pages/shared/AddressManagement.vue'),
  },
  {
    path: ROUTER_DATA.SHARED.USER_MANAGEMENT.PATH,
    name: ROUTER_DATA.SHARED.USER_MANAGEMENT.NAME,
    meta: {
      title: ROUTER_DATA.SHARED.USER_MANAGEMENT.TITLE,
      history: [ROUTER_DATA.SHARED.USER_MANAGEMENT.NAME],
      requiredRole: USER_STATUS.USER_MANAGEMENT_ACCESS,
      routeType: ROUTER_DATA.SHARED.USER_MANAGEMENT.ROUTE_TYPE,
      anchorTag: ROUTER_DATA.SHARED.USER_MANAGEMENT.ANCHOR,
    },
    component: () => import('@/views/pages/shared/UserManagement.vue'),
  },
  {
    path: ROUTER_DATA.SHARED.NOTIFICATIONS.PATH,
    name: ROUTER_DATA.SHARED.NOTIFICATIONS.NAME,
    meta: {
      title: ROUTER_DATA.SHARED.NOTIFICATIONS.TITLE,
      history: [ROUTER_DATA.SHARED.NOTIFICATIONS.NAME],
      routeType: ROUTER_DATA.SHARED.NOTIFICATIONS.ROUTE_TYPE,
      anchorTag: ROUTER_DATA.SHARED.NOTIFICATIONS.ANCHOR,
    },
    component: () => import('@/views/pages/shared/Notifications.vue'),
  },
  {
    path: ROUTER_DATA.SHARED.CHANGE_PASSWORD.PATH,
    name: ROUTER_DATA.SHARED.CHANGE_PASSWORD.NAME,
    meta: {
      title: ROUTER_DATA.SHARED.CHANGE_PASSWORD.TITLE,
      history: [ROUTER_DATA.SHARED.CHANGE_PASSWORD.NAME],
      routeType: ROUTER_DATA.SHARED.CHANGE_PASSWORD.ROUTE_TYPE,
      anchorTag: ROUTER_DATA.SHARED.CHANGE_PASSWORD.ANCHOR,
    },
    component: () => import('@/views/pages/shared/ChangePassword.vue'),
  },
]

const NO_PERMISSIONS_ROUTES = [
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.NAME,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.TITLE,
      history: [ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.NAME],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.ANCHOR,
    },
    component: () => import('@/views/pages/noPermission/Login'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].REGISTER_CONFIRM
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].REGISTER_CONFIRM
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].REGISTER_CONFIRM
          .TITLE,
      history: [],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].REGISTER_CONFIRM
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].REGISTER_CONFIRM
          .ANCHOR,
    },
    component: () => import('@/views/pages/noPermission/RegisterConfirm'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].RESET_PASSWORD
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].RESET_PASSWORD
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].RESET_PASSWORD.TITLE,
      history: [],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].RESET_PASSWORD
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].RESET_PASSWORD
          .ANCHOR,
    },
    component: () => import('@/views/pages/noPermission/ResetPassword'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].CREATE_NEW_PASSWORD
      .PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].CREATE_NEW_PASSWORD
      .NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].CREATE_NEW_PASSWORD
          .TITLE,
      history: [],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].CREATE_NEW_PASSWORD
          .ROUTE_TYPE,
      anchorTag:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].CREATE_NEW_PASSWORD
          .ANCHOR,
      remoteEmailValidation: true,
    },
    component: () => import('@/views/pages/noPermission/SetNewPassword'),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE]
      .SET_INITIAL_B2B_PASSWORD.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE]
      .SET_INITIAL_B2B_PASSWORD.NAME,
    meta: {
      title:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE]
          .SET_INITIAL_B2B_PASSWORD.TITLE,
      history: [],
      routeType:
        ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE]
          .SET_INITIAL_B2B_PASSWORD.ROUTE_TYPE,
      remoteEmailValidation: true,
    },
    component: () => import('@/views/pages/noPermission/SetNewPassword'),
  },
]

function adjustChildrenRouteNames(group, children) {
  let adjustedChildren = cloneDeep(children)
  adjustedChildren.forEach((child) => {
    child.name = `${group}${child.name}`
  })
  return adjustedChildren
}

function getRouteChildren(role) {
  let children
  if (role === PERMISSION_GROUPS.NO_PERMISSION.VALUE) {
    children = NO_PERMISSIONS_ROUTES
  } else {
    switch (role) {
      case PERMISSION_GROUPS.SUPER_ADMIN.VALUE:
        children = SUPER_ADMIN_ROUTES
        break
      case PERMISSION_GROUPS.CUSTOMER.VALUE:
        children = CUSTOMER_ROUTES
        break
      case PERMISSION_GROUPS.SUPPLIER.VALUE:
        children = SUPPLIER_ROUTES
        break
      case PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE:
        children = SUPPLIER_EXPERT_ROUTES
        break
      case PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE:
        children = SUPPLIER_MANAGER_ROUTES
        break
      case PERMISSION_GROUPS.SALES_MANAGER.VALUE:
        children = SALES_MANAGER_ROUTES
        break
      case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
        children = SALES_EXPERT_ROUTES
        break
    }
    children = children.concat(
      adjustChildrenRouteNames(role, SHARED_USER_ROUTES),
    )
  }
  if (IS_DEV_MODE) {
    children = children.concat(adjustChildrenRouteNames(role, DEBUG_ROUTES))
  }
  return children
}

const routes = [
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].DASHBOARD.NAME,
    },
    component: DefaultLayout,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPER_ADMIN.VALUE].HOME.TITLE,
      history: [],
      requiresAuth: true,
      requiredRole: [PERMISSION_GROUPS.SUPER_ADMIN.VALUE],
    },
    children: getRouteChildren(PERMISSION_GROUPS.SUPER_ADMIN.VALUE),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD.NAME,
    },
    component: DefaultLayout,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].HOME.TITLE,
      history: [],
      requiresAuth: true,
      requiredRole: [PERMISSION_GROUPS.CUSTOMER.VALUE],
    },
    children: getRouteChildren(PERMISSION_GROUPS.CUSTOMER.VALUE),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].DASHBOARD.NAME,
    },
    component: DefaultLayout,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].HOME.TITLE,
      history: [],
      requiresAuth: true,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER.VALUE],
    },
    children: getRouteChildren(PERMISSION_GROUPS.SUPPLIER.VALUE),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].DASHBOARD.NAME,
    },
    component: DefaultLayout,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE].HOME.TITLE,
      history: [],
      requiresAuth: true,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE],
    },
    children: getRouteChildren(PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].DASHBOARD
        .NAME,
    },
    component: DefaultLayout,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE].HOME.TITLE,
      history: [],
      requiresAuth: true,
      requiredRole: [PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE],
    },
    children: getRouteChildren(PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].DASHBOARD.NAME,
    },
    component: DefaultLayout,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SALES_EXPERT.VALUE].HOME.TITLE,
      history: [],
      requiresAuth: true,
      requiredRole: [PERMISSION_GROUPS.SALES_EXPERT.VALUE],
    },
    children: getRouteChildren(PERMISSION_GROUPS.SALES_EXPERT.VALUE),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].DASHBOARD.NAME,
    },
    component: DefaultLayout,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.SALES_MANAGER.VALUE].HOME.TITLE,
      history: [],
      requiresAuth: true,
      requiredRole: [PERMISSION_GROUPS.SALES_MANAGER.VALUE],
    },
    children: getRouteChildren(PERMISSION_GROUPS.SALES_MANAGER.VALUE),
  },
  {
    path: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].HOME.PATH,
    name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].HOME.NAME,
    redirect: {
      name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.NAME,
    },
    component: NoPermissionLayout,
    meta: {
      title: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].HOME.TITLE,
      history: [],
      requiresAuth: false,
      requiredRole: [PERMISSION_GROUPS.NO_PERMISSION.VALUE],
    },
    children: getRouteChildren(PERMISSION_GROUPS.NO_PERMISSION.VALUE),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  store.commit('setRedirectComponentEvent', null)
  store.commit('clearActiveOffersHashmap')
  store.commit('setPreviousRouteName', from.name)
  store.commit('setActiveEntityData', null)
  if (
    ![ROUTE_TYPES.RFQ_DETAILS, ROUTE_TYPES.RFQ_EDITOR].includes(
      to.meta.routeType,
    )
  ) {
    store.commit('resetRfQActionStatus')
  }
  await store.dispatch('tryAutoLogin')
  store.commit('emptyWIPStatusToasts')
  if (
    to.meta &&
    to.meta.requiredRole &&
    to.meta.requiredRole.includes(store.getters.getActivePermissionGroup)
  ) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.isLoggedIn) {
        await store.dispatch('prepareRouteChange')
        store.commit('resetStates')
        next({
          name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.NAME,
        })
      } else {
        if (
          (from.name === ROUTER_DATA.SHARED.RFQ_EDIT.NAME ||
            from.name === ROUTER_DATA.SHARED.RFQ_NEW.NAME) &&
          !store.getters.isRFQEditorModalVisible &&
          !store.getters.isForcedRFQEditorExit &&
          store.getters.isActiveRFQModified
        ) {
          store.commit('saveCurrentRouterDestinations', to, from, next)
          if (from.name === ROUTER_DATA.SHARED.RFQ_NEW.NAME) {
            store.commit('showRFQEditorDeleteModal', false)
          } else {
            store.commit('showRFQEditorModal', false)
          }
          store.commit('resetStates')
          next(false)
        } else {
          store.commit('hideRFQEditorModal')
          store.commit('toggleForceRFQEditorExit', false)
          if (
            ![
              ROUTER_DATA.SHARED.RFQ_EDIT.NAME,
              `${store.getters.getActivePermissionGroup}${ROUTER_DATA.SHARED.ADDRESS_MANAGEMENT.NAME}`,
              ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE].RFQ_DETAILS_EDIT
                .NAME,
              ROUTER_DATA[PERMISSION_GROUPS.SUPPLIER.VALUE]
                .RFQ_DETAILS_OFFER_MADE_EDIT.NAME,
            ].includes(to.name)
          ) {
            await store.dispatch('unlockRFQ')
            store.commit('toggleModifiedActiveRFQ', false)
          }
          if (store.getters.isLogoutInProgress) {
            store.commit('triggerLogout')
          } else {
            await store.dispatch('prepareRouteChange')
            store.commit('resetStates')
            next()
          }
        }
      }
    } else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
      if (store.getters.isLoggedIn) {
        await store.dispatch('prepareRouteChange')
        store.commit('resetStates')
        next({
          name: ROUTER_DATA[store.getters.getActivePermissionGroup].HOME.NAME,
        })
      } else {
        await store.dispatch('prepareRouteChange')
        store.commit('resetStates')
        next()
      }
    } else {
      await store.dispatch('prepareRouteChange')
      store.commit('resetStates')
      next()
    }
  } else {
    store.commit('resetStates')
    next({
      name: ROUTER_DATA[store.getters.getActivePermissionGroup].HOME.NAME,
    })
  }
})

router.beforeResolve(async () => {
  let placeholderLoadingScreen = document.getElementById(
    'placeholder-loading-screen',
  )
  if (placeholderLoadingScreen) {
    placeholderLoadingScreen.remove()
  }
})

export default router
