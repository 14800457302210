import cloneDeep from 'lodash/cloneDeep'
import { rfqScenariosForSupplierAnnualQuantity } from '@/assets/inputDataObjects/types/rfq/rfqScenarioForSupplier'
import validationMixins from '@/mixins/validationMixins'
import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'

const SUPPLIER_SCENARIO = {
  selectedScenarios: new Set(),
  annualQuantity: rfqScenariosForSupplierAnnualQuantity,
}

export default {
  props: {
    supplierScenarios: {
      type: Object,
    },
  },
  computed: {
    SUPPLIER_SCENARIO_TARGET() {
      if (this.$props.supplierScenarios) {
        return this.$props.supplierScenarios
      } else if (this.$data[KEYS_GENERAL.FORM]) {
        return this.$data[KEYS_GENERAL.FORM][KEYS_RFQ.SUPPLIER_SCENARIOS]
      }
    },
  },
  mixins: [validationMixins],
  methods: {
    supplierScenarioGotAnnualQuantityMixin(index = null) {
      const INPUT_MIN = parseFloat(
        rfqScenariosForSupplierAnnualQuantity.inputMin,
      )
      const INPUT_MAX = parseFloat(
        rfqScenariosForSupplierAnnualQuantity.inputMax,
      )
      if (index === null) {
        return !this.SUPPLIER_SCENARIO_TARGET.data.some((supplierScenario) => {
          return (
            !supplierScenario.annualQuantity.value ||
            parseFloat(supplierScenario.annualQuantity.value) < INPUT_MIN ||
            parseFloat(supplierScenario.annualQuantity.value) > INPUT_MAX
          )
        })
      } else {
        return (
          this.SUPPLIER_SCENARIO_TARGET.data[index].annualQuantity.value &&
          parseFloat(
            this.SUPPLIER_SCENARIO_TARGET.data[index].annualQuantity.value,
          ) >= INPUT_MIN &&
          parseFloat(
            this.SUPPLIER_SCENARIO_TARGET.data[index].annualQuantity.value,
          ) <= INPUT_MAX
        )
      }
    },
    supplierScenarioGotCustomerScenarioMixin(index = null) {
      if (index === null) {
        return !this.SUPPLIER_SCENARIO_TARGET.data.some((supplierScenario) => {
          return supplierScenario.selectedScenarios.size === 0
        })
      } else {
        return (
          this.SUPPLIER_SCENARIO_TARGET.data[index].selectedScenarios.size > 0
        )
      }
    },
    updateSupplierScenarioIndexMixin() {
      this.SUPPLIER_SCENARIO_TARGET.data.forEach((item, index) => {
        item.annualQuantity.keyName =
          SUPPLIER_SCENARIO.annualQuantity.keyName + (index + 1)
      })
    },
    updateSupplierScenarioErrorCountMixin() {
      let errorCount = 0
      this.SUPPLIER_SCENARIO_TARGET.data.forEach((supplierScenario) => {
        if (supplierScenario.selectedScenarios.size === 0) {
          errorCount++
        }
        if (
          parseFloat(supplierScenario.annualQuantity.value) >=
            parseFloat(supplierScenario.annualQuantity.inputMin) &&
          parseFloat(supplierScenario.annualQuantity.value) <=
            parseFloat(supplierScenario.annualQuantity.inputMax)
        ) {
          supplierScenario.annualQuantity.invalid = false
        } else {
          supplierScenario.annualQuantity.invalid = true
          errorCount++
        }
      })
      this.$data[KEYS_GENERAL.FORM][
        KEYS_RFQ.SUPPLIER_SCENARIOS
      ].errorCount.sum = errorCount
      this.setErrorCounterMixin(this.$data[KEYS_GENERAL.FORM])
    },
    addSupplierScenarioMixin(resetArray = false, validate = true) {
      if (resetArray) {
        this.SUPPLIER_SCENARIO_TARGET.data = []
      }
      this.SUPPLIER_SCENARIO_TARGET.data.push(cloneDeep(SUPPLIER_SCENARIO))
      this.updateSupplierScenarioIndexMixin()
      if (
        this.$data[KEYS_GENERAL.IMPORT_DATA][
          API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_YEARS
        ] === 1
      ) {
        this.updateCustomerScenarioSelectionMixin({
          supplierScenarioIndex: 0,
          customerScenarioIndex: 0,
        })
      }
      if (validate) {
        this.updateSupplierScenarioErrorCountMixin()
      }
    },
    removeSupplierScenarioMixin(index) {
      this.SUPPLIER_SCENARIO_TARGET.data.splice(index, 1)
      this.updateSupplierScenarioIndexMixin()
      this.updateSupplierScenarioErrorCountMixin()
    },
    updateCustomerScenarioSelectionMixin(event) {
      if (
        this.SUPPLIER_SCENARIO_TARGET.data[
          event.supplierScenarioIndex
        ].selectedScenarios.has(event.customerScenarioIndex)
      ) {
        this.SUPPLIER_SCENARIO_TARGET.data[
          event.supplierScenarioIndex
        ].selectedScenarios.delete(event.customerScenarioIndex)
      } else {
        this.SUPPLIER_SCENARIO_TARGET.data[
          event.supplierScenarioIndex
        ].selectedScenarios.add(event.customerScenarioIndex)
      }
      this.updateSupplierScenarioErrorCountMixin()
    },
    updateSupplierScenarioAnnualQuantityMixin(event) {
      let newValue = event.input.value
      if (!newValue) {
        newValue = '0'
      }
      let inputTarget = document.getElementById(
        this.getInputAnchorTagMixin(
          rfqScenariosForSupplierAnnualQuantity.keyName + (event.index + 1),
        ),
      )
      inputTarget.value = this.restoreNumberFormatMixin(
        rfqScenariosForSupplierAnnualQuantity,
        newValue,
      )
      this.SUPPLIER_SCENARIO_TARGET.data[event.index].annualQuantity.value =
        newValue
      this.updateSupplierScenarioErrorCountMixin()
    },
  },
}
