const PREFIX = 'passwordCheck'

const NO_EMAIL = `${PREFIX}NoEmail`
const MINIMUM_LENGTH = `${PREFIX}MinimumLength`
const REQUIRED_CHARACTERS = `${PREFIX}RequiredCharacters`
const FORM = `${PREFIX}Form`

export const KEYS_PASSWORD_CHECK = {
  FORM,
  NO_EMAIL,
  MINIMUM_LENGTH,
  REQUIRED_CHARACTERS,
}
