import {
  cilChevronTop,
  cilChevronBottom,
  cilMenu,
  cilBell,
  cilXCircle,
  cilCheckCircle,
  cilArrowLeft,
  cilArrowRight,
  cilExternalLink,
  cilWarning,
  cilInfo,
  cilFile,
  cilOptions,
  cilMagnifyingGlass,
  cilSpeedometer,
  cilPlus,
  cilList,
  cilFolderOpen,
  cilPencil,
  cilTrash,
  cilAvTimer,
  cilLockLocked,
  cilLockUnlocked,
  cilCircle,
  cilFilterX,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {},
  {
    cilChevronTop,
    cilChevronBottom,
    cilMenu,
    cilBell,
    cilXCircle,
    cilCheckCircle,
    cilArrowLeft,
    cilArrowRight,
    cilExternalLink,
    cilWarning,
    cilInfo,
    cilFile,
    cilOptions,
    cilMagnifyingGlass,
    cilSpeedometer,
    cilPlus,
    cilList,
    cilFolderOpen,
    cilPencil,
    cilTrash,
    cilAvTimer,
    cilLockLocked,
    cilLockUnlocked,
    cilCircle,
    cilFilterX,
  },
  {},
)
