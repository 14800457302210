import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { IMPORT_EXPORT } from '@/constants/importExport'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { FORM_ITEMS } from '@/constants/formItems'
import { VALIDATION_TYPE } from '@/constants/validation'
import { DEFAULT_MASS_PRODUCTION_MARGIN } from '@/constants/constants'

let itemRfQQuoteMassProduction = {
  keyName: KEYS_RFQ.MASS_PRODUCTION_TABLES,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.QUOTATION.QUOTE_PREPARATION.LOGISTIC_COSTS,
  ],
  required: true,
  errorCount: 0,
  subItems: [
    {
      keyName: KEYS_RFQ.MASS_PRODUCTION_TABLES + KEYS_GENERAL.SUB_ITEM_1,
      keyNameParent: KEYS_RFQ.MASS_PRODUCTION_TABLES,
      keyNameGroup: KEYS_RFQ.MASS_PRODUCTION_PREFIX,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.QUOTATION.QUOTE_PREPARATION.LOGISTIC_COSTS,
      ],
      apiTransferStyle:
        IMPORT_EXPORT.API_TRANSFER_STYLE.CUSTOMER_SPECIFIED_PANEL,
      singleLevelObject: true,
      formType: FORM_ITEMS.TYPES.MASS_PRODUCTION_TABLES,
      required: true,
      errorCount: {
        inputs: 0,
        sum: 0,
      },
      data: new Map(),
      inputMin: '-100',
      inputMax: '100',
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
      validationSettings: {
        formatNumber: false,
        decimal: { decimal_digits: '2' },
      },
      messages: {
        placeholder: 'Not offered',
      },
    },
    {
      keyName: KEYS_RFQ.MASS_PRODUCTION_TABLES + KEYS_GENERAL.SUB_ITEM_2,
      keyNameParent: KEYS_RFQ.MASS_PRODUCTION_TABLES,
      keyNameGroup: KEYS_RFQ.MASS_PRODUCTION_PREFIX,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.QUOTATION.QUOTE_PREPARATION.LOGISTIC_COSTS,
      ],
      apiTransferStyle: IMPORT_EXPORT.API_TRANSFER_STYLE.PROPOSED_PANEL,
      singleLevelObject: true,
      formType: FORM_ITEMS.TYPES.MASS_PRODUCTION_TABLES,
      required: true,
      errorCount: {
        inputs: 0,
        sum: 0,
      },
      data: new Map(),
      inputMin: '-100',
      inputMax: '100',
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
      validationSettings: {
        formatNumber: false,
        decimal: { decimal_digits: '2' },
      },
      messages: {
        placeholder: 'Not offered',
      },
    },
  ],
}

export let itemRfQQuoteMassProductionMarginInput = {
  value: '',
  style: 'e-table-input mb-0',
  floatingLabel: false,
  errorCount: 0,
  inputMin: '-100',
  inputMax: '100',
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
  validationSettings: {
    formatNumber: false,
    decimal: { decimal_digits: '2' },
  },
  margin: {
    threshold: {
      red: 0,
      yellow: DEFAULT_MASS_PRODUCTION_MARGIN,
    },
  },
  messages: {
    placeholder: 'Not offered',
  },
}

export let rfqMassProductionTypes = {
  [itemRfQQuoteMassProduction.keyName]: itemRfQQuoteMassProduction,
}
