export const FORM_ITEMS = {
  SELECTION_SUB_ITEM: 'PCBPowerhouse-Selection-Subitem',
  USE_ITEM_VALUE: 'eUseOriginalObjectValue',
  USE_RADIO_BUTTON: 'eUseRadioButton',
  TYPES: {
    SELECT: 'select',
    RADIO: 'radio',
    TEXTAREA: 'textarea',
    DATE: 'date',
    RANGE: 'range',
    CHECKBOX: 'checkbox',
    LOGISTIC_COSTS_TABLES: 'logisticCostsTable',
    MASS_PRODUCTION_TABLES: 'massProductionTable',
    DELIVERY_LOCATION: 'deliveryLocation',
    SUPPLIER_FORMS: 'supplierForms',
    TOGGLE_SWITCH: 'toggleSwitch',
    SWITCH: 'Switch',
    QUOTATION: 'Quotation',
    CURRENCY: 'currency',
    LOT_SIZES: 'lotSizes',
    SUPPLIER_SELECTION: 'supplierSelection',
    SAMPLES_NRE: 'samplesNRE',
    SUPPLIER_SCENARIOS: 'supplierScenarios',
  },
  INPUT_TYPE: {
    PASSWORD: 'password',
    FILE: 'file',
  },
  UNITS: {
    LAYERS: 'L',
    MILLIMETRE: 'mm',
    MICROMETRE: 'µm',
    PERCENT: '%',
  },
}

export const FORM_TYPES_WITH_SUB_FORM_DATA = [
  FORM_ITEMS.TYPES.SUPPLIER_SELECTION,
]
