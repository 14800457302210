<template>
  <CToaster placement="bottom-end">
    <CToast
      class="align-items-center"
      :key="toast.timestamp"
      v-for="toast in this.$store.getters.getWIPStatusToasts"
    >
      <div class="d-flex">
        <CToastBody> {{ toast.content }}</CToastBody>
        <CToastClose class="me-2 m-auto" />
      </div>
    </CToast>
  </CToaster>
</template>

<script>
export default {
  name: 'AppWIPStatusToast',
}
</script>

<style scoped></style>
