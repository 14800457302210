<template>
  <CSidebar
    position="fixed"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand
      ><AppBrand :targetShopware="true" :shortBranding="true"></AppBrand
    ></CSidebarBrand>
    <AppSidebarNav />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AppSidebarNav from '@/components/appSidebar/AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import AppBrand from '@/components/appBrand/AppBrand'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
    AppBrand,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>

<style scoped>
.sidebar-brand a {
  font-weight: 600;
  justify-content: left;
  color: white;
  font-size: 26px;
  text-decoration: none;
}
</style>
