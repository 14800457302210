import { KEYS_RFQ } from '@/constants/objectKeys/rfq'

export const AUTO_CORRECT_VALUE = {
  EVENT: {
    BLUR: 'blur',
    UPDATE: 'update',
  },
  TARGET: {
    SELF: 'self',
  },
  RULE: {
    LOWER_THAN_REF: 'lowerThanRef',
    GREATER_THAN_REF: 'greaterThanRef',
    GREATER_EQUAL_THAN_REF: 'greaterEqualThanRef',
    CHECK_MIN: 'checkMin',
    CHECK_DEFAULT_TARGET: 'checkDefaultTarget',
    NOT_REQUIRED_IF_SELF_NOT_EMPTY: 'notRequiredIfSelfNotEmpty',
    REQUIRED_IF_SELF_NOT_EMPTY: 'requiredIfSelfNotEmpty',
    SET_VISIBILITY: 'setVisibility',
    ADJUST_RFQ_YEAR_LABEL: 'adjustLabel',
    CHECK_DATE_MIN: 'checkDateMin',
    CHECK_DATE_MIN_DISABLE_TARGET_ON_INVALID: 'checkDateMinDisableOnEmpty',
    CHECK_OUT_OF_RANGE: 'checkOutOfRange',
    MIN_REQUIRED_SIZE: 'minRequiredSize',
  },
}

export const AUTO_CORRECT_QUOTE_EXPECTED_BY_RULES = [
  {
    refKey: AUTO_CORRECT_VALUE.TARGET.SELF,
    targetKey: KEYS_RFQ.PROJECT_DATA.FIRST_SAMPLE,
    targetGroupKey: KEYS_RFQ.PROJECT_DATA_PREFIX,
    event: AUTO_CORRECT_VALUE.EVENT.UPDATE,
    rule: AUTO_CORRECT_VALUE.RULE.CHECK_DATE_MIN,
    value: 8,
    valueRefEmpty: 1,
  },
  {
    refKey: KEYS_RFQ.PROJECT_DATA.FIRST_SAMPLE,
    refGroupKey: KEYS_RFQ.PROJECT_DATA_PREFIX,
    targetKey: KEYS_RFQ.PROJECT_DATA.SOP,
    targetGroupKey: KEYS_RFQ.PROJECT_DATA_PREFIX,
    event: AUTO_CORRECT_VALUE.EVENT.UPDATE,
    rule: AUTO_CORRECT_VALUE.RULE.CHECK_DATE_MIN_DISABLE_TARGET_ON_INVALID,
    value: 1,
    disableErrorMessage: true,
  },
]

export const AUTO_CORRECT_FIRST_SAMPLE_RULES = [
  {
    refKey: AUTO_CORRECT_VALUE.TARGET.SELF,
    targetKey: KEYS_RFQ.PROJECT_DATA.SOP,
    targetGroupKey: KEYS_RFQ.PROJECT_DATA_PREFIX,
    event: AUTO_CORRECT_VALUE.EVENT.UPDATE,
    rule: AUTO_CORRECT_VALUE.RULE.CHECK_DATE_MIN_DISABLE_TARGET_ON_INVALID,
    value: 1,
  },
]

export const AUTO_CORRECT_OUT_OF_RANGE = {
  refKey: AUTO_CORRECT_VALUE.TARGET.SELF,
  targetKey: AUTO_CORRECT_VALUE.TARGET.SELF,
  event: AUTO_CORRECT_VALUE.EVENT.BLUR,
  rule: AUTO_CORRECT_VALUE.RULE.CHECK_OUT_OF_RANGE,
}
