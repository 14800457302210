import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { VALIDATION_TYPE } from '@/constants/validation'
import { AUTO_CORRECT_VALUE } from '@/constants/autoCorrectSettings'
import {
  API_OBJECT_GROUPS,
  API_OBJECT_KEY_NAMES,
} from '@/constants/apiObjectKeyNames'
import { IMPORT_EXPORT } from '@/constants/importExport'
import { FORM_ITEMS } from '@/constants/formItems'

let itemUtilRateSpecified = {
  keyName: KEYS_RFQ.TECH_INFORMATION_LEADTIME.UTIL_RATE_SPECIFIC,
  keyNameGroup: KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
  required: true,
  value: '',
  apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME
      .UTILIZATION_RATE_SPECIFIED,
  ],
  inputMin: '0.01',
  inputMax: '100',
  validationSettings: {
    formatNumber: true,
    decimal: { decimal_digits: '2' },
  },
  parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
  unit: FORM_ITEMS.UNITS.PERCENT,
  messages: {
    label: 'Utilization rate (specified panel) in %',
    placeholder: 'Utilization rate (specified panel) in %',
    tooltip: 'U-rate normalized on the shipping panel',
  },
}

let itemUtilRateProposed = {
  keyName: KEYS_RFQ.TECH_INFORMATION_LEADTIME.UTIL_RATE_PROPOSED,
  keyNameGroup: KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
  value: '',
  apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME
      .UTILIZATION_RATE_PROPOSED,
  ],
  required: true,
  inputMin: '0.01',
  inputMax: '100',
  validationSettings: {
    formatNumber: true,
    decimal: { decimal_digits: '2' },
  },
  parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
  unit: FORM_ITEMS.UNITS.PERCENT,
  messages: {
    label: 'Utilization rate (proposed panel) in %',
    placeholder: 'Utilization rate (proposed panel) in %',
    tooltip: 'U-rate normalized on the shipping panel',
  },
}

let itemSizeProposed = {
  keyName: KEYS_RFQ.TECH_INFORMATION_LEADTIME.SIZE_PROPOSED,
  messages: {
    label: 'Size proposed panel',
  },
  apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.SIZE_LENGTH,
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.SIZE_WIDTH,
  ],
  subItems: [
    {
      keyNameGroup: KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
      keyNameParent: KEYS_RFQ.TECH_INFORMATION_LEADTIME.SIZE_PROPOSED,
      keyName:
        KEYS_RFQ.TECH_INFORMATION_LEADTIME.SIZE_PROPOSED +
        KEYS_GENERAL.SUB_ITEM_1,
      value: '',
      apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.SIZE_LENGTH,
      ],
      parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
      autoCorrectSettings: [
        {
          refKey: KEYS_RFQ.TECH_INFORMATION_LEADTIME.SIZE_PROPOSED,
          refGroupKey: KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
          targetKey: KEYS_RFQ.TECH_INFORMATION_LEADTIME.UNITS_PER_PANEL,
          targetGroupKey: KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
          event: AUTO_CORRECT_VALUE.EVENT.UPDATE,
          rule: AUTO_CORRECT_VALUE.RULE.REQUIRED_IF_SELF_NOT_EMPTY,
        },
      ],
      required: true,
      inputMin: '1',
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
      validationSettings: {
        decimal: { decimal_digits: '2' },
      },
      messages: {
        label: 'length [mm]',
        placeholder: 'length [mm]',
      },
    },
    {
      keyNameGroup: KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
      keyNameParent: KEYS_RFQ.TECH_INFORMATION_LEADTIME.SIZE_PROPOSED,
      keyName:
        KEYS_RFQ.TECH_INFORMATION_LEADTIME.UNITS_PER_PANEL +
        KEYS_GENERAL.SUB_ITEM_2,
      value: '',
      apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
      apiObjectKeyNames: [
        API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.SIZE_WIDTH,
      ],
      parseType: IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
      autoCorrectSettings: [
        {
          refKey: KEYS_RFQ.TECH_INFORMATION_LEADTIME.SIZE_PROPOSED,
          refGroupKey: KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
          targetKey: KEYS_RFQ.TECH_INFORMATION_LEADTIME.UNITS_PER_PANEL,
          targetGroupKey: KEYS_RFQ.TECH_INFORMATION_LEADTIME_PREFIX,
          event: AUTO_CORRECT_VALUE.EVENT.UPDATE,
          rule: AUTO_CORRECT_VALUE.RULE.REQUIRED_IF_SELF_NOT_EMPTY,
        },
      ],
      required: true,
      inputMin: '1',
      validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.DECIMAL],
      validationSettings: {
        decimal: { decimal_digits: '2' },
      },
      messages: {
        label: 'width [mm]',
        placeholder: 'width [mm]',
      },
    },
  ],
}

let itemUnitsPerPanel = {
  keyName: KEYS_RFQ.TECH_INFORMATION_LEADTIME.UNITS_PER_PANEL,
  value: '',
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
  apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.UNITS_PER_PANEL,
  ],
  required: true,
  inputMin: '1',
  parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
  messages: {
    label: 'Units per panel (proposed panel)',
    placeholder: 'Units per panel (proposed panel)',
  },
}

let itemUsedLaminate = {
  keyName: KEYS_RFQ.TECH_INFORMATION_LEADTIME.USED_LAMINATE,
  required: true,
  value: '',
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.BASE_MATERIAL,
  ],
  messages: {
    label: 'Used base material brand/ model',
    placeholder: 'Used base material brand/ model',
  },
}

let itemUsedSolderMask = {
  keyName: KEYS_RFQ.TECH_INFORMATION_LEADTIME.USED_SOLDER_MASK,
  required: true,
  value: '',
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY],
  apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.SOLDER_MASK,
  ],
  messages: {
    label: 'Used solder mask brand/ model',
    placeholder: 'Used solder mask brand/ model',
  },
}

let itemProductionLeadTime = {
  keyName: KEYS_RFQ.TECH_INFORMATION_LEADTIME.PRODUCTION_LEAD_TIME,
  required: true,
  value: '',
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
  apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.PRODUCTION_LEAD_TIME,
  ],
  parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
  inputMin: 1,
  inputMax: 200,
  messages: {
    label: 'Production leadtime (mass production) [calendar days]',
    placeholder: 'Production leadtime (mass production) [calendar days]',
  },
}

let itemMaterialLeadTime = {
  keyName: KEYS_RFQ.TECH_INFORMATION_LEADTIME.MATERIAL_LEAD_TIME,
  required: true,
  value: '',
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
  apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.MATERIAL_LEAD_TIME,
  ],
  parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
  inputMin: 1,
  inputMax: 200,
  messages: {
    label: 'Material leadtime (mass production) [calendar days]',
    placeholder: 'Material leadtime (mass production) [calendar days]',
  },
}

let itemSampleLeadTime = {
  keyName: KEYS_RFQ.TECH_INFORMATION_LEADTIME.SAMPLE_LEAD_TIME,
  required: true,
  value: '',
  validationTypes: [VALIDATION_TYPE.NOT_EMPTY, VALIDATION_TYPE.INTEGER],
  apiObjectGroup: API_OBJECT_GROUPS.PROPOSED_PANEL,
  apiObjectKeyNames: [
    API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME.SAMPLE_LEAD_TIME,
  ],
  parseType: IMPORT_EXPORT.PARSE_TYPE.INT,
  inputMin: 1,
  inputMax: 200,
  messages: {
    label: 'Sample leadtime (incl. material leadtime) [calendar days]',
    placeholder: 'Sample leadtime (incl. material leadtime) [calendar days]',
  },
}

export let rfqTechInformationLeadtimeTypes = {
  [itemUtilRateSpecified.keyName]: itemUtilRateSpecified,
  [itemUtilRateProposed.keyName]: itemUtilRateProposed,
  [itemSizeProposed.keyName]: itemSizeProposed,
  [itemUnitsPerPanel.keyName]: itemUnitsPerPanel,
  [itemUsedLaminate.keyName]: itemUsedLaminate,
  [itemUsedSolderMask.keyName]: itemUsedSolderMask,
  [itemProductionLeadTime.keyName]: itemProductionLeadTime,
  [itemMaterialLeadTime.keyName]: itemMaterialLeadTime,
  [itemSampleLeadTime.keyName]: itemSampleLeadTime,
}
