import { INFO_ANCHORS } from '@/constants/anchorTags/infoAnchorTags'

export const APP_TITLE = process.env.VUE_APP_TITLE
export const SHOPWARE_URL = process.env.VUE_APP_API_BASE_URL
export const LANDING_PAGE_URL = process.env.VUE_APP_LANDING_PAGE_URL
export const IS_DEV_MODE = process.env.NODE_ENV === 'development'
export const IS_LOCAL_DEVELOPMENT =
  process.env.VUE_APP_LOCAL_DEVELOPMENT === 'true'

export const STORE = {
  MUTATIONS: {
    UPDATE_CURRENT_USER_DATA: 'updateCurrentUserData',
  },
}

const DEPENDENCY = 'Dependency'

export const SIDEBAR_BREAKPOINT = 768

export const DEPENDENCIES = {
  SALUTATIONS: `salutations${DEPENDENCY}`,
  COUNTRIES: `countries${DEPENDENCY}`,
  ADDRESSES: `addresses${DEPENDENCY}`,
  CURRENCIES: `currencies${DEPENDENCY}`,
  B2B_SHIPPING_ADDRESSES: `shippingAddresses${DEPENDENCY}`,
  B2B_BILLING_ADDRESSES: `billingAddresses${DEPENDENCY}`,
  CURRENT_USER: `currentUser${DEPENDENCY}`,
  FINISHED_LOADING: `finishedLoading${DEPENDENCY}`,
  RFQ_LISTING: `rfqListing${DEPENDENCY}`,
  OFFERS_LISTING: `offerListing${DEPENDENCY}`,
  CREATE_RFQ: `createRFQ${DEPENDENCY}`,
  EDIT_RFQ: `editRFQ${DEPENDENCY}`,
  B2B_ROLES: `b2bRoles${DEPENDENCY}`,
  B2B_CONTACTS: `b2bContacts${DEPENDENCY}`,
  LOCK_RFQ: `lockRFQ${DEPENDENCY}`,
  EDIT_OFFER: `editOffer${DEPENDENCY}`,
  LOCK_OFFER: `lockOffer${DEPENDENCY}`,
  SUPPORT_USERS: `supportUsers${DEPENDENCY}`,
  LIST_SUPPLIERS: `listSuppliers${DEPENDENCY}`,
  DASHBOARD_DATA: `dashboardData${DEPENDENCY}`,
}

const FRONTEND_VALUE_PREFIX = 'frontendValue'
export const FRONTEND_VALUES = {
  STATE_TYPE: `${FRONTEND_VALUE_PREFIX}StateType`,
  OFFER_STATE: `${FRONTEND_VALUE_PREFIX}OfferState`,
  RFQ_STATE: `${FRONTEND_VALUE_PREFIX}RfQState`,
  READ_ONLY_MODE: `${FRONTEND_VALUE_PREFIX}ReadOnlyMode`,
}
export const NEW_PASSWORD = 'new-password'
export const TABLE_ROW_INDEX = 'eTableRowIndex'

export const DATE_FORMAT = 'DD. MMM. YYYY, HH:mm:ss'
export const DATE_FORMAT_MINUTES = 'DD. MMM. YYYY, HH:mm'
export const DATE_FORMAT_SHORT = 'DD. MMM. YYYY'
export const DATE_FORMAT_SHORT_DASHBOARD = 'DD.MM.YYYY'
export const DATE_FORMAT_MONTH_YEAR = 'MM.YYYY'

export const LOCAL_STORAGE_ACTION_STATUS = 'actionStatus'
export const LOCAL_STORAGE_AUTH_DATA = 'authData'
export const LOCAL_STORAGE_PERMISSION_GROUP = 'permissionGroup'

export const NOT_AVAILABLE = 'not available'
export const NOT_SPECIFIED = 'not specified'
export const NOT_RELEVANT = 'not relevant'

export const MISSING_ADDRESS = 'Missing address'
export const UNKNOWN = 'Unknown'

export const NO_SPECIFIC = 'no specific'

export const MAX_ALLOWED_NUMBER = 2147483647
export const MAX_ALLOWED_STRING_LENGTH = 255

export const MAX_ALLOWED_TEXT_AREA_CHARACTERS = 1000
export const OUT_OF_RANGE_VALUE_ERROR_MESSAGE =
  'Please correct to the available range.'

export const PLACEHOLDER = {
  ID: {
    RFQ: ':id',
    OFFER: ':offerId',
    ADDRESS: ':addressId',
    CONTACT: ':contactId',
    CUSTOMER: ':customerId',
  },
  COQ_CURRENCY: ':coqCurrency',
  STATE: ':state',
}

const PRELOAD_PREFIX = 'dataGroup'

export const DATA_GROUPS = {
  ADDRESS_MANAGEMENT: `${PRELOAD_PREFIX}AddressManagement`,
  RFQ: `${PRELOAD_PREFIX}RFQ`,
  LISTING: `${PRELOAD_PREFIX}Listing`,
  SIDEBAR: `${PRELOAD_PREFIX}Sidebar`,
  USER: `${PRELOAD_PREFIX}User`,
  USER_MANAGEMENT: `${PRELOAD_PREFIX}UserManagement`,
  REGISTRATION: `${PRELOAD_PREFIX}Registration`,
  GLOBAL: `${PRELOAD_PREFIX}Global`,
}

export const RFQ_TYPES = {
  YEARS: 0,
  SCENARIOS: 1,
  LOT_SIZES: 2,
}

export const RFQ_TYPE_LABELS = {
  YEARS: 'RfQ Years',
  SCENARIOS: 'Scenarios',
  LOT_SIZES: 'Lot Sizes',
}

export const QUOTE_DETAIL_TEXT = {
  labels: [
    'Total RfQ Quantity',
    'Full Swing Quantity',
    'Average Price per Piece',
    'Quote Validity',
  ],
  ID_TAGS: [
    INFO_ANCHORS.QUOTE.TOTAL_RFQ_QUANTITY,
    INFO_ANCHORS.QUOTE.FULL_SWING_QUANTITY,
    INFO_ANCHORS.QUOTE.AVERAGE_PRICE_PER_PIECE,
    INFO_ANCHORS.QUOTE.VALIDITY,
  ],
  units: ['pieces', 'pieces', '', ''],
}

export const LISTING_MAX_DATA_LENGTH = 35

export const TARGET_ID = {
  RFQ: ':id',
  OFFER: ':offerId',
}

export const PANEL_TYPES = {
  CUSTOMER_SPECIFIED: 1,
  PROPOSED: 2,
}

export const CURRENCY_TYPE = {
  MAIN: 0,
  ADDITIONAL: 1,
  BOTH: 2,
}
export const ACTIONS = {
  SCROLL_TO_CONTACT: 'scrollToContact',
  TRIGGER_VALIDATION: 'triggerValidation',
}

export const LOCK_STATUS = {
  UNLOCKED: 0,
  LOCKED_BY_CURRENT_USER: 1,
  LOCKED_BY_DIFFERENT_USER: 2,
}

export const FILE_TRANSFER_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  SUCCESSFUL: 2,
  FAILED: 3,
}

export const RFQ_EDITOR_MODAL_EVENTS = {
  CANCEL_EDITOR: 0,
  DELETE_DRAFT: 1,
}

export const ASSIGNED_TO = 'Assigned to: '

export const QUOTE_COMPARISON_TYPES = {
  QUANTITY: 0,
  PRICE: 1,
  TOTAL_QUANTITY: 2,
  TOTAL_PRICE: 3,
  FLAT: 5,
  ANNUAL_PRICE_DOWN: 6,
  FLAT_AND_ANNUAL_PRICE_DOWN: 7,
  MCR: 8,
  SERIES: 9,
  SUPPLIER_SELECTION: 10,
  ANNUAL_FORECAST: 11,
}

export const QUOTE_COMPARISON_SUPPLIER_SELECTION_TYPES = {
  NOT_SELECTED: false,
  ACTIVE: new Set(),
  ANNUAL_PRICE_DOWN: 'annualPriceDown',
  FLAT: 'flat',
}

export const OFFER_REJECTED_MESSAGE = 'Your offer has been rejected'

export const TCO_TYPES = {
  TCO_3: 3,
  TCO_5: 5,
}

export const TCO_GROUPS = [TCO_TYPES.TCO_3, TCO_TYPES.TCO_5]

export const CORE_UI_COLORS = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark',
}

export const SUBMIT_TYPES = {
  SAVE: 0,
  SUBMIT: 1,
  PREPARE_CONTRACTS: 2,
  SEND_OUT_CONTRACTS: 3,
  SUBMIT_INSTANT_QUOTE: 5,
  BEGIN_SELECT_SUPPLIERS: 6,
}

export const INSTANT_QUOTE_TYPES = {
  INSTANT_QUOTE: 0,
  INSTANT_QUOTE_WITH_SUPPLIER_DATA: 1,
  NORMAL_QUOTE: 2,
}
export const QUERY_PARAMS = {
  COMPONENT_TARGET: 'component-target',
  OFFER_ID: 'offer-id',
}

export const REDIRECT_COMPONENT_EVENTS = {
  SUPPLIER_COMMENT: 0,
  TECH_CSS_COMMENT: 1,
}

export const MASS_PRODUCTION_TYPES = {
  MARGIN: 1,
  PRICE: 2,
}

export const QUOTE_DELIVERY_OPTIONS = {
  SEA: 'Sea',
  AIR: 'Air',
  EXPRESS: 'Express',
}

export const QUOTE_DELIVERY_OPTIONS_CATEGORIES = [
  QUOTE_DELIVERY_OPTIONS.SEA,
  QUOTE_DELIVERY_OPTIONS.AIR,
  QUOTE_DELIVERY_OPTIONS.EXPRESS,
]

export const PRICE_INDICATION_TOOLTIP = `<ol>
          <li>
            This price indication does not consider special laminate or solder
            mask types, even so indicated in the RfQ form.
          </li>
          <li>
            The price indication only working for 2L to 8L STD PCB and market
            standard other specifications.
          </li>
          <li>Any questions, please reach out to our sales team.</li>
        </ol>`

export const DEFAULT_MASS_PRODUCTION_MARGIN = 10

export const MISSING_COST_MATRIX_DATA =
  'Missing Cost Matrix data order to generate quote calculation tables!'

export const SCROLLSPY_CATEGORIES = {
  H2: 2,
  H3: 3,
  H4: 4,
}
