<template>
  <CHeader
    id="e-app-header"
    position="sticky"
    class="mb-4"
    v-if="$store.getters.isLoggedIn === true"
  >
    <CContainer fluid v-if="$store.getters.isLoggedIn">
      <CHeaderToggler
        class="ps-1"
        @click="$store.commit('toggleSidebar')"
        :id="this.getActionAnchorTagMixin(ACTION_ANCHORS.TOGGLE_SIDEBAR)"
      >
        <CIcon icon="cilMenu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="ms-auto me-4"> </CHeaderNav>
      <CHeaderNav class="ms-3 me-4">
        <AppHeaderDropdownAccnt :id="getHeaderDropdownAnchorTagMixin()" />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
  <CHeader v-else id="e-app-header" class="justify-content-between w-100 py-0">
    <div>
      <AppBrand
        :targetShopware="true"
        :color="'black'"
        :height="26"
        :id="getLoginHeaderAnchorTagMixin(LOGIN_PAGE_ANCHOR_TAGS.LANDING_PAGE)"
        :useBoschLogo="true"
      ></AppBrand>
    </div>
    <div class="d-flex align-items-center">
      <AppBrandLogo :color="'black'" :height="22"></AppBrandLogo>
    </div>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import { ROUTER_DATA } from '@/constants/routerData'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import AppBrand from '@/components/appBrand/AppBrand'
import anchorTagsMixins from '@/mixins/anchorTagsMixins'
import { LOGIN_PAGE_ANCHOR_TAGS } from '@/constants/anchorTags/loginPageAnchorTags'
import AppBrandLogo from '@/components/appBrand/AppBrandLogo.vue'
import { ACTION_ANCHORS } from '@/constants/anchorTags/actionAnchorTags'

export default {
  name: 'AppHeader',
  computed: {
    ACTION_ANCHORS() {
      return ACTION_ANCHORS
    },
  },
  components: {
    AppBrandLogo,
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    AppBrand,
  },
  mixins: [anchorTagsMixins],
  data() {
    return {
      ROUTER_DATA,
      PERMISSION_GROUPS,
      LOGIN_PAGE_ANCHOR_TAGS,
    }
  },
  setup() {
    return {
      logo,
    }
  },
}
</script>

<style scoped>
.header {
  min-height: unset;
}

#e-app-header {
  z-index: 1100;
}

.brand {
  font-weight: bold;
  font-size: 26px;
  color: black;
  text-decoration: none;
}

.nav-link {
  color: black;
  font-size: 18px;
  padding-left: 28px;
  padding-right: 28px;
}
</style>
