const PREFIX = 'preloadScreen'

const LOADED = `${PREFIX}LoadedDependencies`
const REQUIRED = `${PREFIX}RequiredDependencies`

export const KEYS_PRELOAD = {
  PREFIX,
  LOADED,
  REQUIRED,
}
