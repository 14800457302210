import { FILE_TYPES } from '@/constants/fileTypes'
import { FILE_TRANSFER_STATUS } from '@/constants/constants'

export default {
  emits: ['updateValue'],
  methods: {
    getAcceptedFileFormatsMixin(item) {
      if (item.file) {
        let formatString = ''
        if (item.file.formats !== undefined) {
          item.file.formats.forEach((format) => {
            formatString += `.${format}, `
          })
        }
        return formatString
      }
    },
    highlightFileUploadAreaMixin(event, enable = true) {
      const HIGHLIGHT_CLASS = 'e-active-file-upload-area'
      const NOT_ALLOWED = 'e-no-multiple-files-allowed'

      if (enable) {
        event.currentTarget.classList.add(HIGHLIGHT_CLASS)
        event.currentTarget.classList.add(NOT_ALLOWED)
      } else {
        event.currentTarget.classList.remove(HIGHLIGHT_CLASS)
      }
    },
    uploadDroppedFileMixin(event, item, isDragAndDropEvent = false) {
      let validationObject = {
        item: item,
      }
      if (isDragAndDropEvent) {
        validationObject.value = event.dataTransfer.files
      } else {
        validationObject.value = event.target.files
      }

      if (validationObject.value.length > 1) {
        item.messages.status = FILE_TYPES.STATUS.VALIDATION.MULTIPLE_FILES
        item.style = FILE_TYPES.STATUS.UPLOAD.ERROR.STYLE
        item.file.status = FILE_TRANSFER_STATUS.FAILED
      } else if (
        !item.file.formats.includes(
          validationObject.value[0].name
            .substring(validationObject.value[0].name.lastIndexOf('.') + 1)
            .toLowerCase(),
        )
      ) {
        item.messages.status = FILE_TYPES.STATUS.VALIDATION.FORMAT
        item.style = FILE_TYPES.STATUS.UPLOAD.ERROR.STYLE
        item.file.status = FILE_TRANSFER_STATUS.FAILED
      } else if (
        validationObject.value[0].size / FILE_TYPES.BYTES_TO_MIB_CONVERSION >
        FILE_TYPES.MAXIMUM_ALLOWED_SIZE_IN_MIB
      ) {
        item.messages.status = FILE_TYPES.STATUS.VALIDATION.SIZE.replace(
          '__filesize__',
          FILE_TYPES.MAXIMUM_ALLOWED_SIZE_IN_MIB,
        )
        item.style = FILE_TYPES.STATUS.UPLOAD.ERROR.STYLE
        item.file.status = FILE_TYPES.STATUS.VALIDATION.FORMAT
      } else {
        this.$emit('updateValue', validationObject)
      }
      this.highlightFileUploadAreaMixin(event, false)
    },
  },
}
