export const AXIOS_STATUS = {
  ERROR: {
    GENERAL: 'Oops Something went wrong',
    INTERNAL: 'Internal Server Error',
  },
  LOGIN: [
    {
      REG_EXP: new RegExp('The customer with the id is inactive.', 'g'),
      VALUE:
        'Please wait for your account to be activated. We will notify you once done.',
    },
  ],
  USER_MANAGEMENT: [
    {
      REG_EXP: new RegExp(
        'Validation violations detected, can not proceed:\n' +
          '\temail: Dieser Wert wird bereits verwendet.',
        'g',
      ),
      VALUE: 'The email address is already in use.',
    },
  ],
  DOWNLOAD: {
    PROGRESS: 'Downloading: __percent__ %',
    FINISHED: 'Download finished',
    NOT_IMPLEMENTED:
      "The download function has not yet been implemented for this user's view (__permissionGroup__)",
  },
  NOT_IMPLEMENTED: 'This feature has not yet been implemented',
}
