import { AXIOS_STATUS } from '@/constants/axiosStatus'
import { ROUTER_DATA } from '@/constants/routerData'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { IS_DEV_MODE } from '@/constants/constants'

export default {
  methods: {
    printErrorMixin: function (error) {
      if (error) {
        let statusText
        let canDisplayInternalErrors = true
        if (!IS_DEV_MODE && error.response.status === 500) {
          canDisplayInternalErrors = false
        }
        if (
          error.response &&
          error.response.data.errors &&
          canDisplayInternalErrors
        ) {
          statusText = `${error.response.data.errors[0].detail}`
        } else if (error.response && error.response.status) {
          switch (error.response.status) {
            case 500:
              statusText = AXIOS_STATUS.ERROR.INTERNAL
          }
        } else {
          statusText = AXIOS_STATUS.ERROR.GENERAL
        }
        if (this.$data && this.$data.downloadInfo) {
          this.$data.downloadInfo.statusText = statusText
          this.$data.downloadInfo.status = 2
        }
        // Remove the shopware ID from the status text
        statusText = statusText
          ? statusText.replace(new RegExp('"(.*?)"\\s*', 'g'), '')
          : ''
        switch (this.$route.name) {
          case ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.NAME:
            for (let i = 0; i < AXIOS_STATUS.LOGIN.length; i++) {
              if (statusText.match(AXIOS_STATUS.LOGIN[i].REG_EXP)) {
                statusText = AXIOS_STATUS.LOGIN[i].VALUE
                break
              }
            }
            break
          case `${this.$store.getters.getActivePermissionGroup}${ROUTER_DATA.SHARED.USER_MANAGEMENT.NAME}`:
            for (let i = 0; i < AXIOS_STATUS.USER_MANAGEMENT.length; i++) {
              if (statusText.match(AXIOS_STATUS.USER_MANAGEMENT[i].REG_EXP)) {
                statusText = AXIOS_STATUS.USER_MANAGEMENT[i].VALUE
                break
              }
            }
            break
        }
        return statusText
      }
    },
  },
}
