<template>
  <CFooter
    class="bg-dark w-100 justify-content-start"
    v-if="!$store.getters.isLoggedIn"
  >
    <AppBrand :height="20"></AppBrand>
    <CNav class="support-nav ml-0">
      <CNavItem>
        <a
          :href="ROUTER_DATA.SHARED.LEGAL_NOTICE.PATH"
          target="_blank"
          class="nav-link"
          :id="getLoginFooterAnchorTagMixin(LOGIN_PAGE_ANCHOR_TAGS.IMPRINT)"
        >
          {{ ROUTER_DATA.SHARED.LEGAL_NOTICE.TITLE }}
        </a>
      </CNavItem>
      <CNavItem>
        <a
          :href="ROUTER_DATA.SHARED.PRIVACY.PATH"
          target="_blank"
          class="nav-link"
          :id="
            getLoginFooterAnchorTagMixin(
              LOGIN_PAGE_ANCHOR_TAGS.DATA_PROTECTION_NOTICE,
            )
          "
        >
          {{ ROUTER_DATA.SHARED.PRIVACY.TITLE }}
        </a>
      </CNavItem>
      <CNavItem>
        <a
          :href="ROUTER_DATA.SHARED.TERMS_CONDITIONS.PATH"
          target="_blank"
          class="nav-link"
          :id="
            getLoginFooterAnchorTagMixin(LOGIN_PAGE_ANCHOR_TAGS.GENERAL_TERMS)
          "
        >
          {{ ROUTER_DATA.SHARED.TERMS_CONDITIONS.TITLE }}
        </a>
      </CNavItem>
      <CNavItem>
        <a
          :href="ROUTER_DATA.SHARED.TERMS_OF_USE.PATH"
          target="_blank"
          class="nav-link"
          :id="
            getLoginFooterAnchorTagMixin(LOGIN_PAGE_ANCHOR_TAGS.TERMS_OF_USE)
          "
        >
          {{ ROUTER_DATA.SHARED.TERMS_OF_USE.TITLE }}
        </a>
      </CNavItem>
    </CNav>
  </CFooter>
</template>

<script>
import { ROUTER_DATA } from '@/constants/routerData'
import AppBrand from '@/components/appBrand/AppBrand'
import { LOGIN_PAGE_ANCHOR_TAGS } from '@/constants/anchorTags/loginPageAnchorTags'
import anchorTagsMixins from '@/mixins/anchorTagsMixins'

export default {
  name: 'AppFooter',
  mixins: [anchorTagsMixins],
  components: {
    AppBrand,
  },
  data() {
    return {
      ROUTER_DATA,
      LOGIN_PAGE_ANCHOR_TAGS,
    }
  },
}
</script>

<style scoped>
.footer {
  font-size: 15px;
}

.brand {
  font-weight: bold;
}
a,
a:hover {
  color: white;
  text-decoration: none;
  padding-left: 13px;
  padding-right: 13px;
}

.support-nav a {
  opacity: 67%;
}
</style>
