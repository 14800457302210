export const BADGE_TRANSLATION = {
  IN_PROGRESS: 'In progress',
  IN_CLARIFICATION: 'In clarification',
  CHANGE_REQUESTED: 'Change requested',
  QUOTE_RECEIVED: 'Quote received',
  QUOTE_ACCEPTED: 'Quote accepted',
  QUOTE_REJECTED: 'Quote rejected',
  REJECTED: 'Rejected',
  CANCELLED: 'RfQ cancelled',
  DRAFT: 'Draft',
  DECLINED: 'Declined',
  EXPIRED: 'Expired',
  SAMPLE_ARRIVED: 'Sample arrived',
  SAMPLE_ORDERED: 'Sample ordered',
  TERMINATED: 'Terminated',
  DONE: 'Done',
  ACCEPTED: 'Accepted',
  SERIES_DELIVERY: 'Series delivery',
  CLARIFICATION_NEEDED: 'Clarification needed',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  REQUEST_AVAILABLE: 'Request available',
  NEW_RFQ: 'New RfQ',
  NEW: 'New',
  OFFER_NEEDS_REWORK: 'Rework needed',
  OFFER_EXPIRED: 'Offer Expired',
  RFQ_EXPIRED: 'RfQ expired',
  OFFER_IN_PROGRESS: 'In progress',
  RFQ_REJECTED: 'RfQ rejected',
  RFQ_LOST: 'RfQ lost',
  OFFER_UNDER_CONTRACTING: 'Approval needed',
  OFFER_CONTRACTED: 'Contracted',
  OFFER_PREPARATION: 'Offer preparation',
  OFFER_REQUESTED: 'Offer requested',
  DISABLED: '',
  RFQ_TO_PREPARE: 'RfQ to prepare',
  OFFERS_TO_REVIEW: 'Offers to review',
  CONTRACTS_AT_MANAGER: 'Contracts at manager',
  CONTRACT_TO_APPROVE: 'Contract to approve',
  QUOTE_TO_PREPARE: 'Quote to prepare',
  QUOTE_APPROVAL: 'Quote approval',
  QUOTE_TO_APPROVE: 'Quote to approve',
  QUOTE_TO_REWORK: 'Quote to rework',
  QUOTE_TO_SEND: 'Quote to send',
  QUOTE_SENT: 'Quote sent',
  QUOTE_APPROVED: 'Quote approved',
  RFQ_TO_REWORK: 'RfQ to rework',
  FINAL_SOURCING: 'Final sourcing',
  FINISH_CONTRACTING: 'Finish contracting',
  UNDER_CONTRACTING: 'Under contracting',
  CONTRACTS_AT_SUPPLIERS: 'Contracts at suppliers',
  CONTRACTED: 'Contracted',
  ARCHIVED: 'Archived',
}

export const BADGE_STYLE = {
  BLUE: 'e-status-color-blue',
  DARK_BLUE: 'e-status-color-dark-blue',
  GREY: 'e-status-color-grey',
  GREEN: 'e-status-color-green',
  DARK_GREEN: 'e-status-color-dark-green',
  YELLOW: 'e-status-color-yellow',
  PURPLE: 'e-status-color-purple',
  ORANGE: 'e-status-color-orange',
  RED: 'e-status-color-red',
  LIGHT_RED: 'e-status-color-light-red',
  DARK_RED: 'e-status-color-dark-red',
  DISABLED: 'e-status-color-disabled',
}
