import notificationsMixins from '@/mixins/notificationsMixins'
import { KEYS_PRELOAD } from '@/constants/objectKeys/preload'
import { AXIOS } from '@/constants/axios'
import { DATA_MIXIN } from '@/constants/data'
import dataMixins from '@/mixins/dataMixins'
import cloneDeep from 'lodash/cloneDeep'
import { FILE_TYPES } from '@/constants/fileTypes'
import { KEYS_RFQ } from '@/constants/objectKeys/rfq'
import { STATES } from '@/constants/states'
import { ROUTE_TYPES, ROUTER_DATA } from '@/constants/routerData'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { FILE_TRANSFER_STATUS, PLACEHOLDER } from '@/constants/constants'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'

export default {
  inject: ['$axios'],
  mixins: [notificationsMixins, dataMixins],
  watch: {
    '$store.getters.getLoginStatus': {
      handler() {
        this.axiosUpdateHeaderConfigMixin()
      },
      deep: true,
    },
  },
  mounted() {
    this.axiosUpdateHeaderConfigMixin()
  },
  methods: {
    axiosGetRelatedOffersMixin(offers) {
      offers.forEach((offer, index) => {
        if (offer.state !== STATES.OFFER_NOT_SELECTED.VALUE) {
          let newCommand = this.axiosGetNewCommandObjectMixin()
          switch (this.$store.getters.getActivePermissionGroup) {
            case PERMISSION_GROUPS.SALES_MANAGER.VALUE:
              newCommand.path =
                AXIOS.API.GET.GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SALES_MANAGER.replace(
                  PLACEHOLDER.ID.OFFER,
                  offer.id,
                )
              break
            case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
              newCommand.path =
                AXIOS.API.GET.GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SALES_EXPERT.replace(
                  PLACEHOLDER.ID.OFFER,
                  offer.id,
                )
              break
            case PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE:
              newCommand.path =
                AXIOS.API.GET.GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SUPPLIER_MANAGER.replace(
                  PLACEHOLDER.ID.OFFER,
                  offer.id,
                )
              break
            default:
              newCommand.path =
                AXIOS.API.GET.GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SUPPLIER_EXPERT.replace(
                  PLACEHOLDER.ID.OFFER,
                  offer.id,
                )
          }
          newCommand.targets.data = DATA_MIXIN.ADD_RFQ_OFFER
          newCommand.offerIndex = index
          newCommand.isPreloadDependency = true
          this.axiosGetMixin(newCommand)
        }
      })
    },
    axiosUpdateHeaderConfigMixin() {
      if (this.$store.getters.getLoginStatus !== 0) {
        this.$store.getters.getAxiosData.config.headers[
          AXIOS.HEADERS.KEYS.CONTEXT_TOKEN
        ] = this.$store.state.contextToken
      } else {
        this.$store.getters.getAxiosData.config.headers[
          AXIOS.HEADERS.KEYS.CONTEXT_TOKEN
        ] = AXIOS.HEADERS.VALUE.DUMMY_CONTEXT_TOKEN
      }
    },
    axiosGetNewCommandObjectMixin() {
      let newCommand = cloneDeep(AXIOS.COMMANDS)
      newCommand.config = cloneDeep(this.$store.getters.getAxiosData.config)
      delete newCommand.config.signal
      return newCommand
    },
    axiosCanContinueTransmissionMixin(data) {
      let userCanContinue = true
      if (
        this.$route.meta.routeType === ROUTE_TYPES.RFQ_EDITOR &&
        data[API_OBJECT_KEY_NAMES.RFQ.LOCKED_BY]
      ) {
        userCanContinue =
          data[API_OBJECT_KEY_NAMES.RFQ.LOCKED_BY] ===
          this.$store.getters.currentUserData.id
      }
      return userCanContinue
    },
    axiosSetResponseTargetMixin(command, response) {
      // Check if the user can continue with transmitting data on this view
      if (this.axiosCanContinueTransmissionMixin(response.data)) {
        if (command.targets.emitter !== null) {
          this.$emit(command.targets.emitter, response.data)
        }
        if (
          command.isPreloadDependency &&
          (command.type === AXIOS.TYPES.GET ||
            command.type === AXIOS.TYPES.POST) &&
          this.$data[KEYS_PRELOAD.PREFIX]
        ) {
          this.$data[KEYS_PRELOAD.PREFIX][KEYS_PRELOAD.LOADED]++
        }
        if (command.targets.data !== null) {
          switch (command.targets.data) {
            case DATA_MIXIN.LOGIN_USER:
              this.dataLoginUserMixin(response.data)
              break
            case DATA_MIXIN.SET_SHIPPING_ADDRESSES:
              this.dataSetShippingAddressesMixin(response.data)
              break
            case DATA_MIXIN.SET_BILLING_ADDRESSES:
              this.dataSetBillingAddressesMixin(response.data)
              break
            case DATA_MIXIN.SET_COUNTRIES:
              this.dataSetCountriesMixin(response.data)
              break
            case DATA_MIXIN.SET_CURRENT_USER:
              this.dataSetCurrentUserMixin(response.data)
              break
            case DATA_MIXIN.REGISTER_USER:
              this.dataRegisterUserMixin()
              break
            case DATA_MIXIN.RFQ_LISTING:
              this.dataSetRFQListingMixin(response.data)
              break
            case DATA_MIXIN.OFFERS_LISTING:
              this.dataSetOfferListingMixin(
                response.data,
                command.layoutVariant,
              )
              break
            case DATA_MIXIN.RFQ_CREATE_NEW:
              this.dataSetNewRFQMixin(response.data)
              break
            case DATA_MIXIN.SUPPLIER_MAKE_OFFER:
              this.dataMadeOfferMixin()
              break
            case DATA_MIXIN.RFQ_COPY_AND_CREATE_NEW:
              this.dataPrepareRFQCopyAndCreateNewMixin(response.data)
              break
            case DATA_MIXIN.RFQ_COPY:
              this.dataSetRFQCopyMixin(response.data)
              break
            case DATA_MIXIN.RFQ_EDIT:
              this.dataSetEditRFQMixin(response.data)
              break
            case DATA_MIXIN.OFFER_EDIT:
              this.dataSetEditOfferMixin(response.data)
              break
            case DATA_MIXIN.RFQ_SAVE_DRAFT:
              this.dataSaveRFQDraftMixin()
              break
            case DATA_MIXIN.RFQ_SAVE_DRAFT_AND_COPY:
              this.dataGetRFQAndCreateCopyMixin(response.data)
              break
            case DATA_MIXIN.CHECK_PASSWORD:
              this.dataCheckPasswordMixin(response.data, true)
              break
            case DATA_MIXIN.RFQ_SAVE_AS_NEW:
              this.dataSaveRFQAsNewMixin()
              break
            case FILE_TYPES.GERBER:
            case FILE_TYPES.SPECIFICATION:
            case FILE_TYPES.PANEL_DRAWING:
            case FILE_TYPES.OTHER:
            case FILE_TYPES.TECH_CONFIRMED:
            case FILE_TYPES.TECH_CSCC:
            case FILE_TYPES.COMMUNICATION:
            case FILE_TYPES.CONTRACT:
            case FILE_TYPES.ADDENDUM:
            case FILE_TYPES.OFFER_UPLOAD:
            case FILE_TYPES.PDF_PARSER:
            case FILE_TYPES.CUSTOMER_QUOTE_PROPOSED:
            case FILE_TYPES.CUSTOMER_QUOTE_SPECIFIED:
              this.setFileUploadDataMixin(
                response.data,
                command,
                FILE_TYPES.STATUS.UPLOAD.SUCCESS.TEXT,
              )
              break
            case DATA_MIXIN.SET_SIDEBAR:
              this.dataSetSidebarMixin(response.data)
              break
            case DATA_MIXIN.B2B_CONTACTS:
              this.dataSetB2BContactsMixin(response.data)
              break
            case DATA_MIXIN.SEND_PASSWORD_RECOVERY_MAIL:
              this.dataSetRecoveryMailStatusMixin(1)
              break
            case DATA_MIXIN.RESET_PASSWORD:
              this.dataSetRecoveryMailStatusMixin(2)
              this.dataChangedPasswordMixin(response.data)
              break
            case DATA_MIXIN.CHANGE_PASSWORD:
              this.dataChangedPasswordMixin(response.data)
              break
            case DATA_MIXIN.B2B_REGISTER_CONTACT:
              this.dataSetLastContactStatus(1)
              break
            case DATA_MIXIN.B2B_EDIT_CONTACT:
              this.dataSetLastContactStatus(2)
              break
            case DATA_MIXIN.SET_INITIAL_PASSWORD:
              this.dataSetRecoveryMailStatusMixin(3)
              this.dataChangedPasswordMixin(response.data)
              break
            case DATA_MIXIN.SAVE_EDIT_MODE:
              this.dataRedirectSaveEditMixin()
              break
            case DATA_MIXIN.REJECT_RFQ:
              this.dataRejectRFQMixin()
              break
            case DATA_MIXIN.REJECT_OFFER:
              this.dataRejectOfferMixin()
              break
            case DATA_MIXIN.UPDATE_QUOTE:
              this.dataUpdateImportedQuoteMixin(response.data, command)
              break
            case DATA_MIXIN.ACCEPT_CONTRACT:
              this.dataAcceptedContractMixin()
              break
            case DATA_MIXIN.EXECUTE_NEXT_COMMANDS:
              this.dataExecuteNextCommandMixin(command)
              break
            case DATA_MIXIN.UPDATE_COMMENTS:
              this.dataUpdateCommentsMixin(command, response.data)
              break
            case DATA_MIXIN.SWITCH_TO_SUPPORT_USER:
              this.dataSwitchToSupportUserMixin(response.data)
              break
            case DATA_MIXIN.SUPPLIER_LISTING:
              this.dataSetSuppliersMixin(response.data)
              break
            case DATA_MIXIN.ASSIGN_TO_ME:
              this.dataSetAssignToMeMixin()
              break
            case DATA_MIXIN.EXIT_RFQ_EDITOR:
              this.dataExitEditorMixin()
              break
            case DATA_MIXIN.ADD_RFQ_OFFER:
              this.dataAddRfqOfferMixin(response.data, command)
              break
            case DATA_MIXIN.REDIRECT_AFTER_OFFERS_RECALL:
              this.dataSetRedirectAfterOffersRecallMixin()
              break
            case DATA_MIXIN.SET_NEW_DUE_DATE_FOR_OFFERS:
              this.dataSetNewDueDateForOffersMixin()
              break
            case DATA_MIXIN.REQUEST_CLARIFICATION:
              this.dataRequestClarificationMixin()
              break
            case DATA_MIXIN.PROVIDE_RFQ_INFORMATION:
              this.dataProvideRfQInformationMixin()
              break
            case DATA_MIXIN.REQUEST_OFFER_REWORK:
              this.dataRequestOfferReworkMixin()
              break
            case DATA_MIXIN.GENERATE_CONTRACT:
              this.dataGenerateContractMixin(response)
              break
            case DATA_MIXIN.GENERATE_QUOTE_DOCUMENT:
              this.dataGenerateQuoteDocumentMixin(response)
              break
            case DATA_MIXIN.PREPARE_CONTRACTS:
              this.dataPrepareContractsMixin()
              break
            case DATA_MIXIN.SEND_OUT_CONTRACTS:
              this.dataSendOutContractsMixin()
              break
            case DATA_MIXIN.REQUEST_OFFER:
              this.dataRequestOfferMixin()
              break
            case DATA_MIXIN.SUBMIT_OFFERS:
              this.dataSubmitOffersMixin()
              break
            case DATA_MIXIN.SUBMIT_OFFERS_INSTANT_QUOTE:
              this.dataSubmitOffersInstantQuoteMixin()
              break
            case DATA_MIXIN.REOPEN_OFFERS:
              this.dataReopenOffersMixin()
              break
            case DATA_MIXIN.ADD_NEW_SUPPLIER:
              this.dataAddNewSupplierMixin()
              break
            case DATA_MIXIN.DASHBOARD:
              this.dataSetDashboardDataMixin(response.data)
              break
            case DATA_MIXIN.VALIDATE_RFQ:
              this.dataValidateRFQMixin()
              break
            case DATA_MIXIN.SELECT_SUPPLIERS:
              this.dataSelectSuppliersMixin()
              break
            case DATA_MIXIN.CALCULATE_INSTANT_QUOTE:
              this.dataCalculateInstantQuoteMixin()
              break
            case DATA_MIXIN.CREATE_QUOTE:
              this.dataCreateQuoteMixin()
              break
            case DATA_MIXIN.SEND_QUOTE:
              this.dataSendQuoteMixin()
              break
            case DATA_MIXIN.REDIRECT_AFTER_SUPPLIER_EXPERT_REWORK_REQUEST:
              this.dataRequestQuoteRework()
              break
            case DATA_MIXIN.INITIALIZE_QUOTE_PANELS_BEFORE_FILE_UPLOAD:
              this.sendFileUploadDataMixin(command.fileItem)
              break
            case DATA_MIXIN.BEGIN_SUPPLIER_SELECTION:
              this.dataBeginSupplierSelection()
          }
        }
        if (command.targets.refresh !== null) {
          this.$store.commit('setAxiosDataRefreshTargets', {
            targets: command.targets.refresh,
            isPreloadDependency: command.isPreloadDependency,
            dataGroup: command.dataGroup,
          })
          if (!command.isPreloadDependency && command.action) {
            this.$store.commit('deactivateActionStatus', command.action.value)
          }
        } else if (
          !command.isPreloadDependency &&
          !(command.action && command.action.triggersRouteChange) &&
          (!command.nextCommandsList || !command.nextCommandsList.length > 0)
        ) {
          this.$store.commit('setAxiosDataProcessingStatus', false)
          if (command.action !== undefined) {
            this.$store.commit('deactivateActionStatus', command.action.value)
          }
        }
      } else {
        this.$store.commit('setRFQLockedStatus', response.data)
        this.$router.push({
          name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].MY_RFQS.DRAFTS
            .NAME,
        })
      }
    },
    axiosRFQListingPathMixin() {
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
          return AXIOS.API.POST.RFQ_LISTING_SALES_EXPERT
        case PERMISSION_GROUPS.SALES_MANAGER.VALUE:
          return AXIOS.API.POST.RFQ_LISTING_SALES_MANAGER
        case PERMISSION_GROUPS.SUPPLIER.VALUE:
          return AXIOS.API.POST.RFQ_LISTING_SUPPLIER
        case PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE:
          return AXIOS.API.POST.RFQ_LISTING_SUPPLIER_EXPERT
        case PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE:
          return AXIOS.API.POST.RFQ_LISTING_SUPPLIER_MANAGER
        default:
          return AXIOS.API.POST.RFQ_LISTING_CUSTOMER
      }
    },
    axiosOfferListingPathMixin() {
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
          return AXIOS.API.POST.OFFERS_LISTING_SALES_EXPERT
        case PERMISSION_GROUPS.SUPPLIER.VALUE:
          return AXIOS.API.POST.OFFERS_LISTING_SUPPLIER
        default:
          return AXIOS.API.POST.OFFERS_LISTING_SUPPLIER_EXPERT
      }
    },
    axiosActiveRFQPathMixin(id) {
      let path = ''
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
          path = AXIOS.API.GET.GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SALES_EXPERT
          break
        case PERMISSION_GROUPS.SALES_MANAGER.VALUE:
          path = AXIOS.API.GET.GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SALES_MANAGER
          break
        case PERMISSION_GROUPS.SUPPLIER.VALUE:
          path = AXIOS.API.GET.GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SUPPLIER
          break
        case PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE:
          path =
            AXIOS.API.GET.GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SUPPLIER_EXPERT
          break
        case PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE:
          path =
            AXIOS.API.GET.GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_SUPPLIER_MANAGER
          break
        default:
          path = AXIOS.API.GET.GET_SINGLE_RFQ_WITH_DATABASE_ID_FOR_CUSTOMER
      }
      return path.replace(PLACEHOLDER.ID.RFQ, id)
    },
    axiosActiveOfferPathMixin(id) {
      let path = ''
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE:
          path =
            AXIOS.API.GET.GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SUPPLIER_EXPERT.replace(
              PLACEHOLDER.ID.RFQ,
              id,
            )
          break
        default:
          path =
            AXIOS.API.GET.GET_SINGLE_OFFER_WITH_DATABASE_ID_FOR_SUPPLIER.replace(
              PLACEHOLDER.ID.OFFER,
              id,
            )
      }
      return path
    },
    axiosAdjustStatusMixin(command) {
      if (
        command.showProcessingStatus &&
        (!command.fileItem || command.forceProcessingStatus)
      ) {
        this.$store.commit('setAxiosDataProcessingStatus', true)
      }
      if (command.action) {
        this.$store.commit('activateActionStatus', command.action.value)
      }
    },
    axiosPostMixin(command) {
      this.axiosAdjustStatusMixin(command)
      command.type = AXIOS.TYPES.POST
      this.$axios
        .post(command.path, command.body, command.config)
        .then((response) => {
          this.axiosRFQStatusActionMixin(command)
          this.axiosSetResponseTargetMixin(command, response)
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            this.$store.commit('setErrorLog', {
              error: error,
              command: command,
            })
          }
          if (error) {
            //Special handling for Check Password response
            if (
              error.response &&
              (command.targets.data === DATA_MIXIN.CHECK_HASH ||
                command.targets.data === DATA_MIXIN.CHECK_PASSWORD)
            ) {
              this.dataCheckPasswordMixin(
                error.response.data,
                command.targets.data === DATA_MIXIN.CHECK_PASSWORD,
              )
            } else {
              if (command.file) {
                this.setFileUploadDataMixin(
                  null,
                  command,
                  this.printErrorMixin(error),
                  true,
                )
                command.file.transferInProgress = false
              }
              this.$store.commit('setAxiosDataProcessingStatus', false)
              if (error.response) {
                this.$store.commit(
                  'setAxiosDataErrorMessage',
                  this.printErrorMixin(error),
                )
              }
            }
          } else {
            this.$store.commit('setAxiosDataProcessingStatus', false)
          }
        })
    },
    axiosPutMixin(command) {
      this.axiosAdjustStatusMixin(command)
      command.type = AXIOS.TYPES.PUT
      this.$axios
        .put(command.path, command.body, command.config)
        .then((response) => {
          this.axiosRFQStatusActionMixin(command)
          this.axiosSetResponseTargetMixin(command, response)
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            this.$store.commit('setErrorLog', {
              error: error,
              command: command,
            })
          }
          if (command.file) {
            this.setFileUploadDataMixin(
              null,
              command,
              this.printErrorMixin(error),
              true,
            )
          }
          this.$store.commit('setAxiosDataProcessingStatus', false)
          if (error.response) {
            this.$store.commit(
              'setAxiosDataErrorMessage',
              this.printErrorMixin(error),
            )
          }
        })
    },
    axiosGetMixin(command) {
      this.axiosAdjustStatusMixin(command)
      command.type = AXIOS.TYPES.GET
      this.$axios
        .get(command.path, command.config)
        .then((response) => {
          if (command.fileItem) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            let fileName = command.fileItem.file.name
            link.setAttribute('href', url)
            link.setAttribute('target', '_blank')
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            if (command.fileItem.file.downloadStatus) {
              command.fileItem.messages.downloadStatus =
                FILE_TYPES.STATUS.DOWNLOAD.SUCCESS.TEXT
              command.fileItem.file.downloadStatus = 2
            }
            command.fileItem.file.transferInProgress = false
            if (command.forceProcessingStatus) {
              this.$store.commit('setAxiosDataProcessingStatus', false)
            }
          } else {
            this.axiosSetResponseTargetMixin(command, response)
          }
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            this.$store.commit('setErrorLog', {
              error: error,
              command: command,
            })
          }
          this.$store.commit('setAxiosDataProcessingStatus', false)
          if (command.fileItem && command.fileItem.messages && error.response) {
            command.fileItem.messages.downloadStatus =
              this.printErrorMixin(error)
            command.fileItem.file.downloadStatus = 3
            command.fileItem.file.transferInProgress = false
          } else if (error.response) {
            this.$store.commit(
              'setAxiosDataErrorMessage',
              this.printErrorMixin(error),
            )
          }
        })
    },
    axiosGetAllMixin(commands) {
      this.$store.commit('setAxiosDataProcessingStatus', true)
      const fetchDependencies = (command) =>
        command.type === AXIOS.TYPES.GET
          ? this.$axios.get(command.path, command.config)
          : this.$axios.post(
              command.path,
              command.body,
              command.config
                ? command.config
                : this.$store.getters.getAxiosData.config,
            )
      const promiseArray = commands.map(fetchDependencies)
      Promise.all(promiseArray)
        .then((data) => {
          data.forEach((response, index) => {
            this.$store.commit('setLastPromiseAllCommand', commands[index])
            this.axiosSetResponseTargetMixin(commands[index], response)
          })
          this.$store.commit('setAxiosDataProcessingStatus', false)
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            this.$store.commit('setErrorLog', {
              error: error,
              command: this.$store.getters.getLastPromiseAllCommand,
            })
          }
          this.redirectIfLockFailedMixin(commands, error)
          if (
            error.response &&
            error.response.status === 403 &&
            this.$store.getters.isLoggedIn
          ) {
            this.$store.commit(
              'setAxiosDataErrorMessage',
              this.printErrorMixin(error),
            )
            this.$store.commit('triggerLogout')
          } else if (error.message !== 'canceled') {
            this.printErrorMixin(error)
          }
        })
    },
    axiosPatchMixin(command) {
      this.axiosAdjustStatusMixin(command)
      command.type = AXIOS.TYPES.PATCH
      this.$axios
        .patch(command.path, command.body, command.config)
        .then((response) => {
          this.axiosRFQStatusActionMixin(command)
          this.axiosSetResponseTargetMixin(command, response)
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            this.$store.commit('setErrorLog', {
              error: error,
              command: command,
            })
          }
          this.$store.commit('setAxiosDataProcessingStatus', false)
          if (error.response) {
            this.$store.commit(
              'setAxiosDataErrorMessage',
              this.printErrorMixin(error),
            )
          }
        })
    },
    axiosDeleteMixin(command) {
      this.axiosAdjustStatusMixin(command)
      command.type = AXIOS.TYPES.DELETE
      this.$axios
        .delete(command.path, command.config)
        .then((response) => {
          this.axiosRFQStatusActionMixin(command)
          this.axiosSetResponseTargetMixin(command, response)
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            this.$store.commit('setErrorLog', {
              error: error,
              command: command,
            })
          }
          this.$store.commit('setAxiosDataProcessingStatus', false)
          if (error.response) {
            this.$store.commit(
              'setAxiosDataErrorMessage',
              this.printErrorMixin(error),
            )
          }
        })
    },
    axiosAddCommandToQueueMixin(
      commandList,
      command,
      dataTargetBeforeNextCommand = null,
    ) {
      if (dataTargetBeforeNextCommand) {
        command.targets.data = dataTargetBeforeNextCommand
        command.targets.excuteNextCommand = true
      } else {
        command.targets.data = DATA_MIXIN.EXECUTE_NEXT_COMMANDS
      }

      commandList.push(command)
      return commandList
    },
    axiosUpdateDashboardMixin() {
      let newCommand = this.axiosGetNewCommandObjectMixin()
      newCommand.path = AXIOS.API.POST.GET_DASHBOARD_DATA
      newCommand.targets.data = DATA_MIXIN.DASHBOARD
      newCommand.showProcessingStatus = false
      this.$store.commit('setAxiosUpdateDashboardStatus', true)
      if (this.$store.getters.getGlobalOverviewStatus) {
        newCommand.config.params = {
          global: 1,
        }
      }
      this.axiosPostMixin(newCommand)
    },
    axiosUpdateSidebarMixin() {
      let newCommand = this.axiosGetNewCommandObjectMixin()
      newCommand.path = AXIOS.API.GET.SIDEBAR
      newCommand.targets.data = DATA_MIXIN.SET_SIDEBAR
      newCommand.showProcessingStatus = false
      this.$store.commit('setAxiosUpdateSidebarStatus', true)
      if (this.$store.getters.getGlobalOverviewStatus) {
        newCommand.config.params = {
          global: 1,
        }
      }
      this.axiosGetMixin(newCommand)
    },
    axiosUpdateCurrentUserMixin(hideStatus = true) {
      let newCommand = this.axiosGetNewCommandObjectMixin()
      newCommand.path = AXIOS.API.GET.FETCH_CURRENT_USER
      newCommand.targets.data = DATA_MIXIN.SET_CURRENT_USER
      if (hideStatus) {
        newCommand.showProcessingStatus = false
      }
      this.axiosGetMixin(newCommand)
    },
    setGlobalListingFilterMixin() {
      if (
        this.$store.getters.getActivePermissionGroup ===
          PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE &&
        this.$data[KEYS_RFQ.FILTER]
      ) {
        this.$store.getters.getGlobalOverviewStatus
          ? (this.$data[KEYS_RFQ.FILTER][KEYS_GENERAL.GLOBAL] = true)
          : this.$data[KEYS_RFQ.FILTER][(KEYS_GENERAL.GLOBAL = false)]
      }
    },
    axiosUpdateRFQListingsMixin() {
      this.setGlobalListingFilterMixin()
      let newCommand = this.axiosGetNewCommandObjectMixin()
      switch (this.$store.getters.getActivePermissionGroup) {
        case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
          newCommand.path = AXIOS.API.POST.RFQ_LISTING_SALES_EXPERT
          break
        case PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE:
          newCommand.path = AXIOS.API.POST.RFQ_LISTING_SUPPLIER_EXPERT
          break
        default:
          newCommand.path = AXIOS.API.POST.RFQ_LISTING_CUSTOMER
      }
      newCommand.body = cloneDeep(this.$data[KEYS_RFQ.FILTER])
      newCommand.targets.data = DATA_MIXIN.RFQ_LISTING
      newCommand.type = AXIOS.TYPES.POST

      this.$store.commit('setAxiosUpdateListingStatus', true)
      this.axiosPostMixin(newCommand)
    },
    axiosUpdateOfferListingsMixin(layoutVariant) {
      let newCommand = this.axiosGetNewCommandObjectMixin()
      newCommand.path = this.axiosOfferListingPathMixin()
      newCommand.body = this.$data[KEYS_RFQ.FILTER]
      newCommand.targets.data = DATA_MIXIN.OFFERS_LISTING
      newCommand.layoutVariant = layoutVariant
      this.$store.commit('setAxiosUpdateListingStatus', true)
      this.axiosPostMixin(newCommand)
    },
    axiosRFQStatusActionMixin(command) {
      if (command.rfqStatus) {
        this.$store.commit('setRFQStatusMessage', command)
        if (
          this.$route.name ===
          ROUTER_DATA[this.$store.getters.getActivePermissionGroup].RFQ_DETAILS
            .NAME
        ) {
          switch (command.rfqStatus) {
            case STATES.DELETED.VALUE:
              this.$router.push({
                name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                  .MY_RFQS.DRAFTS.NAME,
              })
              break
            case STATES.CHANGE_REQUESTED.VALUE:
              this.$router.push({
                name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                  .MY_RFQS.CLARIFICATION.NAME,
              })
              break
            case STATES.CANCELLED.VALUE:
              this.$router.push({
                name: ROUTER_DATA[this.$store.getters.getActivePermissionGroup]
                  .ARCHIVE.NAME,
              })
          }
        }
      }
    },
    getAxiosFileStatusMixin(status) {
      switch (status) {
        case FILE_TRANSFER_STATUS.FAILED:
          return 'danger'
        case FILE_TRANSFER_STATUS.SUCCESSFUL:
          return 'success'
        default:
          return 'warning'
      }
    },
    redirectIfLockFailedMixin(commands, error) {
      if (this.$route.meta.lockRedirectTarget) {
        let lockCommand
        commands.forEach((command) => {
          if (command.isLockCommand) {
            lockCommand = command
          }
        })

        if (lockCommand && error.config.url === lockCommand.path) {
          this.$router.push({
            name: this.$route.meta.lockRedirectTarget,
          })
        }
      }
    },
  },
}
