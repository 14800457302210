import { KEYS_GENERAL } from '@/constants/objectKeys/general'
import { FORM_ITEMS } from '@/constants/formItems'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
import { KEYS_CUSTOMER } from '@/constants/objectKeys/customer'
import { DELIVERY_TYPE, deliveryObject } from '@/constants/deliveries'
import cloneDeep from 'lodash/cloneDeep'
import deliveryMixins from '@/mixins/deliveryMixins'
import { FILE_TYPES } from '@/constants/fileTypes'
import { ROUTER_DATA } from '@/constants/routerData'
import quotesMixins from '@/mixins/quotesMixins'
import { COMMUNICATION_ITEMS, KEYS_RFQ } from '@/constants/objectKeys/rfq'
import isInt from 'validator/es/lib/isInt'
import { rfqCopperCriteriaTypes } from '@/assets/inputDataObjects/types/rfq/copperCriteria'
import utilMixins from '@/mixins/utilMixins'
import {
  CURRENCY_TYPE,
  MISSING_ADDRESS,
  NOT_SPECIFIED,
  PANEL_TYPES,
  QUOTE_DELIVERY_OPTIONS,
  RFQ_TYPE_LABELS,
  RFQ_TYPES,
} from '@/constants/constants'
import rfqTypesMixins from '@/mixins/rfqTypesMixins'
import { KEYS_QUOTE } from '@/constants/objectKeys/quote'
import { itemQuoteFile } from '@/assets/inputDataObjects/types/rfq/quotes'
import { supplierExpertSupplierOffers } from '@/assets/inputDataObjects/forms/offer/supplierExpertSupplierOffers'
import supplierScenariosMixin from '@/mixins/supplierScenariosMixin'
import { IMPORT_EXPORT } from '@/constants/importExport'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { AXIOS } from '@/constants/axios'
import quotationMixins from '@/mixins/quotationMixins'
export default {
  mixins: [
    quotesMixins,
    deliveryMixins,
    utilMixins,
    rfqTypesMixins,
    supplierScenariosMixin,
    quotationMixins,
  ],
  methods: {
    importQuoteFilesMixin(quote, panel, fileObject) {
      if (panel[KEYS_QUOTE.QUOTE_FILE_NAME]) {
        quote[fileObject.keyName] = fileObject
        quote[fileObject.keyName].file.name = panel[KEYS_QUOTE.QUOTE_FILE_NAME]
        if (panel.panelType === PANEL_TYPES.PROPOSED) {
          quote[fileObject.keyName].file.url.download =
            AXIOS.API.GET.DOWNLOAD_CUSTOMER_QUOTE_PROPOSED_FILE
        }
      }
      return quote
    },
    importQuotesMixin(quotes, data) {
      if (
        data[API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP] &&
        data[API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP].length
      ) {
        data[API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP].sort((item1, item2) => {
          if (item1.createdAt.date === item2.createdAt.date) return 0
          if (item1.createdAt.date > item2.createdAt.date) return -1
          if (item1.createdAt.date < item2.createdAt.date) return 1
        })
        data[API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP].forEach((singleQuote) => {
          let newQuote = cloneDeep(singleQuote)

          if (singleQuote[API_OBJECT_KEY_NAMES.QUOTATION.QUOTE_PANELS]) {
            singleQuote[API_OBJECT_KEY_NAMES.QUOTATION.QUOTE_PANELS].forEach(
              (panel) => {
                if (
                  panel.panelType ===
                  newQuote[
                    API_OBJECT_KEY_NAMES.QUOTATION
                      .PANEL_TYPE_TO_DISPLAY_TO_CUSTOMER
                  ]
                ) {
                  newQuote = this.importQuoteFilesMixin(
                    newQuote,
                    panel,
                    cloneDeep(itemQuoteFile),
                  )
                }
              },
            )
          }

          newQuote.sortedDetails = this.sortQuoteDetailsMixin(newQuote)
          quotes.data.push(newQuote)
        })
      }
      return quotes
    },
    getDeletedDeliveryLocationLabelMixin(locationId, locations) {
      let name
      for (let i = 0; i < locations.length; i++) {
        if (
          locations[i].customerAddressId === locationId &&
          locations[i].address
        ) {
          name =
            locations[i].address.customFields &&
            locations[i].address.customFields.elio_address_nickname
              ? locations[i].address.customFields.elio_address_nickname
              : locations[i].address.city
          break
        }
      }
      return name
    },
    importDeliveryLabelMixin(location) {
      let labelData = {
        label: MISSING_ADDRESS,
        missingAddress: true,
      }
      if (
        this.$store.getters.getActivePermissionGroup ===
        PERMISSION_GROUPS.CUSTOMER.VALUE
      ) {
        location = this.$data[KEYS_CUSTOMER.SHIPPING_ADDRESSES].find(
          (x) => x.id === location.customerAddressId,
        )
      }

      if (location) {
        if (
          location[API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.CATEGORY] &&
          location[API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.CATEGORY][
            API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.NICKNAME
          ]
        ) {
          labelData = {
            label:
              location[API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.CATEGORY][
                API_OBJECT_KEY_NAMES.ADDRESS.CUSTOM_FIELDS.NICKNAME
              ],
            missingAddress: false,
          }
        } else if (
          location.city ||
          (location.address && location.address.city)
        ) {
          labelData = {
            label: location.city ? location.city : location.address.city,
            missingAddress: false,
          }
        }
      }
      return labelData
    },
    importDeliveriesMixin(deliveries, data) {
      let deliveriesData = []
      if (
        data[API_OBJECT_KEY_NAMES.RFQ.DELIVERY] &&
        this.$data[KEYS_CUSTOMER.SHIPPING_ADDRESSES] !== null
      ) {
        let newDeliveries = data[API_OBJECT_KEY_NAMES.RFQ.DELIVERY]

        newDeliveries.sort((item1, item2) => {
          if (item1.start.date === item2.start.date) return 0
          if (item1.start.date > item2.start.date) return 1
          if (item1.start.date < item2.start.date) return -1
        })

        newDeliveries.sort((item1, item2) => {
          if (item1.scenarioNumber === item2.scenarioNumber) return 0
          if (item1.scenarioNumber > item2.scenarioNumber) return 1
          if (item1.scenarioNumber < item2.scenarioNumber) return -1
        })

        let uniqueLocations = new Map()
        newDeliveries.forEach((deliveryItem) => {
          deliveryItem.locations.map((location) => {
            uniqueLocations.set(location.customerAddressId, location)
          })
        })

        if (uniqueLocations.size) {
          uniqueLocations.forEach((location) => {
            let newDeliveryObject = cloneDeep(deliveryObject)
            newDeliveryObject.location.value = location.customerAddressId

            if (location.address) {
              newDeliveryObject.location.rfqCustomerAddressId =
                location.address.id
            }

            let labelData = this.importDeliveryLabelMixin(cloneDeep(location))
            newDeliveryObject.location.label = labelData.label
            newDeliveryObject.location.missingAddress = labelData.missingAddress

            let sopIndex = 0
            newDeliveries.forEach((delivery) => {
              const filteredLocation = delivery.locations.filter(
                (deliveryLocation) => {
                  return (
                    deliveryLocation.customerAddressId ===
                    location.customerAddressId
                  )
                },
              )

              let deliveryType = DELIVERY_TYPE.SOP
              if (delivery.isSample) {
                deliveryType = DELIVERY_TYPE.SAMPLES
              } else if (delivery.annualForecast) {
                deliveryType = DELIVERY_TYPE.ANNUAL_FORECAST
              }

              switch (deliveryType) {
                case DELIVERY_TYPE.SAMPLES:
                  newDeliveryObject.samples = filteredLocation[0].amount
                    ? filteredLocation[0].amount
                    : 0
                  break
                case DELIVERY_TYPE.ANNUAL_FORECAST:
                  newDeliveryObject.annualForecast = filteredLocation[0].amount
                    ? filteredLocation[0].amount
                    : 0
                  break
                default:
                  newDeliveryObject.sop[sopIndex] = filteredLocation[0].amount
                    ? filteredLocation[0].amount
                    : 0
                  sopIndex++
                  break
              }
            })
            deliveriesData.push(cloneDeep(newDeliveryObject))
          })
        }
      }
      deliveries.data = deliveriesData
      if (deliveriesData.length) {
        deliveries.activeLocations = deliveriesData.length
        deliveries.availableLocations = this.initializeAvailableLocationsMixin(
          deliveries.locations,
          data,
        )
      }
      this.validateDeliveryLocationsMixin(deliveries)

      const SCENARIOS =
        data[API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.SCENARIOS_PREFIX]
      // Add scenarios data to delivery
      if (SCENARIOS && SCENARIOS.length) {
        switch (data[API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE]) {
          case RFQ_TYPES.SCENARIOS:
            deliveries.individualScenarios =
              SCENARIOS[0].scenario.customScenario
            break
          default:
            deliveries.individualScenarios = SCENARIOS[0].scenario
        }
      }
      return deliveries
    },
    importSupplierScenariosFormDataMixin(supplierScenarios, data) {
      const SCENARIOS =
        data[API_OBJECT_KEY_NAMES.RFQ.SUPPLIER_EXPERT_DATA.SCENARIOS_PREFIX]
      if (
        SCENARIOS &&
        SCENARIOS.length &&
        data[API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE] ===
          RFQ_TYPES.SCENARIOS
      ) {
        SCENARIOS[0].scenario.scenario.forEach((customScenario, index) => {
          this.addSupplierScenarioMixin(false, false)
          supplierScenarios.data[index].annualQuantity.value = String(
            customScenario.annualQuantity,
          )
          supplierScenarios.data[index].selectedScenarios = new Set(
            customScenario.scenarios,
          )
        })
        this.updateSupplierScenarioErrorCountMixin()
      }
    },
    importSupplierOffersFormDataMixin(supplierFormsArray, offerData, index) {
      if (supplierFormsArray && offerData) {
        let supplierForm = cloneDeep(supplierExpertSupplierOffers)
        this.importFormDataMixin(supplierForm, offerData, null)
        supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].info.company =
          offerData.supplier
            ? offerData.supplier.company
            : `${NOT_SPECIFIED} ${index + 1}`
        supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].info.id = offerData.supplierId
        supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].info.offerId = offerData.id
        supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].info.state = offerData.state
        supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].info.annualPriceDown =
          offerData.annualPriceDown
        supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].info.flatPrice =
          offerData.flatPrice
        supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].info.gotProposedPanel =
          supplierForm[KEYS_RFQ.QUOTATIONS].data.some((panel) => {
            return panel.panelType === PANEL_TYPES.PROPOSED
          })

        if (offerData[API_OBJECT_KEY_NAMES.OFFER.QUOTA_ALLOCATION]) {
          let selection = offerData[API_OBJECT_KEY_NAMES.OFFER.QUOTA_ALLOCATION]
          if (Array.isArray(selection.selected)) {
            selection.selected = new Set(selection.selected)
          }
          supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].selection = selection
        }
        supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].currency =
          offerData[API_OBJECT_KEY_NAMES.OFFER.CURRENCY]

        // Add first supplier comment
        if (offerData[API_OBJECT_KEY_NAMES.RFQ.COMMENTS]) {
          offerData[API_OBJECT_KEY_NAMES.RFQ.COMMENTS].every((commentData) => {
            if (commentData.customerId === offerData.supplierId) {
              supplierForm[KEYS_RFQ.SUPPLIER_SETTINGS].info.firstComment =
                commentData.comment
              return false
            }
            return true
          })
        }

        supplierFormsArray.push(cloneDeep(supplierForm))
        this.$data[KEYS_GENERAL.ACTIVE_SUPPLIER_IDS].add(offerData.supplierId)
        supplierFormsArray.sort((supplierA, supplierB) => {
          let companyA = supplierA[KEYS_RFQ.SUPPLIER_SETTINGS].info.company
          let companyB = supplierB[KEYS_RFQ.SUPPLIER_SETTINGS].info.company
          return companyA.localeCompare(companyB)
        })
      }
    },
    importFormDataMixin(form, data, target = KEYS_GENERAL.FORM) {
      Object.entries(form).forEach(([key, item]) => {
        if (item.singleLevelObject) {
          form[key] = this.importDataToFormMixin(item, data)
        } else {
          Object.entries(item).forEach(([itemKey, subItem]) => {
            Object.assign(
              item[itemKey],
              this.importDataToFormMixin(subItem, data),
            )
          })
        }
      })
      if (target === null) {
        Object.assign(
          form,
          this.importSetItemVisibilityAndRequirementsMixin(form),
        )
      } else {
        Object.assign(
          this.$data[target],
          this.importSetItemVisibilityAndRequirementsMixin(form),
        )
      }
    },
    importAutoCorrectSettingsMixin(form, targetForm) {
      Object.values(form).forEach((item) => {
        if (item.singleLevelObject) {
          this.prepareAutoCorrectSettingsCheckMixin(item, targetForm)
        } else {
          Object.values(item).forEach((subItem) => {
            this.prepareAutoCorrectSettingsCheckMixin(subItem, targetForm)
          })
        }
      })
    },
    importSetItemVisibilityAndRequirementsMixin(form) {
      if (
        form[KEYS_RFQ.BASIC_FACTOR_PREFIX] &&
        form[KEYS_RFQ.BASIC_FACTOR_PREFIX][
          KEYS_RFQ.BASIC_FACTOR.BASIC_TECHNOLOGY
        ].value === 'HDI'
      ) {
        form[KEYS_RFQ.OTHERS_PREFIX][
          KEYS_RFQ.OTHERS.BURIED_VIA
        ].isHidden = false
      } else if (form[KEYS_RFQ.OTHERS_PREFIX]) {
        form[KEYS_RFQ.OTHERS_PREFIX][KEYS_RFQ.OTHERS.BURIED_VIA].isHidden = true
      }
      return form
    },
    importRFQInnerLayersMixin(item, data) {
      let newInnerLayers = []
      if (data) {
        let simplifiedEditor = true
        let form = this.$data[KEYS_GENERAL.FORM_COPY]
          ? this.$data[KEYS_GENERAL.FORM_COPY]
          : this.$data[KEYS_GENERAL.FORM]
        const topInnerLayerCount =
          (form[KEYS_RFQ.BASIC_FACTOR_PREFIX][KEYS_RFQ.BASIC_FACTOR.LAYER]
            .value -
            2) /
          2
        let innerLayerData =
          data[API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.INNER_LAYER]

        if (innerLayerData === null) {
          innerLayerData =
            rfqCopperCriteriaTypes[KEYS_RFQ.COPPER_CRITERIA.INNER_LAYER]
              .options[
              rfqCopperCriteriaTypes[KEYS_RFQ.COPPER_CRITERIA.INNER_LAYER]
                .defaultOption
            ].value
        }

        if (typeof innerLayerData === 'object') {
          if (innerLayerData.topInnerLayers) {
            let lastInnerLayerValue = null
            Object.values(innerLayerData.topInnerLayers).forEach(
              (innerLayerValue) => {
                if (lastInnerLayerValue === null) {
                  lastInnerLayerValue = innerLayerValue
                } else if (lastInnerLayerValue !== innerLayerValue) {
                  simplifiedEditor = false
                }
                newInnerLayers.push(innerLayerValue)
              },
            )
          }
        } else if (innerLayerData && isInt(innerLayerData)) {
          for (let i = 0; i < topInnerLayerCount; i++) {
            newInnerLayers.push(innerLayerData)
          }
        }
        this.$data[KEYS_GENERAL.SIMPLIFIED_INNER_LAYERS_EDITOR] =
          simplifiedEditor
        this.$data[KEYS_GENERAL.INNER_LAYERS] = newInnerLayers
      }
      return newInnerLayers
    },
    getObjectTargetMixin(item, data) {
      if (
        item.apiObjectGroup &&
        item.apiObjectGroup.IMPORT &&
        data[item.apiObjectGroup.VALUE]
      ) {
        return Array.isArray(data[item.apiObjectGroup.VALUE])
          ? data[item.apiObjectGroup.VALUE][0]
          : data[item.apiObjectGroup.VALUE]
      } else if (
        Object.values(KEYS_RFQ.TECH_INFORMATION_LEADTIME).some((i) =>
          [item.keyName, item.keyNameParent].includes(i),
        )
      ) {
        if (
          data[API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME_OBJECT] &&
          data[API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME_OBJECT]
            .length
        ) {
          return data[
            API_OBJECT_KEY_NAMES.OFFER.TECH_INFORMATION_LEADTIME_OBJECT
          ][0]
        }
      } else if (
        Object.values(KEYS_RFQ.NRE_MOV).some((i) =>
          [item.keyName, item.keyNameParent].includes(i),
        )
      ) {
        const CURRENCY = API_OBJECT_KEY_NAMES.OFFER.CHARGES.CURRENCY
        const ADDITIONAL_CURRENCY =
          API_OBJECT_KEY_NAMES.OFFER.ADDITIONAL_CURRENCY
        const CHARGES = API_OBJECT_KEY_NAMES.OFFER.CHARGES_OBJECT
        if (item[CURRENCY]) {
          switch (item[CURRENCY].type) {
            case CURRENCY_TYPE.BOTH:
              item[CURRENCY].values = [
                data[CURRENCY],
                data[ADDITIONAL_CURRENCY],
              ]
              break
            case CURRENCY_TYPE.ADDITIONAL:
              item[CURRENCY].values = [data[ADDITIONAL_CURRENCY]]
              break
            case CURRENCY_TYPE.MAIN:
              item[CURRENCY].values = [data[CURRENCY]]
              break
          }
        }
        if (data[CHARGES] && data[CHARGES].length) {
          for (let i = 0; i < data[CHARGES].length; i++) {
            if (item[CURRENCY].values.includes(data[CHARGES][i][CURRENCY])) {
              data = data[CHARGES][i]
              break
            }
          }
        }
      }
      return data
    },
    importQuotationsMixin(item, data) {
      const QUOTATIONS = API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS
      const RFQ_TYPE = API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE
      let gotData = false

      if (data[QUOTATIONS.PREFIX] && data[QUOTATIONS.PREFIX].length) {
        const FIRST_QUOTATION_OBJECT =
          data[QUOTATIONS.PREFIX][0][QUOTATIONS.QUOTATION]
        Object.keys(FIRST_QUOTATION_OBJECT).length > 0
          ? (gotData = true)
          : (gotData = false)
      }

      if (gotData) {
        let sortedCurrencyData = cloneDeep(data[QUOTATIONS.PREFIX])

        // Check if additional currency data from a previous session exists and delete it from the import
        if (!data[API_OBJECT_KEY_NAMES.OFFER.ADDITIONAL_CURRENCY]) {
          sortedCurrencyData.forEach((panel) => {
            Object.keys(panel.quotation).forEach((currency) => {
              if (currency !== data[API_OBJECT_KEY_NAMES.OFFER.CURRENCY]) {
                delete panel.quotation[currency]
              }
            })
          })
        }
        item.data = sortedCurrencyData.sort((a, b) => {
          return (
            a[API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS.PANEL_TYPE] -
            b[API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS.PANEL_TYPE]
          )
        })
      } else {
        item[RFQ_TYPE] = data[RFQ_TYPE]
        item.data = []

        const rfqData = data[API_OBJECT_KEY_NAMES.OFFER.RFQ]
          ? data[API_OBJECT_KEY_NAMES.OFFER.RFQ]
          : data
        if (rfqData) {
          item.data.push(this.generateQuotationPanelMixin(data, rfqData))
        }
      }
      return item
    },
    importCommunicationItemMixin(item, data) {
      let newData = this.getObjectTargetMixin(item, data)[
        item.apiObjectKeyNames[0]
      ]
      //Used for the supplier expert suppliers tab
      let supplierComments = []
      let supplierData = {}
      if (
        newData ||
        item.keyName === KEYS_RFQ.COM.COM_BOSCH_SUPPLIER_COMMENTS
      ) {
        switch (item.keyName) {
          case KEYS_RFQ.ADDITIONAL_INFO:
          case KEYS_RFQ.NOTES_FOR_ME:
            item.value = newData
            break
          case KEYS_RFQ.NOTES_SALES:
            if (
              this.$route.name === ROUTER_DATA.SHARED.RFQ_EDIT.NAME &&
              newData.length
            ) {
              item.value = newData[0].comment
            } else {
              item.comments = newData
            }
            break
          case KEYS_RFQ.COM.COM_BOSCH_SUPPLIER_COMMENTS:
            if (data.offers) {
              data.offers.forEach((offerData) => {
                supplierData.comments = offerData.comments
                if (offerData.supplier) {
                  supplierData.supplier = {
                    company: offerData.supplier.company,
                    customerNumber: offerData.supplier.customerNumber,
                  }
                } else {
                  supplierData.supplier = {
                    company: '',
                    customerNumber: '',
                  }
                }
                supplierData.targetId = offerData.id
                supplierComments.push(cloneDeep(supplierData))
              })
              item.comments = supplierComments
            }
            break
          default:
            item.comments = newData
        }
      }
      return item
    },
    importSpecifiedLotSizesMixin(item, data) {
      if (
        data[API_OBJECT_KEY_NAMES.RFQ.SPECIFY_LOT_SIZES] &&
        data[API_OBJECT_KEY_NAMES.RFQ.SPECIFY_LOT_SIZES].length
      ) {
        if (item.keyName === KEYS_RFQ.PROJECT_DATA.SPECIFY_LOT_SIZES) {
          item.checked = true
          item.value = 'true'
          item.required = true
          if (data[API_OBJECT_KEY_NAMES.RFQ.SPECIFY_LOT_SIZES][0] !== null) {
            item.subItems[0].value = String(
              data[API_OBJECT_KEY_NAMES.RFQ.SPECIFY_LOT_SIZES].length,
            )
          }
          item.importEvent = true
          item.subItems[0].importEvent = true
        } else {
          item.data = data[API_OBJECT_KEY_NAMES.RFQ.SPECIFY_LOT_SIZES]
        }
      }
      return item
    },
    importPanelDataMixin: function (item, data) {
      if (
        data[API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP] &&
        data[API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP][0]
      ) {
        const QUOTE = data[API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP][0]
        if (QUOTE.quotePanels) {
          let panelType = null
          switch (item.apiTransferStyle) {
            case IMPORT_EXPORT.API_TRANSFER_STYLE.CUSTOMER_SPECIFIED_PANEL:
              panelType = PANEL_TYPES.CUSTOMER_SPECIFIED
              break
            case IMPORT_EXPORT.API_TRANSFER_STYLE.PROPOSED_PANEL:
              panelType = PANEL_TYPES.PROPOSED
              break
          }
          QUOTE.quotePanels.forEach((quotePanel) => {
            if (quotePanel.panelType === panelType) {
              data = quotePanel
            }
          })
        }
      }
      return data
    },
    importQuoteScenarioPricingDeliveriesMixin: function (costData, panelType) {
      let missingDeliveryTypes = Object.values(QUOTE_DELIVERY_OPTIONS)
      let newPricingDeliveriesArray = []
      if (costData.quoteScenarioPricingDeliveries) {
        costData.quoteScenarioPricingDeliveries.forEach((pricingDelivery) => {
          missingDeliveryTypes.splice(
            missingDeliveryTypes.indexOf(pricingDelivery.deliveryType),
            1,
          )
          newPricingDeliveriesArray.push(pricingDelivery)
        })
      }
      if (missingDeliveryTypes.length) {
        missingDeliveryTypes.forEach((deliveryType) => {
          newPricingDeliveriesArray.push({
            logisticCost: 0,
            id: null,
            deliveryType: deliveryType,
            quoteScenarioPricingId: costData.quoteScenarioPricingId,
          })
          const BIT_MASK =
            1 << (this.getDeliveryOptionBitValueMixin(deliveryType) - 1)
          if (
            panelType === PANEL_TYPES.CUSTOMER_SPECIFIED &&
            this.$data.formQuoteTableSpecifiedPanels !== undefined
          ) {
            this.$data.formQuoteTableSpecifiedPanels &= ~BIT_MASK
            this.$data.formActiveTableSpecifiedPanels =
              this.$data.formQuoteTableSpecifiedPanels
          } else if (
            panelType === PANEL_TYPES.PROPOSED &&
            this.$data.formQuoteTableProposedPanels !== undefined
          ) {
            this.$data.formQuoteTableProposedPanels &= ~BIT_MASK
            this.$data.formActiveTableProposedPanels =
              this.$data.formQuoteTableProposedPanels
          }
        })
      }
      return newPricingDeliveriesArray
    },
    importLogisticCostsTableMixin: function (item, data) {
      let logisticCostData =
        data[API_OBJECT_KEY_NAMES.QUOTATION.QUOTE_PREPARATION.LOGISTIC_COSTS]
      if (logisticCostData) {
        let newMapData = new Map()
        if (item.keyNameParent === KEYS_RFQ.MASS_PRODUCTION_TABLES) {
          logisticCostData.forEach((costData) => {
            costData.label = costData.customerAddress.city
            costData.rfqCustomerAddressId = costData.customerAddress.id
            costData.quoteScenarioPricingDeliveries.forEach(
              (pricingDelivery) => {
                newMapData.set(
                  `${costData.customerAddress.id}${
                    item.apiTransferStyle ===
                    IMPORT_EXPORT.API_TRANSFER_STYLE.CUSTOMER_SPECIFIED_PANEL
                      ? PANEL_TYPES.CUSTOMER_SPECIFIED
                      : PANEL_TYPES.PROPOSED
                  }${costData.number}${pricingDelivery.deliveryType}`,
                  {
                    deliveryType: pricingDelivery.deliveryType,
                    lotSizes: cloneDeep(
                      pricingDelivery.massProductionCost
                        ? pricingDelivery.massProductionCost.lotSizes
                        : this.LOT_SIZES_TEMPLATE,
                    ),
                    maxPrice: this.getAdjustedMassProductionPricesMixin(
                      cloneDeep(this.LOT_SIZES_TEMPLATE),
                      pricingDelivery.massProductionCost
                        ? pricingDelivery.massProductionCost.lotSizes
                        : this.LOT_SIZES_TEMPLATE,
                      pricingDelivery.logisticCost,
                      costData.quantity,
                      this.activeTableIsBasedOnFlatPrice,
                      this.$data[KEYS_GENERAL.IMPORT_DATA][
                        API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE
                      ],
                      true,
                    ),
                    price: this.getAdjustedMassProductionPricesMixin(
                      cloneDeep(this.LOT_SIZES_TEMPLATE),
                      pricingDelivery.massProductionCost
                        ? pricingDelivery.massProductionCost.lotSizes
                        : this.LOT_SIZES_TEMPLATE,
                      pricingDelivery.logisticCost,
                      costData.quantity,
                      this.activeTableIsBasedOnFlatPrice,
                      this.$data[KEYS_GENERAL.IMPORT_DATA][
                        API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE
                      ],
                    ),
                    logisticCost: pricingDelivery.logisticCost,
                  },
                )
              },
            )
          })
        } else {
          const PANEL_TYPE =
            item.apiTransferStyle ===
            IMPORT_EXPORT.API_TRANSFER_STYLE.CUSTOMER_SPECIFIED_PANEL
              ? PANEL_TYPES.CUSTOMER_SPECIFIED
              : PANEL_TYPES.PROPOSED
          logisticCostData.forEach((costData) => {
            costData.label = costData.customerAddress.city
            costData.rfqCustomerAddressId = costData.customerAddress.id
            costData.quoteScenarioPricingDeliveries =
              this.importQuoteScenarioPricingDeliveriesMixin(
                costData,
                PANEL_TYPE,
              )
            newMapData.set(
              `${costData.rfqCustomerAddressId}${PANEL_TYPE}${costData.number}`,
              costData,
            )
          })
        }
        item.data = newMapData
      }
      return item
    },
    importDataToFormMixin: function (item, data, parentItem = undefined) {
      if (
        [
          IMPORT_EXPORT.API_TRANSFER_STYLE.PROPOSED_PANEL,
          IMPORT_EXPORT.API_TRANSFER_STYLE.CUSTOMER_SPECIFIED_PANEL,
        ].includes(item.apiTransferStyle)
      ) {
        data = this.importPanelDataMixin(item, data)
      }
      if (item.apiObjectKeyNames) {
        //loop through subItems
        switch (true) {
          case item.apiObjectKeyNames.includes(
            API_OBJECT_KEY_NAMES.QUOTATION.QUOTE_PREPARATION.LOGISTIC_COSTS,
          ) && !item.subItems:
            item = this.importLogisticCostsTableMixin(item, data)
            break
          case COMMUNICATION_ITEMS.includes(item.keyName):
            item = this.importCommunicationItemMixin(item, data)
            break
          case item.apiObjectKeyNames.includes(
            API_OBJECT_KEY_NAMES.RFQ.SPECIFY_LOT_SIZES,
          ):
            item = this.importSpecifiedLotSizesMixin(item, data)
            break
          case item.apiObjectKeyNames.includes(
            API_OBJECT_KEY_NAMES.RFQ.DELIVERY,
          ):
            item = this.importDeliveriesMixin(item, data)
            break
          case item.apiObjectKeyNames.includes(
            API_OBJECT_KEY_NAMES.OFFER.QUOTATIONS.PREFIX,
          ):
            item = this.importQuotationsMixin(item, data)
            break
          case item.apiObjectKeyNames.includes(
            API_OBJECT_KEY_NAMES.RFQ.QUOTES_GROUP,
          ):
            item = this.importQuotesMixin(item, data)
            break
          case item.apiObjectKeyNames.includes(
            API_OBJECT_KEY_NAMES.RFQ.COPPER_CRITERIA.INNER_LAYER,
          ):
            item = this.importRFQInnerLayersMixin(item, data)
            break
          default:
            if (
              item.apiObjectKeyNames.includes(
                API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.EXPECTED_LIFETIME,
              )
            ) {
              item.isHidden =
                data[API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE] ===
                RFQ_TYPES.YEARS
            } else if (
              item.apiObjectKeyNames.includes(
                API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_YEARS,
              )
            ) {
              switch (data[API_OBJECT_KEY_NAMES.RFQ.PROJECT_DATA.RFQ_TYPE]) {
                case RFQ_TYPES.YEARS:
                  item.messages.label = RFQ_TYPE_LABELS.YEARS
                  item.messages.type = 'Year'
                  item.messages.typePlural = 'Years'
                  break
                case RFQ_TYPES.SCENARIOS:
                  item.messages.label = RFQ_TYPE_LABELS.SCENARIOS
                  item.messages.type = ''
                  item.messages.typePlural = ''
                  break
                case RFQ_TYPES.LOT_SIZES:
                  item.messages.label = RFQ_TYPE_LABELS.LOT_SIZES
                  item.messages.type = ''
                  item.messages.typePlural = ''
              }
            }
            if (
              (item.apiObjectKeyNames.length > 0 ||
                item.radioValue !== undefined) &&
              item.subItems &&
              !item.advancedParams
            ) {
              if (item.targetIdIfKeysAreEqual && item.subItems) {
                const apiObjectKeyNames =
                  item.subItems[item.targetIdIfKeysAreEqual].apiObjectKeyNames
                let keyValues = new Set()
                apiObjectKeyNames.forEach((keyName) => {
                  keyValues.add(data[keyName])
                })

                if (keyValues.size === 1) {
                  let subItem = item.subItems[item.targetIdIfKeysAreEqual]
                  if (
                    this.getObjectTargetMixin(subItem, data)[
                      subItem.apiObjectKeyNames[0]
                    ]
                  ) {
                    subItem.value = this.getObjectTargetMixin(subItem, data)[
                      subItem.apiObjectKeyNames[0]
                    ]
                    item.radioValue = subItem.radioValueGroup
                    item.importLocked = true
                  }
                } else {
                  item.subItems.forEach((subItem, index) => {
                    if (index !== item.targetIdIfKeysAreEqual) {
                      if (
                        this.getObjectTargetMixin(subItem, data)[
                          subItem.apiObjectKeyNames[0]
                        ]
                      ) {
                        subItem.value = this.getObjectTargetMixin(
                          subItem,
                          data,
                        )[subItem.apiObjectKeyNames[0]]
                        item.radioValue = subItem.radioValueGroup
                        item.importLocked = true
                      }
                    }
                  })
                }
              } else {
                item.subItems.forEach((subItem) => {
                  if (item.importLocked !== true) {
                    Object.assign(
                      subItem,
                      this.importDataToFormMixin(subItem, data, item),
                    )
                  }
                })
              }
            }
            //Handle Files
            if (item.file && item.file.type) {
              item.file.name = ''
              switch (item.file.type) {
                case FILE_TYPES.GERBER:
                  if (data[API_OBJECT_KEY_NAMES.RFQ.DOCUMENTS.GERBER_FILE]) {
                    item.file.name =
                      data[API_OBJECT_KEY_NAMES.RFQ.DOCUMENTS.GERBER_FILE]
                  }
                  break
                case FILE_TYPES.SPECIFICATION:
                  if (data[API_OBJECT_KEY_NAMES.RFQ.DOCUMENTS.SPECIFICATION]) {
                    item.file.name =
                      data[API_OBJECT_KEY_NAMES.RFQ.DOCUMENTS.SPECIFICATION]
                  }
                  break
                case FILE_TYPES.PANEL_DRAWING:
                  if (data[API_OBJECT_KEY_NAMES.RFQ.DOCUMENTS.PANEL_DRAWING]) {
                    item.file.name =
                      data[API_OBJECT_KEY_NAMES.RFQ.DOCUMENTS.PANEL_DRAWING]
                  }
                  break
                case FILE_TYPES.OTHER:
                  if (data[API_OBJECT_KEY_NAMES.RFQ.DOCUMENTS.OTHERS]) {
                    item.file.name =
                      data[API_OBJECT_KEY_NAMES.RFQ.DOCUMENTS.OTHERS]
                  }
                  break
                case FILE_TYPES.TECH_CONFIRMED:
                  if (
                    data[
                      API_OBJECT_KEY_NAMES.OFFER.TECH_CONFIRMED.CONFIRMED_FILE
                    ]
                  ) {
                    item.file.name =
                      data[
                        API_OBJECT_KEY_NAMES.OFFER.TECH_CONFIRMED.CONFIRMED_FILE
                      ]
                  }
                  break
                case FILE_TYPES.TECH_CSCC:
                  if (
                    data[API_OBJECT_KEY_NAMES.OFFER.TECH_CONFIRMED.CSCC_FILE]
                  ) {
                    item.file.name =
                      data[API_OBJECT_KEY_NAMES.OFFER.TECH_CONFIRMED.CSCC_FILE]
                  }
                  break
                case FILE_TYPES.OFFER_UPLOAD:
                  if (
                    data[
                      API_OBJECT_KEY_NAMES.OFFER.OFFER_UPLOAD.OFFER_UPLOAD_FILE
                    ]
                  ) {
                    item.file.name =
                      data[
                        API_OBJECT_KEY_NAMES.OFFER.OFFER_UPLOAD.OFFER_UPLOAD_FILE
                      ]
                  }
                  break
                case FILE_TYPES.COMMUNICATION:
                  if (data[API_OBJECT_KEY_NAMES.RFQ.COM.COM_FILE]) {
                    item.file.name = data[API_OBJECT_KEY_NAMES.RFQ.COM.COM_FILE]
                  }
                  break
                case FILE_TYPES.CONTRACT:
                  if (data[API_OBJECT_KEY_NAMES.OFFER.CONTRACT.CONTRACT_FILE]) {
                    item.file.name =
                      data[API_OBJECT_KEY_NAMES.OFFER.CONTRACT.CONTRACT_FILE]
                  }
                  break
                case FILE_TYPES.ADDENDUM:
                  if (data[API_OBJECT_KEY_NAMES.OFFER.CONTRACT.ADDENDUM_FILE]) {
                    item.file.name =
                      data[API_OBJECT_KEY_NAMES.OFFER.CONTRACT.ADDENDUM_FILE]
                  }
                  break
                case FILE_TYPES.CUSTOMER_QUOTE_SPECIFIED:
                case FILE_TYPES.CUSTOMER_QUOTE_PROPOSED:
                  if (data[API_OBJECT_KEY_NAMES.RFQ.CUSTOMER_QUOTE.FILE]) {
                    item.file.name =
                      data[API_OBJECT_KEY_NAMES.RFQ.CUSTOMER_QUOTE.FILE]
                  }
                  break
              }
              item.value = item.file.name
            } else {
              if (item.advancedParams && item.advancedParams.import) {
                item.advancedParams.import.forEach((param) => {
                  switch (param.target) {
                    case FORM_ITEMS.USE_RADIO_BUTTON:
                      if (param.invert) {
                        item.radioValue = data[param.apiKey] ? 0 : 1
                      } else {
                        item.radioValue = data[param.apiKey] ? 1 : 0
                      }
                      break
                    default:
                      item.subItems.forEach((subItem) => {
                        if (subItem.radioValueGroup === item.radioValue) {
                          subItem.value = data[param.apiKey]
                        }
                      })
                  }
                })
              } else {
                if (
                  item.subItems &&
                  item.formType !== FORM_ITEMS.TYPES.SELECT
                ) {
                  item.subItems.forEach((subItem) => {
                    if (item.importLocked !== true) {
                      Object.assign(
                        subItem,
                        this.importDataToFormMixin(subItem, data, item),
                      )
                    }
                  })
                } else {
                  let newData = this.getObjectTargetMixin(item, data)[
                    item.apiObjectKeyNames[0]
                  ]
                  if (
                    item.checked !== undefined &&
                    newData !== null &&
                    item.subItems === undefined &&
                    !item.toggle
                  ) {
                    item.checked = Boolean(newData)
                  } else {
                    item.value = newData
                    if (typeof item.value === FORM_ITEMS.INPUT_TYPE.NUMBER) {
                      item.value = String(item.value)
                    }
                  }
                  switch (item.formType) {
                    case FORM_ITEMS.TYPES.SELECT:
                      if (item.subItems && item.options) {
                        let isDefaultOption = false
                        let foundOptionalInput = false
                        item.options.forEach((option) => {
                          if (option.value === item.value) {
                            isDefaultOption = true
                          } else if (
                            option.value === FORM_ITEMS.SELECTION_SUB_ITEM
                          ) {
                            foundOptionalInput = true
                          }
                        })

                        if (
                          isDefaultOption === false &&
                          foundOptionalInput === true &&
                          item.value
                        ) {
                          item.subItems[0].value = item.value
                          item.value = FORM_ITEMS.SELECTION_SUB_ITEM
                          item.subSelectionActive = true
                        }
                      }
                      break
                    case FORM_ITEMS.TYPES.DATE:
                      if (item.value) {
                        item.value = this.convertToDateMixin(item.value.date)
                      }
                      break
                  }
                }
              }

              if (item.value && item.checked === false) {
                item.checked = true
              }

              // Set right value for basic checkbox objects
              if (
                item.formType === FORM_ITEMS.TYPES.CHECKBOX &&
                !item.subItems
              ) {
                item.checked = Boolean(data[item.apiObjectKeyNames[0]])
              }

              // Make sure that the parent only gets marked as checked if one of its children have data
              if (item.checked !== undefined && item.subItems) {
                let isParentChecked = false
                item.apiObjectKeyNames.forEach((apiKey) => {
                  if (data[apiKey]) {
                    isParentChecked = true
                  }
                })
                item.checked = isParentChecked
              }

              if (!item.value && item.value !== 0) {
                item.value = ''
              } else if (parentItem && item.radioValueGroup !== undefined) {
                parentItem.radioValue = item.radioValueGroup
                if (item.lockParentAfterImport === true) {
                  parentItem.importLocked = true
                }
              }

              //Adjust for null values if the item contains a default import rule
              if (!item.value && item.value !== 0 && item.importPlaceholder) {
                item.value = item.importPlaceholder
              }
            }
        }
      }
      return item
    },
  },
}
