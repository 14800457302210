import { RFQ_ACTIONS } from '@/constants/rfqActions'
import { ADDRESS_ACTIONS } from '@/constants/addressActions'
import { USER_ACTIONS } from '@/constants/userActions'

function generateActionStatus() {
  let actionStatus = {}
  Object.values(RFQ_ACTIONS).forEach((action) => {
    actionStatus[action.VALUE] = false
  })
  Object.values(ADDRESS_ACTIONS).forEach((action) => {
    actionStatus[action] = false
  })
  Object.values(USER_ACTIONS).forEach((action) => {
    actionStatus[action] = false
  })
  return actionStatus
}

export const ACTION_STATUS = generateActionStatus()
