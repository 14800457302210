import { IMPORT_EXPORT } from '@/constants/importExport'
import capitalize from 'lodash/capitalize'
import { NOT_SPECIFIED } from '@/constants/constants'

export default {
  methods: {
    adjustAllObjectDataMixin(
      object,
      parseType = IMPORT_EXPORT.PARSE_TYPE.DOUBLE,
    ) {
      Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'object' && object[key] !== null) {
          this.adjustAllObjectDataMixin(object[key])
        } else {
          switch (parseType) {
            case IMPORT_EXPORT.PARSE_TYPE.DOUBLE:
              object[key] = parseFloat(object[key])
              break
            default:
              object[key] = parseInt(object[key])
          }
        }
      })
    },
    convertTextMixins(text, isCurrency = false) {
      if (isCurrency) {
        let currencies = this.$store.getters.getCurrencies
        let currencyText = 'Missing currency Data'
        currencies.every((currency) => {
          if (text === currency.id) {
            currencyText = `${currency.translated.shortName} ${currency.symbol}`
            return false
          }
          return true
        })
        return currencyText
      } else {
        if (text === '') {
          return capitalize(NOT_SPECIFIED)
        } else {
          switch (String(text).toLowerCase()) {
            case 'true':
              return 'Yes'
            case 'false':
              return 'No'
          }
        }
      }
      return text
    },
  },
}
