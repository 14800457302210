import { createStore } from 'vuex'
import router from '@/router'
import {
  LOCAL_STORAGE_ACTION_STATUS,
  LOCAL_STORAGE_AUTH_DATA,
  LOCAL_STORAGE_PERMISSION_GROUP,
  LOCK_STATUS,
  PLACEHOLDER,
  RFQ_EDITOR_MODAL_EVENTS,
  SIDEBAR_BREAKPOINT,
} from '@/constants/constants'
import { ROUTER_DATA } from '@/constants/routerData'
import { AXIOS } from '@/constants/axios'
import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'
import { USER_STATUS } from '@/constants/userStatus'
import { AXIOS_STATUS } from '@/constants/axiosStatus'
import { STATES } from '@/constants/states'
import { MODALS_STATUS } from '@/constants/modals'
import { ACTION_STATUS } from '@/constants/actionStatus'
import { API_OBJECT_KEY_NAMES } from '@/constants/apiObjectKeyNames'
let controller = new AbortController()

function getActivePermissionGroupName(state, groupId) {
  if (state.permissionGroups) {
    for (const [key, value] of Object.entries(state.permissionGroups)) {
      if (value === groupId) {
        return key
      }
    }
  }
  return PERMISSION_GROUPS.NO_PERMISSION.VALUE
}

function restoreSuperAdminSession() {
  let localStorageAuth = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_AUTH_DATA),
  )
  localStorageAuth.contextToken = localStorageAuth.superAdminContextToken
  delete localStorageAuth.superAdminContextToken
  localStorage.setItem(
    LOCAL_STORAGE_AUTH_DATA,
    JSON.stringify(localStorageAuth),
  )
  location.reload()
}

function logout(state) {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_DATA)
  localStorage.removeItem(LOCAL_STORAGE_PERMISSION_GROUP)
  localStorage.removeItem(LOCAL_STORAGE_ACTION_STATUS)
  controller.abort()
  controller = new AbortController()
  state.axiosData.config.signal = controller.signal
  state.axiosData.isPreloading = false
  state.axiosData.isProcessing = false
  state.contextToken = ''
  state.currentUserData = null
  state.activeUserStatus = null
  state.activeRFQID = null
  state.userRoles = null
  state.activeInternalRFQID = null
  state.routerDestinations = null
  state.isLogoutInProgress = false
  state.showRFQEditorModal = false
  state.rfqEditorModalType = RFQ_EDITOR_MODAL_EVENTS.CANCEL_EDITOR
  state.forceRFQEditorExit = false
  state.modifiedActiveRFQ = false
  state.loginStatus = 0
  state.recoveryMailStatus = 0
  state.changedPassword = false
  state.previousRouteName = null
  state.wipStatusToasts = []
  state.activeRFQListingData = {}
  state.activeOfferListingData = {}
  state.activeRFQCopyData = null
  state.currencies = []
  state.activeCommunicationID = null
  state.activeItemLockedStatus = 0
  state.isGlobalOverviewActive = false
  state.activeOffersHashmap = new Map()
  state.redirectComponentEvent = null
  state.preventNextRfqStatusUpdate = false
  state.activePermissionGroup = PERMISSION_GROUPS.NO_PERMISSION.VALUE
  router.push({
    name: ROUTER_DATA[PERMISSION_GROUPS.NO_PERMISSION.VALUE].LOGIN.NAME,
  })
}

export default createStore({
  state: {
    sidebarVisible: '',
    contextToken: '',
    completedRegistration: false,
    currentUserData: null,
    userRoles: null,
    countries: null,
    currencies: [],
    salutations: null,
    activeRFQID: null,
    activeOfferID: null,
    activeRFQStatus: null,
    activeUserStatus: null,
    routerDestinations: null,
    isLogoutInProgress: false,
    showRFQEditorModal: false,
    rfqEditorModalType: RFQ_EDITOR_MODAL_EVENTS.CANCEL_EDITOR,
    forceRFQEditorExit: false,
    modifiedActiveRFQ: false,
    triggeredAutoLogin: false,
    previousRouteName: null,
    lockData: null,
    errorLog: null,
    // recoveryMailStatus:
    // 0 Disabled
    // 1 Sent Mail request
    // 2 Reset Password
    // 3 Set contact Password
    recoveryMailStatus: 0,
    // loginStatus:
    // 0 = Not logged in
    // 1 = Login process through Button
    // 2 = Login process through local storage
    // 3 = Completed login with user data
    loginStatus: 0,
    activePermissionGroup: PERMISSION_GROUPS.NO_PERMISSION.VALUE,
    permissionGroups: null,
    sidebarData: null,
    activeItemLockedStatus: 0,
    axiosData: {
      config: {
        headers: {
          [AXIOS.HEADERS.KEYS.ACCESS_KEY]: AXIOS.HEADERS.VALUE.ACCESS_KEY,
        },
        signal: controller.signal,
      },
      errorMessage: '',
      isProcessing: false,
      isPreloading: false,
      isUpdatingListing: false,
      isUpdatingSidebar: false,
      isUpdatingDashboard: false,
      refreshTargets: {},
    },
    activeCommunicationID: null,
    modalTypeStatus: cloneDeep(MODALS_STATUS),
    activeRFQListingData: {},
    activeOfferListingData: {},
    activeEntityData: null,
    activeRFQCopyData: null,
    lastPromiseAllCommand: null,
    isGlobalOverviewActive: false,
    actionStatus: cloneDeep(ACTION_STATUS),
    activeOffersHashmap: new Map(),
    redirectComponentEvent: null,
    preventNextRfqStatusUpdate: false,
    forceScrollSpyAnchorUpdate: null,
  },
  mutations: {
    clearRefreshTargets(state, dataGroup) {
      state.axiosData.refreshTargets[dataGroup] = null
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    updateSidebarVisible(state, payload) {
      if (window.innerWidth < SIDEBAR_BREAKPOINT) {
        state.sidebarVisible = payload.value
      }
    },
    changedPassword(state, contextToken) {
      if (state.loginStatus === 3) {
        state.contextToken = contextToken
        state.activeUserStatus = USER_STATUS.CHANGED_PASSWORD
        let localStorageAuth = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_AUTH_DATA),
        )
        state.axiosData.config.headers[AXIOS.HEADERS.KEYS.CONTEXT_TOKEN] =
          contextToken
        localStorageAuth.contextToken = state.contextToken
        localStorage.setItem(
          LOCAL_STORAGE_AUTH_DATA,
          JSON.stringify(localStorageAuth),
        )
        router.push({
          name: ROUTER_DATA[state.activePermissionGroup].HOME.NAME,
        })
      } else {
        logout(state)
      }
    },
    triggerLogin(state, payload) {
      state.completedRegistration = false
      state.contextToken = payload.authData.contextToken
      state.sidebarVisible = true
      state.activeRFQID = null
      state.activeOfferID = null
      state.activeUserStatus = null
      state.activeInternalRFQID = null
      state.routerDestinations = null
      state.isLogoutInProgress = false
      state.showRFQEditorModal = false
      state.rfqEditorModalType = RFQ_EDITOR_MODAL_EVENTS.CANCEL_EDITOR
      state.forceRFQEditorExit = false
      state.modifiedActiveRFQ = false
      state.previousRouteName = null
      state.isGlobalOverviewActive = false
      state.redirectComponentEvent = null
      state.lastPromiseAllCommand = null
      state.preventNextRfqStatusUpdate = false
      state.activeItemLockedStatus = LOCK_STATUS.UNLOCKED
      state.lockData = null
      if (payload.authData.isAutoLogin) {
        state.loginStatus = 2
      } else {
        state.loginStatus = 1
      }
      state.activeRFQListingData = {}
      state.activeOfferListingData = {}
      state.activeEntityData = null
      state.activeRFQCopyData = null
      state.activeOffersHashmap = new Map()
    },
    triggerLogout(state) {
      let newCommand = cloneDeep(AXIOS.COMMANDS)
      newCommand.config = cloneDeep(state.axiosData.config)
      newCommand.path = AXIOS.API.POST.LOGOUT_USER
      axios
        .post(newCommand.path, {}, newCommand.config)
        .then(() => {
          logout(state)
        })
        .catch(() => {
          logout(state)
        })
    },
    completedRegistration(state) {
      state.completedRegistration = true
    },
    saveLoginToLocalStorage(state, authData) {
      localStorage.setItem(LOCAL_STORAGE_AUTH_DATA, JSON.stringify(authData))
    },
    updateCurrentUserData(state, currentUserData) {
      state.currentUserData = currentUserData
      state.activePermissionGroup = getActivePermissionGroupName(
        state,
        currentUserData.groupId,
      )
    },
    updateCountries(state, countriesData) {
      state.countries = countriesData
    },
    updateCurrencies(state, currenciesData) {
      state.currencies = currenciesData
    },
    updateSalutations(state, salutationsData) {
      state.salutations = salutationsData
    },
    updateActiveRFQIDs(state, rfq) {
      state.activeRFQID = rfq.id
      state.activeInternalRFQID = rfq.internalId
    },
    unsetActiveRFQID(state) {
      state.activeRFQID = null
      state.activeInternalRFQID = null
    },
    updateActiveOfferID(state, offer) {
      state.activeOfferID = offer.id
    },
    unsetActiveOfferID(state) {
      state.activeOfferID = null
    },
    clearActiveOffersHashmap(state) {
      state.activeOffersHashmap = new Map()
    },
    updateActiveOffersHashmap(state, activeOfferData) {
      state.activeOffersHashmap.set(activeOfferData.id, {
        id: activeOfferData.id,
        supplier: activeOfferData.supplier,
        validUntil: activeOfferData.validUntil,
        supplierId: activeOfferData.supplierId,
        state: activeOfferData.state,
        comments: activeOfferData.comments,
        [API_OBJECT_KEY_NAMES.OFFER.CONTRACT.CONTRACT_FILE]:
          activeOfferData[API_OBJECT_KEY_NAMES.OFFER.CONTRACT.CONTRACT_FILE],
        [API_OBJECT_KEY_NAMES.OFFER.CONTRACT.ADDENDUM_FILE]:
          activeOfferData[API_OBJECT_KEY_NAMES.OFFER.CONTRACT.ADDENDUM_FILE],
      })
    },
    setActiveEntityData(state, entity) {
      state.activeEntityData = entity
    },
    setActiveRFQListingData(state, rfq) {
      state.activeRFQListingData = rfq
    },
    setActiveOfferListingData(state, offer) {
      state.activeOfferListingData = offer
    },
    setActiveRFQCopyData(state, rfq) {
      state.activeRFQCopyData = rfq
    },
    preventNextRfqStatusUpdate(state) {
      state.preventNextRfqStatusUpdate = true
    },
    activeRFQStatus(state, status) {
      if (state.preventNextRfqStatusUpdate) {
        state.preventNextRfqStatusUpdate = false
      } else {
        state.activeRFQStatus = status
      }
    },
    activeUserStatus(state, status) {
      state.activeUserStatus = status
    },
    saveCurrentRouterDestinations(state, to, from, next) {
      state.routerDestinations = {
        to,
        from,
        next,
      }
    },
    setAvailablePermissionGroups(state, permissionGroups) {
      state.permissionGroups = permissionGroups
    },
    showRFQEditorModal(state, isLogout = false) {
      state.isLogoutInProgress = isLogout
      state.showRFQEditorModal = true
      state.rfqEditorModalType = RFQ_EDITOR_MODAL_EVENTS.CANCEL_EDITOR
    },
    showRFQEditorDeleteModal(state, isLogout = false) {
      state.isLogoutInProgress = isLogout
      state.showRFQEditorModal = true
      state.rfqEditorModalType = RFQ_EDITOR_MODAL_EVENTS.DELETE_DRAFT
    },
    hideRFQEditorModal(state) {
      state.showRFQEditorModal = false
    },
    toggleForceRFQEditorExit(state, status) {
      state.forceRFQEditorExit = status
    },
    toggleModifiedActiveRFQ(state, status) {
      state.modifiedActiveRFQ = status
    },
    setSidebarData(state, data) {
      state.sidebarData = data
      state.axiosData.isUpdatingSidebar = false
    },
    abortAxiosRequests(state) {
      if (state.loginStatus !== 1 && state.loginStatus !== 2) {
        controller.abort()
        controller = new AbortController()
        state.axiosData.config.signal = controller.signal
        state.axiosData.isPreloading = false
        state.axiosData.isProcessing = false
      }
    },
    updateLockedStatus(state, data) {
      // 0: Unlocked
      // 1: Locked by current user
      // 2: Locked by different user
      let lockedBy = data[API_OBJECT_KEY_NAMES.RFQ.LOCKED_BY]
      if (lockedBy) {
        state.activeItemLockedStatus =
          lockedBy === state.currentUserData.id
            ? LOCK_STATUS.LOCKED_BY_CURRENT_USER
            : LOCK_STATUS.LOCKED_BY_DIFFERENT_USER
      } else {
        state.activeItemLockedStatus = LOCK_STATUS.UNLOCKED
      }
    },
    setAxiosDataPreloadStatus(state, status) {
      state.axiosData.isPreloading = status
    },
    setAxiosDataProcessingStatus(state, status) {
      state.axiosData.isProcessing = status
    },
    setLastPromiseAllCommand(state, command) {
      state.lastPromiseAllCommand = command
    },
    setAxiosDataErrorMessage(state, message) {
      state.axiosData.errorMessage = message
    },
    setAxiosDataRefreshTargets(state, data) {
      let targetData = []
      data.targets.forEach((target) => {
        targetData.push({
          name: target,
          isPreloadDependency: data.isPreloadDependency,
        })
      })
      state.axiosData.refreshTargets[data.dataGroup] = targetData
    },
    setUserRoles(state, rolesResponse) {
      state.userRoles = rolesResponse.roles
    },
    setLoginStatus(state, status) {
      state.loginStatus = status
    },
    setRFQStatusMessage(state, command) {
      if (command.rfqStatus) {
        state.activeRFQStatus = command.rfqStatus
      }
    },
    setRFQLockedStatus(state, data) {
      if (data) {
        state.activeRFQStatus = STATES.LOCKED.VALUE
        state.lockData = data
      }
    },
    finishLoginProcess(state) {
      state.permissionGroups = state.sidebarData['permission-groups']
      state.activePermissionGroup = getActivePermissionGroupName(
        state,
        state.currentUserData.groupId,
      )
      if (
        state.activePermissionGroup !== PERMISSION_GROUPS.NO_PERMISSION.VALUE
      ) {
        switch (state.loginStatus) {
          case 1:
            router.push({
              name: ROUTER_DATA[state.activePermissionGroup].HOME.NAME,
            })
            state.loginStatus = 3
            break
          case 2:
            state.loginStatus = 3
        }
      } else {
        logout(state)
      }
      localStorage.setItem(
        LOCAL_STORAGE_PERMISSION_GROUP,
        JSON.stringify(state.activePermissionGroup),
      )
    },
    setRecoveryMailStatus: (state, statusCode) => {
      state.recoveryMailStatus = statusCode
    },
    setTriggerAutoLogin(state) {
      state.triggeredAutoLogin = true
    },
    addWIPStatusToast(state) {
      state.wipStatusToasts.push({
        timestamp: new Date().toUTCString(),
        content: AXIOS_STATUS.NOT_IMPLEMENTED,
      })
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies
    },
    emptyWIPStatusToasts(state) {
      state.wipStatusToasts = []
    },
    setPreviousRouteName: (state, name) => {
      state.previousRouteName = name
    },
    setModalTypeStatusVisible: (state, target) => {
      state.modalTypeStatus[target] = true
    },
    setModalTypeStatusInVisible: (state, target) => {
      state.modalTypeStatus[target] = false
    },
    setAxiosUpdateListingStatus: (state, listingStatus) => {
      state.axiosData.isUpdatingListing = listingStatus
    },
    setAxiosUpdateSidebarStatus: (state, sidebarStatus) => {
      state.axiosData.isUpdatingSidebar = sidebarStatus
    },
    setAxiosUpdateDashboardStatus: (state, dashboardStatus) => {
      state.axiosData.isUpdatingDashboard = dashboardStatus
    },
    toggleGlobalOverviewStatus: (state) => {
      state.isGlobalOverviewActive = !state.isGlobalOverviewActive
    },
    resetStates: (state) => {
      state.modalTypeStatus = cloneDeep(MODALS_STATUS)
      state.actionStatus = cloneDeep(ACTION_STATUS)
      state.axiosData.isUpdatingListing = false
      state.axiosData.isUpdatingSidebar = false
      state.axiosData.isUpdatingDashboard = false
      state.activeRFQListingData = {}
      state.activeOfferListingData = {}
    },
    activateActionStatus: (state, action) => {
      state.actionStatus[action] = true
    },
    deactivateActionStatus: (state, action) => {
      state.actionStatus[action] = false
    },
    setActiveCommunicationID: (state, id) => {
      state.activeCommunicationID = id
    },
    setErrorLog: (state, errorLog) => {
      state.errorLog = errorLog
    },
    forceAxiosActionStatusEvent: (state, action) => {
      state.axiosData.isProcessing = true
      state.actionStatus[action] = true
    },
    switchToSupportUser(state, data) {
      let localStorageAuth = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_AUTH_DATA),
      )
      localStorageAuth.contextToken = data.contextToken
      localStorageAuth.superAdminContextToken = state.contextToken
      localStorage.setItem(
        LOCAL_STORAGE_AUTH_DATA,
        JSON.stringify(localStorageAuth),
      )
      location.reload()
    },
    switchToSuperAdmin(state) {
      let newCommand = cloneDeep(AXIOS.COMMANDS)
      newCommand.config = cloneDeep(state.axiosData.config)
      newCommand.path = AXIOS.API.POST.LOGOUT_USER
      axios
        .post(newCommand.path, {}, newCommand.config)
        .then(() => {
          restoreSuperAdminSession()
        })
        .catch(() => {
          restoreSuperAdminSession()
        })
    },
    setRedirectComponentEvent(state, event) {
      state.redirectComponentEvent = event
    },
    resetRfQActionStatus() {
      let actionStatus = localStorage.getItem(LOCAL_STORAGE_ACTION_STATUS)
      if (actionStatus) {
        localStorage.removeItem(LOCAL_STORAGE_ACTION_STATUS)
      }
    },
    setRfQActionStatus(state, status) {
      localStorage.setItem(LOCAL_STORAGE_ACTION_STATUS, status)
    },
    forceScrollSpyAnchorUpdate(state) {
      state.forceScrollSpyAnchorUpdate = new Date()
    },
  },
  actions: {
    unlockRFQ({ commit, getters }) {
      return new Promise((resolve) => {
        if (getters.getActiveRFQID || getters.getActiveOfferID) {
          let newCommand = cloneDeep(AXIOS.COMMANDS)
          newCommand.config = cloneDeep(getters.getAxiosData.config)
          switch (getters.getActivePermissionGroup) {
            case PERMISSION_GROUPS.SUPPLIER.VALUE:
              newCommand.path = AXIOS.API.POST.OFFER_UNLOCK.replace(
                PLACEHOLDER.ID.OFFER,
                getters.getActiveOfferID,
              )
              break
            default:
              newCommand.path = AXIOS.API.POST.RFQ_UNLOCK.replace(
                PLACEHOLDER.ID.RFQ,
                getters.getActiveRFQID,
              )
          }
          axios
            .post(newCommand.path, {}, newCommand.config)
            .then((response) => {
              if (response.status === 403) {
                commit('triggerLogout')
              }
              resolve()
            })
            .catch((error) => {
              commit('setAxiosDataErrorMessage', error.toJSON())
              resolve()
            })
        } else {
          resolve()
        }
      })
    },
    deleteRfQAndExit({ commit, getters }) {
      return new Promise((resolve) => {
        if (getters.getActiveRFQID) {
          let newCommand = cloneDeep(AXIOS.COMMANDS)
          newCommand.config = cloneDeep(getters.getAxiosData.config)
          newCommand.path = AXIOS.API.POST.RFQ_DELETE.replace(
            PLACEHOLDER.ID.RFQ,
            getters.getActiveRFQID,
          )
          newCommand.rfqStatus = STATES.DELETED.VALUE
          axios
            .post(newCommand.path, {}, newCommand.config)
            .then((response) => {
              if (response.status === 403) {
                commit('triggerLogout')
              }
              commit('setRFQStatusMessage', newCommand)
              resolve()
            })
            .catch((error) => {
              commit('setAxiosDataErrorMessage', error.toJSON())
              resolve()
            })
        } else {
          resolve()
        }
      })
    },
    tryAutoLogin({ commit, getters }) {
      return new Promise((resolve) => {
        let authData
        if (
          localStorage.getItem(LOCAL_STORAGE_AUTH_DATA) &&
          !getters.triggeredAutoLogin
        ) {
          authData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_DATA))
          authData.isAutoLogin = true
          let newCommand = cloneDeep(AXIOS.COMMANDS)
          newCommand.config = cloneDeep(getters.getAxiosData.config)
          newCommand.config.headers[AXIOS.HEADERS.KEYS.CONTEXT_TOKEN] =
            authData.contextToken
          newCommand.path = AXIOS.API.GET.SIDEBAR

          axios
            .get(newCommand.path, newCommand.config)
            .then((response) => {
              if (response.status === 200) {
                commit(
                  'setAvailablePermissionGroups',
                  response.data['permission-groups'],
                )
                newCommand.path = AXIOS.API.GET.FETCH_CURRENT_USER
                axios
                  .post(newCommand.path, newCommand.body, newCommand.config)
                  .then((response) => {
                    if (response.status === 200) {
                      commit('updateCurrentUserData', response.data)
                      commit('triggerLogin', { authData })
                      commit('setLoginStatus', 3)
                    } else {
                      commit('triggerLogout')
                    }
                    commit('setTriggerAutoLogin')
                    resolve()
                  })
                  .catch(() => {
                    commit('triggerLogout')
                    commit('setTriggerAutoLogin')
                    resolve()
                  })
              } else {
                commit('triggerLogout')
                commit('setTriggerAutoLogin')
                resolve()
              }
            })
            .catch(() => {
              commit('triggerLogout')
              commit('setTriggerAutoLogin')
              resolve()
            })
        } else {
          commit('setTriggerAutoLogin')
          resolve()
        }
      })
    },
    exitEditorAndDelete({ dispatch, getters }) {
      dispatch('deleteRfQAndExit').then(() => {
        if (getters.currentRouterDestinations) {
          router.push({ name: getters.currentRouterDestinations.to.name })
        } else {
          router.push({
            name: ROUTER_DATA[PERMISSION_GROUPS.CUSTOMER.VALUE].DASHBOARD.NAME,
          })
        }
      })
    },
    exitEditor({ commit, getters, dispatch }) {
      if (getters.isLogoutInProgress) {
        dispatch('unlockRFQ').then(() => {
          commit('triggerLogout')
        })
      } else {
        router.push({ name: getters.currentRouterDestinations.to.name })
      }
    },
    prepareRouteChange({ commit }) {
      commit('abortAxiosRequests')
    },
  },
  getters: {
    getActionStatus: (state) => {
      return state.actionStatus
    },
    getWIPStatusToasts: (state) => {
      return state.wipStatusToasts
    },
    isLoggedIn: (state) => {
      return state.loginStatus === 3
    },
    completedRegistration: (state) => {
      return state.completedRegistration
    },
    currentUserData: (state) => {
      return state.currentUserData
    },
    countries: (state) => {
      return state.countries
    },
    currencies: (state) => {
      return state.currencies
    },
    salutations: (state) => {
      return state.salutations
    },
    activeRFQStatus: (state) => {
      return state.activeRFQStatus
    },
    rfqActionStatus: () => {
      let actionStatus = localStorage.getItem(LOCAL_STORAGE_ACTION_STATUS)
      return actionStatus ? actionStatus : null
    },
    lockData: (state) => {
      return state.lockData
    },
    isRFQEditorModalVisible: (state) => {
      return state.showRFQEditorModal
    },
    isLogoutInProgress: (state) => {
      return state.isLogoutInProgress
    },
    currentRouterDestinations: (state) => {
      return state.routerDestinations
    },
    isForcedRFQEditorExit: (state) => {
      return state.forceRFQEditorExit
    },
    isActiveRFQModified: (state) => {
      return state.modifiedActiveRFQ
    },
    getActiveRFQID: (state) => {
      return state.activeRFQID
    },
    getActiveOfferID: (state) => {
      return state.activeOfferID
    },
    getActiveRFQListingData: (state) => {
      return state.activeRFQListingData
    },
    getActiveOfferListingData: (state) => {
      return state.activeOfferListingData
    },
    getActiveEntityData: (state) => {
      return state.activeEntityData
    },
    getActiveRFQCopyData: (state) => {
      return state.activeRFQCopyData
    },
    getActiveUserStatus: (state) => {
      return state.activeUserStatus
    },
    getActiveInternalRFQID: (state) => {
      return state.activeInternalRFQID
    },
    sidebarData: (state) => {
      return state.sidebarData
    },
    getAxiosData: (state) => {
      return state.axiosData
    },
    getActivePermissionGroup: (state) => {
      return state.activePermissionGroup
    },
    getLoginStatus: (state) => {
      return state.loginStatus
    },
    isLoginInProgress: (state) => {
      return state.loginStatus === 1 || state.loginStatus === 2
    },
    userRoles: (state) => {
      return state.userRoles
    },
    isDebtor: (state) => {
      if (state.currentUserData && state.currentUserData.customFields) {
        return state.currentUserData.customFields.isAdmin
      }
      return false
    },
    getRecoveryMailStatus: (state) => {
      return state.recoveryMailStatus
    },
    triggeredAutoLogin: (state) => {
      return state.triggeredAutoLogin
    },
    getRoleTranslation(state) {
      const currentRole = state.activePermissionGroup
      switch (currentRole) {
        case PERMISSION_GROUPS.CUSTOMER.VALUE:
          return PERMISSION_GROUPS.CUSTOMER.TRANSLATION
        case PERMISSION_GROUPS.SUPPLIER.VALUE:
          return PERMISSION_GROUPS.SUPPLIER.TRANSLATION
        case PERMISSION_GROUPS.SUPPLIER_EXPERT.VALUE:
          return PERMISSION_GROUPS.SUPPLIER_EXPERT.TRANSLATION
        case PERMISSION_GROUPS.SALES_EXPERT.VALUE:
          return PERMISSION_GROUPS.SALES_EXPERT.TRANSLATION
        case PERMISSION_GROUPS.SALES_MANAGER.VALUE:
          return PERMISSION_GROUPS.SALES_MANAGER.TRANSLATION
        case PERMISSION_GROUPS.SUPPLIER_MANAGER.VALUE:
          return PERMISSION_GROUPS.SUPPLIER_MANAGER.TRANSLATION
        case PERMISSION_GROUPS.NO_PERMISSION.VALUE:
          return PERMISSION_GROUPS.NO_PERMISSION.TRANSLATION
      }
    },
    getAdminRoleID: (state) => {
      let adminRoleID = ''
      state.userRoles.forEach((role) => {
        if (role.name === USER_STATUS.ADMIN) {
          adminRoleID = role.id
        }
      })
      return adminRoleID
    },
    getPreviousRouteName: (state) => {
      return state.previousRouteName
    },
    getModalTypeStatus: (state) => {
      return state.modalTypeStatus
    },
    getCurrencies: (state) => {
      return state.currencies
    },
    getActiveCommunicationID(state) {
      return state.activeCommunicationID
    },
    getAxiosPreloadStatus(state) {
      return state.axiosData.isPreloading
    },
    getActiveItemLockedStatus(state) {
      return state.activeItemLockedStatus
    },
    gotPdfParserAccess: (state) => {
      return state.currentUserData.customFields.elio_pdf_creation_allowed
    },
    getGlobalOverviewStatus(state) {
      return state.isGlobalOverviewActive
    },
    getErrorLog: (state) => {
      return state.errorLog
    },
    getSuperAdminContextToken() {
      let localStorageAuth = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_AUTH_DATA),
      )
      return localStorageAuth.superAdminContextToken
    },
    getRFQEditorModalEventType(state) {
      return state.rfqEditorModalType
    },
    getForcedScrollSpyAnchorUpdate(state) {
      return state.forceScrollSpyAnchorUpdate
    },
    getSingleActiveOffer(state) {
      if (state.activeOffersHashmap.entries()) {
        return state.activeOffersHashmap.entries().next().value[1]
      } else {
        return null
      }
    },
    getActiveOffersHashmap(state) {
      return state.activeOffersHashmap
    },
    getRedirectComponentEvent(state) {
      return state.redirectComponentEvent
    },
    getLastPromiseAllCommand(state) {
      return state.lastPromiseAllCommand
    },
  },
})
