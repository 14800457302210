import { KEYS_REGISTRATION } from '@/constants/objectKeys/registration'
import { DEPENDENCIES } from '@/constants/constants'
import { KEYS_GENERAL } from '@/constants/objectKeys/general'

export default {
  data() {
    return {
      [DEPENDENCIES.SALUTATIONS]: [],
    }
  },
  watch: {
    [DEPENDENCIES.SALUTATIONS]() {
      this.createSalutationsOptionsMixin()
    },
  },
  methods: {
    insertSalutationsOptionsMixin: function () {
      if (
        this.$data[KEYS_GENERAL.FORM] &&
        this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.SALUTATIONS] &&
        this.$data[KEYS_GENERAL.SALUTATIONS_OPTIONS]
      ) {
        this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.SALUTATIONS].options =
          this.$data[KEYS_GENERAL.SALUTATIONS_OPTIONS]
      }
    },
    getUserSalutationKeyMixin: function () {
      const salutations = this.$store.getters.salutations
      const salutationId = this.$store.getters.currentUserData.salutationId
      let displayName = ''
      salutations.elements.forEach((salutation) => {
        if (salutationId === salutation.id) {
          displayName = salutation.salutationKey
        }
      })
      return displayName
    },
    createSalutationsOptionsMixin: function () {
      if (
        this.$data[KEYS_GENERAL.FORM] &&
        this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.SALUTATIONS] &&
        this.$store.getters.salutations !== null
      ) {
        let salutations = this.$store.getters.salutations
        let options = [
          {
            label:
              this.$data[KEYS_GENERAL.FORM][KEYS_REGISTRATION.SALUTATIONS]
                .messages.placeholder,
          },
        ]

        this.$data[KEYS_GENERAL.SALUTATIONS_OPTIONS] = options.concat(
          salutations.elements.map((salutation) => {
            return {
              label: salutation.displayName,
              value: salutation.salutationKey,
            }
          }),
        )
      }
    },
  },
}
