const DASHBOARD = 'Dashboard'
const RFQ_DETAILS = 'RfQDetails'
const RFQ_CONTRACT_DETAILS = 'RfQContractDetails'
const CUSTOMER = 'Customer'
const SUPPLIER = 'Supplier'
const SUPPLIER_EXPERT = 'SupplierExpert'
const SUPPLIER_MANAGER = 'SupplierManager'
const SALES_EXPERT = 'SalesExpert'
const SALES_MANAGER = 'SalesManager'
const SUPER_ADMIN = 'SuperAdmin'

export const NAVIGATION_ANCHORS = {
  MISC: {
    RFQ_NEW: 'RfQNew',
    RFQ_EDIT: 'RfQEdit',
    ADDRESS_MANAGEMENT: 'AddressManagement',
    USER_MANAGEMENT: 'UserManagement',
    CHANGE_PASSWORD: 'ChangePassword',
    NOTIFICATIONS: 'Notifications',
    SETTINGS: 'Settings',
    PROFILE: 'Profile',
    DEBUG_BADGES: 'DebugBadges',
  },
  SUPPORT: {
    IMPRINT: 'Imprint',
    DATA_PROTECTION_NOTICE: 'DataProtectionNotice',
    GENERAL_TERMS: 'GeneralTerms',
    TERMS_OF_USE: 'TermsOfUse',
  },
  SUPER_ADMIN: {
    GROUP: SUPER_ADMIN,
    DASHBOARD: `${SUPER_ADMIN}${DASHBOARD}`,
  },
  CUSTOMER: {
    GROUP: CUSTOMER,
    DASHBOARD: `${CUSTOMER}${DASHBOARD}`,
    RFQ_DETAILS: RFQ_DETAILS,
    MY_RFQS: {
      CATEGORY: 'MyRfQs',
      NEW_RFQ: 'NewRfQ',
      OVERVIEW: 'RfQOverview',
      IN_PROGRESS: 'InProgress',
      IN_CLARIFICATION: 'InClarification',
      QUOTE_RECEIVED: 'QuoteReceived',
      DRAFTS: 'Drafts',
    },
    MRB: {
      CATEGORY: 'MRB',
      OVERVIEW: 'MRBOverview',
      ORDERS: 'Orders',
      CONTRACTS: 'Contracts',
      SERIES_DELIVERY: 'SeriesDelivery',
    },
    ARCHIVE: 'RfQArchive',
  },
  SUPPLIER: {
    GROUP: SUPPLIER,
    DASHBOARD: `${SUPPLIER}${DASHBOARD}`,
    RFQ_INBOX: 'RfQInbox',
    RUNNING_RFQ: 'RunningRfQs',
    CONTRACTS: 'Contracts',
    ARCHIVE: 'Archive',
    RFQ_DETAILS: RFQ_DETAILS,
    RFQ_CONTRACT_DETAILS: RFQ_CONTRACT_DETAILS,
  },
  SUPPLIER_EXPERT: {
    GROUP: SUPPLIER_EXPERT,
    DASHBOARD: `${SUPPLIER_EXPERT}${DASHBOARD}`,
    NEW_RFQS: 'NewRfQs',
    RFQ_DETAILS: RFQ_DETAILS,
    GLOBAL_OVERVIEW: 'GlobalOverview',
    MY_RFQS: {
      CATEGORY: 'MyRfQs',
      OVERVIEW: 'Overview',
      RFQ_PREPARATION: 'RfQPreparation',
      RUNNING_RFQ: 'RunningRfQ',
      OFFER_COLLECTION: 'OfferCollection',
    },
    MY_CONTRACTS: {
      CATEGORY: 'MyContracts',
      SOURCING_CONTRACTING: 'SourcingContracting',
      CONTRACTS: 'Contracts',
      ARCHIVE: 'Archive',
    },
  },
  SUPPLIER_MANAGER: {
    GROUP: SUPPLIER_MANAGER,
    DASHBOARD: `${SUPPLIER_MANAGER}${DASHBOARD}`,
    RFQ_DETAILS: RFQ_DETAILS,
  },
  SALES_EXPERT: {
    GROUP: SALES_EXPERT,
    DASHBOARD: `${SALES_EXPERT}${DASHBOARD}`,
    RFQ_DETAILS: RFQ_DETAILS,
    NEW_RFQS: 'NewRfQs',
    MY_RFQS: {
      CATEGORY: 'MyRfQs',
      OVERVIEW: 'Overview',
      IN_VALIDATION: 'InValidation',
      QUOTE_PREPARATION: 'QuotePreparation',
    },
    MY_CONTRACTS: {
      CATEGORY: 'MyContracts',
      OVERVIEW: 'ContractsOverview',
      CONTRACT_PREPARATION: 'ContractPreparation',
      IN_CONTRACT: 'InContract',
    },
    ARCHIVE: 'Archive',
  },
  SALES_MANAGER: {
    GROUP: SALES_MANAGER,
    DASHBOARD: `${SALES_MANAGER}${DASHBOARD}`,
    RFQ_DETAILS: RFQ_DETAILS,
  },
  NO_PERMISSION: {
    LOGIN: 'Login',
    REGISTER: 'Register',
    REGISTER_CONFIRM: 'RegisterConfirm',
    RESET_PASSWORD: 'ResetPassword',
    CREATE_NEW_PASSWORD: 'CreateNewPassword',
  },
}
