import { PERMISSION_GROUPS } from '@/constants/objectKeys/permissionGroups'
import { BADGE_STYLE, BADGE_TRANSLATION } from '@/constants/badges'

export const USER_STATUS = {
  ACTIVE: {
    TRANSLATION: BADGE_TRANSLATION.ACTIVE,
    STYLE: BADGE_STYLE.DARK_GREEN,
  },
  INACTIVE: {
    TRANSLATION: BADGE_TRANSLATION.INACTIVE,
    STYLE: BADGE_STYLE.DARK_RED,
  },
  ADDRESS_MANAGEMENT_ACCESS: [PERMISSION_GROUPS.CUSTOMER.VALUE],
  USER_MANAGEMENT_ACCESS: [
    PERMISSION_GROUPS.CUSTOMER.VALUE,
    PERMISSION_GROUPS.SUPPLIER.VALUE,
  ],
  CHANGED_PASSWORD: 'changedPassword',
  ADMIN: 'Admin',
  CUSTOMER: 'Customer',
  ROOT: 'root',
  INVALID_HASH: 'invalidHash',
}
